import { useStateValue } from "src/context/StateProvider";
import { igboGamesData, yorubaGamesData } from "./gamesData";
import { useState, useEffect } from "react";
import GameSelectionCard from "./GameSelectionCard";
import EmptyLessonList from "src/components/Shared/EmptyLessonList";

export default function ScratchGames() {
  const [{ userLessonDetails, userLessonProgress }] = useStateValue();


  const [gameData, setGameData] = useState<any>(null);

  const getGamesDataByProgress = (gameData: any[]) => {
    if (!userLessonProgress) return;
    // if gameData.title is equal to any userLessonProgress[i].unordered.title, then filter out theát game data
    const filteredData = gameData.filter((game: any) => {
      return userLessonProgress.unordered.some((progress: any) => {

        return game.topic === progress.title;
      });
    });
    return filteredData;
  };

  useEffect(() => {
    if (!userLessonDetails || !userLessonDetails.language) return;
    if (userLessonDetails.language === "Yoruba") {
      setGameData(getGamesDataByProgress(yorubaGamesData));
    }
    if (userLessonDetails.language === "Igbo") {
      setGameData(getGamesDataByProgress(igboGamesData));
    }
  }, [userLessonDetails, userLessonProgress]);


  if (!gameData) return null;

  return (
    <>
      <h1 className="text-center mb-4 text-primary text-2xl font-semibold">
        Scratch Games
      </h1>
      <div className="flex gap-3 flex-wrap justify-center">
        {gameData.length === 0 && (
          <EmptyLessonList
            message="No games available, take some more lessons to unlock games."
            hideButton={true}
          />
        )}
        {gameData.length > 0 &&
          gameData.map((game: any) => (
            <div key={game.id}>
              <GameSelectionCard game={game} />
            </div>
          ))}
      </div>
    </>
  );
}

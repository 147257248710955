import axios from "axios";
import { timezones } from "src/static-data/Timezones";

export async function getClientIpCountry(): Promise<any | undefined[]> {
  try {
    let response = await axios.get("https://ipapi.co/json/");
    let countryCode = response.data.country;
    if (!countryCode) {
      const timeZone = await getClientTimeZone();
      countryCode = await getTimezoneCountryCode(timeZone);
    }

    return { countryCode, ipInfo: response.data };
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getSubjects(): Promise<any> {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/subjects-options`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
    return error;
  }
}

export async function getLanguages(): Promise<any> {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/language-options`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
    return error;
  }
}

export async function getClientIp(): Promise<any | undefined[]> {
  try {
    const response = await fetch("https://ipinfo.io/json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

// This function returns the time zone of the client's machine
export async function getClientTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export async function getTimezoneCountryCode(timezone: string) {
  const timezoneObj = timezones.find((t) => t.IANAtimezone === timezone);
  return timezoneObj ? timezoneObj.countryCode : "";
}

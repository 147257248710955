import React from "react";

interface WordBankProps {
  words: any[];
  onDragStart: (word: any) => void;
  onDrop: (word: any) => void; // New prop to handle drop
  onWordClick: (word: any) => void;
}

const WordBank: React.FC<WordBankProps> = ({
  words,
  onDragStart,
  onDrop,
  onWordClick,
}) => {
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault(); // Necessary to allow dropping
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("application/json");
    const word = JSON.parse(data);
    onDrop(word); // Call the prop function here
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, word: any) => {
    const data = JSON.stringify(word);
    e.dataTransfer.setData("application/json", data);
    e.dataTransfer.setData("source", "wordbank"); // New data type to indicate the source
  };

  return (
    <div
      className="word-bank flex flex-wrap justify-center items-center p-4 min-h-[10rem] bg-pale-purple-bg"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {words.map((word, index) => (
        <div
          key={index}
          draggable
          onDragStart={(e) => handleDragStart(e, word)}
          className="draggable-word-wordbank"
          onClick={() => onWordClick(word)}
        >
          {word.word}
        </div>
      ))}
    </div>
  );
};

export default WordBank;

import { useEffect, useState, useContext } from "react";

import Logo from "src/components/Shared/Header/Logo";
import { Context as DirectPayContext } from "src/context/DirectPayContext";

import { PaymentApi } from "src/api/payment.api";
import { useStateValue } from "src/context/StateProvider";
import { getClientCurrency } from "src/services/currency";
import { IClientCurrencyData } from "src/interfaces/currency";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { routes } from "src/Routes";
import LoadingScreen from "src/components/LottieAnimations/SpinningRose";
import PaymentPlanCard from "src/components/DirectToPay/PaymentPlans/PaymentCard";
import { sortLp } from "src/utils/helper/sortLearningPackage";

import { TrustpilotProvider } from "src/context/TrustpilotContext";
import ChooseAPlan from "./ChooseAPlan";
import Counter from "src/components/Shared/Counter";

export interface IPaymentPlans {
  halfLesson?: IPackageType[];
  fullLesson?: IPackageType[];
}

export type IPackageType = {
  _id: string;
  type: string;
  perLessonRateUSD: string;
  perLessonRateCAD: string;
  perLessonRateGBP: string;
  plan: string;
  discount: number;
  transactionAmountUSD: number;
  transactionAmountGBP: number;
  transactionAmountCAD: number;
  numberOfLessons: number;
  lessonType: string;
};

export default function PaymentPlanB() {
  const {
    state: { isLoading, onBoarding, proficiencyLevel, lessonFrequency },
    actions: { updateOnboarding, setLessonFrequency },
  } = useContext(DirectPayContext);
  const [{ clientIpCountry, user }] = useStateValue();
  const [result, setResult] = useState<IPackageType[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<IPackageType>();
  const [active, setActive] = useState("full");
  const [paymentPackages, setPaymentPackages] = useState<any>(null);
  const [currency, setCurrency] = useState<IClientCurrencyData | null>(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (clientIpCountry) {
      setCurrency(getClientCurrency(clientIpCountry));
    }
  }, [clientIpCountry]);
  const handleToggle = () => {
    setActive((prev) => (prev === "full" ? "half" : "full"));
  };

  useEffect(() => {
    const getPackages = async () => {
      const response = await PaymentApi.getLearningPackages({
        numberOfUsers: onBoarding?.onboarding?.numberOfUsers?.[0] || 1,
      });
      setPaymentPackages(response.data);
    };
    getPackages();
  }, []);

  useEffect(() => {
    switch (proficiencyLevel) {
      case "Intermediate or Above":
        let intermediate =
          active === "full"
            ? paymentPackages?.intermediate["fullLesson"]
            : paymentPackages?.intermediate["halfLesson"];
        setResult(intermediate?.sort(sortLp));
        break;

      default:
        let beginner =
          active === "full"
            ? paymentPackages?.beginner["fullLesson"]
            : paymentPackages?.beginner["halfLesson"];
        setResult(beginner?.sort(sortLp));
        break;
    }
  }, [active, lessonFrequency, paymentPackages, proficiencyLevel]);

  const selectPaymentBundle = async (lessonPackage: IPackageType) => {
    setSelectedPlan(lessonPackage);

    await updateOnboarding(
      {
        learningPackage: {
          discount: lessonPackage.discount,
          lessonType: lessonPackage?.lessonType,
          type: lessonPackage.type,
          currencyCode: !["USD", "GBP", "CAD"].includes(currency!.currencyCode)
            ? "USD"
            : currency!.currencyCode,
          perLessonRate:
            currency?.currencyCode === "USD"
              ? lessonPackage.perLessonRateUSD
              : currency?.currencyCode === "GBP"
              ? lessonPackage.perLessonRateGBP
              : currency?.currencyCode === "CAD"
              ? lessonPackage.perLessonRateCAD
              : lessonPackage.perLessonRateUSD,
          plan: lessonPackage.plan,
          numberOfLessons: lessonPackage.numberOfLessons,
          transactionAmount:
            currency?.currencyCode === "USD"
              ? lessonPackage.transactionAmountUSD
              : currency?.currencyCode === "GBP"
              ? lessonPackage.transactionAmountGBP
              : currency?.currencyCode === "CAD"
              ? lessonPackage.transactionAmountCAD
              : lessonPackage.transactionAmountUSD,
        },
      },
      onBoarding?.onboarding?._id
    );

    navigate(
      `${routes.CHECKOUT.url}?learningPackage=${lessonPackage._id}&userId=${user?._id}`
    );
  };
  if (!user) {
    return <Navigate to={routes.LOGIN.url} />;
  }

  return (
    <TrustpilotProvider>
      <div>
        {!paymentPackages || !result || isLoading ? (
          <LoadingScreen />
        ) : (
          <div className="text-primary relative min-h-screen h-full">
            <div className="p-8   bg-pale-purple-bg">
              <Logo />
              {/* <div className="hidden md:block max-w-xs">
              <button
                onClick={() => setisPayment(false)}
                className="gray-button-2 flex  items-center space-x-2  mt-7"
              >
                <FaChevronLeft className="w-4 h-4" />
                <span>Back to Tutor</span>
              </button>
            </div> */}
            </div>
            <ChooseAPlan />
            <div className={`tab-content mt-2`} id="lesson-frequency">
              <h3 className="text-secondary font-semibold text-center text-xl mb-2">
                Number of lessons per week
              </h3>
              <Counter val={lessonFrequency} setValue={setLessonFrequency} />
            </div>
            <div className="container mt-20 px-3 md:px-8 pb-12 left-8 right-8 ">
              <div className="flex justify-center">
                <div>
                  <div className="flex gap-1 justify-center md:justify-start md:gap-6 mx-auto pb-8">
                    <div className="flex gap-2 items-center cursor-pointer">
                      <p
                        className={`
                        ${
                          active === "half" ? "text-secondary" : "text-primary"
                        } font-semibold text-lg md:font-bold md:text-xl  transition-transform duration-300 ease-in-out`}
                      >
                        25 minutes
                      </p>
                    </div>
                    <div className="flex items-center justify-center space-x-2">
                      <div
                        className={`${
                          active === "full" ? "bg-secondary" : "bg-secondary"
                        } relative w-[59px] h-7 md:h-8 rounded-full transition-all duration-300 py-[2px]`}
                        onClick={handleToggle}
                      >
                        <div
                          className={`${
                            active === "full"
                              ? "translate-x-full"
                              : "translate-x-0"
                          } absolute left-0 w-1/2 h-[24px] md:h-[28px] bg-white rounded-full transition-transform duration-300 ease-in-out`}
                        ></div>
                      </div>
                    </div>
                    <div className="flex gap-2 items-center cursor-pointer">
                      <p
                        className={`
                        ${
                          active === "full" ? "text-secondary" : "text-primary"
                        } font-semibold text-lg md:font-bold md:text-xl  transition-transform duration-300 ease-in-out`}
                      >
                        55 minutes
                      </p>
                    </div>
                  </div>

                  <div
                    className={`items-start grid grid-cols-1 sm:grid-cols-2 ${
                      !pathname.includes("dashboard") && "lg:grid-cols-3"
                    } xl:grid-cols-3  gap-7  md:gap-5  `}
                  >
                    {result
                      ?.filter(
                        (p) => p?.type !== "Beginner & Elementary Package"
                      )
                      ?.map((payment: any, index: any) => (
                        <div key={index}>
                          <PaymentPlanCard
                            paymentPlans={payment}
                            selectPaymentBundle={selectPaymentBundle}
                            selected={selectedPlan?.type === payment.type}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </TrustpilotProvider>
  );
}

export const sortLp = (a: any, b: any) => {
  // Check if type is "two taster lessons" (case-insensitive)
  const aIsOneLessonType = a?.type?.toLowerCase() === "two taster lessons";
  const bIsOneLessonType = b?.type?.toLowerCase() === "two taster lessons";

  if (aIsOneLessonType && !bIsOneLessonType) return -1;
  if (!aIsOneLessonType && bIsOneLessonType) return 1;

  // If neither or both are "1 lesson" type, maintain original order
  return 0;
};

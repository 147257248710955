import { useEffect, useState } from "react";
import { NotificationApi } from "src/api/notification.api";
import { useStateValue } from "src/context/StateProvider";
import { IGeneralNotification } from "src/interfaces/notification";
import ContactIcon from "./ContactIcon";
import FavoriteIcon from "./FavoriteIcon";
import MessageIcon from "./MessageIcon";
import NotificationIcon from "./NotificationIcon";
import ProfileIcon from "./ProfileIcon";
import { LessonApi } from "src/api/lesson.api";
import { NavLink, useParams } from "react-router-dom";
import { routes } from "src/Routes";
import { StudentApi } from "src/api/student.api";

export interface ICowryPouch {
  _id: string;
  userId: string;
  balance: number;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export default function UserProfile() {
  const [{ user }, dispatch] = useStateValue();
  const params = useParams();
  const [cowryPouch, setcowryPouch] = useState<ICowryPouch>();

  const getCowryPouch = async () => {
    const response = await StudentApi.getCowryPouch(user.cowryPouchId);
    await dispatch({
      type: "SET_USER_COWRY_POUCH",
      userCowryPouch: response.data,
    });
    setcowryPouch(response.data);
  };
  useEffect(() => {
    getCowryPouch();
  }, []);
  useEffect(() => {
    dispatch({
      type: "SET_COMPONENT_LOADING_STATUS",
      isComponentLoading: true,
    });
    if (user) {
      const getNotifications = async () => {
        const response = await NotificationApi.getUreadNotifications(user._id);
        let feedbackResponse;
        let lessonDetails;
        let newRewards;
        let lessonProgress;
        if (user.type === "student") {
          lessonProgress = await StudentApi.getLessonProgress(user?._id);
          feedbackResponse = await LessonApi.getStudentLessonFeedback(user._id);
          lessonDetails = await LessonApi.getStudentLessonDetails(user._id);
          newRewards = await LessonApi.getStudentNewRewards(user._id);
        } else if (user.type === "tutor") {
          if (!params.studentId) {
            feedbackResponse = await LessonApi.getTutorLessonFeedback(user._id);
          }
        }

        await dispatch({
          type: "SET_LESSON_FEEDBACKS",
          lessonFeedbacks: feedbackResponse.lessons,
        });
        await dispatch({
          type: "SET_USER_UNREAD_FEEDBACKS",
          unreadFeedbacks: feedbackResponse.unread,
        });
        await dispatch({
          type: "SET_USER_UNREAD_NOTIFICATIONS",
          unreadNotifications: response?.notifications,
        });
        await dispatch({
          type: "SET_USER_UNREAD_MESSAGES",
          unreadMessages: response?.messages,
        });
        await dispatch({
          type: "SET_USER_LESSON_DETAILS",
          userLessonDetails: lessonDetails,
        });
        await dispatch({
          type: "SET_USER_NEW_REWARDS",
          userNewRewards: newRewards?.data,
        });
        await dispatch({
          type: "SET_USER_LESSON_PROGRESS",
          userLessonProgress: lessonProgress?.data,
        });
        dispatch({
          type: "SET_COMPONENT_LOADING_STATUS",
          isComponentLoading: false,
        });
      };
      getNotifications();
    }
  }, []);

  return (
    <>
      {/* <div>
        <FavoriteIcon />
      </div> */}
      <div className="hidden lg:block">
        <NavLink to={routes.FAQS.url}>
          <p
            className={
              "cursor-pointer text-primary text-xl lg:text-sm font-semibold hover:text-secondary"
            }
          >
            {routes.FAQS.label}
          </p>
        </NavLink>
      </div>
      <div className="hidden lg:block">
        <ContactIcon colorVariant="dark" />
      </div>
      {user?.type !== "teacher_referrer" && (
        <div className="hidden lg:block">
          <MessageIcon />
        </div>
      )}
      <div className="hidden lg:block">
        <NotificationIcon />
      </div>
      {user?.type === "student" && (
        <div className="flex gap-1 bg-primary py-1 px-[6px] rounded-lg items-center">
          <img
            className="w-6 h-6 rounded-full object-cover"
            src="https://storage.googleapis.com/topset-app-assets/cowry_icon.png"
            alt="cowry"
          />
          <p className="text-white  font-bold text-lg text-center">
            {cowryPouch?.balance ? cowryPouch.balance : 0}
          </p>
        </div>
      )}

      <div className="pl-3">
        <ProfileIcon />
      </div>
    </>
  );
}

import { IncrementCounterProps } from "./types";
import { TiMinus, TiPlus } from "react-icons/ti";

export default function IncrementCounter({
  value,
  minValue,
  maxValue,
  onIncrement,
  onDecrement,
}: IncrementCounterProps) {
  return (
    <div className="flex">
      <div
        className={
          value !== minValue
            ? "rounded-tl-md rounded-bl-md bg-gray-100 text-primary hover:text-secondary px-2 py-3 cursor-pointer"
            : "rounded-tl-md rounded-bl-md bg-gray-100 text-gray-300 px-2 py-3 cursor-not-allowed"
        }
        onClick={() => onDecrement(value)}
      >
        <TiMinus />
      </div>
      <h3 className="text-primary py-2 px-6 bg-gray-50 font-semibold">
        {value}
      </h3>
      <div
        className={
          value !== maxValue
            ? "rounded-tr-md rounded-br-md bg-gray-100 text-primary hover:text-secondary px-2 py-3 cursor-pointer"
            : "rounded-tr-md rounded-br-md bg-gray-100 text-gray-300 px-2 py-3 cursor-not-allowed"
        }
        onClick={() => onIncrement(value)}
      >
        <TiPlus />
      </div>
    </div>
  );
}

import { CheckCircleIcon } from "@heroicons/react/solid";
import RevolutCheckout, { RevolutCheckoutInstance } from "@revolut/checkout";
import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import Lottie from "lottie-react";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import ReactModal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { LessonApi } from "src/api/lesson.api";
import { PaymentApi } from "src/api/payment.api";
import * as loadingAnimation from "src/assets/animations/rose-loader.json";
import CardInfo from "src/components/Checkout/CardInfo";
import CheckoutSummary, {
  ICheckoutSummary,
} from "src/components/Checkout/CheckoutSummary";
import InvalidPaymentLink from "src/components/Checkout/InvalidPaymentLink";
import PreppingDashboard from "src/components/LottieAnimations/PreppingDashboard";
import CircularProgress from "src/components/Shared/CircularProgress";
import { BillingType, Instance, PaymentGateways } from "src/interfaces/payment";
import Header from "src/layouts/DashboardHeader";
import { routes } from "src/Routes";
import { capitalizeFirstCharacter } from "src/utils/helper/core";
import Swal from "sweetalert2";
const logo = `${process.env.REACT_APP_ASSET_CDN}/logo.webp`;
const mobile_logo = `${process.env.REACT_APP_ASSET_CDN}/logo.webp`;

// ORDER = INVOICE | SUBSCRIPTION

interface CheckoutState {
  loading: boolean;
  gateway?: PaymentGateways;
  billingType?: BillingType;
  id?: string | null;
  invalid?: boolean;
  paymentDoc?: any;
  checkoutSummary?: ICheckoutSummary;
  chargeInProgress: boolean;
  bookingInProgress: boolean;
}

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const elements = useElements();
  const stripe = useStripe();
  const browserRoute = useMemo(() => new URL(window.location.href), []);
  const [checkout, setCheckout] = useState<CheckoutState>({
    loading: true,
    chargeInProgress: false,
    invalid: false,
    bookingInProgress: false,
  });
  const [stripeGateway, setStripeGateway] = useState({
    open: false,
    success: false,
    loading: false,
    paymentRequest: undefined,
  });
  const [revolutGateway, setRevolutGateway] = useState({
    iframeLoading: false,
  });
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );
  const { invoiceId, subscriptionId } = useMemo(
    () => ({
      invoiceId: searchParams.get("invoiceId"),
      subscriptionId: searchParams.get("subscriptionId"),
    }),
    [searchParams]
  );
  const cardElementOptions = {
    hidePostalCode: true,
  };

  function toggleInvalid() {
    setCheckout((p) => ({ ...p, loading: false, invalid: true }));
  }

  const getPaymentHistory = useCallback(async () => {
    try {
      if (checkout.id) {
        let response;

        response = await PaymentApi.getPayment({
          [`${checkout.billingType?.toLowerCase()}Id`]: checkout.id,
        });

        if (!response || !response?.data) toggleInvalid();
        else return response.data;
      }
    } catch (error) {
      toggleInvalid();
      console.log("caught error: ", error);
    }
  }, [checkout.id, checkout.billingType]);

  const updatePaymentHistory = useCallback(
    async (state: string) => {
      const paymentApi = new PaymentApi();

      console.log("0001", checkout?.paymentDoc?._id);

      try {
        const res = await paymentApi.updatePayment({
          paymentId: checkout?.paymentDoc?._id,
          state,
        });

        console.log("update payment", res);
      } catch (error) {
        console.log("caught error: ", error);
      }
    },
    [checkout?.paymentDoc?._id]
  );

  useEffect(() => {
    let id: string | null, billingType: BillingType;

    if (invoiceId) {
      id = invoiceId;
      billingType = BillingType.INVOICE;
    } else {
      id = subscriptionId;
      billingType = BillingType.SUBSCRIPTION;
    }

    setCheckout((p) => ({ ...p, id, billingType }));
  }, [invoiceId, subscriptionId]);

  useEffect(() => {
    if (checkout.id && checkout.billingType) init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout.id, checkout.billingType]);

  async function init(): Promise<void> {
    const history = await getPaymentHistory();

    const recentPayment = history?.[0];

    if (recentPayment?.state === "COMPLETE") {
      toggleInvalid();
    } else {
      let firstName: string,
        lastName: string,
        numberOfLessons: number,
        lessonFrequency: number,
        pkgRate: string,
        subType: string,
        subject: string,
        selectedTutor: any,
        lessonStartDate: string,
        pkgAmount: number,
        perLessonRate: string;

      if (checkout.billingType === BillingType.INVOICE) {
        const _inv = recentPayment?.invoice;

        firstName = _inv?.firstname;
        lastName = _inv?.lastname;

        if (_inv?.customPackage) {
          numberOfLessons = _inv?.customPackage?.numberOfLessons;
          pkgRate = _inv?.customPackage?.pkgRate;
          subType = _inv?.customPackage?.type;
          pkgAmount = _inv?.customPackage?.pkgAmount;
        }
      } else {
        const _sub = recentPayment?.subscription;

        firstName = _sub?.user?.firstname;
        lastName = _sub?.user?.lastname;
        numberOfLessons = _sub?.numberOfLessons;
        lessonFrequency = _sub?.lessonFrequency;
        pkgRate = _sub?.pkgRate;
        subType = _sub?.type;
        pkgAmount = _sub?.pkgAmount;
        subject = capitalizeFirstCharacter(_sub?.user?.onboarding?.subject);
        selectedTutor = {
          firstName: _sub?.user?.onboarding?.selectedTutor?.firstName,
          lastName: _sub?.user?.onboarding?.selectedTutor?.lastName?.charAt(0),
        };
        perLessonRate =
          _sub?.learningPackage?.[
            `perLessonRate${recentPayment?.amount?.currency.toUpperCase()}`
          ];
        lessonStartDate = moment
          .utc(_sub?.user?.onboarding?.lessonSchedule?.[0]?.utc)
          .local()
          .format("dddd MMMM Do YYYY, hh:mm A");
      }

      setCheckout((p) => ({
        ...p,
        loading: false,
        paymentDoc: recentPayment,
        checkoutSummary: {
          amount: recentPayment?.amount?.value,
          currency: recentPayment?.amount?.currency,
          firstName,
          lastName,
          promo: recentPayment?.[`${p.billingType?.toLowerCase()}`]?.promo,
          subjects:
            recentPayment?.[`${p.billingType?.toLowerCase()}`]?.subjects,
          tutors: recentPayment?.[`${p.billingType?.toLowerCase()}`]?.tutors,
          pkgRate,
          subType,
          pkgAmount,
          selectedTutor,
          numberOfLessons,
          type: subType,
          subject,
          lessonFrequency,
          perLessonRate,
          lessonStartDate,
        },
      }));
    }
  }

  async function onPaymentSuccess() {
    setCheckout((p) => ({ ...p, chargeInProgress: false, loading: true }));

    await updatePaymentHistory("COMPLETE");

    setCheckout((p) => ({ ...p, loading: false, bookingInProgress: true }));

    const _bill =
      checkout?.paymentDoc?.[`${checkout.billingType?.toLowerCase()}Id`];

    try {
      const res = await LessonApi.autoBookLesson({
        [`${checkout.billingType?.toLowerCase()}Id`]: _bill,
      });

      console.log("auto book lesson", res);
    } catch (error) {
      console.log("caught error: ", error);
    } finally {
      Swal.fire({
        color: "#341A64",
        title: `<strong>Thanks for your payment</strong>`,
        html: `<p>Your lessons will be booked shortly.</p><p>Feel free to <a href="mailto:info@topset.app" style="text-decoration: underline;" >reach out to us</a> for any further assistance</p>`,
        confirmButtonText: `OK`,
        confirmButtonColor: "#D33479",
      }).then(() => {
        setCheckout((p) => ({ ...p, bookingInProgress: false }));
        navigate(
          `${routes.CHECKOUT_THANK_YOU.url}?amount=${
            checkout?.paymentDoc?.amount?.value as string
          }&currency=${checkout?.paymentDoc?.amount?.currency}`
        );
      });
    }
  }

  async function initiateStripePayment(event: any) {
    setStripeGateway((p) => ({ ...p, loading: true }));
    event.preventDefault();

    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      const { error, paymentMethod } = await stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: `${checkout?.paymentDoc?.invoice?.firstname} ${checkout?.paymentDoc?.invoice?.lastname}`,
          },
        })
        .then(async function (result) {
          setStripeGateway((p) => ({ ...p, success: true, loading: false }));

          setTimeout(async () => {
            setStripeGateway((p) => ({ ...p, open: false }));
            await onPaymentSuccess();
          }, 500);

          return result;
        });

      if (error) console.error(error);
      else {
        const { clientSecret } = await fetch("/create-payment-intent").then(
          (res) => res.json()
        );

        const { error: confirmError, paymentIntent } =
          await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethod.id,
          });

        if (confirmError) console.error(confirmError);
        else console.log("Payment successful:", paymentIntent);
      }
    } else {
      // Handle the case when cardElement is null.
    }
  }

  function openCardPaymentPopup() {
    switch (checkout?.paymentDoc?.gateway.name) {
      case "STRIPE":
        setStripeGateway((p) => ({ ...p, open: true }));
        break;

      default:
        RevolutCheckout(
          checkout?.paymentDoc.publicId || checkout?.paymentDoc.token,
          `${
            browserRoute.host === "learn.topset.app" ||
            browserRoute.host === "topset.app"
              ? "prod"
              : "sandbox"
          }`
        ).then((instance: Instance | RevolutCheckoutInstance) => {
          setTimeout(
            () =>
              setRevolutGateway({ ...revolutGateway, iframeLoading: false }),
            2000
          );

          instance.payWithPopup({
            savePaymentMethodFor: "merchant",
            onError(message) {
              console.log("error", message);
              updatePaymentHistory("FAILED");
            },
            onCancel() {
              instance.destroy();
            },
            async onSuccess() {
              onPaymentSuccess();
            },
          });
        });
    }
  }

  if (checkout?.bookingInProgress) {
    return <PreppingDashboard />;
  }

  if (checkout?.invalid) {
    return (
      <div className="h-screen pb-[30vh] grid place-items-center">
        <InvalidPaymentLink />
      </div>
    );
  }

  return (
    <>
      {checkout?.loading ? (
        <div className="h-screen grid place-items-center">
          <Lottie
            animationData={loadingAnimation}
            style={{
              height: 150,
              width: 150,
            }}
            className="absolute hidden sm:block top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </div>
      ) : (
        <>
          <div className="text-primary h-screen">
            <div className="hidden sm:block relative  ">
              <Header />
            </div>

            <Helmet>
              <title>TopSet | Checkout</title>
              <meta name="description" content="Online tutoring" />
              <meta name="keywords" content="Online tutoring" />
            </Helmet>

            <div className="relative z-10 pb-8 px-8">
              <div className="flex items-center sm:flex-row flex-col justify-between p-6 sm:hidden  ">
                <div
                  className="lg:flex items-center h-10
					cursor-pointer hidden"
                  onClick={() => (window.location.href = "http://topset.app/")}
                >
                  <img src={logo} alt="" width={190} height={150} />
                </div>

                <div
                  className="relative flex items-center h-10
					cursor-pointer my-auto lg:hidden"
                  onClick={() => (window.location.href = "http://topset.app/")}
                >
                  <img src={mobile_logo} alt="" width={160} />
                </div>

                <div className="flex items-center  sm:hidden ">
                  <p>
                    Powered by{" "}
                    <span className="font-bold text-xl text-black">
                      Revolut
                    </span>
                  </p>
                </div>
              </div>

              <section className="text-center z-10 container md:mt-12 px-8 mt-4 left-8 right-8 ">
                <h1 className="font-bold md:text-2xl text-2xl leading-10 max-w-3xl mx-auto ">
                  Almost There! Secure Your Spot to Mastery
                </h1>

                <p className="font-medium text-secondary text-lg md:text-lg leading-7 mt-4 max-w-4xl mx-auto ">
                  Complete your checkout and embark on a journey to fluency with
                  our expert tutors
                </p>
              </section>

              <div
                className={`flex gap-5 container  mt-10 relative z-10 sm:flex-row flex-col ${
                  checkout.loading && "hidden"
                } `}
              >
                {checkout?.checkoutSummary && (
                  <CheckoutSummary details={checkout?.checkoutSummary} />
                )}

                <CardInfo
                  openCardPaymentPopup={openCardPaymentPopup}
                  chargeInProgress={checkout.chargeInProgress}
                  setChargeInProgress={(c: boolean) =>
                    setCheckout((p) => ({ ...p, chargeInProgress: c }))
                  }
                />
              </div>
            </div>

            <div className="sm:flex items-center fixed bottom-0   hidden  w-full justify-end px-6  pb-2 ">
              {checkout?.paymentDoc?.gateway?.name && (
                <p>
                  Powered by{" "}
                  <span className="font-bold text-xl">
                    {checkout?.paymentDoc?.gateway?.name}
                  </span>
                </p>
              )}
            </div>
          </div>

          <ReactModal
            isOpen={stripeGateway.open}
            style={{
              overlay: {
                zIndex: 1000,
                backgroundColor: "rgba(0, 0, 0, 0.43)",
              },
              content: {
                border: "none",
                background: "transparent",
                height: "93vh",
                width: "96vw",
                margin: 0,
                padding: 0,
                display: "grid",
                placeItems: "center",
              },
            }}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
          >
            <div
              style={{
                border: "none",
                background: "white",
                height: 400,
                width: 500,
                borderRadius: "1.5rem",
                padding: 20,
              }}
            >
              <form
                onSubmit={initiateStripePayment}
                className="grid gap-3 relative"
                style={{
                  height: "100%",
                  gridTemplateRows: "auto auto 1fr auto",
                }}
              >
                <div
                  className="h-[40px] w-[40px] absolute cursor-pointer"
                  style={{
                    right: 0,
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                  }}
                  onClick={() => {
                    setStripeGateway((p) => ({ ...p, open: false }));
                    setCheckout((p) => ({ ...p, chargeInProgress: false }));
                  }}
                >
                  <svg
                    fill="none"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>

                  {/* <XIcon
								onClick={() => setStripeGateway(false)}
								className="inline-flex h-10 w-10 text-black lg:hidden cursor-pointer"
							/> */}
                </div>

                <div className="font-bold text-[#191c1f] text-[20px]">
                  Topset Education
                </div>

                <p className="text-[#191c1f] text-[14px]">
                  <span>{checkout?.paymentDoc?.amount?.currency}</span>
                  <span>{checkout?.paymentDoc?.amount?.value}</span>
                </p>

                {stripeGateway.paymentRequest && (
                  <div className="grid align-items-center">
                    <PaymentRequestButtonElement
                      options={{ paymentRequest: stripeGateway.paymentRequest }}
                    />
                  </div>
                )}

                <div className="grid align-items-center">
                  <div className="p-[16px] pt-[20px] rounded-[1rem] bg-[#ebebf2] h-[56px] grid align-items-center">
                    <CardElement options={cardElementOptions} />
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={!stripe}
                  className="bg-[#4F55F1] h-[52px] text-white rounded-[9999px]"
                  style={{
                    boxShadow: "rgb(79 85 241 / 0.45) 0 0.1875rem 0.5rem 0",
                  }}
                >
                  {stripeGateway.success ? (
                    <div className="grid place-items-center">
                      <CheckCircleIcon className="h-10 w-10" />
                    </div>
                  ) : stripeGateway.loading ? (
                    <div className="grid place-items-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    <>
                      <span>
                        Pay{" "}
                        {Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: checkout?.paymentDoc?.amount?.currency,
                        }).format(
                          parseInt?.(
                            checkout?.paymentDoc?.amount?.value as string
                          ) as number
                        )}
                      </span>
                    </>
                  )}
                </button>
              </form>
            </div>
          </ReactModal>
        </>
      )}
    </>
  );
};

export default Checkout;

import { AiFillStar } from "react-icons/ai";
import TestimonialCard from "./TestimonialCard";
import { YorubaMobileTestimonials } from "src/static-data/Testimonials";

function Testimonials() {
  return (
    <div className="container mt-14 ">
      <div className="px-4 flex gap-2">
        <AiFillStar size={35} color="#FEC108" />
        <AiFillStar size={35} color="#FEC108" />
        <AiFillStar size={35} color="#FEC108" />
        <AiFillStar size={35} color="#FEC108" />
        <AiFillStar size={35} color="#FEC108" />
      </div>
      <div className="text-primary font-bold text-2xl mt-4 px-4 flex gap-2">
        Highly rated and <br /> verified tutors
      </div>
      <div className="text-secondary font-medium text-xl mt-3 px-4 flex gap-2">
        Read what so many of our customers love about us
      </div>
      {/* Cards */}
      <div
        className="my-8 flex space-x-7 overflow-scroll 
          p-3 ml-3 scrollbar-hide scrollbar-hide"
      >
        {/* Single card */}
        {YorubaMobileTestimonials.map((testimonial, index) => (
          <TestimonialCard
            key={index}
            imageSrc={testimonial.imageSrc}
            name={testimonial.name}
            bio={testimonial.bio}
            review={testimonial.review}
            reviewBy={testimonial.reviewBy}
            // when={testimonial.when}
          />
        ))}
      </div>
    </div>
  );
}

export default Testimonials;

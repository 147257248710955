import { ITestimonials } from "src/components/DirectToPay/VideoPlayer";

export const yorubaTestimonials : ITestimonials[] = [
    {
      name: "Bibianna's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/subtitle_bibiana.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/bibiana_thumbnail.png`
    },
    {
      name: "Daniel's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/subtitle_daniel.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/lopez_thumbnail.png`
    },
    {
      name: "Emmanuel's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/subtitle_emmanuel.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/eajayi_thumbnail.png`
    },
    {
      name: "Jeanette's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/subtitle_Jaenette.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/jeanette_thumbnail.png`
    },
    {
      name: "Okunlola's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/subtitle_okunola.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/odusote_thumbnail.png`
    },
    {
      name: "Yemi's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/subtitle_yemi.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/yfatunbi_thumbnail.png`
    }
  ];
  


  export const igboTestimonials : ITestimonials[] = [
     
    {
      name: "Ebuka's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/subtitle_ebuka.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/ebuka_thumbnail.png`
    },
    {
      name: "Chinedu's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/Igbo%20-%20Chinedu%20Emenuga%202.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/chinedu2_thumbnail.png`
    },
    {
      name: "Kamsi's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/subtitle_kamsi.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/kamsi_thumbnail.png`
    },
    {
      name: "Marlene's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/subtitle_marlene.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/marlene_thumbnail.png`
    },
    {
      name: "Mrs Elizabeth and Nelson's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/subtitle_mrs_elizabeth.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/eliz_nelson_thumbnail.png`
    }
  ];
  
import React from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "src/Routes";

export default function Breadcrumb() {
  const {
    userId,
    level,
    topic,
    topicId,
    session,
    order,
    dependantId,
    dependantName,
  } = useParams();
  console.log("userId", userId);
  console.log("level", level);
  console.log("topic", topic);
  console.log("session", session);
  console.log("order", order);
  console.log("dependantId", dependantId);
  console.log("dependantName", dependantName);
  const navigate = useNavigate();

  const handleTopicClick = () => {
    navigate(`${routes.STUDENT_DASHBOARD_LEARN.url}/${userId}`);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <section className="text-primary">
      <div className="flex gap-5">
        <button
          onClick={handleBackClick}
          className="border rounded-[9px] p-2 self-start"
        >
          <HiOutlineArrowLeft />
        </button>
        {level ? (
          <div>
            <button
              onClick={handleTopicClick}
              className="bg-transparent  border-none text-primary-gray font-semibold text-2xl "
            >
              <span className="mr-4 ">{order?.padStart(2, "0")}</span>
              {level}
            </button>
            <span className="font-semibold text-secondary text-2xl">
              {" "}
              / {topic}
            </span>
          </div>
        ) : (
          <p className="font-semibold text-2xl">
            <span className="mr-4 text-secondary">
              {order?.padStart(2, "0")}
            </span>

            {topic}
          </p>
        )}
      </div>
    </section>
  );
}

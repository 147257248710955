import React from "react";

export default function FocusAreas({ areas }: { areas: any }) {
  const allAreas = ["Listening", "Speaking", "Reading", "Writing", "Fluency"];

  return (
    <>
      <p className="text-primary-medium py-1 text-xs font-semibold">
        Focus Areas
      </p>
      <div className="flex gap-2 flex-wrap">
        {allAreas.map((area: any) => (
          <div
            key={area}
            className={`text-xs p-1 px-2 rounded-md ${
              areas.includes(area)
                ? "font-semibold bg-secondary  text-white"
                : "text-primary-gray bg-pale-purple-bg"
            }`}
          >
            {area}
          </div>
        ))}
      </div>
    </>
  );
}

import moment from "moment-timezone";
import { deepClone } from "./DeepClone";
import { chargeSavedPaymentMethodForOneOnOneLesson } from "src/server/endpoints/payment";

import {
  categorizeTutorAvailability,
  daysFull,
} from "src/static-data/Calendar";

// seperates each day's availability in "Pre 12pm" "12 - 4pm" "4 - 7pm" "Ater 7pm"
// Sample data (currently not being used) data is geneated dynamically based on tutorDefinedAvailaibility
export const dayCategories = [
  // Sunday
  [false, false, false, false],
  // Monday
  [false, false, false, false],
  // Tuesday
  [false, false, false, false],
  // Wednesday
  [false, false, false, false],
  // Thursday
  [false, false, false, false],
  // Friday
  [false, false, false, false],
  // Saturday
  [false, false, false, false],
];

function sameDay(d1, d2) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export const syncAvailabilityWithBookings = (
  availability,
  bookings,
  weekDates,
  timeZone
) => {
  // reset availability to true for all days and slots before update

  bookings.forEach((booking) => {
    var time =
      new Date(
        new Date(booking.dateAndTime).toLocaleString("en-US", {
          timeZone: timeZone,
        })
      ).getHours() +
      ":" +
      new Date(
        new Date(booking.dateAndTime).toLocaleString("en-US", {
          timeZone: timeZone,
        })
      ).getMinutes();

    if (
      sameDay(
        weekDates[booking.dayIndex].fullDate,
        new Date(booking.dateAndTime)
      )
    ) {
      for (
        let i = timeSlotArray.indexOf(time);
        i < timeSlotArray.indexOf(time) + booking.duration * 2;
        i++
      ) {
        if (i < 0 || i > 29) {
        } else {
          // same day
          availability[booking.dayIndex][i].available = false;
          availability[booking.dayIndex][i].default = false;
        }
      }
    } else {
      for (
        let i = timeSlotArray.indexOf(time);
        i < timeSlotArray.indexOf(time) + booking.duration * 2;
        i++
      ) {
        if (i < 0 || i > 29) {
        } else {
          if (availability[booking.dayIndex][i].default) {
            availability[booking.dayIndex][i].available =
              availability[booking.dayIndex][i].available;
            availability[booking.dayIndex][i].default = false;
          }
        }
      }
    }
  });

  return availability;
};

export const tutorBookings = [
  {
    dateAndTime: new Date(
      new Date(2022, 2, 16, 16, 0, 0, 0).toLocaleString("en-US", {
        timeZone: "Asia/Bishkek",
      })
    ), // new Date(year, month, day, hours, minutes, seconds, milliseconds)
    duration: 1,
    time:
      new Date(
        new Date(2022, 2, 16, 16, 0, 0, 0).toLocaleString("en-US", {
          timeZone: "Asia/Bishkek",
        })
      ).getHours() +
      ":" +
      new Date(
        new Date(2022, 2, 16, 16, 0, 0, 0).toLocaleString("en-US", {
          timeZone: "Asia/Bishkek",
        })
      ).getMinutes(),
    dayIndex: new Date(
      new Date(2022, 2, 16, 16, 0, 0, 0).toLocaleString("en-US", {
        timeZone: "Asia/Bishkek",
      })
    ).getDay(),
    lessonType: "regular",
  },
  {
    dateAndTime: new Date(2022, 2, 12, 10, 30, 0, 0), // new Date(year, month, day, hours, minutes, seconds, milliseconds)
    duration: 2,
    time:
      new Date(2022, 2, 12, 10, 30, 0, 0).getHours() +
      ":" +
      new Date(2022, 2, 12, 10, 30, 0, 0).getMinutes(),
    dayIndex: new Date(2022, 2, 12, 10, 0, 0, 0).getDay(),
    lessonType: "regular",
  },
  {
    dateAndTime: new Date(2022, 2, 21, 19, 0, 0, 0), // new Date(year, month, day, hours, minutes, seconds, milliseconds)
    duration: 2,
    time:
      new Date(2022, 2, 21, 19, 0, 0, 0).getHours() +
      ":" +
      new Date(2022, 2, 21, 19, 30, 0, 0).getMinutes(),
    dayIndex: new Date(2022, 2, 21, 19, 0, 0, 0).getDay(),
    lessonType: "one-off",
  },
];

export const defaultAvailability = [
  //sunday
  {
    available: true,
    timeSlots: [
      {
        start: {
          label: "8:00",
          value: new Date(2022, 2, 12, 8, 0, 0, 0),
          utcValue: new Date(2022, 2, 12, 8, 0, 0, 0),
        },
        end: {
          label: "22:00",
          value: new Date(2022, 2, 12, 22, 0, 0, 0),
          utcValue: new Date(2022, 2, 12, 22, 0, 0, 0),
        },
      },
    ],
  },
  //monday
  {
    available: true,
    timeSlots: [
      {
        start: {
          label: "8:00",
          value: new Date(2022, 2, 12, 8, 0, 0, 0),
          utcValue: new Date(2022, 2, 12, 8, 0, 0, 0),
        },
        end: {
          label: "22:00",
          value: new Date(2022, 2, 12, 22, 0, 0, 0),
          utcValue: new Date(2022, 2, 12, 22, 0, 0, 0),
        },
      },
    ],
  },
  //tuesday
  {
    available: true,
    timeSlots: [
      {
        start: {
          label: "8:00",
          value: new Date(2022, 2, 12, 8, 0, 0, 0),
          utcValue: new Date(2022, 2, 12, 8, 0, 0, 0),
        },
        end: {
          label: "22:00",
          value: new Date(2022, 2, 12, 22, 0, 0, 0),
          utcValue: new Date(2022, 2, 12, 22, 0, 0, 0),
        },
      },
    ],
  },
  //wednesday
  {
    available: true,
    timeSlots: [
      {
        start: {
          label: "8:00",
          value: new Date(2022, 2, 12, 8, 0, 0, 0),
          utcValue: new Date(2022, 2, 12, 8, 0, 0, 0),
        },
        end: {
          label: "22:00",
          value: new Date(2022, 2, 12, 22, 0, 0, 0),
          utcValue: new Date(2022, 2, 12, 22, 0, 0, 0),
        },
      },
    ],
  },
  //thursday
  {
    available: true,
    timeSlots: [
      {
        start: {
          label: "8:00",
          value: new Date(2022, 2, 12, 8, 0, 0, 0),
          utcValue: new Date(2022, 2, 12, 8, 0, 0, 0),
        },
        end: {
          label: "22:00",
          value: new Date(2022, 2, 12, 22, 0, 0, 0),
          utcValue: new Date(2022, 2, 12, 22, 0, 0, 0),
        },
      },
    ],
  },
  //friday
  {
    available: true,
    timeSlots: [
      {
        start: {
          label: "8:00",
          value: new Date(2022, 2, 12, 8, 0, 0, 0),
          utcValue: new Date(2022, 2, 12, 8, 0, 0, 0),
        },
        end: {
          label: "22:00",
          value: new Date(2022, 2, 12, 22, 0, 0, 0),
          utcValue: new Date(2022, 2, 12, 22, 0, 0, 0),
        },
      },
    ],
  },
  //saturday
  {
    available: true,
    timeSlots: [
      {
        start: {
          label: "8:00",
          value: new Date(2022, 2, 12, 8, 0, 0, 0),
          utcValue: new Date(2022, 2, 12, 8, 0, 0, 0),
        },
        end: {
          label: "22:00",
          value: new Date(2022, 2, 12, 22, 0, 0, 0),
          utcValue: new Date(2022, 2, 12, 22, 0, 0, 0),
        },
      },
    ],
  },
];

export const tutorDefinedAvailaibility = [
  // Sunday
  [
    {
      time: new Date(2022, 2, 12, 8, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 8, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 30, 0, 0),
      available: false,
      default: true,
    },
  ],
  // Monday
  [
    {
      time: new Date(2022, 2, 12, 8, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 8, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 30, 0, 0),
      available: false,
      default: true,
    },
  ],

  // Tuesday
  [
    {
      time: new Date(2022, 2, 12, 8, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 8, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 30, 0, 0),
      available: false,
      default: true,
    },
  ],
  // Wednesday
  [
    {
      time: new Date(2022, 2, 12, 8, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 8, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 30, 0, 0),
      available: false,
      default: true,
    },
  ],
  // Thursday
  [
    {
      time: new Date(2022, 2, 12, 8, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 8, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 30, 0, 0),
      available: false,
      default: true,
    },
  ],
  // Friday
  [
    {
      time: new Date(2022, 2, 12, 8, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 8, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 30, 0, 0),
      available: false,
      default: true,
    },
  ],
  // Saturday
  [
    {
      time: new Date(2022, 2, 12, 8, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 8, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 30, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 0, 0, 0),
      available: false,
      default: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 30, 0, 0),
      available: false,
      default: true,
    },
  ],
];

export const defaultAvailabilityBookALesson = [
  // Sunday
  [
    {
      time: new Date(2022, 2, 12, 8, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 8, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 30, 0, 0),
      available: true,
    },
  ],
  // Monday
  [
    {
      time: new Date(2022, 2, 12, 8, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 8, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 30, 0, 0),
      available: true,
    },
  ],

  // Tuesday
  [
    {
      time: new Date(2022, 2, 12, 8, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 8, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 30, 0, 0),
      available: true,
    },
  ],
  // Wednesday
  [
    {
      time: new Date(2022, 2, 12, 8, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 8, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 30, 0, 0),
      available: true,
    },
  ],
  // Thursday
  [
    {
      time: new Date(2022, 2, 12, 8, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 8, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 30, 0, 0),
      available: true,
    },
  ],
  // Friday
  [
    {
      time: new Date(2022, 2, 12, 8, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 8, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 30, 0, 0),
      available: true,
    },
  ],
  // Saturday
  [
    {
      time: new Date(2022, 2, 12, 8, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 8, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 9, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 10, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 11, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 12, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 13, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 14, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 15, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 16, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 17, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 18, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 19, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 20, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 21, 30, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 0, 0, 0),
      available: true,
    },
    {
      time: new Date(2022, 2, 12, 22, 30, 0, 0),
      available: true,
    },
  ],
];

const timeSlotArray = [
  "8:0",
  "8:30",
  "9:0",
  "9:30",
  "10:0",
  "10:30",
  "11:0",
  "11:30",
  "12:0",
  "12:30",
  "13:0",
  "13:30",
  "14:0",
  "14:30",
  "15:0",
  "15:30",
  "16:0",
  "16:30",
  "17:0",
  "17:30",
  "18:0",
  "18:30",
  "19:0",
  "19:30",
  "20:0",
  "20:30",
  "21:0",
  "21:30",
  "22:0",
  "22:30",
];

const timeSlotIndexes = [
  "8:00",
  "8:30",
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
];

function formatDatefromLabel(timeStr, date) {
  const [hours, minutes] = timeStr.split(":").map((str) => parseInt(str, 10));
  const updatedDate = new Date(date);
  updatedDate.setHours(hours);
  updatedDate.setMinutes(minutes);
  return updatedDate;
}

const updateTutorTimeSlotstoClientTimezone = async (slots, clientTimezone) => {
  slots.forEach((slot, index1) => {
    slot.timeSlots.forEach((schedule, index2) => {
      const startDateUtc = moment.utc(schedule.start.utcValue);
      const clientOffset = moment.tz
        .zone(clientTimezone)
        .utcOffset(startDateUtc);
      const startDateInNewTimezone =
        moment(startDateUtc).utcOffset(clientOffset);
      schedule.start.value = new Date(startDateInNewTimezone);

      const endDateUtc = moment.utc(schedule.end.utcValue);
      const endDateInNewTimezone = moment(endDateUtc).utcOffset(clientOffset);
      schedule.end.value = new Date(endDateInNewTimezone);

      function displayDateInTimezone(date, timezone) {
        // Create a new date object in the specified timezone
        const tzDate = new Date(
          date.toLocaleString("en-US", { timeZone: timezone })
        );

        // Get the year, month, and day with proper formatting
        const year = tzDate.getFullYear();
        const month = (tzDate.getMonth() + 1).toString().padStart(2, "0"); // months are zero-indexed, pad with leading zero
        const day = tzDate.getDate().toString().padStart(2, "0");

        // Get the hours, minutes, and seconds with proper formatting
        const hours = tzDate.getHours().toString().padStart(2, "0");
        const minutes = tzDate.getMinutes().toString().padStart(2, "0");
        const seconds = tzDate.getSeconds().toString().padStart(2, "0");

        // Return the date as a string in the format "YYYY-MM-DDTHH:MM:SS"
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      }

      const startDate = displayDateInTimezone(
        schedule.start.value,
        clientTimezone
      );

      const endDate = displayDateInTimezone(schedule.end.value, clientTimezone);

      // ******  set label time to client's timezone
      function formatTime(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();

        // pad the hours and minutes with leading zeros if necessary
        // if (hours < 10) {
        //   hours = '0' + hours;
        // }

        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        return `${hours}:${minutes}`;
      }

      schedule.start.label = formatTime(new Date(startDate));
      schedule.end.label = formatTime(new Date(endDate));
      schedule.start.utcValue = schedule.start.value;
      schedule.end.utcValue = schedule.end.value;
      schedule.start.value = formatDatefromLabel(
        schedule.start.label,
        new Date(startDate)
      );
      schedule.end.value = formatDatefromLabel(
        schedule.end.label,
        new Date(endDate)
      );
    });
  });
  return slots;
};

const updateTimeslotAvailability = (tutorTimeSlots) => {
  var updatedTutorTimeSlots = tutorTimeSlots;
  updatedTutorTimeSlots.forEach((slot, index1) => {
    slot.timeSlots.forEach((schedule, index2) => {
      if (
        timeSlotIndexes.indexOf(schedule.start.label) !== -1 ||
        timeSlotIndexes.indexOf(schedule.end.label) !== -1
      ) {
        slot.available = true;
      } else {
        slot.available = false;
      }
    });
  });
  return updatedTutorTimeSlots;
};

function isDateInRange(startDate, endDate, actualDate) {
  return actualDate >= startDate && actualDate <= endDate;
}

function formatDate(date) {
  // Get the year, month, and day
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // months are zero-indexed
  const day = date.getDate();

  // Get the hours, minutes, and seconds
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Return the date as a string in the format "YYYY-MM-DD HH:MM:SS"
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function convertToUTCDate(dateString, timezone) {
  // Create a new date object in the specified timezone
  const localTime = new Date(dateString);
  const tzDateString = new Date(dateString).toLocaleString("en-US", {
    timeZone: timezone,
  });

  const timeDifference = localTime.getTime() - new Date(tzDateString).getTime();
  const newTzDate = new Date(new Date(dateString).getTime() + timeDifference);

  // Create a new date object from the localized date string
  const tzDate = new Date(newTzDate);

  // Get the year, month, and day
  const year = tzDate.getFullYear();
  const month = tzDate.getMonth();
  const day = tzDate.getDate();

  // Get the hours, minutes, and seconds
  const hours = tzDate.getHours();
  const minutes = tzDate.getMinutes();
  const seconds = tzDate.getSeconds();

  // Return a new date object in the UTC timezone
  return new Date(year, month, day, hours, minutes, seconds);
}

export const createTutorAvailabilityCalendarData = async (
  tutorTimeSlots,
  timeZone,
  isTimezoneChanged
) => {
  // update the timeslot labels to selected timezone values

  var updatedTutorTimeSlots = [...tutorTimeSlots];
  if (isTimezoneChanged) {
    updatedTutorTimeSlots = await updateTutorTimeSlotstoClientTimezone(
      updatedTutorTimeSlots,
      timeZone
    );
  } else {
    updatedTutorTimeSlots = [...tutorTimeSlots];
  }

  // Makes tutor availability data structure compatible with the calendar
  var newAvailability = deepClone(tutorDefinedAvailaibility);
  var setAvailability = async () => {
    for (var i = 0; i < newAvailability.length; i++) {
      newAvailability[i].forEach((slot, index1) => {
        updatedTutorTimeSlots[i].timeSlots.forEach((schedule, index2) => {
          // check start and end time fall within 8:00 - 22:30
          if (
            timeSlotIndexes.indexOf(schedule.start.label) !== -1 ||
            timeSlotIndexes.indexOf(schedule.end.label) !== -1
          ) {
            if (
              isDateInRange(schedule.start.value, schedule.end.value, slot.time)
            ) {
              if (updatedTutorTimeSlots[i].available) {
                slot.available = true;
              } else {
                slot.available = false;
              }
              newAvailability[i][index1].utc = convertToUTCDate(
                formatDate(slot.time),
                timeZone
              );
            } else {
              slot.available = false;
              newAvailability[i][index1].utc = convertToUTCDate(
                formatDate(slot.time),
                timeZone
              );
            }
          } else {
            slot.available = false;
          }
        });
      });

      if (!updatedTutorTimeSlots[i].available) {
        newAvailability.forEach((schedule) => {
          schedule.available = false;
        });
      }
    }
  };

  await setAvailability();

  return newAvailability;
};

export const createAvailabilitySearchFilterValues = async (tutorTimeSlots) => {
  var filterValues = [];
  const grid = await createTutorAvailabilityCalendarData(tutorTimeSlots);
  const calendar = categorizeTutorAvailability(grid);

  calendar.forEach((day, index) => {
    day.forEach((slot, i) => {
      if (slot) {
        filterValues.push([index, i]);
      }
    });
  });

  return filterValues;
};

// Function to generate an array of days and times for each day
export function generateAvailabilityArray(availabilityData) {
  const availabilityArray = [];

  // Iterate through each availability entry
  availabilityData.forEach((entry, index) => {
    if (entry.available) {
      const dayOfWeek = daysFull[index];

      // Push day and time slots to availability array
      availabilityArray.push({
        label: dayOfWeek,
        value: entry.timeSlots.map((slot) => ({
          start: slot.start.label,
          end: slot.end.label,
        })),
      });
    }
  });

  return availabilityArray;
}

import HeaderText from "./HeaderText";
import { preferences } from "src/context/DirectPayContext";
import PersonalityItem from "./PeronalityItem";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

interface IPersonalityForm {
  alt?: boolean;
  onClick?: () => void;
}

export default function WordsThatDescribe({ alt, onClick }: IPersonalityForm) {
  const {
    state: { wordsThatDescribe, ageGroup, whoLessonIsFor },
  } = useContext(DirectPayContext);

  const [wordsThatDescribeHeading, setWordsThatDescribeHeading] = useState(
    "Select 3 words that best describe you"
  );

  useEffect(() => {
    if (ageGroup !== "adults" && whoLessonIsFor === "DEPENDANT") {
      setWordsThatDescribeHeading(
        "Choose 3 attributes that best capture your child's nature"
      );
    } else if (ageGroup === "adults" && whoLessonIsFor === "DEPENDANT") {
      setWordsThatDescribeHeading(
        "Select 3 words that best describe the learner"
      );
    }
  }, [ageGroup, whoLessonIsFor]);

  const handleValidator = () => {
    if (
      wordsThatDescribe.filter((word) =>
        preferences.some((p) => p.value === word)
      ).length < 3
    ) {
      toast.error("Please select 3 words that best describes you.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    if (onClick) onClick();
  };

  return (
    <div className="mt-12 ">
      <section className="max-w-3xl mx-auto">
        <HeaderText>
          Each learner is unique! Our tailored approach aligns with individual
          personalities and the Tutor's teaching styles.
        </HeaderText>
      </section>
      <div className="max-w-4xl mx-auto  mt-5 ">
        <div className="flex  gap-5 flex-wrap flex-col mt-6">
          <p className="mt-6 text-xl text-secondary font-semibold">
            {wordsThatDescribeHeading}
          </p>
          <div className="grid items-center gap-5 font-medium grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
            {preferences
              ?.filter((pref) => pref.category.includes(ageGroup))
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((text: { value: string; label: string }) => (
                <PersonalityItem text={text} key={text.label} dataTest="d2pPersonalityTraits" />
              ))}
          </div>
        </div>
      </div>
      {alt && (
        <button onClick={handleValidator} className="pink-button px-20 mt-14">
          Continue
        </button>
      )}
    </div>
  );
}

interface IPaymentMethod {}
export default function PaymentMethod({}: IPaymentMethod) {
  return (
    <>
      <div className="white-card-rounded mt-4 p-6 ">
        <p className="text-lg">
          Save a payment method for fast and easy lesson payments.
        </p>
        <p className="text-lg">
          Topset uses industry-standard encryption to protect your information.
        </p>
        <h1 className="font-bold text-xl mt-8 text-center sm:text-left">
          Credit or debit card
        </h1>
        <div className="flex items-center justify-between flex-col sm:flex-row">
          <div className="flex gap-2 items-center">
            <div className="w-10">
              <img
                src="https://www.edigitalagency.com.au/wp-content/uploads/new-visa-logo-high-quality-png-latest.png"
                alt="visa-logo"
              />
            </div>
            <p className="text-lg">Visa ****8996</p>
          </div>
          <div className="flex items-center sm:gap-4 flex-col sm:flex-row">
            <button className=" mt-4 sm:mt-0 font-semibold text-sm bg-primary text-white    px-7 py-3  rounded-xl cursor-pointer hover:bg-primary-light ">
              Change card
            </button>
            <button className=" mt-4 sm:mt-0 font-semibold text-sm bg-secondary text-white    px-7 py-3  rounded-xl cursor-pointer hover:bg-secondary-light ">
              Remove
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

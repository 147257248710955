import { ReactNode } from "react";
import ProgressBar from "src/views/MiniGames/LearningApp/App/ProgressBar";
import { ReactComponent as Cancel } from "src/assets/icons/cancel.svg";
import { ReactComponent as Back } from "src/assets/icons/previous.svg";
import { useDirectPayContext } from "src/context/DirectPayContext";
interface IFormContainer {
  children: ReactNode;
  currentIndex: number;
  numberOfQuestions: number;
  onCancel?: () => void;
  onContinue?: () => void;
}
export default function FormContainer({
  children,
  currentIndex,
  numberOfQuestions,
  onCancel,
  onContinue,
}: IFormContainer) {
  const {
    state: { reasonsForLearning, subject },
  } = useDirectPayContext();
  return (
    <div
      className="pt-12 border-t-2 p-8 "
      style={{
        borderColor: "#F2F2F2",
      }}
    >
      <div className=" text-center">
        <div className="max-w-3xl mx-auto mt-24 flex items-center gap-2 sm:gap-10 mb-7">
          <button onClick={onCancel}>
            {currentIndex === 1 ? <Cancel /> : <Back />}
          </button>

          <ProgressBar
            currentIndex={currentIndex}
            numberOfQuestions={numberOfQuestions}
            stroke="bg-primary"
          />
        </div>
        {children}
        <button data-test="d2pContinueButton" onClick={onContinue} className="pink-button px-20 mt-14">
          Continue
        </button>
        {(subject === "igbo" ? currentIndex === 4 : currentIndex === 3) &&
          reasonsForLearning.includes("Other") && (
            <button
              onClick={onContinue}
              className="bg-gray-100 text-black ml-5  p-3 rounded-full mt-14 font-semibold "
            >
              Skip
            </button>
          )}
      </div>
    </div>
  );
}

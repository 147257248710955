import React from "react";

export default function TrustpilotBadge() {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    //@ts-ignore
    if (window.Trustpilot) {
      //@ts-ignore
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      id="trustbox"
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="5419b732fbfb950b10de65e5"
      data-businessunit-id="636e6461cdcaa579cfeb9b1b"
      data-style-height="24px"
      data-style-width="100%"
    >
      <a
        href="https://www.trustpilot.com/review/topset.app"
        target="_blank"
        rel="noopener"
      >
        Trustpilot
      </a>
    </div>
  );
}

import { TrustpilotProvider } from "src/context/TrustpilotContext";
import FormContainer from "../DirectToPay/CustomerInfo/FormContainer";
import { useContext, useEffect, useState } from "react";
import LeanerForm from "../DirectToPay/CustomerInfo/LearnerForm";
import CompetenceForm from "../DirectToPay/CustomerInfo/CompetenceForm";
import Landing from "./Landing";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import FrequencyForm from "../DirectToPay/CustomerInfo/FrequencyForm";
import { useStateValue } from "src/context/StateProvider";
import { getConsultationLink } from "src/services/contact";
import UserDataForm from "../DirectToPay/CustomerInfo/UserDataForm";
import { isValidEmail } from "src/services/shared";
import useHandleSignup from "src/hooks/useHandleSignup";
import LoadingScreen from "../LottieAnimations/SpinningRose";
export default function Container() {
  const [pageIndex, setPageIndex] = useState(0);
  const {
    state: {
      isSigningup,
      whoLessonIsFor,
      numberOfUsers,
      proficiencyLevel,
      userData,
      isLoading,
    },
    actions: { setSigningup, setError },
  } = useContext(DirectPayContext);
  let formNum = 4;
  const [consultationLink, setConsultationLink] = useState("");
  const { handleSignup } = useHandleSignup();

  const [{ clientIpCountry }] = useStateValue();
  useEffect(() => {
    if (clientIpCountry) {
      setConsultationLink(getConsultationLink(clientIpCountry));
    }
  }, [clientIpCountry]);

  const handleNextPage = () => {
    setPageIndex((pageIndex) => pageIndex + 1);
    window.history.pushState({ pageIndex: pageIndex + 1 }, "", "");
  };
  const handleValidator = () => {
    setError({ field: null, errors: [] });

    if (pageIndex === 1 && !whoLessonIsFor) {
      toast.error("Please indicate whether you are a learner or not.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    if (pageIndex === 1 && numberOfUsers > 1) {
      Swal.fire({
        color: "#341A64",
        title: `<strong >Consultation Required for Small Group Classes</strong>`,
        html: `
          <p>
          Small group classes require a personal touch! Please book a consultation with our specialists to get started.
          </p>
          `,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: `
             Book Consultation!
          `,
        confirmButtonColor: "#D33479",
      }).then((result) => {
        if (result.isConfirmed) {
          window.open(consultationLink, "_self");
        }
      });
      return;
    }
    if (pageIndex === 2 && !proficiencyLevel) {
      toast.error("Please select a proficiency level.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      return;
    }

    if (pageIndex === formNum) {
      if (pageIndex === formNum) {
        const errors = [];
        if (!userData?.email) {
          errors.push({
            message: "Please enter your email address.",
            field: "email",
          });
        } else if (!isValidEmail(userData?.email || "")) {
          errors.push({
            message: "Please enter a valid email address.",
            field: "email",
          });
        }
        if (!userData.firstname) {
          errors.push({
            field: "firstname",
            message: "Please enter your first name.",
          });
        }
        if (!userData.lastname) {
          errors.push({
            field: "lastname",
            message: "Please enter your last name.",
          });
        }
        if (!userData.phone_number) {
          errors.push({
            field: "phone_number",
            message: "Please enter your phone number.",
          });
        }
        if (errors.length > 0) {
          setError({
            field: "userData",
            errors,
          });
          return;
        }
        handleSignup();
        return;
      }
    }
    handleNextPage();
  };

  const handleFormCancel = () => {
    if (pageIndex > 0) {
      setPageIndex((pageIndex) => pageIndex - 1);
      window.history.pushState({ pageIndex: pageIndex - 1 }, "", "");
    }
  };
  // Handle browser back/forward button click
  useEffect(() => {
    const handleBackForward = () => {
      if (
        window.history.state &&
        window.history.state.pageIndex !== undefined
      ) {
        if (window.history.state.pageIndex + 1 === 4 && isSigningup) {
          setSigningup(false);
        }
        setPageIndex(window.history.state.pageIndex);
      } else {
        setPageIndex(0);
      }
    };

    window.addEventListener("popstate", handleBackForward);

    return () => {
      window.removeEventListener("popstate", handleBackForward);
    };
  }, [isSigningup, setSigningup]);

  return (
    <TrustpilotProvider>
      <div>
        {isLoading ? (
          <LoadingScreen />
        ) : pageIndex === 0 ? (
          <Landing onClick={handleNextPage} />
        ) : (
          pageIndex >= 1 && (
            <FormContainer
              onContinue={handleValidator}
              onCancel={handleFormCancel}
              currentIndex={pageIndex}
              numberOfQuestions={formNum}
            >
              {pageIndex === 1 && <LeanerForm />}
              {pageIndex === 2 && <CompetenceForm />}
              {pageIndex === 3 && <FrequencyForm />}
              {pageIndex === 4 && <UserDataForm />}
            </FormContainer>
          )
        )}
      </div>
    </TrustpilotProvider>
  );
}

import { useContext, useMemo } from "react";
import HeaderText from "./HeaderText";
import { Context as DirectPayContext } from "src/context/DirectPayContext";

interface ICompetenceCard {
  title: string;
  description: string;
  emoji: string;
  value: string;
}

export default function CompetenceCard({
  title,
  description,
  emoji,
  value,
}: ICompetenceCard) {
  const {
    state: { proficiencyLevel },
    actions: { setProficieny },
  } = useContext(DirectPayContext);

  const selected = useMemo(() => {
    return proficiencyLevel === value ? true : false;
  }, [proficiencyLevel, value]);

  return (
    <div
      className={`border-2 rounded-3xl flex gap-4  text-left transition-colors duration-200  cursor-pointer  py-6 px-4  mx-auto
      ${
        selected
          ? "bg-primary-opaque border-primary"
          : "bg-gray-50 border-gray-100"
      }
      `}
      style={{
        maxWidth: "529px",
      }}
      onClick={() => {
        setProficieny(value);
      }}
      data-test={`d2p${title}LanguageSkillCard`}
    >
      <span className="text-3xl leading-10 ">{emoji}</span>
      <span>
        <HeaderText>{title}</HeaderText>
        <p className="font-medium text-lg leading-6">{description}</p>
      </span>
    </div>
  );
}

import { Link } from "react-router-dom";
import { routes } from "src/Routes";

const logo = `${process.env.REACT_APP_ASSET_CDN}/logo.webp`;
const mobile_logo = `${process.env.REACT_APP_ASSET_CDN}/logo.webp`;

function FixedHeaderWithoutNav() {
  return (
    <header
      className="top-0 z-50 
      bg-white py-5 sticky"
    >
      <nav className="container flex justify-between ">
        {/* left */}
        <div
          className="relative lg:flex items-center h-10 
            cursor-pointer my-auto hidden"
          onClick={() => (window.location.href = "http://topset.app/")}
        >
          <img src={logo} alt="" width={190} height={150} />
        </div>
        <div
          className="relative flex items-center h-10 
            cursor-pointer my-auto lg:hidden"
          onClick={() => (window.location.href = "http://topset.app/")}
        >
          <img src={mobile_logo} alt="" width={160} />
        </div>
        {/* Right */}
        <Link
          to={routes.LOGIN.url}
          className="text-primary hover:text-white transition-all duration-300 hover:bg-primary rounded-2xl hover:shadow-xl font-bold px-6 py-3 "
        >
          LOGIN
        </Link>
      </nav>
    </header>
  );
}

export default FixedHeaderWithoutNav;

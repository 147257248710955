import { useEffect, useState } from "react";
import DropdownContent from "./DropdownContent";
import { MdArrowDropDown } from "react-icons/md";
import SelectedOptions from "./SelectedOptions";

interface IMultiSelectDropdown {
  options: { value: string; label: string }[];
  selectedOptions: string[];
  placeholderText: string;
  handleAddSelection: (text: string) => void;
  handleRemoveSelection: (text: string) => void;
}
export default function MultiSelectDropdown({
  options,
  selectedOptions,
  placeholderText,
  handleAddSelection,
  handleRemoveSelection,
}: IMultiSelectDropdown) {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleRemoveSelectedOption = (option: string) => {
    handleRemoveSelection(option);
    setOpen(false);
  };
  const handleAddSelectedOption = (option: string) => {
    handleAddSelection(option);
    setOpen(false);
  };

  // Close dropdown when clicked outside DropdownContent
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (!target.closest(".dropdown-content")) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div
        className="mt-3 h-10 rounded-xl min-w-[150px] py-3 px-4 flex items-center justify-between cursor-pointer bg-gray-50"
        onClick={handleToggle}
      >
        <p className="text-light-purple font-medium">{placeholderText}</p>
        <MdArrowDropDown className="text-primary" size={30} />
      </div>
      <div className="my-2">
        <SelectedOptions
          selectedOptions={selectedOptions}
          onOptionClick={handleRemoveSelectedOption}
        />
      </div>
      <div className="dropdown-content">
        <DropdownContent open={open}>
          {options.map((a) => (
            <p
              key={a.label}
              className={`leading-5 text-primary font-medium p-3 cursor-pointer rounded-xl hover:bg-primary-pale transition-colors duration-300 `}
              onClick={() => {
                handleAddSelectedOption(a.value);
              }}
            >
              {a.label}
            </p>
          ))}
        </DropdownContent>
      </div>
    </div>
  );
}

import { useContext, useEffect, useState } from "react";
import ThankYouAnimation from "../LottieAnimations/ThankYou";
import { useNavigate } from "react-router-dom";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { routes } from "src/Routes";
import BookLessonConfirmation from "./BookLessonConfirmation";

export default function PaymentConfirmation({ payment }: any) {
  const {
    state: {
      onBoarding: { onboarding },
      isBookingMoreLessons,
    },
  } = useContext(DirectPayContext);

  const [count, setCount] = useState(5);
  const navigate = useNavigate();
  useEffect(() => {
    if (onboarding?.status !== "COMPLETE") return;
    setTimeout(() => {
      setCount(count - 1);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    if (onboarding?.status !== "COMPLETE") return;
    setTimeout(() => {
      navigate(routes.STUDENT_DASHBOARD_UPCOMING_LESSONS.url);
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (onboarding?.status !== "COMPLETE") {
    return <BookLessonConfirmation />;
  }

  return (
    <section className="text-primary body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
          <ThankYouAnimation />
          <h1 className="leading-relaxed text-3xl font-extrabold">
            {isBookingMoreLessons ? "Welcome back!" : "Welcome aboard!"}
          </h1>
          <h1 className="leading-relaxed text-xl font-extrabold">
            {isBookingMoreLessons ? "Continue" : "Embark on"} your journey of
            discovery and connection
          </h1>
          {/* <NavLink to={routes.STUDENT_DASHBOARD_UPCOMING_LESSONS.url}>
            <button className="pink-button px-20 mt-8">
              Visit My Dashboard
            </button>
          </NavLink> */}
          <p className="text-secondary font-medium">
            Redirecting to your dashboard in {count}...
          </p>
        </div>
      </div>
    </section>
  );
}

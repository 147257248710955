import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import FadeInDown from "src/components/MotionDivs/FadeInDown";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LessonApi } from "src/api/lesson.api";
import { useStateValue } from "src/context/StateProvider";

type DetailsModalProps = {
  isConfirmationModalOpen: boolean;
  setIsConfirmationModalOpen: any;
  virtualImmersionSession: any;
  getVirtualImmersions: any;
};

export default function ConfirmationModal({
  virtualImmersionSession,
  isConfirmationModalOpen,
  setIsConfirmationModalOpen,
  getVirtualImmersions,
}: DetailsModalProps) {
  const [{ user }] = useStateValue();
  const [isLoading, setIsLoading] = React.useState(false);
  if (!isConfirmationModalOpen) return null;

  const handleEnrollinSession = async (lesson: any) => {
    setIsLoading(true);
    if (user.type === "tutor") {
      toast.error("Only students can enroll in sessions", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }
    const response = await LessonApi.postEnrollStudentInVirtualImmersion({
      virtualImmersionSession,
      user,
    });
    if (response.success) {
      setIsLoading(false);
      getVirtualImmersions();
      setIsConfirmationModalOpen(false);
      toast.success("Enrolled in session successfully", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    } else {
      toast.error("Not eligible for virtual immersions", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const handleCloseModal = () => {
    setIsConfirmationModalOpen(false);
  };

  return (
    <div className="modalOverlay">
      <FadeInDown>
        <div className="bg-white flex flex-col justify-center items-center p-4 rounded-md relative max-w-[250px] overflow-scroll">
          <div className="absolute right-3 top-3">
            <AiOutlineClose
              className="text-gray-400 hover:text-red-400 cursor-pointer"
              onClick={() => setIsConfirmationModalOpen(false)}
            />
          </div>
          <h3 className="text-primary text-xl text-center font-semibold flex justify-center mt-4 ">
            Ready for an adventure?
          </h3>
          <p className="text-primary text-center text-xs my-4">
            Virtual immersion sessions are free for a limited time. Confirming
            will add this session to your upcoming lessons.
          </p>
          <p className="text-primary flex text-sm text-center justify-center mt-3"></p>
          <button
            className="flex gap-2 justify-center items-center cursor-pointer bg-secondary p-2 w-40 rounded-lg"
            onClick={() => handleEnrollinSession(virtualImmersionSession)}
            disabled={isLoading}
          >
            <p className="text-white text-xs font-semibold">
              Confirm Enrollment
            </p>
          </button>
          <button
            className="flex gap-2 justify-center items-center mt-2 cursor-pointer bg-pale-purple-bg p-2 w-40 rounded-lg"
            onClick={() => handleCloseModal()}
          >
            <p className="text-primary text-xs font-semibold">Cancel</p>
          </button>
        </div>
      </FadeInDown>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useStateValue } from "src/context/StateProvider";
import { IClientCurrencyData } from "src/interfaces/currency";
import { getClientCurrency } from "src/services/currency";

export default function useCurrency() {
  const [{ clientIpCountry }] = useStateValue();

  const [currency, setCurrency] = useState<IClientCurrencyData | null>(null);

  useEffect(() => {
    if (clientIpCountry) {
      let cp = getClientCurrency(clientIpCountry);
      switch (cp.currencyCode) {
        case "USD":
        case "CAD":
        case "GBP":
          setCurrency(cp);
          break;
        default:
          setCurrency({
            symbol: "$",
            fromNairaValue: 0.0024,
            fromPoundValue: 1.34,
            fromDollarValue: 1,
            currencyCode: "USD",
          });
      }
    }
  }, [clientIpCountry]);
  return currency;
}

import LazyLoad from "react-lazyload";

type StepCardProps = {
  imageSrc: string;
  title: string;
  text: string;
};

export default function StepCard(props: StepCardProps) {
  return (
    <div className="rounded-2xl shadow-lg py-6 px-6 bg-white w-full">
      <div className="flex gap-8 items-center lg:w-[450px]">
        <div>
          <LazyLoad>
            <img src={props.imageSrc} width={80} height={80} alt="" />
          </LazyLoad>
        </div>
        <div className="flex flex-col">
          <h2 className="text-primary font-bold text-lg mt-4 flex gap-2">
            {props.title}
          </h2>
          <p className="text-primary text-sm mt-2">{props.text}</p>
        </div>
      </div>
    </div>
  );
}

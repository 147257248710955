export function deepClone<T>(objToBeCloned: T): T {
    if (objToBeCloned === null || typeof objToBeCloned !== "object") {
        return objToBeCloned;
    }

    const obj = objToBeCloned as any;

    if (obj instanceof Date) {
        return new Date(obj.getTime()) as unknown as T;
    }

    if (Array.isArray(obj)) {
        const clonedArray: any[] = [];
        for (const currentItem of obj) {
            clonedArray.push(deepClone<any>(currentItem));
        }
        return clonedArray as unknown as T;
    }

    const clonedObj: { [key: string]: any } = {};
    for (const currentKey in obj) {
        if (obj.hasOwnProperty(currentKey)) {
            clonedObj[currentKey] = deepClone<any>(obj[currentKey]);
        }
    }

    return clonedObj as unknown as T;
}

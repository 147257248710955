import { useState } from "react";
import Item from "./Item";
interface ISlider {
  items: Array<string>;
  selected: number;
  setSelected: any;
  handleSelect: (id: number) => void;
}
export default function Slider({
  items,
  selected,

  handleSelect,
}: ISlider) {
  return (
    <div className="p-1 bg-pale-purple-bg  border rounded-md sm:w-fit sm:flex-row sm:gap-4 flex-col justify-between flex  mt-4 font-semibold items-center">
      {items.map((item, index) => (
        <Item
          handleSelect={handleSelect}
          id={index}
          title={item}
          selected={selected}
          key={index}
        />
      ))}
    </div>
  );
}

import clsx from "clsx";
import { IoGameControllerOutline } from "react-icons/io5";
import { NavLink, useLocation } from "react-router-dom";
import { routes } from "src/Routes";
import { useStateValue } from "src/context/StateProvider";

export default function MiniGames() {
  const [{ showMobileMenu }, dispatch] = useStateValue();

  const closeMobileMenu = async () => {
    await dispatch({
      type: "SET_SHOW_MOBILE_MENU",
      showMobileMenu: !showMobileMenu,
    });
  };

  const location = useLocation();
  return (
    <div>
      <NavLink to={routes.STUDENT_DASHBOARD_MINIGAMES.url}>
        <div
          className={clsx(
            {
              "menu-inactive":
                routes.STUDENT_DASHBOARD_MINIGAMES.url !== location.pathname,
            },
            {
              "menu-active":
                routes.STUDENT_DASHBOARD_MINIGAMES.url === location.pathname,
            }
          )}
          onClick={closeMobileMenu}
        >
          <div className="flex gap-4">
            <div className="flex gap-4">
              <div>
                <IoGameControllerOutline className="text-lg" />
              </div>
              <div>{routes.STUDENT_DASHBOARD_MINIGAMES.label}</div>
            </div>
          </div>
        </div>
      </NavLink>
    </div>
  );
}

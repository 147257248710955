"use client";

import clsx from "clsx";
import { motion } from "framer-motion";

import { fadeIn } from "src/utils/motion";

const InsightCard = ({
  title,
  subtitle,
  index,
}: {
  title: string;
  subtitle: string;
  index: number;
}) => (
  <motion.div
    variants={fadeIn("up", "spring", index * 0.5, 1)}
    className="flex lg:flex-row flex-col gap-4 relative rounded-2xl shadow-lg py-8 px-6 bg-white"
  >
    <div
      className={clsx({
        "absolute bg-primary h-full w-4 top-0 left-0 border rounded-tl-2xl rounded-bl-2xl":
          index === 1,
        "absolute bg-secondary h-full w-4 top-0 left-0 border rounded-tl-2xl rounded-bl-2xl":
          index === 2,
        "absolute bg-yellow-400 h-full w-4 top-0 left-0 border rounded-tl-2xl rounded-bl-2xl":
          index === 3,
        "absolute bg-purple-400 h-full w-4 top-0 left-0 border rounded-tl-2xl rounded-bl-2xl":
          index === 4,
      })}
    ></div>
    <div className="w-full flex justify-between items-center">
      <div className="flex-1 ml-8 flex flex-col">
        <h4 className="text-primary font-semibold text-2xl">{title}</h4>
        <p className="mt-2 text-light-purple">{subtitle}</p>
      </div>
    </div>
  </motion.div>
);

export default InsightCard;

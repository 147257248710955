import { useContext, useEffect, useState } from "react";
import TrustpilotBadge from "src/components/Shared/TrustpilotBadge";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import LandingListItem from "./LandingListItem";
import Carousel from "react-multi-carousel";
import { responsiveB } from "src/static-data/direct-to-pay/carousel-responsiveness";
import useTestimonials from "src/hooks/useTestimonials";
import TestimonialPlayerModal from "../DirectToPay/VideoPlayer";
import { IoPlayCircleOutline } from "react-icons/io5";
import "../DirectToPay/carousel.css";
import LandingPaymentPlan from "../DirectToPay/LandingPagePricing/PaymentPlan";

interface ILanding {
  onClick: () => void;
}
export default function Landing({ onClick }: ILanding) {
  const {
    state: { subject },
  } = useContext(DirectPayContext);
  const [vWidth, setvWidth] = useState(window.innerWidth);
  const {
    testimonials,
    videoDetails,
    isVideoModalOpen,
    setisVideoModalOpen,
    handlePlayClick,
  } = useTestimonials();
  useEffect(() => {
    const handleResize = () => {
      setvWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // capitalise first charater of string
  const capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <>
      <TestimonialPlayerModal
        videoDetails={videoDetails}
        isVideoModalOpen={isVideoModalOpen}
        setisVideoModalOpen={setisVideoModalOpen}
      />
      <h1 className="text-center font-semibold hidden md:block mt-20 md:text-2xl text-lg leading-5">
        Here’s what you will gain from our {capitalize(subject)} tutoring
        service.
      </h1>
      <div className="px-8 md:flex md:gap-12 mt-12 mx-auto max-w-6xl ">
        <div className="md:w-1/2">
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsiveB}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            keyBoardControl={true}
            customTransition="all 2.5s"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            className="text-center"
          >
            {testimonials.map((testimonial, index) => (
              <div key={index} className=" relative inline-block">
                <img
                  src={testimonial.thumbnail}
                  alt={`Thumbnail for ${testimonial.videoUrl}`}
                  style={{ objectPosition: "left center" }}
                  className=" w-[390px] h-[360px] bg-black rounded-3xl object-cover"
                />
                <div
                  className="absolute bottom-1/2 left-[44%]"
                  onClick={() => {
                    handlePlayClick(testimonial);
                  }}
                >
                  <IoPlayCircleOutline className="text-white text-5xl" />
                </div>
                <p className="text-md text-center font-normal text-primary mt-2">
                  {testimonial.name}
                </p>
              </div>
            ))}
          </Carousel>
        </div>
        <div className="mt-10 md:hidden">
          <TrustpilotBadge />
        </div>
        <section className="mt-6 md:mt-0 pb-6 flex-1">
          <h1 className="font-semibold md:hidden text-lg leading-5">
            Here’s what you will gain from our tutoring service.
          </h1>
          <div className="mt-5 flex flex-col gap-4">
            <LandingListItem text="Speak proper greetings confidently" />
            <LandingListItem text="Make introductions and respond to questions" />
            <LandingListItem
              text={`Give and take directions in ${
                subject[0].toUpperCase() + subject.slice(1)
              }`}
            />
            <LandingListItem
              text={`Count from 1 - 10 fluently in ${
                subject[0].toUpperCase() + subject.slice(1)
              }`}
            />
            <LandingListItem
              text={`Describe places and transportation effortlessly`}
            />
            <LandingListItem
              text={`Discuss food, clothing, parts of the body, colors, animals, family members and more.`}
            />
          </div>
          <div className="hidden md:flex mt-12">
            <button
              data-test="d2pGetStartedButton"
              onClick={onClick}
              className="pink-button "
            >
              Get Started
            </button>
          </div>
        </section>

        <div
          className="fixed bottom-4 md:hidden flex justify-center"
          style={{
            width: vWidth - 64 + "px",
            maxWidth: "1088px",
          }}
        >
          <button
            onClick={onClick}
            className="pink-button "
            style={{
              maxWidth: vWidth <= 640 ? "100%" : "30%",
              width: "100%",
            }}
          >
            Get Started
          </button>
        </div>
      </div>
      <div className="text-center font-semibold hidden md:block mt-12 md:text-2xl text-lg leading-5">
        <TrustpilotBadge />
      </div>
      <div className="max-w-6xl mt-10 mx-auto  ">
        <LandingPaymentPlan onClick={onClick} />
      </div>
    </>
  );
}

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  personality,
  preferences,
  useDirectPayContext,
} from "src/context/DirectPayContext";
import { routes } from "src/Routes";

interface IValidator {
  pageIndex: number;
  setPageIndex: (value: React.SetStateAction<number>) => void;
  formNum: number;
  setFormNum: (value: React.SetStateAction<number>) => void;
}
export default function useValidator({
  pageIndex,
  setPageIndex,
  formNum,
  setFormNum,
}: IValidator) {
  const {
    state: {
      whoLessonIsFor,
      ageGroup,
      reasonsForLearning,
      wordsThatDescribe,
      proficiencyLevel,
      subject,
      numberOfUsers,
      onBoarding,
      otherReason,
    },
    actions: { setError, updateOnboarding },
  } = useDirectPayContext();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      await updateOnboarding(
        {
          onboarding: {
            ...onBoarding.onboarding,
            ageGroup,
            otherReason,
            reasonsForLearning,
            wordsThatDescribe,
          },
        },
        onBoarding.onboarding?._id
      );
      navigate(routes.DIRECT_TO_PAY_MEET_TUTORS_B.url);
    } catch (error) {
      toast.error("Unable to find tutors", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      console.error(error);
    }
  };
  const handleFormCancel = () => {
    if (pageIndex > 0) {
      setPageIndex((pageIndex) => pageIndex - 1);
      window.history.pushState({ pageIndex: pageIndex - 1 }, "", "");
    }

    if (reasonsForLearning.includes("Other")) {
      if (subject === "igbo") {
        if (pageIndex === 5) {
          setFormNum(formNum - 1);
        }
      } else {
        if (pageIndex === 4) {
          setFormNum(formNum - 1);
        }
      }
    }
  };
  const handleNextPage = () => {
    setPageIndex((pageIndex) => pageIndex + 1);
    window.history.pushState({ pageIndex: pageIndex + 1 }, "", "");
    window.scrollTo(0, 100);
  };

  const validateWhoLessonIsFor = () => {
    if (!whoLessonIsFor) {
      toast.error("Please indicate whether you are a learner or not.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    return true;
  };

  const validateAgeGroup = () => {
    if (!ageGroup) {
      toast.error("Please select an age group.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    return true;
  };

  const validateReasonsForLearning = () => {
    if (reasonsForLearning.length === 0) {
      toast.error("Please select a reason for learning.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    return true;
  };

  const validatePersonality = () => {
    if (!personality.find((p) => wordsThatDescribe.includes(p.value))) {
      toast.error(
        numberOfUsers > 1
          ? "Please select your group's social personality type."
          : "Please select your social personality type.",
        { position: toast.POSITION.BOTTOM_RIGHT }
      );
      return false;
    }
    return true;
  };

  const validatePreferences = () => {
    if (
      wordsThatDescribe.filter((word) =>
        preferences.some((p) => p.value === word)
      ).length < 3
    ) {
      toast.error(
        numberOfUsers > 1
          ? "Please select 3 words that best describes your group."
          : "Please select 3 words that best describes you.",
        { position: toast.POSITION.BOTTOM_RIGHT }
      );
      return false;
    }
    return true;
  };

  const validateProficiencyLevel = () => {
    if (!proficiencyLevel) {
      toast.error("Please select a proficiency level.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    return true;
  };

  // Subject-specific validation functions
  const igboValidation = {
    2: () => true, // No specific validation for Igbo on page 3
    3: () => {
      if (!validateReasonsForLearning()) return false;
      if (reasonsForLearning.includes("Other")) {
        setFormNum((prev) => prev + 1);
      }
      return true;
    },
    4: () => {
      if (reasonsForLearning.includes("Other")) {
        return true;
      }
      return validatePersonality();
    },
    5: () => {
      if (reasonsForLearning.includes("Other")) {
        return validatePersonality();
      }
      return validatePreferences();
    },
    6: () => {
      return validatePreferences();
    },
  };

  const yorubaValidation = {
    2: () => {
      if (reasonsForLearning.includes("Other")) {
        setFormNum((prev) => prev + 1);
        return true;
      }
      return validateReasonsForLearning();
    },
    3: () => {
      if (reasonsForLearning.includes("Other")) {
        return true;
      }
      return validatePersonality();
    },
    4: () => {
      if (reasonsForLearning.includes("Other")) {
        return validatePersonality();
      }
      return validatePreferences();
    },
    5: () => {
      if (reasonsForLearning.includes("Other")) {
        return validatePreferences();
      }
      return validateProficiencyLevel();
    },
    6: () => {
      return validatePreferences();
    },
  };
  const handleValidator = () => {
    setError({ field: null, errors: [] });

    const commonValidation = {
      1: validateAgeGroup,
    };

    const subjectValidation =
      subject === "igbo" ? igboValidation : yorubaValidation;

    const validationMap = {
      ...commonValidation,
      ...subjectValidation,
    };

    if (pageIndex in validationMap) {
      if (!validationMap[pageIndex as keyof typeof validationMap]()) {
        return;
      }
    }

    if (pageIndex === formNum) {
      handleSubmit();

      return;
    }

    handleNextPage();
  };
  return { handleValidator, handleSubmit, handleNextPage, handleFormCancel };
}

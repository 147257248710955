import React from "react";
import { WordsListProps } from "./types";
import Flashcard from "src/components/MiniFlashcard/MiniFlashcard";

export default function WordList({ words }: WordsListProps) {
  return (
    <>
      <h2 className="text-center mt-6 mb-2 text-primary font-bold text-2xl">
        Word Generator
      </h2>
      <p className="text-center mb-6 text-light-purple text-sm">
        Use the options to filter words. Click on the button to get new words.
      </p>

      {words && words.length === 0 && (
        <p className="text-center my-6 text-secondary text-sm">
          No words found. Please try different filters.
        </p>
      )}
      <div className="flex gap-4 justify-center flex-wrap">
        {words?.map((word, index) => (
          <div key={index}>
            <Flashcard word={word} />
          </div>
        ))}
      </div>
      <div></div>
    </>
  );
}

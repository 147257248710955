import axios, { AxiosResponse } from "axios";
import { OnBoardingPayload } from "src/context/DirectPayContext";

export async function getOnBoardingDetails(
  userId: string
): Promise<AxiosResponse> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_V2_SERVER_URL}/auth/onboarding`,
      {
        params: {
          userId,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log("caught error: ", error);
    throw error;
  }
}
export async function updateOnboardingDetails(
  body: OnBoardingPayload,
  onboardingId?: string,
  userId?: string
): Promise<AxiosResponse> {
  const id = onboardingId ? { onboardingId } : { userId };
  try {
    let response = await axios.patch(
      `${process.env.REACT_APP_V2_SERVER_URL}/auth/onboarding`,
      { ...body, ...id }
    );
    return response.data;
  } catch (error: any) {
    console.log("caught error: ", error);
    throw error;
  }
}

import { FiPhoneCall } from "react-icons/fi";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useEffect, useState } from "react";
import { IPhoneNumber } from "src/interfaces/contact";
import { useStateValue } from "src/context/StateProvider";
import { getContactDetails } from "src/services/contact";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

export default function ContactIcon({
  colorVariant,
}: {
  colorVariant: string;
}) {
  const [{ clientIpCountry }] = useStateValue();
  const [contactNumber, setContactNumber] = useState<IPhoneNumber>({
    label: "0203 2898 195",
    phone: "+442032898195",
  });

  useEffect(() => {
    if (clientIpCountry) {
      setContactNumber(getContactDetails(clientIpCountry));
    }
  }, [clientIpCountry]);

  if (!contactNumber) return null;

  return (
    <Tippy content="Call us" delay={1000}>
      <div className="relative cursor-pointer flex gap-4">
        <a
          className={clsx({
            "text-primary font-semibold": colorVariant === "dark",
            "text-white font-semibold": colorVariant === "light",
          })}
          href={`tel:${contactNumber.phone}`}
        >
          {contactNumber.label}
        </a>
        <FiPhoneCall
          className={clsx({
            "text-primary hover:text-primary-medium": colorVariant === "dark",
            "text-white": colorVariant === "light",
          })}
          size={25}
        />{" "}
      </div>
    </Tippy>
  );
}

import { ILandingPackageType } from "src/components/DirectToPay/PaymentPlans/PaymentPlan";

export const landingPageLearningPackage: ILandingPackageType[] = [
  {
    type: "Weekly",
    perLessonRateUSD: "$18",
    perLessonRateCAD: "$24",
    perLessonRateGBP: "£12",
    totalLessonRateUSD: "$18",
    totalLessonRateCAD: "$24",
    totalLessonRateGBP: "£12",
    plan: "Billed Weekly",
    discount: 0,
    lessonType: "Per Lesson",
  },
  {
    type: "Monthly",
    perLessonRateUSD: "$15",
    perLessonRateCAD: "$20",
    perLessonRateGBP: "£10",
    totalLessonRateUSD: "$60",
    totalLessonRateCAD: "$80",
    totalLessonRateGBP: "£40",
    plan: "Billed every 4 weeks",
    discount: 16,
    lessonType: "Per Month",
  },
  {
    type: "1 Course Bundle",
    perLessonRateUSD: "$12",
    perLessonRateCAD: "$16",
    perLessonRateGBP: "£8",
    totalLessonRateUSD: "$300",
    totalLessonRateCAD: "$400",
    totalLessonRateGBP: "£200",
    plan: "Level 1 or Level 2",
    discount: 33,
    lessonType: "For 25 Lessons",
  },
  {
    type: "2 Course Bundle",
    perLessonRateUSD: "$10.5",
    perLessonRateCAD: "$14",
    perLessonRateGBP: "£7",
    totalLessonRateUSD: "$525",
    totalLessonRateCAD: "$700",
    totalLessonRateGBP: "£350",
    plan: "Level 1 and Level 2",
    discount: 41,
    lessonType: "For 50 Lessons",
  },
];

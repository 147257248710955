import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";
import { routes } from "src/Routes";
import { useStateValue } from "src/context/StateProvider";
import { RiGogglesLine } from "react-icons/ri";

export default function Messages() {
  const [{ userLessonDetails, userLessonProgress, showMobileMenu }, dispatch] =
    useStateValue();

  const closeMobileMenu = async () => {
    await dispatch({
      type: "SET_SHOW_MOBILE_MENU",
      showMobileMenu: !showMobileMenu,
    });
  };

  const shouldShowVirtualImmersionLink = (userLessonProgress: {
    unordered: any[];
  }) => {
    return userLessonProgress?.unordered?.some(
      (topic: { title: string; isLocked: boolean }) =>
        (topic?.title === "Numbers" ||
          topic?.title === "Numbers 1-10" ||
          topic?.title === "Numbers 11-20") &&
        topic?.isLocked === false
    );
  };

  const location = useLocation();

  if (!shouldShowVirtualImmersionLink(userLessonProgress)) {
    return null;
  }

  return (
    <div>
      <NavLink
        to={`${routes.STUDENT_DASHBOARD_VIRTUAL_IMMERSIONS.url}/${userLessonDetails?.language}`}
      >
        <div
          className={clsx(
            {
              "menu-inactive": !location.pathname.includes(
                routes.STUDENT_DASHBOARD_VIRTUAL_IMMERSIONS.url
              ),
            },
            {
              "menu-active": location.pathname.includes(
                routes.STUDENT_DASHBOARD_VIRTUAL_IMMERSIONS.url
              ),
            }
          )}
          onClick={closeMobileMenu}
        >
          <div className="flex gap-4">
            <div className="flex gap-4 pr-5">
              <div>
                <RiGogglesLine className="text-lg" />
              </div>
              <div>{routes.STUDENT_DASHBOARD_VIRTUAL_IMMERSIONS.label}</div>
            </div>
          </div>
        </div>
      </NavLink>
    </div>
  );
}

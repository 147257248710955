interface IItem {
  selected: number;
  handleSelect: (id: number) => void;
  id: number;
  title: string;
}
export default function Item({ selected, handleSelect, id, title }: IItem) {
  return (
    <p
      className={`  ${
        selected === id && "border rounded-md text-secondary bg-white shadow "
      }  py-1 px-10 cursor-pointer sm:w-auto w-full `}
      onClick={() => handleSelect(id)}
    >
      {title}
    </p>
  );
}

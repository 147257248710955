import { RevolutCheckoutInstance } from "@revolut/checkout";
import { LessonSchedule } from "./direct-pay";

export interface ILearningPackagesReq {
	level?: string;
	numberOfUsers: number;
}
export interface ICreateSubscription {
	userId: string | null;
	learningPackageId: string | null;
	lessonFrequency?: number;
	lessonSchedule?: Array<LessonSchedule>;
	currency: any;
}
export interface IUpdateSubscription {
	userId?: string | null;
	learningPackageId?: string | null;
	lessonFrequency?: number;
	lessonSchedule?: Array<LessonSchedule>;
	currency?: any;
	subscriptionId: string;
}

export interface IGetSubscription {
	userId?: string;
	status?: string;
}
export interface IUpdateOrder {
	paymentId: string;
	state: string;
	reference: string | null;
}

export type Instance = {
	payWithPopup: InstancePayWithPopup;
	paymentRequest: (options: PaymentRequestOptions) => {
		render(): any;
		destroy: () => void;
		canMakePayment: () => any;
	};
	destroy: () => void;
};
type InstancePayWithPopup = (options: PopupOptions) => RevolutCheckoutInstance;

type RevolutCheckoutError = {
	type: string;
	message: string;
	code?: number;
};

type PopupOptions = {
	onSuccess?: () => void;
	onError?: (error: RevolutCheckoutError) => void;
	onCancel?: () => void;
	name?: string;
	email?: string;
	phone?: string;
	locale?: string;
	savePaymentMethodFor?: string;
	billingAddress?: {
		countryCode: string;
		region?: string;
		city?: string;
		postcode: string;
		streetLine1?: string;
		streetLine2?: string;
	};
	shippingAddress?: {
		countryCode: string;
		region?: string;
		city?: string;
		postcode: string;
		streetLine1?: string;
		streetLine2?: string;
	};
};
export interface PaymentRequestOptions {
	target: HTMLElement;
	locale?: "en";
	requestShipping?: boolean;
	shippingOptions?: ShippingOption[];
	onShippingOptionChange?: (shippingOption: ShippingOption) => Promise<{
		status: "fail" | "success";
		total: {
			amount: number;
			label?: string;
		};
	}>;
	onShippingAddressChange?: (shippingAddress: Address) => Promise<{
		status: "fail" | "success";
		shippingOptions?: ShippingOption[];
		total: {
			amount: number;
			label?: string;
		};
	}>;
	buttonStyle?: {
		radius?: "small" | "large";
		size?: "none" | "small" | "large";
		variant?: "light" | "dark" | "light-outline";
		action?: "subscribe" | "donate" | "pay" | "buy";
	};
}
interface ShippingOption {
	id: string;
	label: string;
	amount: number;
	description?: string;
}

type Address =
	| {
			city: string;
			phone: string;
			region: string;
			country: string;
			recipient: string;
			postalCode: string;
			sortingCode: string;
			addressLine: string[];
			dependentLocality: string;
	  }
	| {
			city: string;
			region: string;
			country: string;
			postalCode: string;
	  };

export enum PaymentGateways {
	NIGERIAN_TRANSFER = "NIGERIAN_TRANSFER",
	PAYSTACK = "PAYSTACK",
	STRIPE = "STRIPE",
	PAYPAL = "PAYPAL",
	REVOLUT = "REVOLUT",
}

export enum BillingType {
	INVOICE = "INVOICE",
	SUBSCRIPTION = "SUBSCRIPTION",
}
export interface ILearningPackagesReq {
	level?: string;
	numberOfUsers: number;
}

import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "src/Routes";
import LoadingScreen from "src/components/LottieAnimations/SpinningRose";
import { useStateValue } from "src/context/StateProvider";
import { toast } from "react-toastify";

toast.configure();

const GoogleCallback = () => {
  const [{ user }, dispatch] = useStateValue();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const code = new URLSearchParams(location.search).get("code");
    if (code) {
      // Send code to your server also send userId to server
      fetch(`${process.env.REACT_APP_SERVER_URL}/auth/google/callback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code, userId: user._id, userType: user.type }),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle response data
          dispatch({
            type: "SET_USER",
            user: data.user,
          });
          toast.success("Calendar Synced Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          if (data.userType === "student") {
            navigate(routes.STUDENT_DASHBOARD_SETTINGS.url);
          } else {
            navigate(routes.TUTOR_DASHBOARD_SETTINGS.url);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [location]);

  return <LoadingScreen />;
};

export default GoogleCallback;

import { motion } from "framer-motion";

const CircularProgress = ({ percentage = 80 }) => {
  const radius = 10;
  const svgSize = radius * 2; // Set the SVG size based on the radius
  return (
    <svg width={svgSize} height={svgSize} xmlns="http://www.w3.org/2000/svg">
      <motion.circle
        cx={radius}
        cy={radius}
        r={radius}
        fill="transparent"
        stroke="#D33479"
        strokeWidth="5"
        strokeLinecap={"round"}
        strokeDasharray="100"
        animate={{ strokeDashoffset: percentage, rotate: 360 }}
        transition={{ repeat: Infinity, duration: 1 }}
      />
    </svg>
  );
};

export default CircularProgress;

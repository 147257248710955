export const isValidEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const getTutorPercentageMatch = (match: any, index: any) => {
  if (match < 100 && match > 90 && index === 0) {
    return match + 2;
  }
  if (match < 90 && match > 80 && index === 0) {
    return match + 10;
  }
  if (match < 80 && match > 70 && index === 0) {
    return match + 20;
  }
  if (match < 70 && match > 60 && index === 0) {
    return match + 30;
  }
  if (match < 60 && match > 50 && index === 0) {
    return match + 40;
  }
  if (match < 50 && match > 40 && index === 0) {
    return match + 50;
  }
  if (match < 40 && match > 30 && index === 0) {
    return match + 60;
  }
  if (match < 30 && match > 20 && index === 0) {
    return match + 70;
  }
  if (match < 100 && match > 90 && index === 1) {
    return match + 1;
  }
  if (match < 90 && match > 80 && index === 1) {
    return match + 7;
  }
  if (match < 80 && match > 70 && index === 1) {
    return match + 17;
  }
  if (match < 70 && match > 60 && index === 1) {
    return match + 27;
  }
  if (match < 60 && match > 50 && index === 1) {
    return match + 37;
  }
  if (match < 50 && match > 40 && index === 1) {
    return match + 47;
  }
  if (match < 40 && match > 30 && index === 1) {
    return match + 57;
  }
  if (match < 30 && match > 20 && index === 1) {
    return match + 67;
  }
  if (match < 90 && match > 80 && index === 2) {
    return match + 4;
  }
  if (match < 80 && match > 70 && index === 2) {
    return match + 14;
  }
  if (match < 70 && match > 60 && index === 2) {
    return match + 24;
  }
  if (match < 60 && match > 50 && index === 2) {
    return match + 34;
  }
  if (match < 50 && match > 40 && index === 2) {
    return match + 44;
  }
  if (match < 40 && match > 30 && index === 2) {
    return match + 54;
  }
  if (match < 30 && match > 20 && index === 2) {
    return match + 64;
  }
  return match;
};
import { useContext, useMemo } from "react";
import {
  Context as DirectPayContext,
  preferences,
} from "src/context/DirectPayContext";
interface IPersonalityItem {
  text: {
    value: string;
    label: string;
  },
  dataTest?: string
}

export default function PersonalityItem({ text, dataTest }: IPersonalityItem) {
  const {
    state: { wordsThatDescribe },
    actions: { setTrait },
  } = useContext(DirectPayContext);

  const selected = useMemo(() => {
    return wordsThatDescribe.includes(text.value) ? true : false;
  }, [wordsThatDescribe, text]);

  const disabled =
    preferences.some((p) => p.value === text.value) &&
    !wordsThatDescribe.includes(text.value) &&
    wordsThatDescribe.filter((word) =>
      preferences.some((p) => p.value === word)
    ).length === 3;

  return (
    <button
      className={`rounded-3xl border-2  py-1 px-3  text-m leading-6 transition-colors duration-200   ${
        selected ? "bg-primary-pale border-primary" : " border-gray-100"
      } ${disabled ? "opacity-50" : "opacity-100"}`}
      onClick={() => {
        setTrait(text.value);
      }}
      disabled={disabled}
      data-test={dataTest}
    >
      {text.label}
    </button>
  );
}

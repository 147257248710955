import Lottie from "lottie-react";
import * as findingMatch from "src/assets/animations/findingMatch.json";
import LoadingMessages from "src/components/Shared/LoadingMessages";
import { findingTutorMessages } from "src/static-data/LoadingMessages";

export default function FindingPerfectTutorMatch() {
  return (
    <>
      <div className="grid place-items-center h-screen">
        <div className="flex flex-col items-center">
          <div className="-mb-10 pb-8">
            <h1 className="lg:text-2xl font-semibold text-primary-light text-center">
              Finding the perfect tutor match for you...
            </h1>
          </div>
          <Lottie
            animationData={findingMatch}
            style={{
              height: 250,
            }}
          />
          <LoadingMessages messages={findingTutorMessages} />
        </div>
      </div>
    </>
  );
}

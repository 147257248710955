import React from "react";
import RatingSummary from "./RatingSummary";
import ReviewList from "./ReviewList";
import { ITutorReview, ITutorSearchResult } from "src/interfaces/tutor";
import RatingBreakdown from "./RatingBreakdown";

type RatingBreakdownType = {
  reviews: ITutorReview[];
  ratings: any;
};

export default function Reviews({ reviews, ratings }: RatingBreakdownType) {
  if (!reviews.length) {
    return null;
  }

  console.log("ratings", ratings);

  return (
    <div className="sm:shadow-md sm:border-[1px] sm:border-gray-200 rounded-2xl py-4 px-6 sm:w-[680px]">
      {/* Title */}
      <div className="flex gap-4 items-end justify-between">
        <p className="text-primary font-semibold">Ratings & Reviews</p>
      </div>
      {/* Divider */}
      <div className="border-b-[1px] border-gray-200 w-[full] my-4"></div>
      {/* Ratings */}
      <div className="sm:flex justify-between mt-3">
        <RatingSummary reviews={reviews} ratings={ratings} />
        <div className="mt-4">
          <RatingBreakdown reviews={reviews} ratings={ratings} />
        </div>
      </div>
      {/* Divider */}
      <div className="border-b-[1px] border-gray-200 w-[full] my-6"></div>
      {/* Reviews */}
      <div>
        <ReviewList reviews={reviews} />
      </div>
    </div>
  );
}

import React from "react";
import { FcGoogle } from "react-icons/fc";
import { ImFacebook2 } from "react-icons/im";

export default function SocialButtons() {
  const googleAuth = () => {
    window.open(`${process.env.REACT_APP_SERVER_URL}/auth/google`, "_self");
  };

  const facebookAuth = () => {
    window.open(`${process.env.REACT_APP_SERVER_URL}/auth/google`, "_self");
  };

  return (
    <>
      <div className="flex gap-4">
        <div
          className="flex hover:bg-slate-100 gap-4 items-center justify-center 
                  p-4 rounded-lg cursor-pointer border-2 flex-grow"
        >
          <FcGoogle size={25} />
          <h3 className=" md:text-sm font-semibold" onClick={googleAuth}>
            Google
          </h3>
        </div>

        {/* Button Two */}
        <div
          className="flex gap-4 hover:bg-slate-100 items-center justify-center 
      p-4 rounded-lg cursor-pointer border-2 flex-grow"
        >
          <ImFacebook2 className="text-blue-900" size={25} />
          <h3 className=" md:text-sm font-semibold" onClick={facebookAuth}>
            Facebook
          </h3>
        </div>
      </div>
    </>
  );
}

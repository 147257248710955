import React from "react";
import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";
import { routes } from "src/Routes";
import { useStateValue } from "src/context/StateProvider";

export default function TutorFeedback() {
  const location = useLocation();
  const [{ showMobileMenu, unreadFeedbacks }, dispatch] = useStateValue();

  const closeMobileMenu = async () => {
    await dispatch({
      type: "SET_SHOW_MOBILE_MENU",
      showMobileMenu: !showMobileMenu,
    });
  };

  return (
    <div>
      {" "}
      <NavLink to={routes.STUDENT_DASHBOARD_FEEDBACK.url}>
        <div
          className={clsx(
            "submenu-inactive",
            {
              "submenu-active":
                routes.STUDENT_DASHBOARD_FEEDBACK.url === location.pathname ||
                location.pathname.includes(
                  routes.STUDENT_DASHBOARD_FEEDBACK.url
                ),
            },
            "flex  items-center gap-4"
          )}
        >
          <p onClick={closeMobileMenu}>
            {routes.STUDENT_DASHBOARD_FEEDBACK.label}
          </p>
          {unreadFeedbacks && (
            <div
              className={clsx({
                "bg-secondary hover:bg-secondary-light text-xxs w-auto px-2.5 py-1 text-white rounded-full flex justify-between":
                  unreadFeedbacks?.length,
                hidden: !unreadFeedbacks?.length,
              })}
            >
              {unreadFeedbacks?.length}
            </div>
          )}
        </div>
      </NavLink>
    </div>
  );
}

import React from "react";
import { MdLanguage } from "react-icons/md";

export default function PeaceOfMindCard() {
  return (
    <div className="rounded-2xl shadow-lg py-8 px-6 bg-white">
      <div className="bg-pink-100 py-3 pl-2 rounded-full w-14">
        <MdLanguage className="text-secondary ml-[4px]" size={32} />
      </div>
      <div>
        <h3 className="text-secondary font-semibold text-2xl mt-4 flex gap-2">
          Native Language
        </h3>
      </div>
      <div className="text-primary mt-3 lg:block">
        “E káàbò”, “Barka da zuwa”, “Ndewo” Whether you’re starting from scratch
        or dusting off your language skiils, we’ve got you covered!
      </div>
    </div>
  );
}

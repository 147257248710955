// TrustpilotContext.js
import React, { createContext, useState, useContext, useEffect } from "react";

const TrustpilotContext = createContext();

export const useTrustpilot = () => useContext(TrustpilotContext);

export const TrustpilotProvider = ({ children }) => {
  const [isScriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (!document.querySelector("#trustpilot-script")) {
      const script = document.createElement("script");
      script.id = "trustpilot-script";
      script.src =
        "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
      script.async = true;
      script.onload = () => setScriptLoaded(true);
      document.head.appendChild(script);
    } else {
      setScriptLoaded(true);
    }
  }, []);

  return (
    <TrustpilotContext.Provider value={{ isScriptLoaded }}>
      {children}
    </TrustpilotContext.Provider>
  );
};

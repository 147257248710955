import axios from "axios";

export async function getUserDependants(userId: string): Promise<any | undefined[]> {
  try {
      const response  = await axios.get(`${process.env.REACT_APP_SERVER_URL}/child-or-dependant/${userId}`);
      return response.data
    } catch (error) {
      console.log("caught error: ", error);
  }
}

export async function getDependantAccounts(userId: string): Promise<any | undefined[]> {
  try {
      const response  = await axios.get(`${process.env.REACT_APP_SERVER_URL}/child-or-dependant-account/${userId}`);
      return response.data
    } catch (error) {
      console.log("caught error: ", error);
  }
}

export async function postContactUs(values: any): Promise<any | undefined[]> {
  try {
      const response  = await axios.get(`${process.env.REACT_APP_SERVER_URL}/contact-us`, values);
      return response.data
    } catch (error) {
      console.log("caught error: ", error);
  }
}

export async function signInAsUser(userId: any): Promise<any | undefined[]> {
  try {
      const response  = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user-impersonation/${userId}`);
      return response.data
    } catch (error) {
      console.log("caught error: ", error);
  }
}
import { useEffect, useState } from "react";
import { useStateValue } from "src/context/StateProvider";
import { IClientCurrencyData } from "src/interfaces/currency";
import { getClientCurrency } from "src/services/currency";

import { landingPageLearningPackage } from "src/static-data/direct-to-pay/landingPagePricingData";
import { ILandingPackageType } from "../PaymentPlans/PaymentPlan";
import LandingPaymentPlanCard from "./PaymentCard";

export default function LandingPaymentPlan({ onClick }: { onClick: any }) {
  const [{ clientIpCountry }] = useStateValue();
  const [result, setResult] = useState<ILandingPackageType[]>([]);
  const [currency, setCurrency] = useState<IClientCurrencyData | null>(null);

  useEffect(() => {
    if (clientIpCountry) {
      setCurrency(getClientCurrency(clientIpCountry));
    }
  }, [clientIpCountry]);

  useEffect(() => {
    setResult(landingPageLearningPackage);
  }, []);

  return (
    <div>
      <div className="text-primary relative">
        <section className="text-center z-10 container md:mt-6 px-8 mt-4 left-8 right-8 ">
          <h1 className="font-bold md:text-2xl text-2xl leading-10 max-w-3xl mx-auto ">
            Our Individual Package Plans
          </h1>
        </section>

        <div className="container mt-10 px-3 md:px-8 pb-12 left-8 right-8 ">
          <div className="flex justify-center">
            <div>
              <div
                className={`items-start grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-7  md:gap-5  `}
              >
                {result
                  ?.filter(
                    (p: ILandingPackageType) => p?.type !== "2 Course Bundle"
                  )
                  ?.map((payment: ILandingPackageType, index: number) => (
                    <LandingPaymentPlanCard
                      onClick={onClick}
                      paymentPlans={payment}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

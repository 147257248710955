import Axios from "axios";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStateValue } from "src/context/StateProvider";
import PhoneInput from "react-phone-input-2";
import { useContext, useEffect, useState } from "react";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import {
  howYouHearAboutUsOptions,
  selectorTheme,
} from "src/static-data/SelectOptions";
import { getClientTimeZone } from "src/services/client";
import { removeZeroPrefix } from "src/utils/helper/removePhoneZeroPrefix";
import { routes } from "src/Routes";
import { FaCheckCircle } from "react-icons/fa";
import PasswordValidation from "./PasswordValidation";

toast.configure();

export default function StudentEmailSignupForm({ directPayUser = false }: any) {
  const [{ clientIpCountry }, dispatch] = useStateValue();
  const {
    state,
    actions: { signup },
  } = useContext(DirectPayContext);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const refCode = query.get("refCode");
  const animatedComponents = makeAnimated();
  const [locationInfo, setLocationInfo] = useState<any>(null);
  const [howYouHeard, setHowYouHeard] = useState<any>({
    value: "Please select one",
    label: "Please select one",
  });
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const [timeZone, setTimeZone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [isPasswordVerified, setIsPasswordVerified] = useState(false);
  const [shouldShowCriteriaModal, setShouldShowCriteriaModal] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
  });
  const navigate = useNavigate();
  useEffect(() => {
    const getTimeZone = async () => {
      setTimeZone(await getClientTimeZone());
    };
    getTimeZone();
    if (refCode) {
      setHowYouHeard({
        value: "Referral",
        label: "Referral",
      });
    }
  }, [refCode]);

  const handlePhoneChange = (phone: any, country: any) => {
    const { phoneFormat } = removeZeroPrefix(phone, country);
    setLocationInfo(country);
    setPhoneNumber(phoneFormat);
  };

  const handleHowYouHeardOptionsChange = (selectedOption: any) => {
    setHowYouHeard(selectedOption);
  };

  const handlePasswordOnFocus = () => {
    setShouldShowCriteriaModal(true);
  };

  const handlePasswordOnBlur = () => {
    setShouldShowCriteriaModal(false);
    setPasswordTouched(true);
  };

  const handlePasswordChange = (password: string) => {
    const length = password.length >= 8;
    const uppercase = /[A-Z]/.test(password);
    const lowercase = /[a-z]/.test(password);
    const digit = /\d/.test(password);
    const specialChar = /\W|_/.test(password);

    setPasswordCriteria({
      length,
      uppercase,
      lowercase,
      digit,
      specialChar,
    });

    setIsPasswordVerified(
      length && uppercase && lowercase && digit && specialChar
    );

    setPassword(password);
  };

  // const googleAuth = () => {
  //   window.open(`${process.env.REACT_APP_SERVER_URL}/auth/google`, "_self");
  // };

  // const facebookAuth = () => {
  //   window.open(`${process.env.REACT_APP_SERVER_URL}/auth/google`, "_self");
  // };

  return (
    <>
      {/* Form */}
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          email: "",
          password: "",
          confirmPassword: "",
          role: "student",
          welcomeEmailUrl: `${process.env.CURRENT_DOMAIN}`,
          phone_number: "",
          locationInfo: "",
          howYouHeard: refCode
            ? {
                value: "Referral",
                label: "Referral",
              }
            : "",
          timeZone: "",
          referralCode: refCode ? refCode : "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          confirmPassword: Yup.string()
            .oneOf([password, null], "Passwords must match")
            .required("Confirm Password is required"),
          firstname: Yup.string().max(255).required("First name is required"),
          lastname: Yup.string().max(255).required("Last name is required"),
          referralCode: Yup.string().max(255),
        })}
        onSubmit={async (values) => {
          // generate random 4 digit number string
          try {
            if (phoneNumber.length < 9) {
              toast.error("Please enter a valid phone number");
              return;
            }
            if (howYouHeard.value === "Please select one") {
              toast.error("Please tell us how you heard about us");
              return;
            }

            values.password = password;
            values.phone_number = phoneNumber;
            values.locationInfo = locationInfo;
            values.howYouHeard = howYouHeard.value;
            values.timeZone = timeZone;

            if (directPayUser) {
              try {
                const response = await signup({
                  ...state,
                  userData: {
                    ...values,
                    leadFunnel: state.leadFunnel,
                  },
                });
                toast.success("Signup was successful", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
                await dispatch({
                  type: "SET_USER",
                  user: response.data.user,
                });
                navigate(routes.DIRECT_TO_PAY_MEET_TUTORS.url);
              } catch (error: any) {
                console.error(error);
                if (error.response) {
                  if (error.response.status === 409) {
                    toast.error(
                      "User with email already exists. Please log in",
                      {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      }
                    );
                  } else {
                    toast.error("An error occured", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  }
                }
              }
            } else {
              Axios({
                method: "POST",
                data: values,
                withCredentials: true,
                url: `${process.env.REACT_APP_SERVER_URL}/auth/local-signup`,
              }).then(async (res) => {
                if (res.data.success) {
                  toast.success("Signup was successful", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                  await dispatch({
                    type: "SET_USER",
                    user: res.data.user,
                  });
                }
                if (!res.data.success) {
                  toast.error(res.data.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                }
              });
            }
          } catch (error) {
            console.log("caught error: ", error);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="flex flex-col md:flex-row gap-3 mt-4">
              <div className="mt-4">
                <label className="block mb-2 text-primary text-sm font-medium">
                  First Name
                </label>
                <input
                  className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  placeholder="First Name"
                  name="firstname"
                  value={values.firstname}
                />
                <p
                  className={clsx({
                    "text-red-500 text-xs mt-1":
                      touched.firstname && errors.firstname,
                    hidden: !(touched.firstname && errors.firstname),
                  })}
                >
                  {errors.firstname}
                </p>
              </div>
              <div className="mt-4">
                <label className="block mb-2 text-primary text-sm font-medium">
                  Last Name
                </label>
                <input
                  className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  placeholder="Last Name"
                  name="lastname"
                  value={values.lastname}
                />
                <p
                  className={clsx({
                    "text-red-500 text-xs mt-1":
                      touched.lastname && errors.lastname,
                    hidden: !(touched.lastname && errors.lastname),
                  })}
                >
                  {errors.lastname}
                </p>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-primary text-xxs uppercase mb-1">
                Phone Number
              </p>
              <PhoneInput
                inputStyle={{ width: "100%" }}
                country={clientIpCountry?.toLowerCase()}
                containerStyle={{
                  borderRadius: 20,
                }}
                value={phoneNumber}
                onChange={(phone, country) => handlePhoneChange(phone, country)}
                inputProps={{
                  "data-test": "phoneNumber",
                }}
              />
            </div>
            <div className="mt-4">
              <label className="block mb-2 text-primary text-sm font-medium">
                Email Address
              </label>
              <input
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                placeholder="Email Address"
                name="email"
                value={values.email}
              />
              <p
                className={clsx({
                  "text-red-500 text-xs mt-1": touched.email && errors.email,
                  hidden: !(touched.email && errors.email),
                })}
              >
                {errors.email}
              </p>
            </div>

            <div className="mt-4">
              <label className="block mb-2 text-primary text-sm font-medium">
                Password
              </label>

              <input
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                onFocus={handlePasswordOnFocus}
                onBlur={handlePasswordOnBlur}
                onChange={(e) => handlePasswordChange(e.target.value)}
                placeholder="Create a password"
                type="password"
                name="password"
                value={password}
              />
              <p
                className={clsx({
                  "text-red-500 text-xs mt-1":
                    passwordTouched && !isPasswordVerified,
                  hidden: !(passwordTouched && !isPasswordVerified),
                })}
              >
                {
                  "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
                }
              </p>
            </div>
            {shouldShowCriteriaModal && (
              <div className="mt-1">
                <PasswordValidation passwordCriteria={passwordCriteria} />
              </div>
            )}
            <div className="mt-4">
              <label className="block mb-2 text-primary text-sm font-medium">
                Confirm Password
              </label>

              <input
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Confirm password"
                type="password"
                name="confirmPassword"
                value={values.confirmPassword}
              />
              <p
                className={clsx({
                  "text-red-500 text-xs mt-1":
                    touched.confirmPassword && errors.confirmPassword,
                  hidden: !(touched.confirmPassword && errors.confirmPassword),
                })}
              >
                {errors.confirmPassword}
              </p>
            </div>
            <div className="mt-4">
              <label className="block mb-2 text-primary text-sm font-medium">
                How did you hear about us?
              </label>

              <Select
                value={howYouHeard}
                onChange={handleHowYouHeardOptionsChange}
                options={howYouHearAboutUsOptions}
                components={animatedComponents}
                placeholder="How did you hear about us?"
                isMulti={false}
                theme={selectorTheme}
                isSearchable
                className="text-primary text-lg rounded-3xl"
              />
            </div>
            <div className="mt-4">
              <label className="block mb-2 text-primary text-sm font-medium">
                Referral Code
              </label>
              <input
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                placeholder="Referral Code"
                name="referralCode"
                value={values?.referralCode}
              />
              <p
                className={clsx({
                  "text-red-500 text-xs mt-1":
                    touched.referralCode && errors.referralCode,
                  hidden: !(touched.referralCode && errors.referralCode),
                })}
              >
                {errors.referralCode}
              </p>
            </div>
            <div className="mt-6">
              <button
                type="submit"
                disabled={isSubmitting}
                className={`${
                  isSubmitting
                    ? "disabled-button"
                    : "bg-primary hover:bg-primary-light"
                } flex gap-4  items-center justify-center 
                        p-4 mt-2 rounded-lg cursor-pointer border-2  w-full`}
              >
                <h3 className="text-white font-semibold md:text-sm">
                  Sign up with email
                </h3>
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

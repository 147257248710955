import React from "react";
import { BsCheck } from "react-icons/bs";
import { MdConnectWithoutContact } from "react-icons/md";
import LazyLoad from "react-lazyload";

export default function WhatYouWillLearn() {
  return (
    <div className="container py-20">
      <div className="flex gap-6 justify-between flex-col md:flex-row">
        <div className="flex-1">
          <h2 className="text-primary font-bold  text-3xl mt-83  flex gap-2">
            What you will learn
          </h2>
          <div className="ml-12">
            <div className="flex gap-2  mt-6 lg:justify-start items-center">
              <div className="p-2 rounded-full bg-secondary">
                <BsCheck className="text-white" />
              </div>
              <h3 className="text-primary">Understand language foundations</h3>
            </div>
            <div className="flex gap-2  mt-6 lg:justify-start items-center">
              <div className="p-2 rounded-full bg-secondary">
                <BsCheck className="text-white" />
              </div>
              <h3 className="text-primary">Common phrases</h3>
            </div>
            <div className="flex gap-2  mt-6 lg:justify-start items-center">
              <div className="p-2 rounded-full bg-secondary">
                <BsCheck className="text-white" />
              </div>
              <h3 className="text-primary">Pronunciation and tones</h3>
            </div>
            <div className="flex gap-2  mt-6 lg:justify-start items-center">
              <div className="p-2 rounded-full bg-secondary">
                <BsCheck className="text-white" />
              </div>
              <h3 className="text-primary">Communicating fluidly</h3>
            </div>
            <div className="flex gap-2  mt-6 lg:justify-start items-center">
              <div className="p-2 rounded-full bg-secondary">
                <BsCheck className="text-white" />
              </div>
              <h3 className="text-primary">Storytelling</h3>
            </div>
          </div>
        </div>
        <div className="flex-1 -mt-6">
          <LazyLoad>
            <img
              src="https://tutoring-app.s3.us-east-2.amazonaws.com/start-yoruba.gif"
              alt=""
              width="1500"
            />
          </LazyLoad>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";

interface ICounter {
  min?: number;
  val?: number;
  max?: number;
  setValue?: (value: any) => void;
}
export default function Counter({ val, min = 1, max = 2, setValue }: ICounter) {
  const [count, setCount] = useState(val || min);
  const handleChange = (sign: string) => {
    if (sign === "+") {
      if (count < max) {
        setCount(count + 1);
        setValue && setValue(count + 1);
      }
    } else {
      if (count > 1) {
        setCount(count - 1);
        setValue && setValue(count - 1);
      }
    }
  };
  return (
    <div className=" flex items-center gap-6 justify-center">
      <button
        className="block border-2 p-2 rounded-l-3xl rounded-r-lg"
        style={{ borderColor: "#F4F4F4" }}
        onClick={() => handleChange("-")}
        disabled={count === min}
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 51 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.4121 27.0468H38.4008"
            stroke="#341A64"
            opacity={count === min ? 0.5 : 1}
            strokeWidth="6.40735"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <p className="font-bold text-3xl">{count}</p>
      <button
        className="block border-2 p-2 rounded-r-3xl rounded-l-lg"
        style={{ borderColor: "#F4F4F4" }}
        onClick={() => handleChange("+")}
        disabled={count === max}
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 51 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3105 27.0468H38.2992"
            stroke="#341A64"
            strokeWidth="6.40735"
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={count === max ? 0.5 : 1}
          />
          <path
            d="M25.8052 40.1818V13.9117"
            stroke="#341A64"
            strokeWidth="6.40735"
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={count === max ? 0.5 : 1}
          />
        </svg>
      </button>
    </div>
  );
}

import { format } from "date-fns";
import moment from "moment";
import { useDirectPayContext } from "src/context/DirectPayContext";

export default function Subject({ subject }: any) {
  return (
    <div className="flex justify-between items-center mt-2 gap-4 flex-wrap">
      <p className="flex-1">{subject?.name}</p>
      <p className="font-semibold flex-1 text-right ">
        {subject?.schedule
          ?.map((schedule: any) =>
            format(
              new Date(schedule?.date).setHours(schedule?.hour),
              "eeee, MMMM d 'at' HH:mm a"
            )
          )
          .join(",")}
      </p>
    </div>
  );
}

import { useContext } from "react";
import ThankYouAnimation from "../LottieAnimations/ThankYou";
import { NavLink } from "react-router-dom";
import { routes } from "src/Routes";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
export default function BookLessonConfirmation({ payment }: any) {
  const {
    state: {
      onBoarding: { onboarding },
    },
  } = useContext(DirectPayContext);
  return (
    <section className="text-primary body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
          <ThankYouAnimation />
          <h1 className="leading-relaxed text-3xl font-extrabold">
            Thank you for your payment!
          </h1>
          <h1 className="leading-relaxed text-xl font-extrabold">
            Let’s find a perfect tutor for you
          </h1>
          <p className="text-secondary text-xl font-medium">
            We just need a bit more information to get your lessons booked!
          </p>

          <NavLink
            to={
              routes.DIRECT_TO_PAY_GET_STARTED_B.url +
              `?lang=${onboarding?.subject}`
            }
          >
            <button data-test="d2pContinueButton" className="pink-button px-20 mt-8">Continue</button>
          </NavLink>
        </div>
      </div>
    </section>
  );
}

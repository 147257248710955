
interface TutorDescription {
  [key: string]: string;
}


export const tutorDescription : TutorDescription  = {
    "DOWN_TO_EARTH_EXTROVERTED" : "These tutors are known for their approachable and outgoing nature. They create a friendly and engaging learning environment that makes students feel immediately at ease. Their teaching style is highly interactive, with plenty of real-world examples and hands-on activities. They are great at motivating students and encouraging participation, making learning a fun and dynamic experience.",
    "DOWN_TO_EARTH_INTROVERTED" :  "These tutors have a calm and supportive teaching style, offering a patient and thoughtful approach to learning. They prefer one-on-one or small group settings, where they can provide individual attention and tailor their methods to each student's needs. Their approachable demeanor and quiet confidence create a safe and encouraging space for students to learn at their own pace.",
    "DOWN_TO_EARTH_AMBIVERTED" : "These tutors are known for their approachable and outgoing nature. They create a friendly and engaging learning environment that makes students feel immediately at ease. Their teaching style is highly interactive, with plenty of real-world examples and hands-on activities. They are great at motivating students and encouraging participation, making learning a fun and dynamic experience.",
    "POLISHED_INTROVERTED" :  "These tutors offer a refined and focused learning experience. They are highly knowledgeable and methodical, providing clear, well-organized lessons tailored to the student's learning goals. Their calm and composed demeanor helps create a serene learning environment, where students can concentrate on their language fluency. They are particularly good at helping students develop deep, conceptual understandings of complex material.",
    "POLISHED_EXTROVERTED" : "These tutors are characterized by their depth of knowledge and energetic approach. They are excellent communicators, often using their charisma to inspire and captivate their students. Their lessons are well-structured and articulate, blending high standards of professionalism with a warm and engaging delivery. They are adept at preparing students for high-levels of fluency and foster a deep cultural connection.",
    "POLISHED_AMBIVERTED" :  "These tutors are characterized by their depth of knowledge and energetic approach. They are excellent communicators, often using their charisma to inspire and captivate their students. Their lessons are well-structured and articulate, blending high standards of professionalism with a warm and engaging delivery. They are adept at preparing students for high-levels of fluency and foster a deep cultural connection.",
 
  }
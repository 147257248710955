import React from "react";
import { NavLink } from "react-router-dom";
import { routes } from "src/Routes";

export default function ForgotPasswordLink() {
  return (
    <div>
      <NavLink to={routes.FORGOT_PASSWORD.url}>
        <h3 className="text-secondary text-xs cursor-pointer">
          Forgot password ?
        </h3>
      </NavLink>
    </div>
  );
}

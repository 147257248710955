import { FiPhoneCall } from "react-icons/fi";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useEffect, useState } from "react";
import { useStateValue } from "src/context/StateProvider";
import { getContactAddress } from "src/services/contact";
import clsx from "clsx";

export default function ContactAddress({
  colorVariant,
}: {
  colorVariant: string;
}) {
  const [{ clientIpCountry }] = useStateValue();
  const [address, setAddress] = useState<String>(
    "Topset Learning Ltd, 128 City Road, London, United Kingdom, EC1V 2NX"
  );

  useEffect(() => {
    if (clientIpCountry) {
      setAddress(getContactAddress(clientIpCountry));
    }
  }, [clientIpCountry]);

  if (!address) return null;

  return (
    <Tippy content="Call us" delay={1000}>
      <div className="relative px-12 text-center">
        <p
          className={clsx({
            "text-primary text-xs": colorVariant === "dark",
            "text-white text-xs": colorVariant === "light",
          })}
        >
          {address}
        </p>
      </div>
    </Tippy>
  );
}

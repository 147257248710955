import { useContext } from "react";
import HeaderText from "./HeaderText";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
export default function FrequencyForm({ max = 2 }: any) {
  const {
    state: { lessonFrequency },
    actions: { setLessonFrequency },
  } = useContext(DirectPayContext);
  const handleChangeFreq = (sign: string) => {
    if (sign === "+") {
      if (lessonFrequency < max) {
        setLessonFrequency(lessonFrequency + 1);
      }
    } else {
      if (lessonFrequency > 1) {
        setLessonFrequency(lessonFrequency - 1);
      }
    }
  };
  return (
    <div className="mt-8">
      <section className="max-w-xl mx-auto">
        <HeaderText> How many lessons do you want per week?</HeaderText>
        <p className="mt-3  font-semibold text-secondary">
          This helps us set your preferred days for your lessons
        </p>

        <div className="mt-14 flex items-center gap-6 justify-center">
          <button
            className="block border-2 p-2 rounded-l-3xl rounded-r-lg"
            style={{ borderColor: "#F4F4F4" }}
            onClick={() => handleChangeFreq("-")}
            disabled={lessonFrequency === 1}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 51 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4121 27.0468H38.4008"
                stroke="#341A64"
                opacity={lessonFrequency === 1 ? 0.5 : 1}
                strokeWidth="6.40735"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <p className="font-bold text-3xl">{lessonFrequency}</p>
          <button
            className="block border-2 p-2 rounded-r-3xl rounded-l-lg"
            style={{ borderColor: "#F4F4F4" }}
            onClick={() => handleChangeFreq("+")}
            disabled={lessonFrequency === max}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 51 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3105 27.0468H38.2992"
                stroke="#341A64"
                strokeWidth="6.40735"
                strokeLinecap="round"
                strokeLinejoin="round"
                opacity={lessonFrequency === max ? 0.5 : 1}
              />
              <path
                d="M25.8052 40.1818V13.9117"
                stroke="#341A64"
                strokeWidth="6.40735"
                strokeLinecap="round"
                strokeLinejoin="round"
                opacity={lessonFrequency === max ? 0.5 : 1}
              />
            </svg>
          </button>
        </div>
      </section>
    </div>
  );
}

import { useEffect, useState } from "react";

import { useStateValue } from "src/context/StateProvider";
import { getClientCurrency } from "src/services/currency";
import { IClientCurrencyData } from "src/interfaces/currency";
import { RiVipCrownLine } from "react-icons/ri";
import { ILandingPackageType } from "../PaymentPlans/PaymentPlan";

export default function LandingPaymentPlanCard({
  paymentPlans,
  onClick,
}: {
  paymentPlans: ILandingPackageType;
  onClick: any;
}) {
  const [{ clientIpCountry }] = useStateValue();
  const [currency, setCurrency] = useState<IClientCurrencyData | null>(null);
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState<boolean>(false);

  useEffect(() => {
    if (clientIpCountry) {
      let cp = getClientCurrency(clientIpCountry);
      switch (cp.currencyCode) {
        case "USD":
        case "CAD":
        case "GBP":
          setCurrency(cp);
          break;
        default:
          setCurrency({
            symbol: "$",
            fromNairaValue: 0.0024,
            fromPoundValue: 1.34,
            fromDollarValue: 1,
            currencyCode: "USD",
          });
      }
    }
  }, [clientIpCountry]);

  return (
    <div
      className={`px-3 pb-4 ${!isMoreInfoOpen && "sm:h-[310px] lg:h-[350px]"} ${
        paymentPlans.type.includes("Beginner & Elementary Package") ||
        paymentPlans.type.includes("2 Course Bundle")
          ? "gray-card-rounded"
          : "white-card-more-rounded"
      } `}
    >
      {paymentPlans.discount === 16 ? (
        <div className="flex justify-end">
          <div className="bg-secondary py-2 px-4 rounded-lg w-38 -mt-6 text-white">
            <p className="font-semibold">{paymentPlans.discount}% Discount</p>
          </div>
        </div>
      ) : paymentPlans.discount === 33 ? (
        <div className="flex justify-end">
          <div className="bg-green-500 py-2 px-4 rounded-lg w-38 -mt-6 flex gap-2 items-center text-white">
            <RiVipCrownLine size={18} />
            <p className="font-semibold">{paymentPlans.discount}% Discount</p>
          </div>
        </div>
      ) : (
        <div className="pb-4"></div>
      )}

      <div className="flex flex-col h-full">
        <div className="flex gap-3 justify-between text-primary pt-4 pb-2 ">
          {/* Package */}
          <p className="text-primary font-semibold w-60 text-lg">
            {paymentPlans.type}
          </p>
        </div>
        <div className=" py-2">
          <p className="text-primary-medium font-bold text-xl">
            {currency?.currencyCode === "USD"
              ? `${paymentPlans.perLessonRateUSD}`
              : currency?.currencyCode === "GBP"
              ? ` ${paymentPlans.perLessonRateGBP}`
              : currency?.currencyCode === "CAD"
              ? ` ${paymentPlans.perLessonRateCAD}`
              : `${paymentPlans.perLessonRateUSD}`}
            <span className="text-base">/Session</span>
          </p>
        </div>
        <div className="pb-4">
          <p className="text-gray-500 font-semibold">Total Price</p>
          <p className="text-primary-medium text-3xl font-semibold">
            {currency?.currencyCode === "USD"
              ? `${paymentPlans.totalLessonRateUSD}`
              : currency?.currencyCode === "GBP"
              ? ` ${paymentPlans.totalLessonRateGBP}`
              : currency?.currencyCode === "CAD"
              ? ` ${paymentPlans.totalLessonRateCAD}`
              : `${paymentPlans.totalLessonRateUSD}`}
          </p>
          <p className="text-primary mt-[-6px] font-medium text-md">
            {paymentPlans.lessonType}
          </p>
          <p className="text-primary mt-4 font-medium text-md">
            {paymentPlans.plan}
          </p>
        </div>

        <button
          className={`purple-button rounded-3xl w-full mt-3 sm:mt-auto ${
            !isMoreInfoOpen && "sm:mb-5"
          }`}
          onClick={onClick}
          data-test="d2pChooseAPaymentBundleButton"
        >
          Get Started
        </button>
      </div>
    </div>
  );
}

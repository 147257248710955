import { FC, ReactNode, Reducer, createContext, useReducer } from "react";
// interface Action<T> {
//   type: string;
//   payload?: T;
// }
interface ActionsMap<A> {
  [key: string]: (dispatch: React.Dispatch<A>) => (...args: any[]) => void;
}
interface BoundActionsMap {
  [key: string]: (...args: any[]) => void;
}

interface ContextProps<S, F> {
  state: S;
  actions: F;
}

interface CreateDataContextOptions<S, A> {
  reducer: Reducer<S, A>;
  actions: ActionsMap<A>;
  initialState: S;
}
export const createDataContext = <S extends {}, A extends {}, F extends {}>(
  options: CreateDataContextOptions<S, A>
) => {
  const Context = createContext<ContextProps<S, F>>({
    state: options.initialState,
    actions: {} as F,
  });

  const Provider: FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(options.reducer, options.initialState);

    const boundActions: BoundActionsMap = {};

    for (const key in options.actions) {
      boundActions[key] = options.actions[key](dispatch);
    }

    return (
      <Context.Provider
        value={{
          state,
          actions: boundActions as F,
        }}
      >
        {children}
      </Context.Provider>
    );
  };
  return { Context, Provider };
};

import Messages from "./Messages";
import Profile from "./Profile";
import Lessons from "./Lessons";
import Notifications from "./Notifications";
import { useStateValue } from "src/context/StateProvider";
import ParentMessages from "./ParentMessages";
import MiniGames from "./MiniGames";
import Rewards from "./Rewards";
import Leaderboards from "./Leaderboard";
import VirtualImmersions from "./VirtualImmersions";
import Learn from "./Learn";
import { useEffect, useState } from "react";
import { CustomerApi } from "src/api/customer.api";
import ParentResources from "./ParentResources";

export default function Menu() {
  const [{ user, userLessonDetails }] = useStateValue();
  const [dependants, setDependants] = useState<any>(null);

  useEffect(() => {
    const getDependants = async () => {
      const response = await CustomerApi.getDependantAccounts(user._id);
      setDependants(response);
    };
    if (user?.isParent) {
      getDependants();
    }
  }, [user]);

  return (
    <div>
      <p
        data-test="studentSideMenuHeader"
        className="text-xs text-gray-400 px-4 mb-6"
      >
        Student Dashboard
      </p>
      <ul>
        <li>
          <Profile />
        </li>
        <li>
          <Lessons />
        </li>
        <li>
          {user.isParent && dependants ? (
            <ParentResources dependants={dependants} />
          ) : (
            <Learn />
          )}
        </li>
        <li>
          <VirtualImmersions />
        </li>
        {/* <li>
          <MyTutors />
        </li> */}
        {/* <li>
          <Homework />
        </li> */}
        {/* Adult Level */}
        {userLessonDetails && (
          <div>
            {userLessonDetails.level === "Child Level" && (
              <li>
                <MiniGames />
              </li>
            )}
          </div>
        )}
        <li>
          {user.isParent && dependants ? (
            <ParentMessages dependants={dependants} />
          ) : (
            <Messages />
          )}
        </li>
        <li>
          <Rewards />
        </li>
        <li>
          <Leaderboards />
        </li>
        <li>
          <Notifications />
        </li>
      </ul>
    </div>
  );
}

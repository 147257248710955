import axios from "axios";
import { ICreateSubscription } from "src/interfaces/payment";

export class BillingApi {
	static async createSubscription(
		body: ICreateSubscription
	): Promise<any | undefined[]> {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_V2_SERVER_URL}/subscription`,
				body
			);
			return response.data;
		} catch (error) {
			console.log("caught error: ", error);
		}

		return;
	}
}

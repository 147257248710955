import React from "react";
import { PiCalendarDotsFill } from "react-icons/pi";

const SelectDateAndContinue = () => {
  return (
    <>
      <div className="flex items-center h-[300px] md:h-full justify-center">
        <div>
          <p className="bg-primary-opaque mx-auto p-2  w-[36px] rounded-xl">
            <PiCalendarDotsFill className="text-primary-medium text-xl" />
          </p>
          <p className="text-primary max-w-[120px]  font-medium text-center  md:text-center text-sm">
            Select a date to continue
          </p>
        </div>
      </div>
    </>
  );
};

export default SelectDateAndContinue;

export function splitIntoCategories(str: string) {
    if (!str) {
        return [];
    }
    // Split the string using the pattern that looks for the emojis as separators.
    // Adjust the regex to match the specific emojis used in your strings.
    const regex = /(:mortar_board:|:smiley:|:memo:|🎓|😃|📝)/;
    // Split the string, then filter out any empty strings that might result from the split.
    const parts = str.split(regex).filter((part) => part.trim() !== "");

    // Combine the emoji with the following text as one string in the array.
    const combinedParts = [];
    for (let i = 0; i < parts.length; i += 2) {
        combinedParts.push(parts[i]?.trim() + " " + parts[i + 1]?.trim());
    }

    return combinedParts;
}

interface EmojiMap {
    [shortcode: string]: string;
}

// Define the mapping of shortcodes to their emoji representations
// export const emojiMap: EmojiMap = {
//     ":mortar_board:": "🎓",
//     ":smiley:": "😃",
//     ":memo:": "📝",
//     // Extend this map as needed
// };
export const emojiMap: EmojiMap = {
    ":mortar_board: Education": "",
    ":smiley: Personality": "",
    ":memo: Experience": "",
    "🎓 Education": "",
    "😃 Personality": "",
    "📝 Experience": "",
    // Extend this map as needed
};

// Function to replace shortcodes in each string of the array with emojis
// export const replaceShortcodesInArray = (inputArray: string[]): string[] => {
//     return inputArray.map((text) =>
//         text.replace(/:(\w+): Education/g, (match) => emojiMap[match] || match)
//     );
// };

export const replaceShortcodesInArray = (inputArray: string[]): string[] => {
    if (!inputArray) {
        return [];
    }
    return inputArray.map((text) =>
        text
            .replace(":mortar_board: Education", emojiMap[":mortar_board: Education"])
            .replace(":smiley: Personality", emojiMap[":smiley: Personality"])
            .replace(":memo: Experience", emojiMap[":memo: Experience"])
            .replace("🎓 Education:", emojiMap[":mortar_board: Education"])
            .replace("😃 Personality:", emojiMap[":smiley: Personality"])
            .replace("📝 Experience:", emojiMap[":memo: Experience"])
            .replace("🎓 Education", emojiMap[":mortar_board: Education"])
            .replace("😃 Personality", emojiMap[":smiley: Personality"])
            .replace("📝 Experience", emojiMap[":memo: Experience"])
    );
};

export const replaceSingleShortcodeInArray = (inputArray: string): string => {
    if (!inputArray) {
        return "";
    }
    return inputArray.replace(":mortar_board: Education", emojiMap[":mortar_board: Education"])
        .replace(":smiley: Personality", emojiMap[":smiley: Personality"])
        .replace(":memo: Experience", emojiMap[":memo: Experience"])
        .replace("🎓 Education:", emojiMap[":mortar_board: Education"])
        .replace("😃 Personality:", emojiMap[":smiley: Personality"])
        .replace("📝 Experience:", emojiMap[":memo: Experience"])
        .replace("🎓 Education", emojiMap[":mortar_board: Education"])
        .replace("😃 Personality", emojiMap[":smiley: Personality"])
        .replace("📝 Experience", emojiMap[":memo: Experience"])
};

export const renderCorrectEmoji = (str: string) => {
    if (str?.includes("Education")) {
        return "🎓";
    } else if (str?.includes("Personality")) {
        return "😃";
    } else if (str?.includes("Experience")) {
        return "📝";
    } else {
        return "🏆";
    }
}
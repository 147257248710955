import React, { useState } from "react";
import { FlashcardProps } from "./types";

export default function Flashcard({ word }: FlashcardProps) {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="perspective">
      <div
        className={`flashcard ${isFlipped ? "flipped" : ""}`}
        onClick={handleFlip}
      >
        <div className="front bg-white flex-col p-6 shadow-lg rounded-lg cursor-pointer">
          <p className="text-primary font-semibold text-xl">{word.word}</p>
        </div>
        <div className="back bg-pale-purple-bg p-6 shadow-lg rounded-lg cursor-pointer">
          <p className="text-primary font-semibold text-xl">
            {word.englishTranslation}
          </p>
        </div>
      </div>
    </div>
  );
}

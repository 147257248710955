import OptionSelect from "./OptionSelect";
import {
  ageGroupOptions,
  levelOptions,
  partsOfSpeechOptions,
  topicOptions,
} from "../data/selectOptions";
import { WordsFilterProps } from "./types";
import MultiOptionSelect from "./MultiOptionSelect";
import IncrementCounter from "./IncrementCounter";
import MultiSelectDropdown from "./MultiSelectDropdown";

export default function Wordsfilter({
  ageGroup,
  setAgeGroup,
  level,
  setLevel,
  partsOfSpeech,
  setPartsOfSpeech,
  topics,
  setTopics,
  numberOfWords,
  setNumberOfWords,
  handleGetWords,
}: WordsFilterProps) {
  const minNumberOfWords = 1;
  const maxNumberOfWords = 10;
  const handleAgeGroupChange = (value: string) => {
    setAgeGroup(value);
  };
  const handleLevelChange = (value: string) => {
    setLevel(value);
  };
  const handlePartsOfSpeechChange = (value: string) => {
    if (partsOfSpeech.includes(value)) {
      setPartsOfSpeech(partsOfSpeech.filter((part) => part !== value));
    } else {
      setPartsOfSpeech([...partsOfSpeech, value]);
    }
  };

  const handleAddTopic = (value: string) => {
    if (topics.includes(value)) {
      return;
    }
    setTopics([...topics, value]);
  };
  const handleRemoveTopic = (value: string) => {
    setTopics(topics.filter((topic) => topic !== value));
  };
  const handleOnincrement = (value: number) => {
    if (value === maxNumberOfWords) {
      return;
    }
    setNumberOfWords(value + 1);
  };
  const handleOnDecrement = (value: number) => {
    if (value === minNumberOfWords) {
      return;
    }
    setNumberOfWords(value - 1);
  };

  const handleResetFilters = () => {
    setAgeGroup(ageGroupOptions[0].value);
    setLevel("1");
    setPartsOfSpeech([]);
    setTopics([]);
    setNumberOfWords(3);
  };

  return (
    <>
      <div className="white-card-rounded p-4">
        <div className="flex flex-wrap gap-4">
          <div className="p-4 flex-1">
            <h3 className="element-title">Number of words</h3>
            <IncrementCounter
              value={numberOfWords}
              minValue={minNumberOfWords}
              maxValue={maxNumberOfWords}
              onIncrement={handleOnincrement}
              onDecrement={handleOnDecrement}
            />
          </div>
          <div className="p-4 flex-1">
            <h3 className="element-title">Topic(s)</h3>
            <MultiSelectDropdown
              placeholderText="Select Topic"
              options={topicOptions}
              handleAddSelection={handleAddTopic}
              handleRemoveSelection={handleRemoveTopic}
              selectedOptions={topics}
            />
          </div>
          <div className="p-4 flex-1">
            <h3 className="element-title">Parts of speech</h3>
            <MultiOptionSelect
              options={partsOfSpeechOptions}
              onOptionClick={handlePartsOfSpeechChange}
              selectedOption={partsOfSpeech}
            />
          </div>
        </div>
        <div className="flex flex-wrap gap-4">
          <div className="p-4 flex-1">
            <h3 className="element-title">Age Group</h3>
            <OptionSelect
              options={ageGroupOptions}
              onOptionClick={handleAgeGroupChange}
              selectedOption={ageGroup}
            />
          </div>
          <div className="p-4 flex-1">
            <h3 className="element-title">Level</h3>
            <OptionSelect
              options={levelOptions}
              onOptionClick={handleLevelChange}
              selectedOption={level}
            />
          </div>
          <div className="p-4 flex-1 gap-4 mt-4">
            <button
              className="pink-outline-button w-full"
              onClick={handleResetFilters}
              data-testid="get-words"
            >
              Clear Filters
            </button>
            <button
              className="purple-button w-full mt-2"
              onClick={handleGetWords}
              data-testid="get-words"
            >
              Generate Words
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

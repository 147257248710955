import { useState } from "react";
import { selectorThemeFindATutor, styles } from "src/static-data/SelectOptions";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { MdArrowRightAlt } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { routes } from "src/Routes";
import ReactGA from "react-ga4";
import { useStateValue } from "src/context/StateProvider";

const featherBook = `${process.env.REACT_APP_ASSET_CDN}/feather-book.svg`;
export default function BookALessonSearchBar() {
  const navigate = useNavigate();
  const [selectedSubjectOption, setSelectedSubjectOption] = useState<any>([]);
  const animatedComponents = makeAnimated();
  const [{ languageOptions }] = useStateValue();
  const handleFindTutorClick = () => {
    // track event
    ReactGA.event({
      category: "CTA Click",
      action: "Book a lesson button clicked",
    });
    if (!selectedSubjectOption.value) {
      navigate(routes.BOOK_A_LESSON_VIA_ADMIN.url);
      return;
    }
    navigate(
      `${routes.BOOK_A_LESSON_VIA_ADMIN.url}?subject=${selectedSubjectOption.value}`
    );
  };

  const handleSubjectOptionsChange = (selectedOption: any) => {
    setSelectedSubjectOption(selectedOption);
  };

  return (
    <>
      <div className="bg-white w-full lg:w-[85%]  rounded-2xl mt-8 flex  gap-4 justify-center md:justify-between items-center py-3 px-4 shadow-xl">
        {/* Left Icon */}
        <div className="flex gap-4">
          <div className="hidden sm:flex flex-col justify-around">
            <img src={featherBook} width={35} height={35} alt="" />
          </div>
          <div className="flex sm:w-[310px]">
            {" "}
            <Select
              value={selectedSubjectOption}
              onChange={handleSubjectOptionsChange}
              options={languageOptions}
              components={animatedComponents}
              placeholder="What do you want to learn?"
              isMulti={false}
              theme={selectorThemeFindATutor}
              isSearchable
              styles={styles}
              className="text-primary text-lg rounded-3xl"
            />
          </div>
        </div>

        {/* Button */}
        <button
          type="button"
          className="flex justify-between gap-2 items-center bg-secondary hover:bg-secondary-light hover:shadow-xl text-white rounded-2xl
              px-4 py-3 text-sm"
          onClick={handleFindTutorClick}
        >
          {/* <p className="sm:block hidden">REQUEST A LESSON</p> */}
          <MdArrowRightAlt color="white" size={30} />
        </button>
      </div>
    </>
  );
}

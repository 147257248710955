import React from "react";
import Marquee from "react-fast-marquee";

const Carousel = ({ children }: any) => {
  return (
    <Marquee className="mt-16" speed={80} gradient={false}>
      {children}
    </Marquee>
  );
};

export default Carousel;

export const constants = {
    MIN_NUMBER_OF_REVIEWS: 1,
    BOOKING_FORM_PROPS: {
        CHILD_OR_DEPENDENT: "Child or dependant",
        ME: "Me",
        REGULAR: "Regular",
        ONE_OFF: "One-off",
    },
    LESSON_TYPES: {
        ONE_ON_ONE: "one-on-one",
        GROUP: "group",
        VIRTUAL_IMMERSION: "virtual-immersion",
    },
};

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useStateValue } from "src/context/StateProvider";
import Logout from "src/views/Authentication/Logout";
import StudentMenu from "../StudentDashboard/SideNav/Menu";
import TutorMenu from "../TutorDashboard/SideNav/Menu";

export default function MobileNav() {
  const [{ user }] = useStateValue();

  return (
    <div className="mt-6">
      <PerfectScrollbar className="px-6">
        {user?.type === "tutor" && (
          <div className="pb-12">
            <TutorMenu />
          </div>
        )}
        {user?.type === "student" && (
          <div className="pb-12">
            <StudentMenu />
          </div>
        )}
        <div className="mt-6">
          <Logout />
        </div>
      </PerfectScrollbar>
    </div>
  );
}

import React from "react";
import { motion } from "framer-motion";

export default function FadeInDown({ children }) {
  return (
    <div>
      {" "}
      <motion.div
        initial={{ y: -150 }}
        animate={{ y: 0 }}
        transition={{
          type: "spring",
          stiffness: 100,
          damping: 20,
          duration: 2,
        }}
      >
        {children}
      </motion.div>
    </div>
  );
}

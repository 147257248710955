import React from "react";
interface ILandingListItem {
  text: string;
}
export default function LandingListItem({ text }: ILandingListItem) {
  return (
    <p className="flex items-start gap-3">
      <svg
        width="20"
        height="20"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          minWidth: "20px",
          minHeight: "20px",
        }}
      >
        <path
          d="M14.8459 9.48569L14.846 9.48573L14.85 9.48152C15.2296 9.08888 15.2354 8.46425 14.8459 8.07474C14.4587 7.68748 13.8222 7.68748 13.435 8.07474L9.20651 12.3032L7.44937 10.5461C7.06211 10.1588 6.42569 10.1588 6.03842 10.5461C5.65116 10.9333 5.65116 11.5697 6.03842 11.957L8.50104 14.4196C8.68691 14.6055 8.93981 14.7122 9.20651 14.7122C9.47321 14.7122 9.72611 14.6055 9.91199 14.4196L14.8459 9.48569ZM2.08582 11.2428C2.08582 6.63892 5.83826 2.88648 10.4422 2.88648C15.0461 2.88648 18.7985 6.63892 18.7985 11.2428C18.7985 15.8467 15.0461 19.5992 10.4422 19.5992C5.83826 19.5992 2.08582 15.8467 2.08582 11.2428Z"
          fill="#D33479"
          stroke="#D33479"
          strokeWidth="0.690924"
        />
      </svg>

      <span className="font-medium ">{text}</span>
    </p>
  );
}

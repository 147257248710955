import { useContext, useEffect, useState } from "react";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import AltGetStartedLanding from "./AltGetStartedLanding";
import Logo from "../Shared/Header/Logo";

import FindingPerfectTutorMatch from "../LottieAnimations/FindingPerfectTutorMatch";
import FormLogic from "./FormLogic";
import useValidator from "./hooks/useValidator";

export default function AltGetStarted() {
  const {
    state: {
      subject,

      isLoading,
    },
  } = useContext(DirectPayContext);
  const [pageIndex, setPageIndex] = useState(1);
  const [formNum, setFormNum] = useState(subject === "igbo" ? 5 : 4);
  const { handleNextPage, handleValidator, handleFormCancel } = useValidator({
    pageIndex,
    setPageIndex,
    formNum,
    setFormNum,
  });
  // const handleNextPage = async () => {
  //   if (pageIndex === formNum) {
  //     try {
  //       await updateOnboarding(
  //         {
  //           onboarding: {
  //             ...onBoarding.onboarding,
  //             ageGroup,
  //             otherReason,
  //             reasonsForLearning,
  //             wordsThatDescribe,
  //           },
  //         },
  //         onBoarding.onboarding?._id
  //       );
  //       navigate(routes.DIRECT_TO_PAY_MEET_TUTORS_B.url);
  //     } catch (error) {
  //       toast.error("Unable to find tutors", {
  //         position: toast.POSITION.BOTTOM_RIGHT,
  //       });

  //       console.error(error);
  //     }
  //     return;
  //   }
  //   if (subject === "igbo") {
  //     if (pageIndex === 3) {
  //       if (formNum + 1 <= 6) {
  //         if (reasonsForLearning.includes("Other")) {
  //           setFormNum(formNum + 1);
  //         }
  //       } else {
  //         if (formNum === 5 && !reasonsForLearning.includes("Other")) {
  //           setFormNum(formNum - 1);
  //         }
  //       }
  //     }
  //   } else {
  //     if (formNum + 1 <= 5) {
  //       if (pageIndex === 2) {
  //         if (reasonsForLearning.includes("Other")) {
  //           setFormNum(formNum + 1);
  //         }
  //       }
  //     } else {
  //       if (formNum === 5 && !reasonsForLearning.includes("Other")) {
  //         setFormNum(formNum - 1);
  //       }
  //     }
  //   }
  //   setPageIndex((pageIndex) => pageIndex + 1);
  //   window.history.pushState({ pageIndex: pageIndex + 1 }, "", "");
  // };
  // const handleFormCancel = () => {
  //   if (pageIndex > 0) {
  //     setPageIndex((pageIndex) => pageIndex - 1);
  //     window.history.pushState({ pageIndex: pageIndex - 1 }, "", "");
  //   }

  //   if (reasonsForLearning.includes("Other")) {
  //     if (subject === "igbo") {
  //       if (pageIndex === 5) {
  //         setFormNum(formNum - 1);
  //       }
  //     } else {
  //       if (pageIndex === 4) {
  //         setFormNum(formNum - 1);
  //       }
  //     }
  //   }
  // };

  // Handle browser back/forward button click
  useEffect(() => {
    const handleBackForward = () => {
      if (
        window.history.state &&
        window.history.state.pageIndex !== undefined
      ) {
        setPageIndex(window.history.state.pageIndex);
      } else {
        setPageIndex(0);
      }
    };

    window.addEventListener("popstate", handleBackForward);

    return () => {
      window.removeEventListener("popstate", handleBackForward);
    };
  }, []);
  if (isLoading) {
    return <FindingPerfectTutorMatch />;
  }
  return (
    <div className="text-primary">
      <div className="p-8 flex justify-center sm:justify-start">
        <Logo />
      </div>
      {pageIndex === 0 ? (
        <AltGetStartedLanding onClick={handleNextPage} />
      ) : (
        pageIndex >= 1 && (
          <FormLogic
            formNum={formNum}
            handleFormCancel={handleFormCancel}
            handleValidator={handleValidator}
            pageIndex={pageIndex}
          />
        )
      )}
    </div>
  );
}

import React from "react";
import FormContainer from "./FormContainer";
import { useDirectPayContext } from "src/context/DirectPayContext";
import WordsThatDescribe from "../DirectToPay/CustomerInfo/WordsThatDescribe";
import PersonalityForm from "../DirectToPay/CustomerInfo/PersonalityForm";
import OtherReason from "../DirectToPay/CustomerInfo/OtherReason";
import DialectForm from "../DirectToPay/CustomerInfo/DialectForm";
import AgeGroupForm from "../DirectToPay/CustomerInfo/AgeGroupForm";
import PrimaryReasonForm from "../DirectToPay/CustomerInfo/PrimaryReasonForm";

interface FormLogicProps {
  handleValidator: () => void;
  handleFormCancel: () => void;
  pageIndex: number;
  formNum: number;
}

type FormComponent = React.ComponentType<any>;

const FormLogic: React.FC<FormLogicProps> = ({
  handleValidator,
  handleFormCancel,
  pageIndex,
  formNum,
}) => {
  const {
    state: { subject, reasonsForLearning },
  } = useDirectPayContext();
  const renderIgboForm = (): React.ReactElement => {
    const formMap: Record<number, FormComponent> = {
      1: AgeGroupForm,
      2: DialectForm,
      3: PrimaryReasonForm,
      4: reasonsForLearning.includes("Other") ? OtherReason : PersonalityForm,
      5: reasonsForLearning.includes("Other")
        ? PersonalityForm
        : WordsThatDescribe,
      6: WordsThatDescribe,
    };

    const Component = formMap[pageIndex];
    return <Component />;
  };

  const renderOtherLanguageForm = (): React.ReactElement => {
    const formMap: Record<number, FormComponent> = {
      1: AgeGroupForm,
      2: PrimaryReasonForm,
      3: reasonsForLearning.includes("Other") ? OtherReason : PersonalityForm,
      4: reasonsForLearning.includes("Other")
        ? PersonalityForm
        : WordsThatDescribe,
      5: WordsThatDescribe,
    };

    const Component = formMap[pageIndex];
    return <Component />;
  };

  const renderForm = (): React.ReactElement => {
    return subject === "igbo" ? renderIgboForm() : renderOtherLanguageForm();
  };

  return (
    <FormContainer
      onContinue={handleValidator}
      onCancel={handleFormCancel}
      currentIndex={pageIndex}
      numberOfQuestions={formNum}
    >
      {renderForm()}
    </FormContainer>
  );
};

export default FormLogic;

import { FiMessageSquare } from "react-icons/fi";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "src/context/StateProvider";
import { routes } from "src/Routes";
import { IMessageNotification } from "src/interfaces/notification";
import clsx from "clsx";

export default function MessageIcon() {
  const navigate = useNavigate();
  const [{ user, unreadMessages }] = useStateValue();

  return (
    <Tippy content="Messages" delay={1000}>
      <div
        className="relative cursor-pointer"
        onClick={() =>
          user.type === "student"
            ? navigate(routes.STUDENT_DASHBOARD_MESSAGES.url)
            : navigate(routes.TUTOR_DASHBOARD_MESSAGES.url)
        }
      >
        <FiMessageSquare className="text-primary" size={25} />
        {unreadMessages && (
          <div
            className={clsx({
              "absolute bg-secondary hover:bg-secondary-light text-xs w-auto px-2.5 py-1 text-white rounded-full flex justify-between -top-3 left-4":
                unreadMessages?.length,
              hidden: !unreadMessages?.length,
            })}
          >
            {unreadMessages?.length}
          </div>
        )}
      </div>
    </Tippy>
  );
}

import { HiAcademicCap } from "react-icons/hi";

export default function SupportCard() {
  return (
    <div className="rounded-2xl shadow-lg py-8 px-6 bg-white">
      <div className="bg-purple-100 p-3 rounded-full w-14">
        <HiAcademicCap className="text-purple-500" size={33} />
      </div>
      <div>
        <h3 className="text-secondary font-semibold text-2xl mt-4 flex gap-2">
          Academic
        </h3>
      </div>
      <div className="text-primary mt-3 lg:block">
        From Maths and English to Physics and Reasoning, we provide tutoring in
        most subjects from Primary to A Level
      </div>
    </div>
  );
}

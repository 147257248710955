import { RevolutCheckoutCardField } from "@revolut/checkout";
import { getData } from "country-list";
import { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useStateValue } from "src/context/StateProvider";
import initStripe from "src/utils/stripeUtils";
import "./style.css";

export default function StripePaymentForm({
	order,
	handleSubmitBookingForm,
	setIsPaymentFormOpen,
}: any) {
	const [{ user }] = useStateValue();
	const rcRef = useRef<null | RevolutCheckoutCardField>(null);
	const cardElementRef = useRef(null);
	const [cardErrors, setCardErrors] = useState([]);

	useEffect(() => {
		if (!order) return;

		// let stripePromise;

		// if (!stripePromise) {
		// 	stripePromise = loadStripe(
		// 		`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
		// 	).then((res) => {
		// 		PaymentApi.sendPaymentConfirmation(user, order.public_id).then(
		// 			(response) => {
		// 				handleSubmitBookingForm();
		// 				setIsPaymentFormOpen(false);
		// 			}
		// 		);
		// 	});
		// }
	}, [order]);

	async function handleFormSubmit(event: any) {
		event.preventDefault();

		const data = new FormData(event.target);

		// @ts-ignore
		// rcRef.current.submit({
		// 	// @ts-ignore
		// 	name: data.get("name"),
		// 	// @ts-ignore
		// 	email: order.email,
		// 	savePaymentMethodFor: "merchant",
		// 	billingAddress: {
		// 		// @ts-ignore
		// 		countryCode: data.get("countryCode"),
		// 		// @ts-ignore
		// 		region: data.get("region"),
		// 		// @ts-ignore
		// 		city: data.get("city"),
		// 		// @ts-ignore
		// 		streetLine1: data.get("streetLine1"),
		// 		// @ts-ignore
		// 		streetLine2: data.get("streetLine2"),
		// 		// @ts-ignore
		// 		postcode: data.get("postcode"),
		// 	},
		// });

		const stripe = await initStripe();

		if (stripe) {
			const { error } = await stripe.redirectToCheckout({
				lineItems: [
					{
						price: "plan_QafGqhzk3aut61",
						quantity: 1,
					},
				],
				mode: "subscription",
				successUrl: `http://localhost:3000/success`,
				cancelUrl: `http://localhost:3000/cancel`,
				customerEmail: "customer@email.com",
			});
			console.warn(error.message);
		}
	}

	if (order === null) {
		return (
			<>
				<h2>Checkout</h2>
				<h3>Order not found</h3>
			</>
		);
	}

	const sum = (order.order_amount.value / 100).toLocaleString("en", {
		style: "currency",
		currency: order.order_amount.currency,
	});

	return (
		<div className="rounded-sm w-[600px] p-4">
			<div className="relative">
				<h2 className="text-secondary font-bold text-center mb-6">
					{/* You will be charged ({sum}) for this booking */}
					Please enter your payment method details
				</h2>
				<div className="absolute right-1 top-0 text-gray-400 cursor-pointer">
					<AiOutlineClose
						size={25}
						onClick={() => setIsPaymentFormOpen(false)}
					/>
				</div>
			</div>

			<form onSubmit={handleFormSubmit}>
				<label>
					<input
						className="w-full p-2 border-2 text-primary placeholder-gray-purple border-pale-purple-bg rounded-lg"
						name="name"
						autoComplete="name"
						placeholder="Cardholder Name"
					/>
				</label>
				<label>
					<div
						className="w-full p-2 border-2 text-primary placeholder-gray-purple border-pale-purple-bg rounded-lg mt-2"
						ref={cardElementRef}
					/>
					<p className="form-field-error-message">
						{cardErrors.map(
							(error) =>
								// you can use `error.type` to customise message
								// @ts-ignore
								error.message
						)}
					</p>
				</label>
				<label>
					<select
						className="w-full p-2 border-2 text-primary placeholder-gray-purple border-pale-purple-bg rounded-lg mt-2"
						name="countryCode"
						defaultValue="GB"
						required
					>
						{getData().map((country) => (
							<option key={country.code} value={country.code}>
								{country.name}
							</option>
						))}
					</select>
				</label>
				<label>
					<input
						className="w-full p-2 border-2 text-primary placeholder-gray-purple border-pale-purple-bg rounded-lg mt-2"
						name="region"
						autoComplete="address-level1"
						placeholder="Region"
					/>
				</label>
				<label>
					<input
						className="w-full p-2 border-2 text-primary placeholder-gray-purple border-pale-purple-bg rounded-lg mt-2"
						name="city"
						autoComplete="address-level2"
						placeholder="City"
						required
					/>
				</label>
				<label>
					<input
						className="w-full p-2 border-2 text-primary placeholder-gray-purple border-pale-purple-bg rounded-lg mt-2"
						name="streetLine1"
						autoComplete="address-line1"
						placeholder="Address line 1"
						required
					/>
				</label>
				<label>
					<input
						className="w-full p-2 border-2 text-primary placeholder-gray-purple border-pale-purple-bg rounded-lg mt-2"
						name="streetLine2"
						autoComplete="address-line2"
						placeholder="Address line 2"
					/>
				</label>
				<div>
					<input
						className="w-full p-2 border-2 text-primary placeholder-gray-purple border-pale-purple-bg rounded-lg mt-2"
						name="postcode"
						autoComplete="postal-code"
						placeholder="Postal code"
						required
					/>
				</div>
				<button className="bg-primary hover:bg-primary-medium text-white rounded-lg w-full p-4 font-semibold mt-4">
					Pay {sum}
				</button>
			</form>
		</div>
	);
}

import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Header from "src/layouts/DashboardHeader";
import { content } from "../Content/content";
import { routes } from "src/Routes";
import BetaTag from "src/components/BetaTag";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

export default function LanguageLevelLanding() {
  const { languageId } = useParams();
  const [levels, setLevels] = useState<any[]>([]);
  // TODO: get language name from url

  const findUniqueLevels = (content: any[], languageId: string | undefined) => {
    const uniqueLevels = new Set<number>();
    content.forEach((item: { level: number; language: string }) => {
      if (item.language === languageId) {
        uniqueLevels.add(item.level);
      }
    });
    return Array.from(uniqueLevels);
  };

  useEffect(() => {
    const languageLevels = findUniqueLevels(content, languageId);
    setLevels(languageLevels);
  }, []);

  return (
    <>
      <Header />
      <div className="mt-4">
        <h1 className="lg:text-2xl font-semibold mb-2 text-primary-light text-center">
          Learn{" "}
          {`${languageId?.charAt(0).toUpperCase()}${languageId?.slice(1)}`}
        </h1>
      </div>
      <div className="px-4 flex justify-between items-center py-4">
        <NavLink to={`/learn/${languageId}`}>
          <div className="text-gray-purple cursor-pointer items-center flex gap-2"></div>
        </NavLink>
        <div className="max-w-[150px]">
          <BetaTag />
        </div>
      </div>
      <div className="flex flex-col gap-4 lg:px-4 mt-4">
        {levels.map((level: any, index: number) => (
          <NavLink
            key={index}
            to={`${
              routes.LEARNING_APP.url
            }/${languageId?.toLowerCase()}/${level}`}
          >
            <div className="white-card-rounded">
              <h1 className="lg:text-l font-semibold my-2 text-secondary-light text-center">
                {`Level ${level}`}
              </h1>
            </div>
          </NavLink>
        ))}
      </div>
    </>
  );
}

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LessonApi } from "src/api/lesson.api";
import ConfirmationModal from "./ConfirmationModal";
import React from "react";
import RewardCard from "./RewardCard";
import moment from "moment";

interface LessonRewardState {
  lessonId: string;
  tutorId: string;
  studentId: string;
  studentName: string;
  lessonDate: string;
  lessonSubject: string;
}

export default function LessonRewards() {
  const location = useLocation();
  const state = location.state as LessonRewardState;
  const [lessonRewards, setLessonRewards] = useState([]);
  const [thisLessonsReward, setThisLessonsRewards] = useState([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    React.useState(false);
  const [selectedReward, setSelectedReward] = React.useState(null);

  const { lessonId, studentName, lessonDate, lessonSubject } = state;

  const getRewardsData = async () => {
    const rewards = await LessonApi.getLessonRewards();
    setLessonRewards(rewards.data);
    const thisLessonsRewards = await LessonApi.getLessonRewardsByLesson(
      lessonId
    );
    setThisLessonsRewards(thisLessonsRewards.data);
  };

  useEffect(() => {
    getRewardsData();
  }, []);

  const handleClickReward = (reward: any) => () => {
    const isAssigned = thisLessonsReward.some(
      (thisReward: any) => thisReward.lessonRewardSpecId === reward._id
    );
    if (isAssigned) {
      return;
    }
    setSelectedReward(reward);
    setIsConfirmationModalOpen(true);
  };

  // Now you can use these variables in your component
  return (
    <div>
      <h1 className="text-primary text-center font-semibold text-2xl">
        {lessonSubject} Lesson with {studentName}
      </h1>
      <p className="text-primary text-center mb-8 text-sm">
        {moment(lessonDate).format("dddd, MMMM Do YYYY")}
      </p>
      <div className="flex gap-4 flex-wrap justify-center">
        {lessonRewards.map((reward: any) => (
          <div key={reward._id} onClick={handleClickReward(reward)}>
            <RewardCard
              reward={reward}
              thisLessonsRewards={thisLessonsReward}
            />
          </div>
        ))}
      </div>
      <div>
        <ConfirmationModal
          isConfirmationModalOpen={isConfirmationModalOpen}
          setIsConfirmationModalOpen={setIsConfirmationModalOpen}
          selectedReward={selectedReward}
          state={state}
          getRewardsData={getRewardsData}
        />
      </div>
    </div>
  );
}

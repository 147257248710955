import { useContext } from "react";
import HeaderText from "./HeaderText";
import ReasonCard from "./ReasonCard";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { toast } from "react-toastify";

interface IPrimaryReasonForm {
  alt?: boolean;
  onClick?: () => void;
}

export default function PrimaryReasonForm({
  alt,
  onClick,
}: IPrimaryReasonForm) {
  const {
    state: { subject, reasonsForLearning },
  } = useContext(DirectPayContext);

  const handleValidator = () => {
    if (reasonsForLearning.length === 0) {
      toast.error("Please select a reason for learning.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    if (onClick) onClick();
  };
  return (
    <div className="mt-12">
      <section className="mx-auto">
        <HeaderText>
          What’s your primary reason for learning
          <span>{subject === "yoruba" ? " Yoruba" : " Igbo"}</span>?
        </HeaderText>
        <p className=" mt-5 font-semibold text-secondary">
          This helps our tutors ensure that your primary learning goals are met
        </p>
      </section>
      <div className=" max-w-7xl mx-auto mt-9 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-7 ">
        <ReasonCard
          icon="🗣️"
          text="Learning my heritage language"
          dataTest="d2pReasonForLearningHeritage"
        />
        <ReasonCard icon="👨‍👩‍👧‍👦" text="Teaching my child" />
        <ReasonCard icon="❤️" text="For my Nigerian partner" />
        <ReasonCard icon="🌳" text="Exploring my new found roots" />
        <ReasonCard
          icon="⚙️"
          text="Other"
          dataTest="d2pReasonForLearningOther"
        />
      </div>
      {alt && (
        <button onClick={handleValidator} className="pink-button px-20 mt-14">
          Continue
        </button>
      )}
    </div>
  );
}

import { useNavigate } from "react-router-dom";
import ErrorAnimation from "../LottieAnimations/Error";

export default function InvalidPaymentLink({ dp }: any) {
  const navigate = useNavigate();
  return (
    <section className="text-primary body-font">
      <div className="container px-5 mx-auto">
        <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
          <ErrorAnimation />
          <h1 className=" font-extrabold text-red-600 text-2xl">
            Invalid Payment Link
          </h1>
          <span className="inline-block h-1 w-10 rounded bg-primary-medium mt-8 mb-6"></span>
          <p className="text-lg">
            We're sorry, but the payment link you are trying to access appears
            to be invalid or has expired. Please double-check the link and try
            again. If you believe this is an error or need further assistance,
            don't hesitate to contact our
            <a href="mailto:info@topset.app" className="underline">
              {" "}
              support team.{" "}
            </a>
            We're here to help.
          </p>
          <button
            onClick={() => {
              if (dp) {
                navigate(-1);
              } else {
                navigate("/");
              }
            }}
            className="mt-6 cursor-pointer bg-secondary border-secondary border-2 text-white py-3 px-4 rounded-lg hover:bg-white hover:text-secondary hover:border-2 hover:py-3 hover:px-4 hover:border-secondary"
          >
            Back {!dp && "to home"}
          </button>
        </div>
      </div>
    </section>
  );
}

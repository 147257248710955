import {
  getOnBoardingDetails,
  updateOnboardingDetails,
} from "src/server/endpoints/onboarding";

export class OnBoardingApi {
  static getOnBoardingDetails(userId: string) {
    return getOnBoardingDetails(userId);
  }
  static updateOnBoardingDetails(
    body: any,
    onboardingId?: string,
    userId?: string
  ) {
    return updateOnboardingDetails(body, onboardingId, userId);
  }
}

import { useContext } from "react";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import HeaderText from "./HeaderText";
export default function LeanerForm() {
  const {
    state: { numberOfUsers, whoLessonIsFor, subject },
    actions: { addLearner, removeLearner, setUserRole },
  } = useContext(DirectPayContext);

  const handleSelectRole = (role: typeof whoLessonIsFor) => {
    if (whoLessonIsFor === role) {
      setUserRole(null);
    } else {
      setUserRole(role);
    }
  };
  return (
    <div>
      <section className="max-w-2xl mx-auto">
        <HeaderText>
          How many individuals are registering to learn{" "}
          {subject === "yoruba" ? "Yoruba" : "Igbo"}?
        </HeaderText>
      </section>
      <div className="mt-14 flex items-center gap-6 justify-center">
        <button
          className="block border-2 p-2 rounded-l-3xl rounded-r-lg"
          style={{ borderColor: "#F4F4F4" }}
          onClick={removeLearner}
          disabled={numberOfUsers === 1}
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 51 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4121 27.0468H38.4008"
              stroke="#341A64"
              opacity={numberOfUsers === 1 ? 0.5 : 1}
              strokeWidth="6.40735"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <p className="font-bold text-3xl">{numberOfUsers}</p>
        <button
          className="block border-2 p-2 rounded-r-3xl rounded-l-lg"
          style={{ borderColor: "#F4F4F4" }}
          onClick={addLearner}
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 51 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3105 27.0468H38.2992"
              stroke="#341A64"
              strokeWidth="6.40735"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M25.8052 40.1818V13.9117"
              stroke="#341A64"
              strokeWidth="6.40735"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      <p className="mt-12 font-semibold text-lg">Are you one of them?</p>
      <div className="mt-6 flex flex-col items-center sm:flex-row gap-10 justify-center">
        <button
          className="px-6 py-3 border-2 rounded-xl font-semibold text-xl leading-7 transition-colors duration-200"
          style={{
            backgroundColor: whoLessonIsFor === "ME" ? "#F7F2FF" : "#F2F2F280",
            borderColor: whoLessonIsFor === "ME" ? "#341A64" : "#F4F4F4",
          }}
          onClick={() => {
            handleSelectRole("ME");
          }}
          data-test="d2pYesIAmButton"
        >
          Yes, I am
        </button>
        <button
          className="px-6 py-3 border-2 rounded-xl font-semibold text-xl leading-7 transition-colors duration-200"
          style={{
            backgroundColor:
              whoLessonIsFor === "DEPENDANT" ? "#F7F2FF" : "#F2F2F280",
            borderColor: whoLessonIsFor === "DEPENDANT" ? "#341A64" : "#F4F4F4",
          }}
          onClick={() => {
            handleSelectRole("DEPENDANT");
          }}
        >
          No, I am not
        </button>
      </div>
    </div>
  );
}

import React from "react";
import { useNavigate } from "react-router-dom";

export default function GameSelectionCard({ game }: any) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/student/dashboard/minigames/scratch-games/${game.projectId}`);
  };

  return (
    <div
      key={game.id}
      className="cursor-pointer transition-transform transform hover:scale-105"
      onClick={() => {
        handleNavigate();
      }}
    >
      <div className="white-card-rounded max-w-[300px] relative">
        <img
          src={game.image}
          alt={game.title}
          className="w-full h-full object-cover rounded-xl"
        />
        <div className="absolute inset-0 bg-black opacity-25 hover:opacity-0 rounded-xl"></div>
      </div>
      <h2 className="text-center text-primary mt-3 max-w-[300px]">
        {`${game.title} (${game.topic})`}
      </h2>
    </div>
  );
}

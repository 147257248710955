import { useEffect, useState } from "react";
import TestimonialCard from "./TestimonialCard";
import {
  YorubaWebTestimonials1,
  YorubaWebTestimonials2,
} from "src/static-data/Testimonials";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { routes } from "src/Routes";
import { useStateValue } from "src/context/StateProvider";
import LoginModal from "src/components/Shared/LoginModal";
import SignupModal from "src/components/Shared/SignupModal";

const leftVariants = {
  finish: { y: 25, transition: { duration: 5, ease: "easeOut", delay: 0 } },
  start: { y: -45 },
};
const rightVariants = {
  finish: { y: -25, transition: { duration: 5, ease: "easeOut", delay: 0 } },
  start: { y: 45 },
};

function WebTestimonials() {
  const [{ user }] = useStateValue();
  const leftControls = useAnimation();
  const rightControls = useAnimation();
  const [ref, inView] = useInView();
  const navigate = useNavigate();
  const [loginIsOpen, setLoginIsOpen] = useState<boolean>(false);
  const [signupIsOpen, setSignupIsOpen] = useState<boolean>(false);

  const handleFindTutorClick = () => {
    // if (user) {
    //   navigate(routes.BOOK_A_LESSON_VIA_ADMIN.url);
    //   return;
    // } else {
    //   setLoginIsOpen(true);
    // }
    navigate(routes.BOOK_A_LESSON_VIA_ADMIN.url);
  };

  useEffect(() => {
    if (inView) {
      leftControls.start("finish");
      rightControls.start("finish");
    }
    if (!inView) {
      leftControls.start("start");
      rightControls.start("start");
    }
  }, [leftControls, rightControls, inView]);

  return (
    <>
      <div className="container py-12 px-12 flex gap-12 overflow-hidden items-center">
        {/* Left Div */}
        <div className="flex gap-4 rotate-6" ref={ref}>
          {/* Left Column */}
          <motion.div
            className="flex flex-col gap-4"
            animate={leftControls}
            initial="start"
            variants={leftVariants}
          >
            {YorubaWebTestimonials1.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                imageSrc={testimonial.imageSrc}
                name={testimonial.name}
                bio={testimonial.bio}
                review={testimonial.review}
                reviewBy={testimonial.reviewBy}
                // when={testimonial.when}
              />
            ))}
          </motion.div>
          {/* Right Column */}
          <motion.div
            className="flex flex-col gap-4"
            animate={rightControls}
            initial="start"
            variants={rightVariants}
          >
            {YorubaWebTestimonials2.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                imageSrc={testimonial.imageSrc}
                name={testimonial.name}
                bio={testimonial.bio}
                review={testimonial.review}
                reviewBy={testimonial.reviewBy}
                // when={testimonial.when}
              />
            ))}
          </motion.div>
        </div>
        {/* Right Div */}
        <div className="px-4">
          <h2 className="text-primary font-bold text-3xl mt-4  flex gap-2">
            Highly rated and verified tutors
          </h2>
          <p className="text-primary text-lg mt-8 flex gap-2">
            Our teachers have received raving reviews from students and we
            wouldn’t have it any other way! All our teachers hold a verified
            university degree as well as teaching experience.
          </p>
          {/* Button */}
          <button
            type="button"
            className="flex mt-10 justify-between gap-2 items-center bg-secondary hover:bg-secondary-light hover:shadow-xl text-white rounded-2xl
              px-12 py-3 z-10"
            onClick={handleFindTutorClick}
          >
            REQUEST A LESSON
          </button>
        </div>
      </div>
      <LoginModal
        loginIsOpen={loginIsOpen}
        setLoginIsOpen={setLoginIsOpen}
        setSignupIsOpen={setSignupIsOpen}
      />
      <SignupModal
        signupIsOpen={signupIsOpen}
        setSignupIsOpen={setSignupIsOpen}
        setLoginIsOpen={setLoginIsOpen}
      />
    </>
  );
}

export default WebTestimonials;

import { NavLink } from "react-router-dom";
import Lottie from "lottie-react";
import * as emptyAnimation from "src/assets/animations/empty-loading.json";
import Header from "src/layouts/DashboardHeader";

export default function EmptyList({ message }: any) {
  return (
    <>
      <div className="grid place-items-center h-[75vh]">
        <div className="flex flex-col items-center">
          <div className="-mb-24">
            <h1 className="lg:text-2xl text-primary-light text-center">
              {message}
            </h1>
          </div>
          <Lottie
            animationData={emptyAnimation}
            style={{
              height: 350,
              width: 350,
            }}
          />
        </div>
      </div>
    </>
  );
}

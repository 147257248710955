export const aryIannaTimeZones = [
  { value: "Africa/Johannesburg", label: "Africa/Johannesburg" },
  { value: "Africa/Lagos", label: "Africa/Lagos" },
  { value: "America/Los_Angeles", label: "America/Los_Angeles" },
  { value: "America/New_York", label: "America/New_York" },
  { value: "Asia/Kolkata", label: "Asia/Kolkata" },
  { value: "Asia/Tokyo", label: "Asia/Tokyo" },
  { value: "Europe/Berlin", label: "Europe/Berlin" },
  { value: "Europe/London", label: "Europe/London" },
  { value: "Europe/Moscow", label: "Europe/Moscow" },
  { value: "Pacific/Auckland", label: "Pacific/Auckland" },
  { value: "Pacific/Honolulu", label: "Pacific/Honolulu" },
  { value: "UTC", label: "UTC" },
  { value: "Africa/Abidjan", label: "Africa/Abidjan" },
  { value: "Africa/Accra", label: "Africa/Accra" },
  { value: "Africa/Addis_Ababa", label: "Africa/Addis_Ababa" },
  { value: "Africa/Algiers", label: "Africa/Algiers" },
  { value: "Africa/Asmara", label: "Africa/Asmara" },
  { value: "Africa/Bamako", label: "Africa/Bamako" },
  { value: "Africa/Bangui", label: "Africa/Bangui" },
  { value: "Africa/Banjul", label: "Africa/Banjul" },
  { value: "Africa/Bissau", label: "Africa/Bissau" },
  { value: "Africa/Blantyre", label: "Africa/Blantyre" },
  { value: "Africa/Brazzaville", label: "Africa/Brazzaville" },
  { value: "Africa/Bujumbura", label: "Africa/Bujumbura" },
  { value: "Africa/Cairo", label: "Africa/Cairo" },
  { value: "Africa/Casablanca", label: "Africa/Casablanca" },
  { value: "Africa/Ceuta", label: "Africa/Ceuta" },
  { value: "Africa/Conakry", label: "Africa/Conakry" },
  { value: "Africa/Dakar", label: "Africa/Dakar" },
  { value: "Africa/Dar_es_Salaam", label: "Africa/Dar_es_Salaam" },
  { value: "Africa/Djibouti", label: "Africa/Djibouti" },
  { value: "Africa/Douala", label: "Africa/Douala" },
  { value: "Africa/El_Aaiun", label: "Africa/El_Aaiun" },
  { value: "Africa/Freetown", label: "Africa/Freetown" },
  { value: "Africa/Gaborone", label: "Africa/Gaborone" },
  { value: "Africa/Harare", label: "Africa/Harare" },
  { value: "Africa/Juba", label: "Africa/Juba" },
  { value: "Africa/Kampala", label: "Africa/Kampala" },
  { value: "Africa/Khartoum", label: "Africa/Khartoum" },
  { value: "Africa/Kigali", label: "Africa/Kigali" },
  { value: "Africa/Kinshasa", label: "Africa/Kinshasa" },
  { value: "Africa/Libreville", label: "Africa/Libreville" },
  { value: "Africa/Lome", label: "Africa/Lome" },
  { value: "Africa/Luanda", label: "Africa/Luanda" },
  { value: "Africa/Lubumbashi", label: "Africa/Lubumbashi" },
  { value: "Africa/Lusaka", label: "Africa/Lusaka" },
  { value: "Africa/Malabo", label: "Africa/Malabo" },
  { value: "Africa/Maputo", label: "Africa/Maputo" },
  { value: "Africa/Maseru", label: "Africa/Maseru" },
  { value: "Africa/Mbabane", label: "Africa/Mbabane" },
  { value: "Africa/Mogadishu", label: "Africa/Mogadishu" },
  { value: "Africa/Monrovia", label: "Africa/Monrovia" },
  { value: "Africa/Nairobi", label: "Africa/Nairobi" },
  { value: "Africa/Ndjamena", label: "Africa/Ndjamena" },
  { value: "Africa/Niamey", label: "Africa/Niamey" },
  { value: "Africa/Nouakchott", label: "Africa/Nouakchott" },
  { value: "Africa/Ouagadougou", label: "Africa/Ouagadougou" },
  { value: "Africa/Porto-Novo", label: "Africa/Porto-Novo" },
  { value: "Africa/Sao_Tome", label: "Africa/Sao_Tome" },
  { value: "Africa/Tripoli", label: "Africa/Tripoli" },
  { value: "Africa/Tunis", label: "Africa/Tunis" },
  { value: "Africa/Windhoek", label: "Africa/Windhoek" },
  { value: "America/Adak", label: "America/Adak" },
  { value: "America/Anchorage", label: "America/Anchorage" },
  { value: "America/Anguilla", label: "America/Anguilla" },
  { value: "America/Antigua", label: "America/Antigua" },
  { value: "America/Araguaina", label: "America/Araguaina" },
  {
    value: "America/Argentina/Buenos_Aires",
    label: "America/Argentina/Buenos_Aires",
  },
  {
    value: "America/Argentina/Catamarca",
    label: "America/Argentina/Catamarca",
  },
  { value: "America/Argentina/Cordoba", label: "America/Argentina/Cordoba" },
  { value: "America/Argentina/Jujuy", label: "America/Argentina/Jujuy" },
  { value: "America/Argentina/La_Rioja", label: "America/Argentina/La_Rioja" },
  { value: "America/Argentina/Mendoza", label: "America/Argentina/Mendoza" },
  {
    value: "America/Argentina/Rio_Gallegos",
    label: "America/Argentina/Rio_Gallegos",
  },
  { value: "America/Argentina/Salta", label: "America/Argentina/Salta" },
  { value: "America/Argentina/San_Juan", label: "America/Argentina/San_Juan" },
  { value: "America/Argentina/San_Luis", label: "America/Argentina/San_Luis" },
  { value: "America/Argentina/Tucuman", label: "America/Argentina/Tucuman" },
  { value: "America/Argentina/Ushuaia", label: "America/Argentina/Ushuaia" },
  { value: "America/Aruba", label: "America/Aruba" },
  { value: "America/Asuncion", label: "America/Asuncion" },
  { value: "America/Atikokan", label: "America/Atikokan" },
  { value: "America/Bahia", label: "America/Bahia" },
  { value: "America/Bahia_Banderas", label: "America/Bahia_Banderas" },
  { value: "America/Barbados", label: "America/Barbados" },
  { value: "America/Belem", label: "America/Belem" },
  { value: "America/Belize", label: "America/Belize" },
  { value: "America/Blanc-Sablon", label: "America/Blanc-Sablon" },
  { value: "America/Boa_Vista", label: "America/Boa_Vista" },
  { value: "America/Bogota", label: "America/Bogota" },
  { value: "America/Boise", label: "America/Boise" },
  { value: "America/Cambridge_Bay", label: "America/Cambridge_Bay" },
  { value: "America/Campo_Grande", label: "America/Campo_Grande" },
  { value: "America/Cancun", label: "America/Cancun" },
  { value: "America/Caracas", label: "America/Caracas" },
  { value: "America/Cayenne", label: "America/Cayenne" },
  { value: "America/Cayman", label: "America/Cayman" },
  { value: "America/Chicago", label: "America/Chicago" },
  { value: "America/Chihuahua", label: "America/Chihuahua" },
  { value: "America/Costa_Rica", label: "America/Costa_Rica" },
  { value: "America/Creston", label: "America/Creston" },
  { value: "America/Cuiaba", label: "America/Cuiaba" },
  { value: "America/Curacao", label: "America/Curacao" },
  { value: "America/Danmarkshavn", label: "America/Danmarkshavn" },
  { value: "America/Dawson", label: "America/Dawson" },
  { value: "America/Dawson_Creek", label: "America/Dawson_Creek" },
  { value: "America/Denver", label: "America/Denver" },
  { value: "America/Detroit", label: "America/Detroit" },
  { value: "America/Dominica", label: "America/Dominica" },
  { value: "America/Edmonton", label: "America/Edmonton" },
  { value: "America/Eirunepe", label: "America/Eirunepe" },
  { value: "America/El_Salvador", label: "America/El_Salvador" },
  { value: "America/Fort_Nelson", label: "America/Fort_Nelson" },
  { value: "America/Fortaleza", label: "America/Fortaleza" },
  { value: "America/Glace_Bay", label: "America/Glace_Bay" },
  { value: "America/Godthab", label: "America/Godthab" },
  { value: "America/Goose_Bay", label: "America/Goose_Bay" },
  { value: "America/Grand_Turk", label: "America/Grand_Turk" },
  { value: "America/Grenada", label: "America/Grenada" },
  { value: "America/Guadeloupe", label: "America/Guadeloupe" },
  { value: "America/Guatemala", label: "America/Guatemala" },
  { value: "America/Guayaquil", label: "America/Guayaquil" },
  { value: "America/Guyana", label: "America/Guyana" },
  { value: "America/Halifax", label: "America/Halifax" },
  { value: "America/Havana", label: "America/Havana" },
  { value: "America/Hermosillo", label: "America/Hermosillo" },
  {
    value: "America/Indiana/Indianapolis",
    label: "America/Indiana/Indianapolis",
  },
  { value: "America/Indiana/Knox", label: "America/Indiana/Knox" },
  { value: "America/Indiana/Marengo", label: "America/Indiana/Marengo" },
  { value: "America/Indiana/Petersburg", label: "America/Indiana/Petersburg" },
  { value: "America/Indiana/Tell_City", label: "America/Indiana/Tell_City" },
  { value: "America/Indiana/Vevay", label: "America/Indiana/Vevay" },
  { value: "America/Indiana/Vincennes", label: "America/Indiana/Vincennes" },
  { value: "America/Indiana/Winamac", label: "America/Indiana/Winamac" },
  { value: "America/Inuvik", label: "America/Inuvik" },
  { value: "America/Iqaluit", label: "America/Iqaluit" },
  { value: "America/Jamaica", label: "America/Jamaica" },
  { value: "America/Juneau", label: "America/Juneau" },
  {
    value: "America/Kentucky/Louisville",
    label: "America/Kentucky/Louisville",
  },
  {
    value: "America/Kentucky/Monticello",
    label: "America/Kentucky/Monticello",
  },
  { value: "America/Kralendijk", label: "America/Kralendijk" },
  { value: "America/La_Paz", label: "America/La_Paz" },
  { value: "America/Lima", label: "America/Lima" },
  { value: "America/Los_Angeles", label: "America/Los_Angeles" },
  { value: "America/Lower_Princes", label: "America/Lower_Princes" },
  { value: "America/Maceio", label: "America/Maceio" },
  { value: "America/Managua", label: "America/Managua" },
  { value: "America/Manaus", label: "America/Manaus" },
  { value: "America/Marigot", label: "America/Marigot" },
  { value: "America/Martinique", label: "America/Martinique" },
  { value: "America/Matamoros", label: "America/Matamoros" },
  { value: "America/Mazatlan", label: "America/Mazatlan" },
  { value: "America/Menominee", label: "America/Menominee" },
  { value: "America/Merida", label: "America/Merida" },
  { value: "America/Metlakatla", label: "America/Metlakatla" },
  { value: "America/Mexico_City", label: "America/Mexico_City" },
  { value: "America/Miquelon", label: "America/Miquelon" },
  { value: "America/Moncton", label: "America/Moncton" },
  { value: "America/Monterrey", label: "America/Monterrey" },
  { value: "America/Montevideo", label: "America/Montevideo" },
  { value: "America/Montserrat", label: "America/Montserrat" },
  { value: "America/Nassau", label: "America/Nassau" },
  { value: "America/New_York", label: "America/New_York" },
  { value: "America/Nipigon", label: "America/Nipigon" },
  { value: "America/Nome", label: "America/Nome" },
  { value: "America/Noronha", label: "America/Noronha" },
  {
    value: "America/North_Dakota/Beulah",
    label: "America/North_Dakota/Beulah",
  },
  {
    value: "America/North_Dakota/Center",
    label: "America/North_Dakota/Center",
  },
  {
    value: "America/North_Dakota/New_Salem",
    label: "America/North_Dakota/New_Salem",
  },
  { value: "America/Ojinaga", label: "America/Ojinaga" },
  { value: "America/Panama", label: "America/Panama" },
  { value: "America/Pangnirtung", label: "America/Pangnirtung" },
  { value: "America/Paramaribo", label: "America/Paramaribo" },
  { value: "America/Phoenix", label: "America/Phoenix" },
  { value: "America/Port-au-Prince", label: "America/Port-au-Prince" },
  { value: "America/Port_of_Spain", label: "America/Port_of_Spain" },
  { value: "America/Porto_Velho", label: "America/Porto_Velho" },
  { value: "America/Puerto_Rico", label: "America/Puerto_Rico" },
  { value: "America/Rainy_River", label: "America/Rainy_River" },
  { value: "America/Rankin_Inlet", label: "America/Rankin_Inlet" },
  { value: "America/Recife", label: "America/Recife" },
  { value: "America/Regina", label: "America/Regina" },
  { value: "America/Resolute", label: "America/Resolute" },
  { value: "America/Rio_Branco", label: "America/Rio_Branco" },
  { value: "America/Santarem", label: "America/Santarem" },
  { value: "America/Santiago", label: "America/Santiago" },
  { value: "America/Santo_Domingo", label: "America/Santo_Domingo" },
  { value: "America/Sao_Paulo", label: "America/Sao_Paulo" },
  { value: "America/Scoresbysund", label: "America/Scoresbysund" },
  { value: "America/Sitka", label: "America/Sitka" },
  { value: "America/St_Barthelemy", label: "America/St_Barthelemy" },
  { value: "America/St_Johns", label: "America/St_Johns" },
  { value: "America/St_Kitts", label: "America/St_Kitts" },
  { value: "America/St_Lucia", label: "America/St_Lucia" },
  { value: "America/St_Thomas", label: "America/St_Thomas" },
  { value: "America/St_Vincent", label: "America/St_Vincent" },
  { value: "America/Swift_Current", label: "America/Swift_Current" },
  { value: "America/Tegucigalpa", label: "America/Tegucigalpa" },
  { value: "America/Thule", label: "America/Thule" },
  { value: "America/Thunder_Bay", label: "America/Thunder_Bay" },
  { value: "America/Tijuana", label: "America/Tijuana" },
  { value: "America/Toronto", label: "America/Toronto" },
  { value: "America/Tortola", label: "America/Tortola" },
  { value: "America/Vancouver", label: "America/Vancouver" },
  { value: "America/Whitehorse", label: "America/Whitehorse" },
  { value: "America/Winnipeg", label: "America/Winnipeg" },
  { value: "America/Yakutat", label: "America/Yakutat" },
  { value: "America/Yellowknife", label: "America/Yellowknife" },
  { value: "Antarctica/Casey", label: "Antarctica/Casey" },
  { value: "Antarctica/Davis", label: "Antarctica/Davis" },
  { value: "Antarctica/DumontDUrville", label: "Antarctica/DumontDUrville" },
  { value: "Antarctica/Macquarie", label: "Antarctica/Macquarie" },
  { value: "Antarctica/Mawson", label: "Antarctica/Mawson" },
  { value: "Antarctica/McMurdo", label: "Antarctica/McMurdo" },
  { value: "Antarctica/Palmer", label: "Antarctica/Palmer" },
  { value: "Antarctica/Rothera", label: "Antarctica/Rothera" },
  { value: "Antarctica/Syowa", label: "Antarctica/Syowa" },
  { value: "Antarctica/Troll", label: "Antarctica/Troll" },
  { value: "Antarctica/Vostok", label: "Antarctica/Vostok" },
  { value: "Arctic/Longyearbyen", label: "Arctic/Longyearbyen" },
  { value: "Asia/Aden", label: "Asia/Aden" },
  { value: "Asia/Almaty", label: "Asia/Almaty" },
  { value: "Asia/Amman", label: "Asia/Amman" },
  { value: "Asia/Anadyr", label: "Asia/Anadyr" },
  { value: "Asia/Aqtau", label: "Asia/Aqtau" },
  { value: "Asia/Aqtobe", label: "Asia/Aqtobe" },
  { value: "Asia/Ashgabat", label: "Asia/Ashgabat" },
  { value: "Asia/Atyrau", label: "Asia/Atyrau" },
  { value: "Asia/Baghdad", label: "Asia/Baghdad" },
  { value: "Asia/Bahrain", label: "Asia/Bahrain" },
  { value: "Asia/Baku", label: "Asia/Baku" },
  { value: "Asia/Bangkok", label: "Asia/Bangkok" },
  { value: "Asia/Barnaul", label: "Asia/Barnaul" },
  { value: "Asia/Beirut", label: "Asia/Beirut" },
  { value: "Asia/Bishkek", label: "Asia/Bishkek" },
  { value: "Asia/Brunei", label: "Asia/Brunei" },
  { value: "Asia/Chita", label: "Asia/Chita" },
  { value: "Asia/Choibalsan", label: "Asia/Choibalsan" },
  { value: "Asia/Colombo", label: "Asia/Colombo" },
  { value: "Asia/Damascus", label: "Asia/Damascus" },
  { value: "Asia/Dhaka", label: "Asia/Dhaka" },
  { value: "Asia/Dili", label: "Asia/Dili" },
  { value: "Asia/Dubai", label: "Asia/Dubai" },
  { value: "Asia/Dushanbe", label: "Asia/Dushanbe" },
  { value: "Asia/Famagusta", label: "Asia/Famagusta" },
  { value: "Asia/Kabul", label: "Asia/Kabul" },
  { value: "Asia/Kamchatka", label: "Asia/Kamchatka" },
  { value: "Asia/Karachi", label: "Asia/Karachi" },
  { value: "Asia/Kathmandu", label: "Asia/Kathmandu" },
  { value: "Asia/Khandyga", label: "Asia/Khandyga" },
  { value: "Asia/Kolkata", label: "Asia/Kolkata" },
  { value: "Asia/Krasnoyarsk", label: "Asia/Krasnoyarsk" },
  { value: "Asia/Kuala_Lumpur", label: "Asia/Kuala_Lumpur" },
  { value: "Asia/Kuching", label: "Asia/Kuching" },
  { value: "Asia/Kuwait", label: "Asia/Kuwait" },
  { value: "Asia/Macau", label: "Asia/Macau" },
  { value: "Asia/Magadan", label: "Asia/Magadan" },
  { value: "Asia/Makassar", label: "Asia/Makassar" },
  { value: "Asia/Manila", label: "Asia/Manila" },
  { value: "Asia/Muscat", label: "Asia/Muscat" },
  { value: "Asia/Nicosia", label: "Asia/Nicosia" },
  { value: "Asia/Novokuznetsk", label: "Asia/Novokuznetsk" },
  { value: "Asia/Novosibirsk", label: "Asia/Novosibirsk" },
  { value: "Asia/Omsk", label: "Asia/Omsk" },
  { value: "Asia/Oral", label: "Asia/Oral" },
  { value: "Asia/Phnom_Penh", label: "Asia/Phnom_Penh" },
  { value: "Asia/Pontianak", label: "Asia/Pontianak" },
  { value: "Asia/Pyongyang", label: "Asia/Pyongyang" },
  { value: "Asia/Qatar", label: "Asia/Qatar" },
  { value: "Asia/Qostanay", label: "Asia/Qostanay" },
  { value: "Asia/Qyzylorda", label: "Asia/Qyzylorda" },
  { value: "Asia/Riyadh", label: "Asia/Riyadh" },
  { value: "Asia/Sakhalin", label: "Asia/Sakhalin" },
  { value: "Asia/Samarkand", label: "Asia/Samarkand" },
  { value: "Asia/Seoul", label: "Asia/Seoul" },
  { value: "Asia/Shanghai", label: "Asia/Shanghai" },
  { value: "Asia/Singapore", label: "Asia/Singapore" },
  { value: "Asia/Srednekolymsk", label: "Asia/Srednekolymsk" },
  { value: "Asia/Taipei", label: "Asia/Taipei" },
  { value: "Asia/Tashkent", label: "Asia/Tashkent" },
  { value: "Asia/Tbilisi", label: "Asia/Tbilisi" },
  { value: "Asia/Tehran", label: "Asia/Tehran" },
  { value: "Asia/Thimphu", label: "Asia/Thimphu" },
  { value: "Asia/Tokyo", label: "Asia/Tokyo" },
  { value: "Asia/Tomsk", label: "Asia/Tomsk" },
  { value: "Asia/Ulaanbaatar", label: "Asia/Ulaanbaatar" },
  { value: "Asia/Urumqi", label: "Asia/Urumqi" },
  { value: "Asia/Ust-Nera", label: "Asia/Ust-Nera" },
  { value: "Asia/Vientiane", label: "Asia/Vientiane" },
  { value: "Asia/Vladivostok", label: "Asia/Vladivostok" },
  { value: "Asia/Yakutsk", label: "Asia/Yakutsk" },
  { value: "Asia/Yangon", label: "Asia/Yangon" },
  { value: "Asia/Yekaterinburg", label: "Asia/Yekaterinburg" },
  { value: "Asia/Yerevan", label: "Asia/Yerevan" },
  { value: "Atlantic/Azores", label: "Atlantic/Azores" },
  { value: "Atlantic/Bermuda", label: "Atlantic/Bermuda" },
  { value: "Atlantic/Canary", label: "Atlantic/Canary" },
  { value: "Atlantic/Cape_Verde", label: "Atlantic/Cape_Verde" },
  { value: "Atlantic/Faroe", label: "Atlantic/Faroe" },
  { value: "Atlantic/Madeira", label: "Atlantic/Madeira" },
  { value: "Atlantic/Reykjavik", label: "Atlantic/Reykjavik" },
  { value: "Atlantic/South_Georgia", label: "Atlantic/South_Georgia" },
  { value: "Atlantic/Stanley", label: "Atlantic/Stanley" },
  { value: "Australia/Adelaide", label: "Australia/Adelaide" },
  { value: "Australia/Brisbane", label: "Australia/Brisbane" },
  { value: "Australia/Broken_Hill", label: "Australia/Broken_Hill" },
  { value: "Australia/Currie", label: "Australia/Currie" },
  { value: "Australia/Darwin", label: "Australia/Darwin" },
  { value: "Australia/Eucla", label: "Australia/Eucla" },
  { value: "Australia/Hobart", label: "Australia/Hobart" },
  { value: "Australia/Lindeman", label: "Australia/Lindeman" },
  { value: "Australia/Lord_Howe", label: "Australia/Lord_Howe" },
  { value: "Australia/Melbourne", label: "Australia/Melbourne" },
  { value: "Australia/Perth", label: "Australia/Perth" },
  { value: "Australia/Sydney", label: "Australia/Sydney" },
  { value: "Europe/Amsterdam", label: "Europe/Amsterdam" },
  { value: "Europe/Andorra", label: "Europe/Andorra" },
  { value: "Europe/Astrakhan", label: "Europe/Astrakhan" },
  { value: "Europe/Athens", label: "Europe/Athens" },
  { value: "Europe/Belgrade", label: "Europe/Belgrade" },
  { value: "Europe/Berlin", label: "Europe/Berlin" },
  { value: "Europe/Bratislava", label: "Europe/Bratislava" },
  { value: "Europe/Brussels", label: "Europe/Brussels" },
  { value: "Europe/Bucharest", label: "Europe/Bucharest" },
  { value: "Europe/Budapest", label: "Europe/Budapest" },
  { value: "Europe/Busingen", label: "Europe/Busingen" },
  { value: "Europe/Chisinau", label: "Europe/Chisinau" },
  { value: "Europe/Copenhagen", label: "Europe/Copenhagen" },
  { value: "Europe/Dublin", label: "Europe/Dublin" },
  { value: "Europe/Gibraltar", label: "Europe/Gibraltar" },
  { value: "Europe/Guernsey", label: "Europe/Guernsey" },
  { value: "Europe/Helsinki", label: "Europe/Helsinki" },
  { value: "Europe/Hamburg", label: "Europe/Hamburg" },
  { value: "Europe/Is", label: "Europe/Is" },
  { value: "Europe/Istanbul", label: "Europe/Istanbul" },
  { value: "Europe/Jersey", label: "Europe/Jersey" },
  { value: "Europe/Kaliningrad", label: "Europe/Kaliningrad" },
  { value: "Europe/Kiev", label: "Europe/Kiev" },
  { value: "Europe/Kirov", label: "Europe/Kirov" },
  { value: "Europe/Lisbon", label: "Europe/Lisbon" },
  { value: "Europe/Ljubljana", label: "Europe/Ljubljana" },
  { value: "Europe/London", label: "Europe/London" },
  { value: "Europe/Luxembourg", label: "Europe/Luxembourg" },
  { value: "Europe/Madrid", label: "Europe/Madrid" },
  { value: "Europe/Malta", label: "Europe/Malta" },
  { value: "Europe/Mariehamn", label: "Europe/Mariehamn" },
  { value: "Europe/Minsk", label: "Europe/Minsk" },
  { value: "Europe/Monaco", label: "Europe/Monaco" },
  { value: "Europe/Moscow", label: "Europe/Moscow" },
  { value: "Europe/Oslo", label: "Europe/Oslo" },
  { value: "Europe/Paris", label: "Europe/Paris" },
  { value: "Europe/Podgorica", label: "Europe/Podgorica" },
  { value: "Europe/Prague", label: "Europe/Prague" },
  { value: "Europe/Riga", label: "Europe/Riga" },
  { value: "Europe/Rome", label: "Europe/Rome" },
  { value: "Europe/Samara", label: "Europe/Samara" },
  { value: "Europe/San_Marino", label: "Europe/San_Marino" },
  { value: "Europe/Sarajevo", label: "Europe/Sarajevo" },
  { value: "Europe/Saratov", label: "Europe/Saratov" },
  { value: "Europe/Simferopol", label: "Europe/Simferopol" },
  { value: "Europe/Skopje", label: "Europe/Skopje" },
  { value: "Europe/Sofia", label: "Europe/Sofia" },
  { value: "Europe/Stockholm", label: "Europe/Stockholm" },
  { value: "Europe/Tallinn", label: "Europe/Tallinn" },
  { value: "Europe/Tirane", label: "Europe/Tirane" },
  { value: "Europe/Ulyanovsk", label: "Europe/Ulyanovsk" },
  { value: "Europe/Uzhgorod", label: "Europe/Uzhgorod" },
  { value: "Europe/Vaduz", label: "Europe/Vaduz" },
  { value: "Europe/Vatican", label: "Europe/Vatican" },
  { value: "Europe/Vienna", label: "Europe/Vienna" },
  { value: "Europe/Vilnius", label: "Europe/Vilnius" },
  { value: "Europe/Volgograd", label: "Europe/Volgograd" },
  { value: "Europe/Warsaw", label: "Europe/Warsaw" },
  { value: "Europe/Zagreb", label: "Europe/Zagreb" },
  { value: "Europe/Zaporozhye", label: "Europe/Zaporozhye" },
  { value: "Europe/Zurich", label: "Europe/Zurich" },
  { value: "Indian/Antananarivo", label: "Indian/Antananarivo" },
  { value: "Indian/Chagos", label: "Indian/Chagos" },
  { value: "Indian/Christmas", label: "Indian/Christmas" },
  { value: "Indian/Cocos", label: "Indian/Cocos" },
  { value: "Indian/Comoro", label: "Indian/Comoro" },
  { value: "Indian/Kerguelen", label: "Indian/Kerguelen" },
  { value: "Indian/Mahe", label: "Indian/Mahe" },
  { value: "Indian/Maldives", label: "Indian/Maldives" },
  { value: "Indian/Mauritius", label: "Indian/Mauritius" },
  { value: "Indian/Mayotte", label: "Indian/Mayotte" },
  { value: "Indian/Reunion", label: "Indian/Reunion" },
  { value: "Pacific/Apia", label: "Pacific/Apia" },
  { value: "Pacific/Auckland", label: "Pacific/Auckland" },
  { value: "Pacific/Bougainville", label: "Pacific/Bougainville" },
  { value: "Pacific/Chatham", label: "Pacific/Chatham" },
  { value: "Pacific/Chuuk", label: "Pacific/Chuuk" },
  { value: "Pacific/Easter", label: "Pacific/Easter" },
  { value: "Pacific/Efate", label: "Pacific/Efate" },
  { value: "Pacific/Enderbury", label: "Pacific/Enderbury" },
  { value: "Pacific/Fakaofo", label: "Pacific/Fakaofo" },
  { value: "Pacific/Fiji", label: "Pacific/Fiji" },
  { value: "Pacific/Funafuti", label: "Pacific/Funafuti" },
  { value: "Pacific/Galapagos", label: "Pacific/Galapagos" },
  { value: "Pacific/Gambier", label: "Pacific/Gambier" },
  { value: "Pacific/Guadalcanal", label: "Pacific/Guadalcanal" },
  { value: "Pacific/Guam", label: "Pacific/Guam" },
  { value: "Pacific/Honolulu", label: "Pacific/Honolulu" },
  { value: "Pacific/Kiritimati", label: "Pacific/Kiritimati" },
  { value: "Pacific/Kosrae", label: "Pacific/Kosrae" },
  { value: "Pacific/Kwajalein", label: "Pacific/Kwajalein" },
  { value: "Pacific/Majuro", label: "Pacific/Majuro" },
  { value: "Pacific/Marquesas", label: "Pacific/Marquesas" },
  { value: "Pacific/Midway", label: "Pacific/Midway" },
  { value: "Pacific/Nauru", label: "Pacific/Nauru" },
  { value: "Pacific/Niue", label: "Pacific/Niue" },
  { value: "Pacific/Norfolk", label: "Pacific/Norfolk" },
  { value: "Pacific/Noumea", label: "Pacific/Noumea" },
  { value: "Pacific/Pago_Pago", label: "Pacific/Pago_Pago" },
  { value: "Pacific/Palau", label: "Pacific/Palau" },
  { value: "Pacific/Pitcairn", label: "Pacific/Pitcairn" },
  { value: "Pacific/Pohnpei", label: "Pacific/Pohnpei" },
  { value: "Pacific/Port_Moresby", label: "Pacific/Port_Moresby" },
  { value: "Pacific/Rarotonga", label: "Pacific/Rarotonga" },
  { value: "Pacific/Saipan", label: "Pacific/Saipan" },
  { value: "Pacific/Tahiti", label: "Pacific/Tahiti" },
  { value: "Pacific/Tarawa", label: "Pacific/Tarawa" },
  { value: "Pacific/Tongatapu", label: "Pacific/Tongatapu" },
  { value: "Pacific/Wake", label: "Pacific/Wake" },
  { value: "Pacific/Wallis", label: "Pacific/Wallis" },
];

export const allAryIannaTimeZones = [
  "Europe/Andorra",
  "Asia/Dubai",
  "Asia/Kabul",
  "Europe/Tirane",
  "Asia/Yerevan",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba",
  "America/Argentina/Salta",
  "America/Argentina/Jujuy",
  "America/Argentina/Tucuman",
  "America/Argentina/Catamarca",
  "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan",
  "America/Argentina/Mendoza",
  "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia",
  "Pacific/Pago_Pago",
  "Europe/Vienna",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Broken_Hill",
  "Australia/Brisbane",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Perth",
  "Australia/Eucla",
  "Asia/Baku",
  "America/Barbados",
  "Asia/Dhaka",
  "Europe/Brussels",
  "Europe/Sofia",
  "Atlantic/Bermuda",
  "Asia/Brunei",
  "America/La_Paz",
  "America/Noronha",
  "America/Belem",
  "America/Fortaleza",
  "America/Recife",
  "America/Araguaina",
  "America/Maceio",
  "America/Bahia",
  "America/Sao_Paulo",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Santarem",
  "America/Porto_Velho",
  "America/Boa_Vista",
  "America/Manaus",
  "America/Eirunepe",
  "America/Rio_Branco",
  "America/Nassau",
  "Asia/Thimphu",
  "Europe/Minsk",
  "America/Belize",
  "America/St_Johns",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "Indian/Cocos",
  "Europe/Zurich",
  "Africa/Abidjan",
  "Pacific/Rarotonga",
  "America/Santiago",
  "America/Punta_Arenas",
  "Pacific/Easter",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "America/Bogota",
  "America/Costa_Rica",
  "America/Havana",
  "Atlantic/Cape_Verde",
  "America/Curacao",
  "Indian/Christmas",
  "Asia/Nicosia",
  "Asia/Famagusta",
  "Europe/Prague",
  "Europe/Berlin",
  "Europe/Copenhagen",
  "America/Santo_Domingo",
  "Africa/Algiers",
  "America/Guayaquil",
  "Pacific/Galapagos",
  "Europe/Tallinn",
  "Africa/Cairo",
  "Africa/El_Aaiun",
  "Europe/Madrid",
  "Africa/Ceuta",
  "Atlantic/Canary",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Atlantic/Stanley",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Pacific/Kosrae",
  "Atlantic/Faroe",
  "Europe/Paris",
  "Europe/London",
  "Asia/Tbilisi",
  "America/Cayenne",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Godthab",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "Europe/Athens",
  "Atlantic/South_Georgia",
  "America/Guatemala",
  "Pacific/Guam",
  "Africa/Bissau",
  "America/Guyana",
  "Asia/Hong_Kong",
  "America/Tegucigalpa",
  "America/Port-au-Prince",
  "Europe/Budapest",
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Jayapura",
  "Europe/Dublin",
  "Asia/Jerusalem",
  "Asia/Kolkata",
  "Indian/Chagos",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Atlantic/Reykjavik",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Amman",
  "Asia/Tokyo",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Tarawa",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Qostanay", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Atyrau",
  "Asia/Oral",
  "Asia/Beirut",
  "Asia/Colombo",
  "Africa/Monrovia",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Europe/Riga",
  "Africa/Tripoli",
  "Africa/Casablanca",
  "Europe/Monaco",
  "Europe/Chisinau",
  "Pacific/Majuro",
  "Pacific/Kwajalein",
  "Asia/Yangon",
  "Asia/Ulaanbaatar",
  "Asia/Hovd",
  "Asia/Choibalsan",
  "Asia/Macau",
  "America/Martinique",
  "Europe/Malta",
  "Indian/Mauritius",
  "Indian/Maldives",
  "America/Mexico_City",
  "America/Cancun",
  "America/Merida",
  "America/Monterrey",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Chihuahua",
  "America/Ojinaga",
  "America/Hermosillo",
  "America/Tijuana",
  "America/Bahia_Banderas",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Africa/Maputo",
  "Africa/Windhoek",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Africa/Lagos",
  "America/Managua",
  "Europe/Amsterdam",
  "Europe/Oslo",
  "Asia/Kathmandu",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "America/Panama",
  "America/Lima",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "Pacific/Gambier",
  "Pacific/Port_Moresby",
  "Pacific/Bougainville",
  "Asia/Manila",
  "Asia/Karachi",
  "Europe/Warsaw",
  "America/Miquelon",
  "Pacific/Pitcairn",
  "America/Puerto_Rico",
  "Asia/Gaza",
  "Asia/Hebron",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "Pacific/Palau",
  "America/Asuncion",
  "Asia/Qatar",
  "Indian/Reunion",
  "Europe/Bucharest",
  "Europe/Belgrade",
  "Europe/Kaliningrad",
  "Europe/Moscow",
  "Europe/Simferopol",
  "Europe/Kirov",
  "Europe/Astrakhan",
  "Europe/Volgograd",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Europe/Samara",
  "Asia/Yekaterinburg",
  "Asia/Omsk",
  "Asia/Novosibirsk",
  "Asia/Barnaul",
  "Asia/Tomsk",
  "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk",
  "Asia/Irkutsk",
  "Asia/Chita",
  "Asia/Yakutsk",
  "Asia/Khandyga",
  "Asia/Vladivostok",
  "Asia/Ust-Nera",
  "Asia/Magadan",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Kamchatka",
  "Asia/Anadyr",
  "Asia/Riyadh",
  "Pacific/Guadalcanal",
  "Indian/Mahe",
  "Africa/Khartoum",
  "Europe/Stockholm",
  "Asia/Singapore",
  "America/Paramaribo",
  "Africa/Juba",
  "Africa/Sao_Tome",
  "America/El_Salvador",
  "Asia/Damascus",
  "America/Grand_Turk",
  "Africa/Ndjamena",
  "Indian/Kerguelen",
  "Asia/Bangkok",
  "Asia/Dushanbe",
  "Pacific/Fakaofo",
  "Asia/Dili",
  "Asia/Ashgabat",
  "Africa/Tunis",
  "Pacific/Tongatapu",
  "Europe/Istanbul",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Asia/Taipei",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Wake",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "America/Caracas",
  "Asia/Ho_Chi_Minh",
  "Pacific/Efate",
  "Pacific/Wallis",
  "Pacific/Apia",
  "Africa/Johannesburg",
];

export const timezones = [
  {
    IANAtimezone: "Africa/Abidjan",
    timezoneAbbreviation: "GMT",
    countryCode: "CI",
  },
  {
    IANAtimezone: "Africa/Accra",
    timezoneAbbreviation: "GMT",
    countryCode: "GH",
  },
  {
    IANAtimezone: "Africa/Addis_Ababa",
    timezoneAbbreviation: "EAT",
    countryCode: "ET",
  },
  {
    IANAtimezone: "Africa/Algiers",
    timezoneAbbreviation: "CET",
    countryCode: "DZ",
  },
  {
    IANAtimezone: "Africa/Asmara",
    timezoneAbbreviation: "EAT",
    countryCode: "ER",
  },
  {
    IANAtimezone: "Africa/Bamako",
    timezoneAbbreviation: "GMT",
    countryCode: "ML",
  },
  {
    IANAtimezone: "Africa/Bangui",
    timezoneAbbreviation: "WAT",
    countryCode: "CF",
  },
  {
    IANAtimezone: "Africa/Banjul",
    timezoneAbbreviation: "GMT",
    countryCode: "GM",
  },
  {
    IANAtimezone: "Africa/Bissau",
    timezoneAbbreviation: "GMT",
    countryCode: "GW",
  },
  {
    IANAtimezone: "Africa/Blantyre",
    timezoneAbbreviation: "CAT",
    countryCode: "MW",
  },
  {
    IANAtimezone: "Africa/Brazzaville",
    timezoneAbbreviation: "WAT",
    countryCode: "CG",
  },
  {
    IANAtimezone: "Africa/Bujumbura",
    timezoneAbbreviation: "CAT",
    countryCode: "BI",
  },
  {
    IANAtimezone: "Africa/Cairo",
    timezoneAbbreviation: "EET",
    countryCode: "EG",
  },
  {
    IANAtimezone: "Africa/Casablanca",
    timezoneAbbreviation: "WET",
    countryCode: "MA",
  },
  {
    IANAtimezone: "Africa/Ceuta",
    timezoneAbbreviation: "CET",
    countryCode: "ES",
  },
  {
    IANAtimezone: "Africa/Conakry",
    timezoneAbbreviation: "GMT",
    countryCode: "GN",
  },
  {
    IANAtimezone: "Africa/Dakar",
    timezoneAbbreviation: "GMT",
    countryCode: "SN",
  },
  {
    IANAtimezone: "Africa/Dar_es_Salaam",
    timezoneAbbreviation: "EAT",
    countryCode: "TZ",
  },
  {
    IANAtimezone: "Africa/Djibouti",
    timezoneAbbreviation: "EAT",
    countryCode: "DJ",
  },
  {
    IANAtimezone: "Africa/Douala",
    timezoneAbbreviation: "WAT",
    countryCode: "CM",
  },
  {
    IANAtimezone: "Africa/El_Aaiun",
    timezoneAbbreviation: "WET",
    countryCode: "EH",
  },
  {
    IANAtimezone: "Africa/Freetown",
    timezoneAbbreviation: "GMT",
    countryCode: "SL",
  },
  {
    IANAtimezone: "Africa/Gaborone",
    timezoneAbbreviation: "CAT",
    countryCode: "BW",
  },
  {
    IANAtimezone: "Africa/Harare",
    timezoneAbbreviation: "CAT",
    countryCode: "ZW",
  },
  {
    IANAtimezone: "Africa/Johannesburg",
    timezoneAbbreviation: "SAST",
    countryCode: "ZA",
  },
  {
    IANAtimezone: "Africa/Juba",
    timezoneAbbreviation: "EAT",
    countryCode: "SS",
  },
  {
    IANAtimezone: "Africa/Kampala",
    timezoneAbbreviation: "EAT",
    countryCode: "UG",
  },
  {
    IANAtimezone: "Africa/Khartoum",
    timezoneAbbreviation: "EAT",
    countryCode: "SD",
  },
  {
    IANAtimezone: "Africa/Kigali",
    timezoneAbbreviation: "CAT",
    countryCode: "RW",
  },
  {
    IANAtimezone: "Africa/Kinshasa",
    timezoneAbbreviation: "WAT",
    countryCode: "CD",
  },
  {
    IANAtimezone: "Africa/Lagos",
    timezoneAbbreviation: "WAT",
    countryCode: "NG",
  },
  {
    IANAtimezone: "Africa/Libreville",
    timezoneAbbreviation: "WAT",
    countryCode: "GA",
  },
  {
    IANAtimezone: "Africa/Lome",
    timezoneAbbreviation: "GMT",
    countryCode: "TG",
  },
  {
    IANAtimezone: "Africa/Luanda",
    timezoneAbbreviation: "WAT",
    countryCode: "AO",
  },
  {
    IANAtimezone: "Africa/Lubumbashi",
    timezoneAbbreviation: "CAT",
    countryCode: "CD",
  },
  {
    IANAtimezone: "Africa/Lusaka",
    timezoneAbbreviation: "CAT",
    countryCode: "ZM",
  },
  {
    IANAtimezone: "Africa/Malabo",
    timezoneAbbreviation: "WAT",
    countryCode: "GQ",
  },
  {
    IANAtimezone: "Africa/Maputo",
    timezoneAbbreviation: "CAT",
    countryCode: "MZ",
  },
  {
    IANAtimezone: "Africa/Maseru",
    timezoneAbbreviation: "SAST",
    countryCode: "LS",
  },
  {
    IANAtimezone: "Africa/Mbabane",
    timezoneAbbreviation: "SAST",
    countryCode: "SZ",
  },
  {
    IANAtimezone: "Africa/Mogadishu",
    timezoneAbbreviation: "EAT",
    countryCode: "SO",
  },
  {
    IANAtimezone: "Africa/Monrovia",
    timezoneAbbreviation: "GMT",
    countryCode: "LR",
  },
  {
    IANAtimezone: "Africa/Nairobi",
    timezoneAbbreviation: "EAT",
    countryCode: "KE",
  },
  {
    IANAtimezone: "Africa/Ndjamena",
    timezoneAbbreviation: "WAT",
    countryCode: "TD",
  },
  {
    IANAtimezone: "Africa/Niamey",
    timezoneAbbreviation: "WAT",
    countryCode: "NE",
  },
  {
    IANAtimezone: "Africa/Nouakchott",
    timezoneAbbreviation: "GMT",
    countryCode: "MR",
  },
  {
    IANAtimezone: "Africa/Ouagadougou",
    timezoneAbbreviation: "GMT",
    countryCode: "BF",
  },
  {
    IANAtimezone: "Africa/Porto-Novo",
    timezoneAbbreviation: "WAT",
    countryCode: "BJ",
  },
  {
    IANAtimezone: "Africa/Sao_Tome",
    timezoneAbbreviation: "GMT",
    countryCode: "ST",
  },
  {
    IANAtimezone: "Africa/Tripoli",
    timezoneAbbreviation: "EET",
    countryCode: "LY",
  },
  {
    IANAtimezone: "Africa/Tunis",
    timezoneAbbreviation: "CET",
    countryCode: "TN",
  },
  {
    IANAtimezone: "Africa/Windhoek",
    timezoneAbbreviation: "CAT",
    countryCode: "NA",
  },
  {
    IANAtimezone: "America/Adak",
    timezoneAbbreviation: "HST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Anchorage",
    timezoneAbbreviation: "AKST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Anguilla",
    timezoneAbbreviation: "AST",
    countryCode: "AI",
  },
  {
    IANAtimezone: "America/Antigua",
    timezoneAbbreviation: "AST",
    countryCode: "AG",
  },
  {
    IANAtimezone: "America/Araguaina",
    timezoneAbbreviation: "BRT",
    countryCode: "BR",
  },
  {
    IANAtimezone: "America/Argentina/Buenos_Aires",
    timezoneAbbreviation: "ART",
    countryCode: "AR",
  },
  {
    IANAtimezone: "America/Argentina/Catamarca",
    timezoneAbbreviation: "ART",
    countryCode: "AR",
  },
  {
    IANAtimezone: "America/Argentina/Cordoba",
    timezoneAbbreviation: "ART",
    countryCode: "AR",
  },
  {
    IANAtimezone: "America/Argentina/Jujuy",
    timezoneAbbreviation: "ART",
    countryCode: "AR",
  },
  {
    IANAtimezone: "America/Argentina/La_Rioja",
    timezoneAbbreviation: "ART",
    countryCode: "AR",
  },
  {
    IANAtimezone: "America/Argentina/Mendoza",
    timezoneAbbreviation: "ART",
    countryCode: "AR",
  },
  {
    IANAtimezone: "America/Argentina/Rio_Gallegos",
    timezoneAbbreviation: "ART",
  },
  {
    IANAtimezone: "America/Argentina/Salta",
    timezoneAbbreviation: "ART",
    countryCode: "AR",
  },
  {
    IANAtimezone: "America/Argentina/San_Juan",
    timezoneAbbreviation: "ART",
    countryCode: "AR",
  },
  {
    IANAtimezone: "America/Argentina/San_Luis",
    timezoneAbbreviation: "ART",
    countryCode: "AR",
  },
  {
    IANAtimezone: "America/Argentina/Tucuman",
    timezoneAbbreviation: "ART",
    countryCode: "AR",
  },
  {
    IANAtimezone: "America/Argentina/Ushuaia",
    timezoneAbbreviation: "ART",
    countryCode: "AR",
  },
  {
    IANAtimezone: "America/Aruba",
    timezoneAbbreviation: "AST",
    countryCode: "AW",
  },
  {
    IANAtimezone: "America/Asuncion",
    timezoneAbbreviation: "PYT",
    countryCode: "PY",
  },
  {
    IANAtimezone: "America/Atikokan",
    timezoneAbbreviation: "EST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Bahia",
    timezoneAbbreviation: "BRT",
    countryCode: "BR",
  },
  {
    IANAtimezone: "America/Bahia_Banderas",
    timezoneAbbreviation: "CST",
    countryCode: "MX",
  },
  {
    IANAtimezone: "America/Barbados",
    timezoneAbbreviation: "AST",
    countryCode: "BB",
  },
  {
    IANAtimezone: "America/Belem",
    timezoneAbbreviation: "BRT",
    countryCode: "BR",
  },
  {
    IANAtimezone: "America/Belize",
    timezoneAbbreviation: "CST",
    countryCode: "BZ",
  },
  {
    IANAtimezone: "America/Blanc-Sablon",
    timezoneAbbreviation: "AST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Boa_Vista",
    timezoneAbbreviation: "AMT",
    countryCode: "BR",
  },
  {
    IANAtimezone: "America/Bogota",
    timezoneAbbreviation: "COT",
    countryCode: "CO",
  },
  {
    IANAtimezone: "America/Boise",
    timezoneAbbreviation: "MST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Cambridge_Bay",
    timezoneAbbreviation: "MST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Campo_Grande",
    timezoneAbbreviation: "AMT",
    countryCode: "BR",
  },
  {
    IANAtimezone: "America/Cancun",
    timezoneAbbreviation: "EST",
    countryCode: "MX",
  },
  {
    IANAtimezone: "America/Caracas",
    timezoneAbbreviation: "VET",
    countryCode: "VE",
  },
  {
    IANAtimezone: "America/Cayenne",
    timezoneAbbreviation: "GFT",
    countryCode: "GF",
  },
  {
    IANAtimezone: "America/Cayman",
    timezoneAbbreviation: "EST",
    countryCode: "KY",
  },
  {
    IANAtimezone: "America/Chicago",
    timezoneAbbreviation: "CST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Chihuahua",
    timezoneAbbreviation: "MST",
    countryCode: "MX",
  },
  {
    IANAtimezone: "America/Costa_Rica",
    timezoneAbbreviation: "CST",
    countryCode: "CR",
  },
  {
    IANAtimezone: "America/Creston",
    timezoneAbbreviation: "MST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Cuiaba",
    timezoneAbbreviation: "AMT",
    countryCode: "BR",
  },
  {
    IANAtimezone: "America/Curacao",
    timezoneAbbreviation: "AST",
    countryCode: "CW",
  },
  {
    IANAtimezone: "America/Danmarkshavn",
    timezoneAbbreviation: "UTC",
    countryCode: "GL",
  },
  {
    IANAtimezone: "America/Dawson",
    timezoneAbbreviation: "PST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Dawson_Creek",
    timezoneAbbreviation: "MST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Denver",
    timezoneAbbreviation: "MST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Detroit",
    timezoneAbbreviation: "EST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Dominica",
    timezoneAbbreviation: "AST",
    countryCode: "DM",
  },
  {
    IANAtimezone: "America/Edmonton",
    timezoneAbbreviation: "MST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Eirunepe",
    timezoneAbbreviation: "ACT",
    countryCode: "BR",
  },
  {
    IANAtimezone: "America/El_Salvador",
    timezoneAbbreviation: "CST",
    countryCode: "SV",
  },
  {
    IANAtimezone: "America/Fort_Nelson",
    timezoneAbbreviation: "MST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Fortaleza",
    timezoneAbbreviation: "BRT",
    countryCode: "BR",
  },
  {
    IANAtimezone: "America/Glace_Bay",
    timezoneAbbreviation: "AST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Godthab",
    timezoneAbbreviation: "WGT",
    countryCode: "GL",
  },
  {
    IANAtimezone: "America/Goose_Bay",
    timezoneAbbreviation: "AST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Grand_Turk",
    timezoneAbbreviation: "EST",
    countryCode: "TC",
  },
  {
    IANAtimezone: "America/Grenada",
    timezoneAbbreviation: "AST",
    countryCode: "GD",
  },
  {
    IANAtimezone: "America/Guadeloupe",
    timezoneAbbreviation: "AST",
    countryCode: "GP",
  },
  {
    IANAtimezone: "America/Guatemala",
    timezoneAbbreviation: "CST",
    countryCode: "GT",
  },
  {
    IANAtimezone: "America/Guayaquil",
    timezoneAbbreviation: "ECT",
    countryCode: "EC",
  },
  {
    IANAtimezone: "America/Guyana",
    timezoneAbbreviation: "GYT",
    countryCode: "GY",
  },
  {
    IANAtimezone: "America/Halifax",
    timezoneAbbreviation: "AST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Havana",
    timezoneAbbreviation: "CST",
    countryCode: "CU",
  },
  {
    IANAtimezone: "America/Hermosillo",
    timezoneAbbreviation: "MST",
    countryCode: "MX",
  },
  {
    IANAtimezone: "America/Indiana/Indianapolis",
    timezoneAbbreviation: "EST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Indiana/Knox",
    timezoneAbbreviation: "CST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Indiana/Marengo",
    timezoneAbbreviation: "EST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Indiana/Petersburg",
    timezoneAbbreviation: "EST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Indiana/Tell_City",
    timezoneAbbreviation: "CST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Indiana/Vevay",
    timezoneAbbreviation: "EST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Indiana/Vincennes",
    timezoneAbbreviation: "EST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Indiana/Winamac",
    timezoneAbbreviation: "EST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Inuvik",
    timezoneAbbreviation: "MST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Iqaluit",
    timezoneAbbreviation: "EST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Jamaica",
    timezoneAbbreviation: "EST",
    countryCode: "JM",
  },
  {
    IANAtimezone: "America/Juneau",
    timezoneAbbreviation: "AKST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Kentucky/Louisville",
    timezoneAbbreviation: "EST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Kentucky/Monticello",
    timezoneAbbreviation: "EST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Kralendijk",
    timezoneAbbreviation: "AST",
    countryCode: "BQ",
  },
  {
    IANAtimezone: "America/La_Paz",
    timezoneAbbreviation: "BOT",
    countryCode: "BO",
  },
  {
    IANAtimezone: "America/Lima",
    timezoneAbbreviation: "PET",
    countryCode: "PE",
  },
  {
    IANAtimezone: "America/Los_Angeles",
    timezoneAbbreviation: "PST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Lower_Princes",
    timezoneAbbreviation: "AST",
    countryCode: "SX",
  },
  {
    IANAtimezone: "America/Maceio",
    timezoneAbbreviation: "BRT",
    countryCode: "BR",
  },
  {
    IANAtimezone: "America/Managua",
    timezoneAbbreviation: "CST",
    countryCode: "NI",
  },
  {
    IANAtimezone: "America/Manaus",
    timezoneAbbreviation: "AMT",
    countryCode: "BR",
  },
  {
    IANAtimezone: "America/Marigot",
    timezoneAbbreviation: "AST",
    countryCode: "MF",
  },
  {
    IANAtimezone: "America/Martinique",
    timezoneAbbreviation: "AST",
    countryCode: "MQ",
  },
  {
    IANAtimezone: "America/Matamoros",
    timezoneAbbreviation: "CST",
    countryCode: "MX",
  },
  {
    IANAtimezone: "America/Mazatlan",
    timezoneAbbreviation: "MST",
    countryCode: "MX",
  },
  {
    IANAtimezone: "America/Menominee",
    timezoneAbbreviation: "CST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Merida",
    timezoneAbbreviation: "CST",
    countryCode: "MX",
  },
  {
    IANAtimezone: "America/Metlakatla",
    timezoneAbbreviation: "AKST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Mexico_City",
    timezoneAbbreviation: "CST",
    countryCode: "MX",
  },
  {
    IANAtimezone: "America/Miquelon",
    timezoneAbbreviation: "PMST",
    countryCode: "PM",
  },
  {
    IANAtimezone: "America/Moncton",
    timezoneAbbreviation: "AST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Monterrey",
    timezoneAbbreviation: "CST",
    countryCode: "MX",
  },
  {
    IANAtimezone: "America/Montevideo",
    timezoneAbbreviation: "UYT",
    countryCode: "UY",
  },
  {
    IANAtimezone: "America/Montserrat",
    timezoneAbbreviation: "AST",
    countryCode: "MS",
  },
  {
    IANAtimezone: "America/Nassau",
    timezoneAbbreviation: "EST",
    countryCode: "BS",
  },
  {
    IANAtimezone: "America/New_York",
    timezoneAbbreviation: "EST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Nipigon",
    timezoneAbbreviation: "EST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Nome",
    timezoneAbbreviation: "AKST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Noronha",
    timezoneAbbreviation: "FNT",
    countryCode: "BR",
  },
  {
    IANAtimezone: "America/North_Dakota/Beulah",
    timezoneAbbreviation: "CST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/North_Dakota/Center",
    timezoneAbbreviation: "CST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/North_Dakota/New_Salem",
    timezoneAbbreviation: "CST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Ojinaga",
    timezoneAbbreviation: "MST",
    countryCode: "MX",
  },
  {
    IANAtimezone: "America/Panama",
    timezoneAbbreviation: "EST",
    countryCode: "PA",
  },
  {
    IANAtimezone: "America/Pangnirtung",
    timezoneAbbreviation: "EST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Paramaribo",
    timezoneAbbreviation: "SRT",
    countryCode: "SR",
  },
  {
    IANAtimezone: "America/Phoenix",
    timezoneAbbreviation: "MST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Port-au-Prince",
    timezoneAbbreviation: "EST",
    countryCode: "HT",
  },
  {
    IANAtimezone: "America/Port_of_Spain",
    timezoneAbbreviation: "AST",
    countryCode: "TT",
  },
  {
    IANAtimezone: "America/Porto_Velho",
    timezoneAbbreviation: "AMT",
    countryCode: "BR",
  },
  {
    IANAtimezone: "America/Puerto_Rico",
    timezoneAbbreviation: "AST",
    countryCode: "PR",
  },
  {
    IANAtimezone: "America/Punta_Arenas",
    timezoneAbbreviation: "CLST",
    countryCode: "CL",
  },
  {
    IANAtimezone: "America/Rainy_River",
    timezoneAbbreviation: "CST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Rankin_Inlet",
    timezoneAbbreviation: "CST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Recife",
    timezoneAbbreviation: "BRT",
    countryCode: "BR",
  },
  {
    IANAtimezone: "America/Regina",
    timezoneAbbreviation: "CST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Resolute",
    timezoneAbbreviation: "CST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Rio_Branco",
    timezoneAbbreviation: "ACT",
    countryCode: "BR",
  },
  {
    IANAtimezone: "America/Santarem",
    timezoneAbbreviation: "BRT",
    countryCode: "BR",
  },
  {
    IANAtimezone: "America/Santiago",
    timezoneAbbreviation: "CLST",
    countryCode: "CL",
  },
  {
    IANAtimezone: "America/Santo_Domingo",
    timezoneAbbreviation: "AST",
    countryCode: "DO",
  },
  {
    IANAtimezone: "America/Sao_Paulo",
    timezoneAbbreviation: "BRT",
    countryCode: "BR",
  },
  {
    IANAtimezone: "America/Scoresbysund",
    timezoneAbbreviation: "EGT",
    countryCode: "GL",
  },
  {
    IANAtimezone: "America/Sitka",
    timezoneAbbreviation: "AKST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/St_Barthelemy",
    timezoneAbbreviation: "AST",
    countryCode: "BL",
  },
  {
    IANAtimezone: "America/St_Johns",
    timezoneAbbreviation: "NST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/St_Kitts",
    timezoneAbbreviation: "AST",
    countryCode: "KN",
  },
  {
    IANAtimezone: "America/St_Lucia",
    timezoneAbbreviation: "AST",
    countryCode: "LC",
  },
  {
    IANAtimezone: "America/St_Thomas",
    timezoneAbbreviation: "AST",
    countryCode: "VI",
  },
  {
    IANAtimezone: "America/St_Vincent",
    timezoneAbbreviation: "AST",
    countryCode: "VC",
  },
  {
    IANAtimezone: "America/Swift_Current",
    timezoneAbbreviation: "CST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Tegucigalpa",
    timezoneAbbreviation: "CST",
    countryCode: "HN",
  },
  {
    IANAtimezone: "America/Thule",
    timezoneAbbreviation: "AST",
    countryCode: "GL",
  },
  {
    IANAtimezone: "America/Thunder_Bay",
    timezoneAbbreviation: "EST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Tijuana",
    timezoneAbbreviation: "PST",
    countryCode: "MX",
  },
  {
    IANAtimezone: "America/Toronto",
    timezoneAbbreviation: "EST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Tortola",
    timezoneAbbreviation: "AST",
    countryCode: "VG",
  },
  {
    IANAtimezone: "America/Vancouver",
    timezoneAbbreviation: "PST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Whitehorse",
    timezoneAbbreviation: "PST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Winnipeg",
    timezoneAbbreviation: "CST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "America/Yakutat",
    timezoneAbbreviation: "AKST",
    countryCode: "US",
  },
  {
    IANAtimezone: "America/Yellowknife",
    timezoneAbbreviation: "MST",
    countryCode: "CA",
  },
  {
    IANAtimezone: "Antarctica/Casey",
    timezoneAbbreviation: "AWST",
    countryCode: "AQ",
  },
  {
    IANAtimezone: "Antarctica/Davis",
    timezoneAbbreviation: "DAVT",
    countryCode: "AQ",
  },
  {
    IANAtimezone: "Antarctica/DumontDUrville",
    timezoneAbbreviation: "DDUT",
    countryCode: "AQ",
  },
  {
    IANAtimezone: "Antarctica/Macquarie",
    timezoneAbbreviation: "MIST",
    countryCode: "AQ",
  },
  {
    IANAtimezone: "Antarctica/Mawson",
    timezoneAbbreviation: "MAWT",
    countryCode: "AQ",
  },
  {
    IANAtimezone: "Antarctica/McMurdo",
    timezoneAbbreviation: "NZDT",
    countryCode: "AQ",
  },
  {
    IANAtimezone: "Antarctica/Palmer",
    timezoneAbbreviation: "CLST",
    countryCode: "AQ",
  },
  {
    IANAtimezone: "Antarctica/Rothera",
    timezoneAbbreviation: "ROTT",
    countryCode: "AQ",
  },
  {
    IANAtimezone: "Antarctica/Syowa",
    timezoneAbbreviation: "SYOT",
    countryCode: "AQ",
  },
  {
    IANAtimezone: "Antarctica/Troll",
    timezoneAbbreviation: "UTC",
    countryCode: "AQ",
  },
  {
    IANAtimezone: "Antarctica/Vostok",
    timezoneAbbreviation: "VOST",
    countryCode: "AQ",
  },
  {
    IANAtimezone: "Arctic/Longyearbyen",
    timezoneAbbreviation: "CET",
    countryCode: "SJ",
  },
  { IANAtimezone: "Asia/Aden", timezoneAbbreviation: "AST", countryCode: "YE" },
  {
    IANAtimezone: "Asia/Almaty",
    timezoneAbbreviation: "ALMT",
    countryCode: "KZ",
  },
  {
    IANAtimezone: "Asia/Amman",
    timezoneAbbreviation: "EET",
    countryCode: "JO",
  },
  {
    IANAtimezone: "Asia/Anadyr",
    timezoneAbbreviation: "ANAT",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Asia/Aqtau",
    timezoneAbbreviation: "AQTT",
    countryCode: "KZ",
  },
  {
    IANAtimezone: "Asia/Aqtobe",
    timezoneAbbreviation: "AQTT",
    countryCode: "KZ",
  },
  {
    IANAtimezone: "Asia/Ashgabat",
    timezoneAbbreviation: "TMT",
    countryCode: "TM",
  },
  {
    IANAtimezone: "Asia/Atyrau",
    timezoneAbbreviation: "AQTT",
    countryCode: "KZ",
  },
  {
    IANAtimezone: "Asia/Baghdad",
    timezoneAbbreviation: "AST",
    countryCode: "IQ",
  },
  {
    IANAtimezone: "Asia/Bahrain",
    timezoneAbbreviation: "AST",
    countryCode: "BH",
  },
  { IANAtimezone: "Asia/Baku", timezoneAbbreviation: "AZT", countryCode: "AZ" },
  {
    IANAtimezone: "Asia/Bangkok",
    timezoneAbbreviation: "ICT",
    countryCode: "TH",
  },
  {
    IANAtimezone: "Asia/Barnaul",
    timezoneAbbreviation: "NOVT",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Asia/Beirut",
    timezoneAbbreviation: "EET",
    countryCode: "LB",
  },
  {
    IANAtimezone: "Asia/Bishkek",
    timezoneAbbreviation: "KGT",
    countryCode: "KG",
  },
  {
    IANAtimezone: "Asia/Brunei",
    timezoneAbbreviation: "BNT",
    countryCode: "BN",
  },
  {
    IANAtimezone: "Asia/Chita",
    timezoneAbbreviation: "YAKT",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Asia/Choibalsan",
    timezoneAbbreviation: "ULAT",
    countryCode: "MN",
  },
  {
    IANAtimezone: "Asia/Colombo",
    timezoneAbbreviation: "IST",
    countryCode: "LK",
  },
  {
    IANAtimezone: "Asia/Damascus",
    timezoneAbbreviation: "EET",
    countryCode: "SY",
  },
  {
    IANAtimezone: "Asia/Dhaka",
    timezoneAbbreviation: "BDT",
    countryCode: "BD",
  },
  { IANAtimezone: "Asia/Dili", timezoneAbbreviation: "TLT", countryCode: "TL" },
  {
    IANAtimezone: "Asia/Dubai",
    timezoneAbbreviation: "GST",
    countryCode: "AE",
  },
  {
    IANAtimezone: "Asia/Dushanbe",
    timezoneAbbreviation: "TJT",
    countryCode: "TJ",
  },
  {
    IANAtimezone: "Asia/Famagusta",
    timezoneAbbreviation: "EET",
    countryCode: "CY",
  },
  {
    IANAtimezone: "Asia/Kabul",
    timezoneAbbreviation: "AFT",
    countryCode: "AF",
  },
  {
    IANAtimezone: "Asia/Kamchatka",
    timezoneAbbreviation: "PETT",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Asia/Karachi",
    timezoneAbbreviation: "PKT",
    countryCode: "PK",
  },
  {
    IANAtimezone: "Asia/Kathmandu",
    timezoneAbbreviation: "NPT",
    countryCode: "NP",
  },
  {
    IANAtimezone: "Asia/Khandyga",
    timezoneAbbreviation: "YAKT",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Asia/Kolkata",
    timezoneAbbreviation: "IST",
    countryCode: "IN",
  },
  {
    IANAtimezone: "Asia/Krasnoyarsk",
    timezoneAbbreviation: "KRAT",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Asia/Kuala_Lumpur",
    timezoneAbbreviation: "MYT",
    countryCode: "MY",
  },
  {
    IANAtimezone: "Asia/Kuching",
    timezoneAbbreviation: "MYT",
    countryCode: "MY",
  },
  {
    IANAtimezone: "Asia/Macau",
    timezoneAbbreviation: "CST",
    countryCode: "MO",
  },
  {
    IANAtimezone: "Asia/Magadan",
    timezoneAbbreviation: "MAGT",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Asia/Makassar",
    timezoneAbbreviation: "CIT",
    countryCode: "ID",
  },
  {
    IANAtimezone: "Asia/Manila",
    timezoneAbbreviation: "PHT",
    countryCode: "PH",
  },
  {
    IANAtimezone: "Asia/Muscat",
    timezoneAbbreviation: "GST",
    countryCode: "OM",
  },
  {
    IANAtimezone: "Asia/Nicosia",
    timezoneAbbreviation: "EET",
    countryCode: "CY",
  },
  {
    IANAtimezone: "Asia/Novokuznetsk",
    timezoneAbbreviation: "KRAT",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Asia/Novosibirsk",
    timezoneAbbreviation: "NOVT",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Asia/Omsk",
    timezoneAbbreviation: "OMST",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Asia/Oral",
    timezoneAbbreviation: "ORAT",
    countryCode: "KZ",
  },
  {
    IANAtimezone: "Asia/Phnom_Penh",
    timezoneAbbreviation: "ICT",
    countryCode: "KH",
  },
  {
    IANAtimezone: "Asia/Pontianak",
    timezoneAbbreviation: "WIT",
    countryCode: "ID",
  },
  {
    IANAtimezone: "Asia/Pyongyang",
    timezoneAbbreviation: "KST",
    countryCode: "KP",
  },
  {
    IANAtimezone: "Asia/Qatar",
    timezoneAbbreviation: "AST",
    countryCode: "QA",
  },
  {
    IANAtimezone: "Asia/Qostanay",
    timezoneAbbreviation: "KOST",
    countryCode: "KZ",
  },
  {
    IANAtimezone: "Asia/Qyzylorda",
    timezoneAbbreviation: "QYZT",
    countryCode: "KZ",
  },
  {
    IANAtimezone: "Asia/Riyadh",
    timezoneAbbreviation: "AST",
    countryCode: "SA",
  },
  {
    IANAtimezone: "Asia/Sakhalin",
    timezoneAbbreviation: "SAKT",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Asia/Samarkand",
    timezoneAbbreviation: "UZT",
    countryCode: "UZ",
  },
  {
    IANAtimezone: "Asia/Seoul",
    timezoneAbbreviation: "KST",
    countryCode: "KR",
  },
  {
    IANAtimezone: "Asia/Shanghai",
    timezoneAbbreviation: "CST",
    countryCode: "CN",
  },
  {
    IANAtimezone: "Asia/Singapore",
    timezoneAbbreviation: "SGT",
    countryCode: "SG",
  },
  {
    IANAtimezone: "Asia/Srednekolymsk",
    timezoneAbbreviation: "MAGT",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Asia/Taipei",
    timezoneAbbreviation: "CST",
    countryCode: "TW",
  },
  {
    IANAtimezone: "Asia/Tashkent",
    timezoneAbbreviation: "UZT",
    countryCode: "UZ",
  },
  {
    IANAtimezone: "Asia/Tbilisi",
    timezoneAbbreviation: "GET",
    countryCode: "GE",
  },
  {
    IANAtimezone: "Asia/Tehran",
    timezoneAbbreviation: "IRST",
    countryCode: "IR",
  },
  {
    IANAtimezone: "Asia/Thimphu",
    timezoneAbbreviation: "BTT",
    countryCode: "BT",
  },
  {
    IANAtimezone: "Asia/Tokyo",
    timezoneAbbreviation: "JST",
    countryCode: "JP",
  },
  {
    IANAtimezone: "Asia/Tomsk",
    timezoneAbbreviation: "MSK",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Asia/Ulaanbaatar",
    timezoneAbbreviation: "ULAT",
    countryCode: "MN",
  },
  {
    IANAtimezone: "Asia/Urumqi",
    timezoneAbbreviation: "XJT",
    countryCode: "CN",
  },
  {
    IANAtimezone: "Asia/Ust-Nera",
    timezoneAbbreviation: "VLAT",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Asia/Vientiane",
    timezoneAbbreviation: "ICT",
    countryCode: "LA",
  },
  {
    IANAtimezone: "Asia/Vladivostok",
    timezoneAbbreviation: "VLAT",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Asia/Yakutsk",
    timezoneAbbreviation: "YAKT",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Asia/Yangon",
    timezoneAbbreviation: "MMT",
    countryCode: "MM",
  },
  {
    IANAtimezone: "Asia/Yekaterinburg",
    timezoneAbbreviation: "YEKT",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Asia/Yerevan",
    timezoneAbbreviation: "AMT",
    countryCode: "AM",
  },
  {
    IANAtimezone: "Atlantic/Azores",
    timezoneAbbreviation: "AZOT",
    countryCode: "PT",
  },
  {
    IANAtimezone: "Atlantic/Bermuda",
    timezoneAbbreviation: "AST",
    countryCode: "BM",
  },
  {
    IANAtimezone: "Atlantic/Canary",
    timezoneAbbreviation: "WET",
    countryCode: "ES",
  },
  {
    IANAtimezone: "Atlantic/Cape_Verde",
    timezoneAbbreviation: "CVT",
    countryCode: "CV",
  },
  {
    IANAtimezone: "Atlantic/Faroe",
    timezoneAbbreviation: "WET",
    countryCode: "FO",
  },
  {
    IANAtimezone: "Atlantic/Madeira",
    timezoneAbbreviation: "WET",
    countryCode: "PT",
  },
  {
    IANAtimezone: "Atlantic/Reykjavik",
    timezoneAbbreviation: "GMT",
    countryCode: "IS",
  },
  {
    IANAtimezone: "Atlantic/South_Georgia",
    timezoneAbbreviation: "GST",
    countryCode: "GS",
  },
  {
    IANAtimezone: "Atlantic/St_Helena",
    timezoneAbbreviation: "GMT",
    countryCode: "SH",
  },
  {
    IANAtimezone: "Atlantic/Stanley",
    timezoneAbbreviation: "FKST",
    countryCode: "FK",
  },
  {
    IANAtimezone: "Australia/Adelaide",
    timezoneAbbreviation: "ACST",
    countryCode: "AU",
  },
  {
    IANAtimezone: "Australia/Brisbane",
    timezoneAbbreviation: "AEST",
    countryCode: "AU",
  },
  {
    IANAtimezone: "Australia/Broken_Hill",
    timezoneAbbreviation: "ACST",
    countryCode: "AU",
  },
  {
    IANAtimezone: "Australia/Currie",
    timezoneAbbreviation: "AEST",
    countryCode: "AU",
  },
  {
    IANAtimezone: "Australia/Darwin",
    timezoneAbbreviation: "ACST",
    countryCode: "AU",
  },
  {
    IANAtimezone: "Australia/Eucla",
    timezoneAbbreviation: "ACWST",
    countryCode: "AU",
  },
  {
    IANAtimezone: "Australia/Hobart",
    timezoneAbbreviation: "AEST",
    countryCode: "AU",
  },
  {
    IANAtimezone: "Australia/Lindeman",
    timezoneAbbreviation: "AEST",
    countryCode: "AU",
  },
  {
    IANAtimezone: "Australia/Lord_Howe",
    timezoneAbbreviation: "LHST",
    countryCode: "AU",
  },
  {
    IANAtimezone: "Australia/Melbourne",
    timezoneAbbreviation: "AEST",
    countryCode: "AU",
  },
  {
    IANAtimezone: "Australia/Perth",
    timezoneAbbreviation: "AWST",
    countryCode: "AU",
  },
  {
    IANAtimezone: "Australia/Sydney",
    timezoneAbbreviation: "AEST",
    countryCode: "AU",
  },
  {
    IANAtimezone: "Europe/Amsterdam",
    timezoneAbbreviation: "CET",
    countryCode: "NL",
  },
  {
    IANAtimezone: "Europe/Andorra",
    timezoneAbbreviation: "CET",
    countryCode: "AD",
  },
  {
    IANAtimezone: "Europe/Astrakhan",
    timezoneAbbreviation: "MSK",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Europe/Athens",
    timezoneAbbreviation: "EET",
    countryCode: "GR",
  },
  {
    IANAtimezone: "Europe/Belgrade",
    timezoneAbbreviation: "CET",
    countryCode: "RS",
  },
  {
    IANAtimezone: "Europe/Berlin",
    timezoneAbbreviation: "CET",
    countryCode: "DE",
  },
  {
    IANAtimezone: "Europe/Bratislava",
    timezoneAbbreviation: "CET",
    countryCode: "SK",
  },
  {
    IANAtimezone: "Europe/Brussels",
    timezoneAbbreviation: "CET",
    countryCode: "BE",
  },
  {
    IANAtimezone: "Europe/Bucharest",
    timezoneAbbreviation: "EET",
    countryCode: "RO",
  },
  {
    IANAtimezone: "Europe/Budapest",
    timezoneAbbreviation: "CET",
    countryCode: "HU",
  },
  {
    IANAtimezone: "Europe/Busingen",
    timezoneAbbreviation: "CET",
    countryCode: "DE",
  },
  {
    IANAtimezone: "Europe/Chisinau",
    timezoneAbbreviation: "EET",
    countryCode: "MD",
  },
  {
    IANAtimezone: "Europe/Copenhagen",
    timezoneAbbreviation: "CET",
    countryCode: "DK",
  },
  {
    IANAtimezone: "Europe/Dublin",
    timezoneAbbreviation: "IST",
    countryCode: "IE",
  },
  {
    IANAtimezone: "Europe/Gibraltar",
    timezoneAbbreviation: "CET",
    countryCode: "GI",
  },
  {
    IANAtimezone: "Europe/Guernsey",
    timezoneAbbreviation: "BST",
    countryCode: "GG",
  },
  {
    IANAtimezone: "Europe/Helsinki",
    timezoneAbbreviation: "EET",
    countryCode: "FI",
  },
  {
    IANAtimezone: "Europe/Island",
    timezoneAbbreviation: "GMT",
    countryCode: "IS",
  },
  {
    IANAtimezone: "Europe/Istanbul",
    timezoneAbbreviation: "EET",
    countryCode: "TR",
  },
  {
    IANAtimezone: "Europe/Jersey",
    timezoneAbbreviation: "BST",
    countryCode: "JE",
  },
  {
    IANAtimezone: "Europe/Kaliningrad",
    timezoneAbbreviation: "EET",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Europe/Kiev",
    timezoneAbbreviation: "EET",
    countryCode: "UA",
  },
  {
    IANAtimezone: "Europe/Kirov",
    timezoneAbbreviation: "MSK",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Europe/Lisbon",
    timezoneAbbreviation: "WET",
    countryCode: "PT",
  },
  {
    IANAtimezone: "Europe/Ljubljana",
    timezoneAbbreviation: "CET",
    countryCode: "SI",
  },
  {
    IANAtimezone: "Europe/London",
    timezoneAbbreviation: "BST",
    countryCode: "GB",
  },
  {
    IANAtimezone: "Europe/Luxembourg",
    timezoneAbbreviation: "CET",
    countryCode: "LU",
  },
  {
    IANAtimezone: "Europe/Madrid",
    timezoneAbbreviation: "CET",
    countryCode: "ES",
  },
  {
    IANAtimezone: "Europe/Malta",
    timezoneAbbreviation: "CET",
    countryCode: "MT",
  },
  {
    IANAtimezone: "Europe/Mariehamn",
    timezoneAbbreviation: "EET",
    countryCode: "AX",
  },
  {
    IANAtimezone: "Europe/Minsk",
    timezoneAbbreviation: "MSK",
    countryCode: "BY",
  },
  {
    IANAtimezone: "Europe/Monaco",
    timezoneAbbreviation: "CET",
    countryCode: "MC",
  },
  {
    IANAtimezone: "Europe/Moscow",
    timezoneAbbreviation: "MSK",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Europe/Oslo",
    timezoneAbbreviation: "CET",
    countryCode: "NO",
  },
  {
    IANAtimezone: "Europe/Paris",
    timezoneAbbreviation: "CET",
    countryCode: "FR",
  },
  {
    IANAtimezone: "Europe/Podgorica",
    timezoneAbbreviation: "CET",
    countryCode: "ME",
  },
  {
    IANAtimezone: "Europe/Prague",
    timezoneAbbreviation: "CET",
    countryCode: "CZ",
  },
  {
    IANAtimezone: "Europe/Riga",
    timezoneAbbreviation: "EET",
    countryCode: "LV",
  },
  {
    IANAtimezone: "Europe/Rome",
    timezoneAbbreviation: "CET",
    countryCode: "IT",
  },
  {
    IANAtimezone: "Europe/Samara",
    timezoneAbbreviation: "SAMT",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Europe/San_Marino",
    timezoneAbbreviation: "CET",
    countryCode: "SM",
  },
  {
    IANAtimezone: "Europe/Sarajevo",
    timezoneAbbreviation: "CET",
    countryCode: "BA",
  },
  {
    IANAtimezone: "Europe/Saratov",
    timezoneAbbreviation: "MSK",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Europe/Simferopol",
    timezoneAbbreviation: "MSK",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Europe/Skopje",
    timezoneAbbreviation: "CET",
    countryCode: "MK",
  },
  {
    IANAtimezone: "Europe/Sofia",
    timezoneAbbreviation: "EET",
    countryCode: "BG",
  },
  {
    IANAtimezone: "Europe/Stockholm",
    timezoneAbbreviation: "CET",
    countryCode: "SE",
  },
  {
    IANAtimezone: "Europe/Tallinn",
    timezoneAbbreviation: "EET",
    countryCode: "EE",
  },
  {
    IANAtimezone: "Europe/Tirane",
    timezoneAbbreviation: "CET",
    countryCode: "AL",
  },
  {
    IANAtimezone: "Europe/Ulyanovsk",
    timezoneAbbreviation: "MSK",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Europe/Uzhgorod",
    timezoneAbbreviation: "EET",
    countryCode: "UA",
  },
  {
    IANAtimezone: "Europe/Vaduz",
    timezoneAbbreviation: "CET",
    countryCode: "LI",
  },
  {
    IANAtimezone: "Europe/Vatican",
    timezoneAbbreviation: "CET",
    countryCode: "VA",
  },
  {
    IANAtimezone: "Europe/Vienna",
    timezoneAbbreviation: "CET",
    countryCode: "AT",
  },
  {
    IANAtimezone: "Europe/Vilnius",
    timezoneAbbreviation: "EET",
    countryCode: "LT",
  },
  {
    IANAtimezone: "Europe/Volgograd",
    timezoneAbbreviation: "MSK",
    countryCode: "RU",
  },
  {
    IANAtimezone: "Europe/Warsaw",
    timezoneAbbreviation: "CET",
    countryCode: "PL",
  },
  {
    IANAtimezone: "Europe/Zagreb",
    timezoneAbbreviation: "CET",
    countryCode: "HR",
  },
  {
    IANAtimezone: "Europe/Zaporozhye",
    timezoneAbbreviation: "EET",
    countryCode: "UA",
  },
  {
    IANAtimezone: "Europe/Zurich",
    timezoneAbbreviation: "CET",
    countryCode: "CH",
  },
  {
    IANAtimezone: "Indian/Antananarivo",
    timezoneAbbreviation: "EAT",
    countryCode: "MG",
  },
  {
    IANAtimezone: "Indian/Chagos",
    timezoneAbbreviation: "IOT",
    countryCode: "IO",
  },
  {
    IANAtimezone: "Indian/Christmas",
    timezoneAbbreviation: "CXT",
    countryCode: "CX",
  },
  {
    IANAtimezone: "Indian/Cocos",
    timezoneAbbreviation: "CCT",
    countryCode: "CC",
  },
  {
    IANAtimezone: "Indian/Comoro",
    timezoneAbbreviation: "EAT",
    countryCode: "KM",
  },
  {
    IANAtimezone: "Indian/Kerguelen",
    timezoneAbbreviation: "TFT",
    countryCode: "TF",
  },
  {
    IANAtimezone: "Indian/Mahe",
    timezoneAbbreviation: "SCT",
    countryCode: "SC",
  },
  {
    IANAtimezone: "Indian/Maldives",
    timezoneAbbreviation: "MVT",
    countryCode: "MV",
  },
  {
    IANAtimezone: "Indian/Mauritius",
    timezoneAbbreviation: "MUT",
    countryCode: "MU",
  },
  {
    IANAtimezone: "Indian/Mayotte",
    timezoneAbbreviation: "EAT",
    countryCode: "YT",
  },
  {
    IANAtimezone: "Indian/Reunion",
    timezoneAbbreviation: "RET",
    countryCode: "RE",
  },
  {
    IANAtimezone: "Pacific/Apia",
    timezoneAbbreviation: "WST",
    countryCode: "WS",
  },
  {
    IANAtimezone: "Pacific/Auckland",
    timezoneAbbreviation: "NZST",
    countryCode: "NZ",
  },
  {
    IANAtimezone: "Pacific/Bougainville",
    timezoneAbbreviation: "BST",
    countryCode: "PG",
  },
  {
    IANAtimezone: "Pacific/Chatham",
    timezoneAbbreviation: "CHAST",
    countryCode: "NZ",
  },
  {
    IANAtimezone: "Pacific/Chuuk",
    timezoneAbbreviation: "CHUT",
    countryCode: "FM",
  },
  {
    IANAtimezone: "Pacific/Easter",
    timezoneAbbreviation: "EASST",
    countryCode: "CL",
  },
  {
    IANAtimezone: "Pacific/Efate",
    timezoneAbbreviation: "VUT",
    countryCode: "VU",
  },
  {
    IANAtimezone: "Pacific/Enderbury",
    timezoneAbbreviation: "PHOT",
    countryCode: "KI",
  },
  {
    IANAtimezone: "Pacific/Fakaofo",
    timezoneAbbreviation: "TKT",
    countryCode: "TK",
  },
  {
    IANAtimezone: "Pacific/Fiji",
    timezoneAbbreviation: "FJT",
    countryCode: "FJ",
  },
  {
    IANAtimezone: "Pacific/Funafuti",
    timezoneAbbreviation: "TVT",
    countryCode: "TV",
  },
  {
    IANAtimezone: "Pacific/Galapagos",
    timezoneAbbreviation: "GALT",
    countryCode: "EC",
  },
  {
    IANAtimezone: "Pacific/Gambier",
    timezoneAbbreviation: "GAMT",
    countryCode: "PF",
  },
  {
    IANAtimezone: "Pacific/Guadalcanal",
    timezoneAbbreviation: "SBT",
    countryCode: "SB",
  },
  {
    IANAtimezone: "Pacific/Guam",
    timezoneAbbreviation: "ChST",
    countryCode: "GU",
  },
  {
    IANAtimezone: "Pacific/Honolulu",
    timezoneAbbreviation: "HST",
    countryCode: "US",
  },
  {
    IANAtimezone: "Pacific/Kiritimati",
    timezoneAbbreviation: "LINT",
    countryCode: "KI",
  },
  {
    IANAtimezone: "Pacific/Kosrae",
    timezoneAbbreviation: "KOST",
    countryCode: "FM",
  },
  {
    IANAtimezone: "Pacific/Kwajalein",
    timezoneAbbreviation: "MHT",
    countryCode: "MH",
  },
  {
    IANAtimezone: "Pacific/Majuro",
    timezoneAbbreviation: "MHT",
    countryCode: "MH",
  },
  {
    IANAtimezone: "Pacific/Marquesas",
    timezoneAbbreviation: "MART",
    countryCode: "PF",
  },
  {
    IANAtimezone: "Pacific/Midway",
    timezoneAbbreviation: "SST",
    countryCode: "UM",
  },
  {
    IANAtimezone: "Pacific/Nauru",
    timezoneAbbreviation: "NRT",
    countryCode: "NR",
  },
  {
    IANAtimezone: "Pacific/Niue",
    timezoneAbbreviation: "NUT",
    countryCode: "NU",
  },
  {
    IANAtimezone: "Pacific/Norfolk",
    timezoneAbbreviation: "NFT",
    countryCode: "NF",
  },
  {
    IANAtimezone: "Pacific/Noumea",
    timezoneAbbreviation: "NCT",
    countryCode: "NC",
  },
  {
    IANAtimezone: "Pacific/Pago_Pago",
    timezoneAbbreviation: "SST",
    countryCode: "AS",
  },
  {
    IANAtimezone: "Pacific/Palau",
    timezoneAbbreviation: "PWT",
    countryCode: "PW",
  },
  {
    IANAtimezone: "Pacific/Pitcairn",
    timezoneAbbreviation: "PST",
    countryCode: "PN",
  },
  {
    IANAtimezone: "Pacific/Pohnpei",
    timezoneAbbreviation: "PONT",
    countryCode: "FM",
  },
  {
    IANAtimezone: "Pacific/Port_Moresby",
    timezoneAbbreviation: "PGT",
    countryCode: "PG",
  },
  {
    IANAtimezone: "Pacific/Rarotonga",
    timezoneAbbreviation: "CKT",
    countryCode: "CK",
  },
  {
    IANAtimezone: "Pacific/Saipan",
    timezoneAbbreviation: "ChST",
    countryCode: "MP",
  },
  {
    IANAtimezone: "Pacific/Tahiti",
    timezoneAbbreviation: "TAHT",
    countryCode: "PF",
  },
  {
    IANAtimezone: "Pacific/Tarawa",
    timezoneAbbreviation: "GILT",
    countryCode: "KI",
  },
  {
    IANAtimezone: "Pacific/Tongatapu",
    timezoneAbbreviation: "TOT",
    countryCode: "TO",
  },
  {
    IANAtimezone: "Pacific/Wake",
    timezoneAbbreviation: "WAKT",
    countryCode: "UM",
  },
  {
    IANAtimezone: "Pacific/Wallis",
    timezoneAbbreviation: "WFT",
    countryCode: "WF",
  },
  { IANAtimezone: "UTC", timezoneAbbreviation: "UTC", countryCode: "ZZ" },
  {
    IANAtimezone: "US/Alaska",
    timezoneAbbreviation: "AKDT",
    countryCode: "US",
  },
  {
    IANAtimezone: "US/Arizona",
    timezoneAbbreviation: "MST",
    countryCode: "US",
  },
  {
    IANAtimezone: "US/Central",
    timezoneAbbreviation: "CDT",
    countryCode: "US",
  },
  {
    IANAtimezone: "US/Eastern",
    timezoneAbbreviation: "EDT",
    countryCode: "US",
  },
  { IANAtimezone: "US/Hawaii", timezoneAbbreviation: "HST", countryCode: "US" },
  {
    IANAtimezone: "US/Mountain",
    timezoneAbbreviation: "MDT",
    countryCode: "US",
  },
  {
    IANAtimezone: "US/Pacific",
    timezoneAbbreviation: "PDT",
    countryCode: "US",
  },
  { IANAtimezone: "US/Samoa", timezoneAbbreviation: "SST", countryCode: "US" },
];

/* eslint-disable import/prefer-default-export,class-methods-use-this */

import {
  IStudentLessonRequest,
  IEditableStudentProfile,
  IStudentSettings,
  IStudentProfile,
} from "src/interfaces/student";
import {
  createGrade,
  getConfirmedLessons,
  getConfirmedLessonsViaAdmin,
  getConfirmedLessonsViaAdminSSE,
  getCurriculums,
  getGrades,
  getLessonCount,
  getLessonProgress,
  getLessonRequests,
  getLessonRequestsViaAdmin,
  getLessonRewards,
  getMostRecentLesson,
  getPastConfirmedLessonsViaAdmin,
  getStudentAccountSettings,
  getStudentFeedbackByDateRange,
  getStudentProfileDetails,
  getStudentRefferalStatistics,
  getStudentReportByDateRange,
  postMarKLessonRewardsAsRead,
  postStudentAccountSettings,
  postStudentAvatar,
  postStudentProfileDetails,
  submitBookingForm,
  updateGrade,
  getLeaderboard,
  getCowryPouch,
  updateTopicLastPracticed,
} from "src/server/endpoints/student";

// application

export class StudentApi {
  static submitBookingForm(
    studentId: any,
    tutorId: any,
    selectedSubjectOption: any,
    selectedLessonLevels: any,
    whoLessonIsFor: any,
    tuitionRegularity: any,
    weeklyFrequency: any,
    lessonSchedule: any,
    introductionText: any,
    lessonPrice: any,
    tutorName: any,
    tutorAvatar: string,
    studentAvatar: string,
    bookingPrice: any,
    studentName: any,
    localPrice: string
  ): Promise<any> {
    return submitBookingForm(
      studentId,
      tutorId,
      selectedSubjectOption,
      selectedLessonLevels,
      whoLessonIsFor,
      tuitionRegularity,
      weeklyFrequency,
      lessonSchedule,
      introductionText,
      lessonPrice,
      tutorName,
      tutorAvatar,
      studentAvatar,
      bookingPrice,
      studentName,
      localPrice
    );
  }
  static getLessonRequests(
    studentId: string
  ): Promise<IStudentLessonRequest[]> {
    return getLessonRequests(studentId);
  }
  static getLessonRequestsViaAdmin(
    studentId: string
  ): Promise<IStudentLessonRequest[]> {
    return getLessonRequestsViaAdmin(studentId);
  }
  static getConfirmedLessons(
    studentId: string
  ): Promise<IStudentLessonRequest[]> {
    return getConfirmedLessons(studentId);
  }
  static getConfirmedLessonsViaAdmin(
    studentId: string
  ): Promise<IStudentLessonRequest[]> {
    return getConfirmedLessonsViaAdmin(studentId);
  }
  static getLessonCount(
    studentId: string,
    subscriptionId: string
  ): Promise<any> {
    return getLessonCount(studentId, subscriptionId);
  }
  static getMostRecentLesson(studentId: string): Promise<any> {
    return getMostRecentLesson(studentId);
  }
  static getConfirmedLessonsViaAdminSSE(studentId: string): EventSource {
    return getConfirmedLessonsViaAdminSSE(studentId);
  }
  static getStudentReportByDateRange(
    tutorId: string,
    startDate: Date,
    endDate: Date
  ): Promise<any> {
    return getStudentReportByDateRange(tutorId, startDate, endDate);
  }
  static getStudentFeedbackByDateRange(
    tutorId: string,
    startDate: Date,
    endDate: Date
  ): Promise<any> {
    return getStudentFeedbackByDateRange(tutorId, startDate, endDate);
  }
  static getPastConfirmedLessonsViaAdmin(
    studentId: string
  ): Promise<IStudentLessonRequest[]> {
    return getPastConfirmedLessonsViaAdmin(studentId);
  }
  static getStudentAccountSettings(
    studentId: string | undefined
  ): Promise<any> {
    return getStudentAccountSettings(studentId);
  }
  static postStudentAccountSettings(
    studentId: string | undefined,
    settings: IStudentSettings
  ): Promise<any> {
    return postStudentAccountSettings(studentId, settings);
  }
  static getStudentProfileDetails(
    studentId: string | undefined
  ): Promise<IStudentProfile> {
    return getStudentProfileDetails(studentId);
  }
  static postStudentProfileDetails(
    studentId: string | undefined,
    studentDetails: IEditableStudentProfile
  ): Promise<any> {
    return postStudentProfileDetails(studentId, studentDetails);
  }
  static postStudentAvatar(
    studentId: string | undefined,
    imagData: any
  ): Promise<any> {
    return postStudentAvatar(studentId, imagData);
  }
  static createGrade(body: {
    value: string;
    studentId: string;
    curriculumId: string | undefined;
  }): Promise<any> {
    return createGrade(body);
  }
  static updateGrade(body: {
    term: string;
    value: string;
    curriculumId: string;
    studentId: string;
  }): Promise<any> {
    return updateGrade(body);
  }
  static getGrades(curriculumId: string, studentId: string): Promise<any> {
    return getGrades(curriculumId, studentId);
  }
  static getCurriculums(studentId: string): Promise<any> {
    return getCurriculums(studentId);
  }
  static getLessonRewards(studentId: string): Promise<any> {
    return getLessonRewards(studentId);
  }
  static getLeaderboard(): Promise<any> {
    return getLeaderboard();
  }

  static getCowryPouch(studentId: string): Promise<any> {
    return getCowryPouch(studentId);
  }
  static postMarKLessonRewardsAsRead(studentId: string): Promise<any> {
    return postMarKLessonRewardsAsRead(studentId);
  }
  static getStudentRefferalStatistics(referralCode?: string): Promise<any> {
    return getStudentRefferalStatistics(referralCode);
  }
  static getLessonProgress(studentId: string): Promise<any> {
    return getLessonProgress(studentId);
  }
  static updateTopicLastPracticed(studentId: string, body: any): Promise<any> {
    return updateTopicLastPracticed(studentId, body);
  }
}

import React from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import BetaTag from "src/components/BetaTag";
import { routes } from "src/Routes";

export default function BackNavigation({
  navigationText,
  isBetaVersion,
  url,
}: {
  navigationText: string;
  isBetaVersion?: boolean;
  url: string;
}) {
  const navigate = useNavigate();
  const { level, topic, order, topicId, curriculumId, parentDivisionId } =
    useParams();

  console.log("level", level);
  console.log("topic", topic);
  console.log("order", order);
  console.log("topicId", topicId);
  console.log("curriculumId", curriculumId);
  console.log("parentDivisionId", parentDivisionId);

  return (
    <div className="flex justify-between items-center">
      <NavLink to={url}>
        <div className="text-gray-purple cursor-pointer items-center flex gap-2">
          <div>
            <MdOutlineKeyboardBackspace />
          </div>
          {navigationText}
        </div>
      </NavLink>
      <div className="text-primary font-semibold text-xl">{`${order}. ${topic}`}</div>
      {isBetaVersion && (
        <div className="max-w-[150px]">
          <BetaTag />
        </div>
      )}
    </div>
  );
}

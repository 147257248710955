import { add, sub } from "date-fns";

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const daysFull = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const oneDay = {
  years: 0,
  months: 0,
  weeks: 0,
  days: 1,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

export const oneWeek = {
  years: 0,
  months: 0,
  weeks: 0,
  days: 7,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

export const getCurrentWeek = (firstDay) => {
  const sun = {
    fullDate: firstDay,
    day: days[0],
    date: firstDay.getDate(),
    month: months[firstDay.getMonth()],
    year: firstDay.getFullYear(),
  };
  const mon = {
    fullDate: add(new Date(sun.fullDate), oneDay),
    day: days[1],
    date: add(new Date(sun.fullDate), oneDay).getDate(),
    month: months[add(new Date(sun.fullDate), oneDay).getMonth()],
    year: add(new Date(sun.fullDate), oneDay).getFullYear(),
  };
  const tue = {
    fullDate: add(new Date(mon.fullDate), oneDay),
    day: days[2],
    date: add(new Date(mon.fullDate), oneDay).getDate(),
    month: months[add(new Date(mon.fullDate), oneDay).getMonth()],
    year: add(new Date(mon.fullDate), oneDay).getFullYear(),
  };
  const wed = {
    fullDate: add(new Date(tue.fullDate), oneDay),
    day: days[3],
    date: add(new Date(tue.fullDate), oneDay).getDate(),
    month: months[add(new Date(tue.fullDate), oneDay).getMonth()],
    year: add(new Date(tue.fullDate), oneDay).getFullYear(),
  };
  const thu = {
    fullDate: add(new Date(wed.fullDate), oneDay),
    day: days[4],
    date: add(new Date(wed.fullDate), oneDay).getDate(),
    month: months[add(new Date(wed.fullDate), oneDay).getMonth()],
    year: add(new Date(wed.fullDate), oneDay).getFullYear(),
  };
  const fri = {
    fullDate: add(new Date(thu.fullDate), oneDay),
    day: days[5],
    date: add(new Date(thu.fullDate), oneDay).getDate(),
    month: months[add(new Date(thu.fullDate), oneDay).getMonth()],
    year: add(new Date(thu.fullDate), oneDay).getFullYear(),
  };
  const sat = {
    fullDate: add(new Date(fri.fullDate), oneDay),
    day: days[6],
    date: add(new Date(fri.fullDate), oneDay).getDate(),
    month: months[add(new Date(fri.fullDate), oneDay).getMonth()],
    year: add(new Date(fri.fullDate), oneDay).getFullYear(),
  };
  return [sun, mon, tue, wed, thu, fri, sat];
};

export const getPreviousWeek = (week) => {
  week.forEach((day) => {
    day.fullDate = sub(new Date(day.fullDate), oneWeek);
    day.date = day.fullDate.getDate();
    day.month = months[day.fullDate.getMonth()];
    day.year = day.fullDate.getFullYear();
  });
  return week;
};

export const getNextWeek = (week) => {
  week.forEach((day) => {
    day.fullDate = add(new Date(day.fullDate), oneWeek);
    day.date = day.fullDate.getDate();
    day.month = months[day.fullDate.getMonth()];
    day.year = day.fullDate.getFullYear();
  });
  return week;
};

export const categorizeTutorAvailability = (tutorDefinedAvailaibility) => {
  let categories = [[], [], [], [], [], [], []];
  tutorDefinedAvailaibility.forEach((availability, index) => {
    // PRE 12PM
    if (
      availability[0].available ||
      availability[1].available ||
      availability[2].available ||
      availability[4].available ||
      availability[5].available ||
      availability[6].available ||
      availability[7].available
    ) {
      categories[index].push(true);
    } else {
      categories[index].push(false);
    }
    // 12 - 4PM
    if (
      availability[8].available ||
      availability[9].available ||
      availability[10].available ||
      availability[11].available ||
      availability[12].available ||
      availability[13].available ||
      availability[14].available ||
      availability[15].available
    ) {
      categories[index].push(true);
    } else {
      categories[index].push(false);
    }
    // 4 - 7PM
    if (
      availability[16].available ||
      availability[17].available ||
      availability[18].available ||
      availability[19].available ||
      availability[20].available ||
      availability[21].available
    ) {
      categories[index].push(true);
    } else {
      categories[index].push(false);
    }
    // AFTER 7PM
    if (
      availability[22].available ||
      availability[23].available ||
      availability[24].available ||
      availability[25].available ||
      availability[26].available ||
      availability[27].available ||
      availability[28].available ||
      availability[29].available
    ) {
      categories[index].push(true);
    } else {
      categories[index].push(false);
    }
  });
  return categories;
};

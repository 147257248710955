import React from "react";
interface ISVGLinearGradient {
  id: string;
  to: string;
  from: string;
}
export default function SVGLinearGradient({
  id,
  to,
  from,
}: ISVGLinearGradient) {
  return (
    <svg width="0" height="0">
      <linearGradient id={id} x1="100%" y1="100%" x2="0%" y2="0%">
        <stop stopColor={to} offset="0%" />
        <stop stopColor={from} offset="100%" />
      </linearGradient>
    </svg>
  );
}

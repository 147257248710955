import React, { ReactNode } from "react";
import CardContainer from "./CardContainer";

interface IGroup {
  children: ReactNode;
  title: string;
  completed?: string;
}
export default function Group({ title, completed, children }: IGroup) {
  return (
    <section>
      <div className="flex flex-col sm:flex-row sm:items-center items-start justify-between gap-1">
        <p className=" text-xl font-semibold">{title} Lessons</p>
        {completed && (
          <p
            className={`border-[0.83px] border-gray-200  rounded-lg py-[13.5px] px-[22px] font-semibold text-xl leading-6 ${
              Number(completed) === 100 ? "text-green-700" : "text-primary"
            }`}
          >
            {Number(completed) === 100
              ? "Completed!"
              : completed + "% Unlocked"}
          </p>
        )}
      </div>
      <CardContainer>{children}</CardContainer>
    </section>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { BiBookmarkPlus } from "react-icons/bi";
import { FaBriefcase, FaGraduationCap, FaLaughBeam } from "react-icons/fa";
import { ITutorSearchResult } from "src/interfaces/tutor";
import smile from "src/assets/smile.svg";
import notes from "src/assets/notes.svg";
import { renderCorrectEmoji } from "src/utils/renderTutorAdminBio";
type AboutMeType = {
  tutorBio: string;
  tutorProfile: ITutorSearchResult;
  tutorBioCategories: string[];
  adminBio: string[];
};

export default function AboutMe({
  tutorBio,
  tutorBioCategories,
  adminBio,
}: AboutMeType) {
  return (
    <>
      <div className="md:shadow-md md:border-[1px] md:border-gray-200 md:rounded-2xl lg:ml-2 p-4 px-6 max-w-[680px] mb-12">
        <div>
          <h3 className="text-primary-light font-semibold mb-2">About me</h3>
        </div>
        <p className="text-sm md:text-sm">{tutorBio}</p>
      </div>
      {adminBio.length > 1 && (
        <div className="md:shadow-md md:border-[1px] md:border-gray-200 md:rounded-2xl lg:ml-2 py-4 px-1 max-w-[680px]">
          {/* Admin Bio:string[] */}
          {adminBio.map((bio, index) => (
            <div key={index} className="flex gap-3 px-4 items-center mt-1 py-2">
              {index === 0 && (
                <div className="rounded-full bg-pale-purple-bg px-3 py-2">
                  <p className="text-3xl leading-10">
                    {renderCorrectEmoji(tutorBioCategories[index])}
                  </p>
                </div>
              )}
              {index === 1 && (
                <div className="rounded-full bg-pale-purple-bg px-3 py-2">
                  <p className="text-3xl leading-10">
                    {renderCorrectEmoji(tutorBioCategories[index])}
                  </p>
                </div>
              )}
              {index === 2 && (
                <div className="rounded-full bg-pale-purple-bg px-3 py-2">
                  <p className="text-3xl leading-10">
                    {renderCorrectEmoji(tutorBioCategories[index])}
                  </p>
                </div>
              )}
              <p className="text-primary font-medium text-md md:text-lg mt-[2px]">
                {bio}
              </p>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

import { DirectPayState } from "src/context/DirectPayContext";
import { IGoogleUser } from "src/interfaces/auth";
import { directPaySignup, googleSignIn } from "src/server/endpoints/auth";

export class AuthApi {
  static directPaySignup(body: DirectPayState) {
    return directPaySignup(body);
  }
  static googleSignIn(user: IGoogleUser) {
    return googleSignIn(user);
  }
}

import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";
import { FaRegEnvelope } from "react-icons/fa";
import { routes } from "src/Routes";
import { useStateValue } from "src/context/StateProvider";

export default function Messages() {
  const [{ unreadMessages, showMobileMenu }, dispatch] = useStateValue();

  const closeMobileMenu = async () => {
    await dispatch({
      type: "SET_SHOW_MOBILE_MENU",
      showMobileMenu: !showMobileMenu,
    });
  };

  const location = useLocation();
  return (
    <div>
      <NavLink to={routes.STUDENT_DASHBOARD_MESSAGES.url}>
        <div
          className={clsx(
            {
              "menu-inactive": !location.pathname.includes(
                routes.STUDENT_DASHBOARD_MESSAGES.url
              ),
            },
            {
              "menu-active": location.pathname.includes(
                routes.STUDENT_DASHBOARD_MESSAGES.url
              ),
            }
          )}
          onClick={closeMobileMenu}
        >
          <div className="flex gap-4">
            <div className="flex gap-4 pr-5">
              <div>
                <FaRegEnvelope className="text-lg" />
              </div>
              <div>{routes.STUDENT_DASHBOARD_MESSAGES.label}</div>
            </div>
            {unreadMessages && (
              <div
                className={clsx({
                  "bg-secondary hover:bg-secondary-light text-xxs w-auto px-2.5 py-1 text-white rounded-full flex justify-between":
                    unreadMessages?.length,
                  hidden: !unreadMessages?.length,
                })}
              >
                {unreadMessages?.length}
              </div>
            )}
          </div>
        </div>
      </NavLink>
    </div>
  );
}

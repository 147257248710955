import React from "react";
import { BiShieldQuarter, BiTrendingUp } from "react-icons/bi";
import { FaHandHoldingHeart } from "react-icons/fa";
import { MdConnectWithoutContact } from "react-icons/md";

export default function WhyLearnLanguages() {
  return (
    <div className="container pb-20">
      <h2 className="text-primary font-bold text-3xl mt-4 text-center">
        Why Should You Learn Yorùbá and Igbo?
      </h2>
      <div className="flex gap-6 flex-col lg:flex-row justify-between mt-10">
        <div className="white-card-rounded p-4 w-full">
          <div className="flex gap-2 justify-center lg:justify-start items-center">
            <div className="p-2 rounded-full bg-secondary">
              <MdConnectWithoutContact className="text-white" />
            </div>
            <h3 className="text-primary font-bold">Connect with the Culture</h3>
          </div>

          <p className="text-primary text-sm mt-8">
            Language is the road map of a culture. It tells you where its people
            come from and where they are going
          </p>
        </div>
        <div className="white-card-rounded p-4 ">
          <div className="flex gap-2 justify-center lg:justify-start items-center">
            <div className="p-2 rounded-full bg-green-500">
              <BiTrendingUp className="text-white" />
            </div>
            <h3 className="text-primary font-bold">Fluency is achievable</h3>
          </div>

          <p className="text-primary text-sm mt-8">
            While vocab and pronunciation are a fun challenge, the simple
            grammar make our languages easier to learn than you may think
          </p>
        </div>
        <div className="white-card-rounded p-4 ">
          <div className="flex gap-2 justify-center lg:justify-start items-center">
            <div className="p-2 rounded-full bg-yellow-500">
              <BiShieldQuarter className="text-white" />
            </div>
            <h3 className="text-primary font-bold">The Arts</h3>
          </div>

          <p className="text-primary text-sm mt-8">
            The Arts and Poetry is a huge part of Nigerian culture. Afrobeats
            has taken the world by storm and Nigerian movies are thrilling,
            hilarious and educative.
          </p>
        </div>
        <div className="white-card-rounded p-4 ">
          <div className="flex gap-2 justify-center lg:justify-start items-center">
            <div className="p-2 rounded-full bg-purple-500">
              <FaHandHoldingHeart className="text-white" />
            </div>
            <h3 className="text-primary font-bold">Pass On the Language</h3>
          </div>

          <p className="text-primary text-sm mt-8">
            If you speak to someone in a language he understands, it goes to his
            head. If you talk to someone in his own language, you can reach his
            heart.
          </p>
        </div>
      </div>
    </div>
  );
}

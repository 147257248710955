import { ReactNode } from "react";
import "./dropdown.css"; // Import your CSS file with transition classes

interface IDropdownContent {
  children: ReactNode;
  open: boolean;
}

export default function DropdownContent({ children, open }: IDropdownContent) {
  return (
    <div
      className={` dropdown ${
        open ? "fadeIn" : ""
      } flex text-left flex-col gap-6 py-6 px-3 border rounded-3xl mt-12`}
      style={{
        backgroundColor: "#F8F8F8",
        borderColor: "#F4F4F4",
      }}
    >
      {children}
    </div>
  );
}

import Lottie from "lottie-react";
import * as animation from "src/assets/animations/learning-app-loader.json";

export default function LearningAppLoader({ size }) {
  return (
    <Lottie
      animationData={animation}
      style={{
        height: size,
      }}
    />
  );
}

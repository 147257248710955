import SideNav from "./SideNav";
import Header from "src/layouts/DashboardHeader";
import FooterMenu from "./FooterMenu";

export default function TeacherReferralDashboardLayout({ children }: any) {
  return (
    <>
      <div className="sticky top-0 z-50">
        <Header />
      </div>
      <div>
        <SideNav />
      </div>
      <div className="lg:ml-64 py-10 px-6 flex-grow">{children}</div>
      {/* <div className="sticky bottom-0 lg:hidden z-50">
        <FooterMenu />
      </div> */}
    </>
  );
}

export const selectorTheme = (theme) => {
  return {
    ...theme,
    borderRadius: 10,
    borderColor: "red",
    colors: {
      ...theme.colors,
      primary25: "#F8F5FC",
      primary: "#A188CC",
      text: "red",
      // neutral0: "red",
      neutral5: "green",
      neutral10: "#F8F5FC",
      neutral20: "#A188CC",
      //neutral30: "#3F2077",
      // neutral40: "#341A64",
      neutral50: "#b2aab7",
      neutral60: "#341A64",
      neutral70: "#341A64",
      neutral80: "#341A64",
    },
  };
};

export const styles = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
};

export const selectorThemeHero = (theme) => {
  return {
    ...theme,
    borderRadius: 10,
    borderColor: "red",
    borderWidth: "0px",
    width: "max-content",
    minWidth: "100%",
    colors: {
      ...theme.colors,
      primary25: "#fff",
      primary: "#fff",
      text: "red",
      neutral0: "#fff",
      neutral5: "green",
      neutral10: "#fff",
      neutral20: "#fff",
      neutral30: "#fff",
      neutral40: "#fff",
      neutral50: "#b2aab7",
      neutral60: "#fff",
      neutral70: "#fff",
      neutral80: "#341A64",
    },
  };
};

export const selectorThemeFindATutor = (theme) => {
  return {
    ...theme,
    borderRadius: 10,
    borderColor: "red",
    borderWidth: "0px",
    width: "max-content",
    minWidth: "100%",
    colors: {
      ...theme.colors,
      primary25: "#F8F5FC",
      primary: "#fff",
      text: "red",
      neutral0: "#fff",
      neutral5: "green",
      neutral10: "#F8F5FC",
      neutral20: "#fff",
      neutral30: "#fff",
      neutral40: "#fff",
      neutral50: "#b2aab7",
      neutral60: "#fff",
      neutral70: "#fff",
      neutral80: "#341A64",
    },
  };
};

export const areaOfStudyOptions = [
  { value: "Agricultural Sciences", label: "Agricultural Sciences" },
  { value: "Arts", label: "Arts" },
  { value: "Business", label: "Business" },
  { value: "Computing", label: "Computing" },
  { value: "Engineering", label: "Engineering" },
  {
    value: "Humanities and Social Sciences",
    label: "Humanities and Social Sciences",
  },
  { value: "Igbo", label: "Igbo" },
  { value: "Languages", label: "Languages" },
  { value: "Law", label: "Law" },
  { value: "Linguistics", label: "Linguistics" },
  { value: "Mathematical-Sciences", label: "Mathematical Sciences" },
  {
    value: "Medicine and Health Sciences",
    label: "Medicine and Health Sciences",
  },
  { value: "Natural sciences", label: "Natural sciences" },
  { value: "Sports", label: "Sports" },
  { value: "Yoruba", label: "Yoruba" },
];

export const paymentFrequencyOptions = [
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
];

export const getAssesmentExerciseURL = (subjectOptions, subjectId) => {
  try {
    const subject = subjectOptions.find((subject) => {
      return subject.subject_id == subjectId;
    });
    return subject.exerciseUrl;
  } catch (err) {
    console.log({ err });
  }
};

export const getAssesmentInterviewURL = (subjectOptions, subjectId) => {
  try {
    const subject = subjectOptions.find((subject) => {
      return subject.subject_id == subjectId;
    });
    return subject.interviewUrl;
  } catch (err) {
    console.log({ err });
  }
};

export const groupLessonAgeGroupsOptions = [
  { value: "3 - 4", label: "3 - 4" },
  { value: "4 - 5", label: "4 - 5" },
  { value: "5 - 6", label: "5 - 6" },
  { value: "6 - 7", label: "6 - 7" },
  { value: "7 - 8", label: "7 - 8" },
  { value: "8 - 9", label: "8 - 9" },
  { value: "9 - 10", label: "9 - 10" },
  { value: "10 - 11", label: "10 - 11" },
  { value: "11 - 12", label: "11 - 12" },
  { value: "12 - 13", label: "12 - 13" },
  { value: "13 - 14", label: "13 - 14" },
  { value: "14 - 15", label: "14 - 15" },
  { value: "15 - 16", label: "15 - 16" },
  { value: "16 - 17", label: "16 - 17" },
  { value: "17 - 18", label: "17 - 18" },
  { value: "Adults", label: "Adults" },
];

export const howYouHearAboutUsOptions = [
  { value: "Referral", label: "Referral" },
  { value: "Google", label: "Google" },
  { value: "Facebook", label: "Facebook" },
  { value: "Instagram", label: "Instagram" },
  { value: "Other", label: "Other" },
];

export const AGE_OPTIONS = [
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "Adult", label: "Adult" },
];

export const qualificationOptions = [
  // { value: "High School", label: "High School" },
  { value: "Working towards a degree", label: "Working towards a degree" },
  { value: "Bachelors", label: "Bachelors" },
  { value: "Masters", label: "Masters" },
  { value: "PhD", label: "PhD" },
];

export const experienceOptions = [
  { value: "Just getting started", label: "Just getting started" },
  { value: "Less than 2 years", label: "Less than 2 years" },
  { value: "2 - 4 years", label: "2 - 4 years" },
  { value: "4 +", label: "4 +" },
];

export const genderOptions = [
  { value: "Female", label: "Female" },
  { value: "Male", label: "Male" },
  { value: "Other", label: "I prefer not to say" },
];

export const weeklyLessons = [
  { value: 1, label: "1", desc: "once a week" },
  { value: 2, label: "2", desc: "twice a week" },
  { value: 3, label: "3", desc: "3 days a week" },
  { value: 4, label: "4", desc: "4 days a week" },
  { value: 5, label: "5", desc: "5 days a week" },
  { value: 6, label: "6", desc: "6 days a week" },
  { value: 7, label: "7", desc: "7 days a week" },
];

export const lessonDurationOptions = [
  { value: 0.5, label: "30 minutes", default: false },
  { value: 1, label: "1 hour", default: false },
  { value: 1.5, label: "1.5 hours", default: false },
  { value: 2, label: "2 hours", default: false },
  { value: 2.5, label: "2.5 hours", default: false },
  { value: 3, label: "3 hours", default: false },
];

export const levelOptions = [
  { value: "A-Level", label: "A-Level" },
  { value: "Adult Level", label: "Adult Level" },
  { value: "AP", label: "AP" },
  { value: "Child Education", label: "Child Education" },
  { value: "GCSE", label: "GCSE" },
  { value: "iGCSE", label: "iGCSE" },
  {
    value: "International Baccalaureate",
    label: "International Baccalaureate",
  },
  { value: "KS1", label: "KS1" },
  { value: "KS2", label: "KS2" },
  { value: "KS3", label: "KS3" },
  { value: "SAT", label: "SAT" },
  { value: "Teen Level", label: "Teen Level" },
  { value: "Nigerian Academic", label: "Nigerian Academic" },
];

export const countryOptions = [
  { label: "Afghanistan", value: "AF" },
  { label: "Åland Islands", value: "AX" },
  { label: "Albania", value: "AL" },
  { label: "Algeria", value: "DZ" },
  { label: "American Samoa", value: "AS" },
  { label: "Andorra", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua and Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belgium", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia", value: "BO" },
  { label: "Bosnia and Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Indian Ocean Territory", value: "IO" },
  { label: "Brunei Darussalam", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Canada", value: "CA" },
  { label: "Cape Verde", value: "CV" },
  { label: "Cayman Islands", value: "KY" },
  { label: "Central African Republic", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos (Keeling) Islands", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoros", value: "KM" },
  { label: "Congo", value: "CG" },
  { label: "Congo, The Democratic Republic of the", value: "CD" },
  { label: "Cook Islands", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Cote D'Ivoire", value: "CI" },
  { label: "Croatia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Cyprus", value: "CY" },
  { label: "Czech Republic", value: "CZ" },
  { label: "Denmark", value: "DK" },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Ethiopia", value: "ET" },
  { label: "Falkland Islands (Malvinas)", value: "FK" },
  { label: "Faroe Islands", value: "FO" },
  { label: "Fiji", value: "FJ" },
  { label: "Finland", value: "FI" },
  { label: "France", value: "FR" },
  { label: "French Guiana", value: "GF" },
  { label: "French Polynesia", value: "PF" },
  { label: "French Southern Territories", value: "TF" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Grenada", value: "GD" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard Island and Mcdonald Islands", value: "HM" },
  { label: "Holy See (Vatican City State)", value: "VA" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Iceland", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Iran, Islamic Republic Of", value: "IR" },
  { label: "Iraq", value: "IQ" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italy", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE" },
  { label: "Kiribati", value: "KI" },
  { label: "Korea, Democratic People'S Republic of", value: "KP" },
  { label: "Korea, Republic of", value: "KR" },
  { label: "Kuwait", value: "KW" },
  { label: "Kyrgyzstan", value: "KG" },
  { label: "Lao People'S Democratic Republic", value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lebanon", value: "LB" },
  { label: "Lesotho", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Libyan Arab Jamahiriya", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Macao", value: "MO" },
  { label: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
  { label: "Madagascar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY" },
  { label: "Maldives", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia, Federated States of", value: "FM" },
  { label: "Moldova, Republic of", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montserrat", value: "MS" },
  { label: "Morocco", value: "MA" },
  { label: "Mozambique", value: "MZ" },
  { label: "Myanmar", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Netherlands", value: "NL" },
  { label: "Netherlands Antilles", value: "AN" },
  { label: "New Caledonia", value: "NC" },
  { label: "New Zealand", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolk Island", value: "NF" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Norway", value: "NO" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestinian Territory, Occupied", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua New Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines", value: "PH" },
  { label: "Pitcairn", value: "PN" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Reunion", value: "RE" },
  { label: "Romania", value: "RO" },
  { label: "Russian Federation", value: "RU" },
  { label: "RWANDA", value: "RW" },
  { label: "Saint Helena", value: "SH" },
  { label: "Saint Kitts and Nevis", value: "KN" },
  { label: "Saint Lucia", value: "LC" },
  { label: "Saint Pierre and Miquelon", value: "PM" },
  { label: "Saint Vincent and the Grenadines", value: "VC" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "Sao Tome and Principe", value: "ST" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia and Montenegro", value: "CS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Solomon Islands", value: "SB" },
  { label: "Somalia", value: "SO" },
  { label: "South Africa", value: "ZA" },
  { label: "South Georgia and the South Sandwich Islands", value: "GS" },
  { label: "Spain", value: "ES" },
  { label: "Sri Lanka", value: "LK" },
  { label: "Sudan", value: "SD" },
  { label: "Suriname", value: "SR" },
  { label: "Svalbard and Jan Mayen", value: "SJ" },
  { label: "Swaziland", value: "SZ" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Syrian Arab Republic", value: "SY" },
  { label: "Taiwan, Province of China", value: "TW" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania, United Republic of", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad and Tobago", value: "TT" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkey", value: "TR" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks and Caicos Islands", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "United Arab Emirates", value: "AE" },
  { label: "United Kingdom", value: "GB" },
  { label: "United States", value: "US" },
  { label: "United States Minor Outlying Islands", value: "UM" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Venezuela", value: "VE" },
  { label: "Viet Nam", value: "VN" },
  { label: "Virgin Islands, British", value: "VG" },
  { label: "Virgin Islands, U.S.", value: "VI" },
  { label: "Wallis and Futuna", value: "WF" },
  { label: "Western Sahara", value: "EH" },
  { label: "Yemen", value: "YE" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabwe", value: "ZW" },
];

import { useState } from "react";
import { ReactComponent as DropdownIcon } from "src/assets/icons/dropdown.svg";
import DropdownContent from "./DropdownContent";

interface IDropDown {
  items: { value: string; label: string }[];
  selectedItem: string;
  setSelectedItem: (text: string) => void;
}
export default function Dropdown({
  items,
  selectedItem,
  setSelectedItem,
}: IDropDown) {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleSelect = (group: string) => {
    setSelectedItem(group);
    handleToggle();
  };
  return (
    <div>
      <div
        className="mt-3 h-16 rounded-3xl border-2 py-5 px-4 flex items-center justify-between cursor-pointer"
        style={{
          borderColor: "#F4F4F4",
          backgroundColor: "#F2F2F280",
        }}
        onClick={handleToggle}
        data-test="d2pDropdown"
      >
        <p className="text-xl font-medium">
          {items.find((a) => a.value === selectedItem)?.label}
        </p>
        <DropdownIcon />
      </div>

      <DropdownContent open={open}>
        {items.map((a) => (
          <p
            key={a.label}
            className={`leading-5 text-lg font-medium p-3 cursor-pointer rounded-xl hover:bg-primary-pale transition-colors duration-300 `}
            onClick={() => {
              handleSelect(a.value);
            }}
            data-test="d2pDropdownOptions"
          >
            {a.label}
          </p>
        ))}
      </DropdownContent>
    </div>
  );
}

export const content = [
    {
        level: 1,
        session: 1,
        language: "yoruba",
        topic: "Greetings",
        questions: [
            {
                type: "sentence-construction",
                sourceSentence: "Good morning",
                targetSentences: ["Ẹ káàárọ̀", "ẹ káàárọ̀", "káàárọ̀"],
                targetLanguage: "Yoruba",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "joko",
                        wordBankId: 0,
                        englishTranslation: "Sit",
                        partsOfSpeech: "verb",
                        audioFile: "joko.mp3"
                    },
                    {
                        word: "Ẹ",
                        wordBankId: 1,
                        englishTranslation: "You"
                    },
                    {
                        word: "káàsán",
                        wordBankId: 2,
                        englishTranslation: "Afternoon"
                    },
                    {
                        word: "Báwo",
                        wordBankId: 3,
                        englishTranslation: "How"
                    },
                    {
                        word: "káàárọ̀",
                        wordBankId: 4,
                        englishTranslation: "Morning"
                    },
                    {
                        word: "kini",
                        wordBankId: 5,
                        englishTranslation: "What"
                    },
                    {
                        word: "gbogbo",
                        wordBankId: 6,
                        englishTranslation: "All"
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "Ẹ  káàsán",
                targetSentences: ["good afternoon"],
                targetLanguage: "English",
                sourceLanguage: "Yoruba",
                wordBank: [
                    {
                        word: "Good",
                        wordBankId: 0,
                        yorubaTranslation: "ọdọdọ",
                    },
                    {
                        word: "Bad",
                        wordBankId: 1,
                        yorubaTranslation: "ọjọ́",
                    },
                    {
                        word: "morning",
                        wordBankId: 2,
                        yorubaTranslation: "káàárọ̀",
                    },
                    {
                        word: "you",
                        wordBankId: 3,
                        yorubaTranslation: "ẹ̀wà",
                    },
                    {
                        word: "afternoon",
                        wordBankId: 4,
                        yorubaTranslation: "káàsán",
                    },
                    {
                        word: "evening",
                        wordBankId: 5,
                        yorubaTranslation: "aburo",
                    },
                    {
                        word: "day",
                        wordBankId: 6,
                        yorubaTranslation: "n sọrọ",
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "How are you?",
                targetSentences: ["báwo ni", "Báwo ni"],
                targetLanguage: "Yoruba",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "joko",
                        wordBankId: 0,
                        englishTranslation: "Sit"
                    },
                    {
                        word: "Ẹ",
                        wordBankId: 1,
                        englishTranslation: "You"
                    },
                    {
                        word: "káàsán",
                        wordBankId: 2,
                        englishTranslation: "Afternoon"
                    },
                    {
                        word: "Báwo",
                        wordBankId: 3,
                        englishTranslation: "How"
                    },
                    {
                        word: "káàárọ̀",
                        wordBankId: 4,
                        englishTranslation: "Morning"
                    },
                    {
                        word: "ni",
                        wordBankId: 5,
                        englishTranslation: "am"
                    },
                    {
                        word: "gbogbo",
                        wordBankId: 6,
                        englishTranslation: "All"
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "Good evening",
                targetSentences: ["Ẹ kú ìrọ̀lẹ́", "ẹ kú ìrọ̀lẹ́"],
                targetLanguage: "Yoruba",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "ìrọ̀lẹ́",
                        wordBankId: 0,
                        englishTranslation: "Evening"
                    },
                    {
                        word: "Ẹ",
                        wordBankId: 1,
                        englishTranslation: "You"
                    },
                    {
                        word: "káàsán",
                        wordBankId: 2,
                        englishTranslation: "Afternoon"
                    },
                    {
                        word: "Báwo",
                        wordBankId: 3,
                        englishTranslation: "How"
                    },
                    {
                        word: "káàárọ̀",
                        wordBankId: 4,
                        englishTranslation: "Morning"
                    },
                    {
                        word: "ni",
                        wordBankId: 5,
                        englishTranslation: "am"
                    },
                    {
                        word: "kú",
                        wordBankId: 6,
                        englishTranslation: "good"
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "Good evening (night time)",
                targetSentences: ["Ẹ káàlẹ́", "ẹ káàlẹ́"],
                targetLanguage: "Yoruba",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "ìrọ̀lẹ́",
                        wordBankId: 0,
                        englishTranslation: "Evening"
                    },
                    {
                        word: "Ẹ",
                        wordBankId: 1,
                        englishTranslation: "You"
                    },
                    {
                        word: "káàsán",
                        wordBankId: 2,
                        englishTranslation: "Afternoon"
                    },
                    {
                        word: "Báwo",
                        wordBankId: 3,
                        englishTranslation: "How"
                    },
                    {
                        word: "káàlẹ́",
                        wordBankId: 4,
                        englishTranslation: "evening"
                    },
                    {
                        word: "ni",
                        wordBankId: 5,
                        englishTranslation: "am"
                    },
                    {
                        word: "kú",
                        wordBankId: 6,
                        englishTranslation: "good"
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "You are welcome",
                targetSentences: ["Ẹ káàbọ̀", "ẹ káàbọ̀"],
                targetLanguage: "Yoruba",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "ìrọ̀lẹ́",
                        wordBankId: 0,
                        englishTranslation: "Evening"
                    },
                    {
                        word: "Ẹ",
                        wordBankId: 1,
                        englishTranslation: "You"
                    },
                    {
                        word: "káàsán",
                        wordBankId: 2,
                        englishTranslation: "Afternoon"
                    },
                    {
                        word: "Báwo",
                        wordBankId: 3,
                        englishTranslation: "How"
                    },
                    {
                        word: "káàlẹ́",
                        wordBankId: 4,
                        englishTranslation: "evening"
                    },
                    {
                        word: "káàbọ̀",
                        wordBankId: 7,
                        englishTranslation: "welcome"
                    },
                    {
                        word: "ni",
                        wordBankId: 5,
                        englishTranslation: "am"
                    },
                    {
                        word: "kú",
                        wordBankId: 6,
                        englishTranslation: "good"
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "Thank you",
                targetSentences: ["Ẹ sẹun", "ẹ sẹun"],
                targetLanguage: "Yoruba",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "ìrọ̀lẹ́",
                        wordBankId: 0,
                        englishTranslation: "Evening"
                    },
                    {
                        word: "Ẹ",
                        wordBankId: 1,
                        englishTranslation: "You"
                    },
                    {
                        word: "káàsán",
                        wordBankId: 2,
                        englishTranslation: "Afternoon"
                    },
                    {
                        word: "Báwo",
                        wordBankId: 3,
                        englishTranslation: "How"
                    },
                    {
                        word: "káàlẹ́",
                        wordBankId: 4,
                        englishTranslation: "evening"
                    },
                    {
                        word: "sẹun",
                        wordBankId: 7,
                        englishTranslation: "welcome"
                    },
                    {
                        word: "ni",
                        wordBankId: 5,
                        englishTranslation: "am"
                    },
                    {
                        word: "ó",
                        wordBankId: 6,
                        englishTranslation: "good"
                    },
                ]
            },
        ]
    },
    {
        level: 1,
        session: 5,
        language: "yoruba",
        topic: "Recap (Session 1 to 5)",
        questions: [
            {
                type: "sentence-construction",
                sourceSentence: "What is your name?",
                targetSentences: ["Kíni orúkọ rẹ", "kíni orúkọ rẹ"],
                targetLanguage: "Yoruba",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "joko",
                        wordBankId: 0,
                        englishTranslation: "Sit",
                        partsOfSpeech: "verb",
                        audioFile: "joko.mp3"
                    },
                    {
                        word: "orúkọ",
                        wordBankId: 1,
                        englishTranslation: "name"
                    },
                    {
                        word: "káàsán",
                        wordBankId: 2,
                        englishTranslation: "Afternoon"
                    },
                    {
                        word: "rẹ",
                        wordBankId: 3,
                        englishTranslation: "your"
                    },
                    {
                        word: "káàárọ̀",
                        wordBankId: 4,
                        englishTranslation: "Morning"
                    },
                    {
                        word: "kíni",
                        wordBankId: 5,
                        englishTranslation: "What"
                    },
                    {
                        word: "gbogbo",
                        wordBankId: 6,
                        englishTranslation: "All"
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "Ẹ  káàsán",
                targetSentences: ["good afternoon"],
                targetLanguage: "English",
                sourceLanguage: "Yoruba",
                wordBank: [
                    {
                        word: "Good",
                        wordBankId: 0,
                        yorubaTranslation: "ọdọdọ",
                    },
                    {
                        word: "Bad",
                        wordBankId: 1,
                        yorubaTranslation: "ọjọ́",
                    },
                    {
                        word: "morning",
                        wordBankId: 2,
                        yorubaTranslation: "káàárọ̀",
                    },
                    {
                        word: "beans",
                        wordBankId: 3,
                        yorubaTranslation: "ẹ̀wà",
                    },
                    {
                        word: "afternoon",
                        wordBankId: 4,
                        yorubaTranslation: "káàsán",
                    },
                    {
                        word: "siblings",
                        wordBankId: 5,
                        yorubaTranslation: "aburo",
                    },
                    {
                        word: "playing",
                        wordBankId: 6,
                        yorubaTranslation: "n sọrọ",
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "How are you?",
                targetSentences: ["báwo ni", "Báwo ni"],
                targetLanguage: "Yoruba",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "joko",
                        wordBankId: 0,
                        englishTranslation: "Sit"
                    },
                    {
                        word: "Ẹ",
                        wordBankId: 1,
                        englishTranslation: "You"
                    },
                    {
                        word: "káàsán",
                        wordBankId: 2,
                        englishTranslation: "Afternoon"
                    },
                    {
                        word: "Báwo",
                        wordBankId: 3,
                        englishTranslation: "How"
                    },
                    {
                        word: "káàárọ̀",
                        wordBankId: 4,
                        englishTranslation: "Morning"
                    },
                    {
                        word: "ni",
                        wordBankId: 5,
                        englishTranslation: "am"
                    },
                    {
                        word: "gbogbo",
                        wordBankId: 6,
                        englishTranslation: "All"
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "I am fine",
                targetSentences: ["Dáadáa ni mo wà", "dáadáa ni mo wà"],
                targetLanguage: "Yoruba",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "mo",
                        wordBankId: 0,
                        englishTranslation: "Sit"
                    },
                    {
                        word: "ni",
                        wordBankId: 1,
                        englishTranslation: "You"
                    },
                    {
                        word: "káàsán",
                        wordBankId: 2,
                        englishTranslation: "Afternoon"
                    },
                    {
                        word: "Báwo",
                        wordBankId: 3,
                        englishTranslation: "How"
                    },
                    {
                        word: "Dáadáa",
                        wordBankId: 4,
                        englishTranslation: "Fine"
                    },
                    {
                        word: "wà",
                        wordBankId: 5,
                        englishTranslation: "am"
                    },
                    {
                        word: "gbogbo",
                        wordBankId: 6,
                        englishTranslation: "All"
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "Mo wà ní yàrá",
                targetSentences: ["I am in the room", "i am in the room"],
                targetLanguage: "English",
                sourceLanguage: "Yoruba",
                wordBank: [
                    {
                        word: "he",
                        wordBankId: 0,
                        englishTranslation: "Sit"
                    },
                    {
                        word: "room",
                        wordBankId: 1,
                        englishTranslation: "You"
                    },
                    {
                        word: "I",
                        wordBankId: 2,
                        englishTranslation: "Afternoon"
                    },
                    {
                        word: "house",
                        wordBankId: 3,
                        englishTranslation: "How"
                    },
                    {
                        word: "the",
                        wordBankId: 4,
                        englishTranslation: "Fine"
                    },
                    {
                        word: "in",
                        wordBankId: 5,
                        englishTranslation: "am"
                    },
                    {
                        word: "am",
                        wordBankId: 6,
                        englishTranslation: "All"
                    },
                    {
                        word: "is",
                        wordBankId: 7,
                        englishTranslation: "All"
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "I come from Lagos",
                targetSentences: ["Mo wá láti ìlú Èkó", "mo wá láti ìlú Èkó"],
                targetLanguage: "Yoruba",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "láti",
                        wordBankId: 0,
                        englishTranslation: "Sit"
                    },
                    {
                        word: "Èkó",
                        wordBankId: 1,
                        englishTranslation: "You"
                    },
                    {
                        word: "mo",
                        wordBankId: 2,
                        englishTranslation: "Afternoon"
                    },
                    {
                        word: "Báwo",
                        wordBankId: 3,
                        englishTranslation: "How"
                    },
                    {
                        word: "the",
                        wordBankId: 4,
                        englishTranslation: "Fine"
                    },
                    {
                        word: "wá",
                        wordBankId: 5,
                        englishTranslation: "am"
                    },
                    {
                        word: "ìlú",
                        wordBankId: 6,
                        englishTranslation: "All"
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "I live in New Jersey",
                targetSentences: ["Mò ń gbé ní New Jersey", "mò ń gbé ní New Jersey"],
                targetLanguage: "Yoruba",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "ń gbé",
                        wordBankId: 0,
                        englishTranslation: "live"
                    },
                    {
                        word: "Èkó",
                        wordBankId: 1,
                        englishTranslation: "You"
                    },
                    {
                        word: "mò",
                        wordBankId: 2,
                        englishTranslation: "I"
                    },
                    {
                        word: "New Jersey",
                        wordBankId: 3,
                        englishTranslation: "New Jersey"
                    },
                    {
                        word: "ní",
                        wordBankId: 4,
                        englishTranslation: "in"
                    },
                    {
                        word: "wà",
                        wordBankId: 5,
                        englishTranslation: "am"
                    },
                    {
                        word: "mo",
                        wordBankId: 5,
                        englishTranslation: "I"
                    },
                    {
                        word: "ìlú",
                        wordBankId: 6,
                        englishTranslation: "All"
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "Ade wants to eat beans",
                targetSentences: ["Adé fẹ́ jẹ ẹ̀wà", "adé fẹ́ jẹ ẹ̀wà"],
                targetLanguage: "Yoruba",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "ẹ̀wà",
                        wordBankId: 0,
                        englishTranslation: "Sit"
                    },
                    {
                        word: "Èkó",
                        wordBankId: 1,
                        englishTranslation: "You"
                    },
                    {
                        word: "mo",
                        wordBankId: 2,
                        englishTranslation: "Afternoon"
                    },
                    {
                        word: "jẹ",
                        wordBankId: 3,
                        englishTranslation: "How"
                    },
                    {
                        word: "fẹ́",
                        wordBankId: 4,
                        englishTranslation: "Fine"
                    },
                    {
                        word: "wá",
                        wordBankId: 5,
                        englishTranslation: "am"
                    },
                    {
                        word: "Adé",
                        wordBankId: 6,
                        englishTranslation: "All"
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "Open the door",
                targetSentences: ["Ṣí ilẹ̀kùn", "ṣí ilẹ̀kùn"],
                targetLanguage: "Yoruba",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "ẹ̀wà",
                        wordBankId: 0,
                        englishTranslation: "Sit"
                    },
                    {
                        word: "ilẹ̀kùn",
                        wordBankId: 1,
                        englishTranslation: "door"
                    },
                    {
                        word: "mo",
                        wordBankId: 2,
                        englishTranslation: "Afternoon"
                    },
                    {
                        word: "jẹ",
                        wordBankId: 3,
                        englishTranslation: "How"
                    },
                    {
                        word: "fẹ́",
                        wordBankId: 4,
                        englishTranslation: "Fine"
                    },
                    {
                        word: "ṣí",
                        wordBankId: 5,
                        englishTranslation: "open"
                    },
                    {
                        word: "Adé",
                        wordBankId: 6,
                        englishTranslation: "All"
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "They are siblings",
                targetSentences: ["Ọmọ ìyá ni wọn", "ọmọ ìyá ni wọn"],
                targetLanguage: "Yoruba",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "ẹ̀wà",
                        wordBankId: 0,
                        englishTranslation: "Sit"
                    },
                    {
                        word: "ni",
                        wordBankId: 1,
                        englishTranslation: "are"
                    },
                    {
                        word: "mo",
                        wordBankId: 2,
                        englishTranslation: "Afternoon"
                    },
                    {
                        word: "Ọmọ ìyá",
                        wordBankId: 3,
                        englishTranslation: "Sibling"
                    },
                    {
                        word: "fẹ́",
                        wordBankId: 4,
                        englishTranslation: "Fine"
                    },
                    {
                        word: "ṣí",
                        wordBankId: 5,
                        englishTranslation: "open"
                    },
                    {
                        word: "wọn",
                        wordBankId: 6,
                        englishTranslation: "they"
                    },
                ]
            },
        ]
    },
    {
        level: 1,
        session: 5,
        language: "igbo",
        topic: "Places and Transportation",
        questions: [
            {
                type: "sentence-construction",
                sourceSentence: "Are you going to New York for the holidays?",
                targetSentences: ["Ị na-aga New York maka ezumike", "ị na-aga New York maka ezumike"],
                targetLanguage: "Igbo",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "Ị",
                        wordBankId: 0,
                        englishTranslation: "You"
                    },
                    {
                        word: "ezumike",
                        wordBankId: 1,
                        englishTranslation: "Holiday"
                    },
                    {
                        word: "mana",
                        wordBankId: 2,
                        englishTranslation: "But"
                    },
                    {
                        word: "n'ụlọ",
                        wordBankId: 3,
                        englishTranslation: "Home"
                    },
                    {
                        word: "maka",
                        wordBankId: 4,
                        englishTranslation: "For"
                    },
                    {
                        word: "na-aga",
                        wordBankId: 5,
                        englishTranslation: "going"
                    },
                    {
                        word: "New York",
                        wordBankId: 6,
                        englishTranslation: "New York"
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "Yes, but I'll go to the bank first",
                targetSentences: ["Ee, mana m g'ebu ụzọ gaa n'ụlọ akụ"],
                targetLanguage: "Igbo",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "g'ebu ụzọ",
                        wordBankId: 1,
                        englishTranslation: "to first go"
                    },
                    {
                        word: "m",
                        wordBankId: 0,
                        englishTranslation: "I"
                    },
                    {
                        word: "mana",
                        wordBankId: 2,
                        englishTranslation: "But"
                    },
                    {
                        word: "n'ụlọ akụ",
                        wordBankId: 3,
                        englishTranslation: "Bank"
                    },
                    {
                        word: "maka",
                        wordBankId: 4,
                        englishTranslation: "For"
                    },
                    {
                        word: "na-aga",
                        wordBankId: 5,
                        englishTranslation: "going"
                    },
                    {
                        word: "Ee",
                        wordBankId: 6,
                        englishTranslation: "Yes"
                    },
                    {
                        word: "gaa",
                        wordBankId: 7,
                        englishTranslation: "go"
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "Okay, will you take the train to the airport?",
                targetSentences: ["Ọ dị mma, ị ga-eji ụgbọ oloko gaa n'ọdụ ụgbọ elu", "ọ dị mma, ị ga-eji ụgbọ oloko gaa n'ọdụ ụgbọ elu"],
                targetLanguage: "Igbo",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "g'ebu ụzọ",
                        wordBankId: 1,
                        englishTranslation: "to first go"
                    },
                    {
                        word: "m",
                        wordBankId: 0,
                        englishTranslation: "I"
                    },
                    {
                        word: "mana",
                        wordBankId: 2,
                        englishTranslation: "But"
                    },
                    {
                        word: "ị",
                        wordBankId: 9,
                        englishTranslation: "You"
                    },
                    {
                        word: "n'ụlọ akụ",
                        wordBankId: 3,
                        englishTranslation: "Bank"
                    },
                    {
                        word: "ụgbọ elu",
                        wordBankId: 11,
                        englishTranslation: "Airport"
                    },
                    {
                        word: "ga-eji",
                        wordBankId: 4,
                        englishTranslation: "For"
                    },
                    {
                        word: "na-aga",
                        wordBankId: 5,
                        englishTranslation: "going"
                    },
                    {
                        word: "n'ọdụ",
                        wordBankId: 6,
                        englishTranslation: "Yes"
                    },
                    {
                        word: "gaa",
                        wordBankId: 7,
                        englishTranslation: "go"
                    },
                    {
                        word: "ọ dị mma",
                        wordBankId: 8,
                        englishTranslation: "Okay"
                    },
                    {
                        word: "ụgbọ oloko",
                        wordBankId: 10,
                        englishTranslation: "train"
                    },
                ]
            },
            {
                type: "sentence-construction",
                sourceSentence: "No, I'll drive",
                targetSentences: ["Mba m ga-anya ụgbọ ala", "mba m ga-anya ụgbọ ala"],
                targetLanguage: "Igbo",
                sourceLanguage: "English",
                wordBank: [
                    {
                        word: "g'ebu ụzọ",
                        wordBankId: 1,
                        englishTranslation: "to first go"
                    },
                    {
                        word: "m",
                        wordBankId: 0,
                        englishTranslation: "I"
                    },
                    {
                        word: "Mba",
                        wordBankId: 2,
                        englishTranslation: "No"
                    },
                    {
                        word: "ị",
                        wordBankId: 9,
                        englishTranslation: "You"
                    },
                    {
                        word: "ga-anya",
                        wordBankId: 3,
                        englishTranslation: "will drive"
                    },
                    {
                        word: "ụgbọ elu",
                        wordBankId: 3,
                        englishTranslation: "Airport"
                    },
                    {
                        word: "ga-eji",
                        wordBankId: 4,
                        englishTranslation: "For"
                    },
                    {
                        word: "na-aga",
                        wordBankId: 5,
                        englishTranslation: "going"
                    },
                    {
                        word: "n'ọdụ",
                        wordBankId: 6,
                        englishTranslation: "Yes"
                    },
                    {
                        word: "gaa",
                        wordBankId: 7,
                        englishTranslation: "go"
                    },
                    {
                        word: "ụgbọ ala",
                        wordBankId: 8,
                        englishTranslation: "car"
                    },
                    {
                        word: "ụgbọ oloko",
                        wordBankId: 10,
                        englishTranslation: "train"
                    },
                ]
            },
        ]
    }
]


import React, { useContext } from "react";

import Calendar from "./Calendar";
import InfoBar from "./Calendar/InfoBar";
import { Context as DirectPayContext } from "src/context/DirectPayContext";

export default function NewAvailability({
  tutorAvailability,
  tutorBookings,
  scrollToBookLesson,
  setLessonScheduleFromCalendar,
  selectedLessonScheduleId
}: any) {

  return (
    <div className="md:shadow-md md:border-[1px] md:border-gray-200 md:rounded-2xl py-4 px-6 max-w-[680px]">
      {/* Title */}
    
      <div className="my-3">
        <div className="text-primary text-sm">
          {/* <div className="mb-4">
            <InfoBar />
          </div> */}
          <Calendar
            tutorAvailability={tutorAvailability}
            tutorBookings={tutorBookings}
            scrollToBookLesson={scrollToBookLesson}
            setLessonScheduleFromCalendar={setLessonScheduleFromCalendar}
            selectedLessonScheduleId={selectedLessonScheduleId}
          />
        </div>
      </div>
    </div>
  );
}

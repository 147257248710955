import { useContext, useEffect } from "react";
import HeaderText from "./HeaderText";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
export default function PreDialectForm() {
  const {
    state: { selectedDialect },
    actions: { setDialect },
  } = useContext(DirectPayContext);
  useEffect(() => {
    if (!selectedDialect.type) {
      setDialect({ type: "Central Igbo", match: "", state: "", towns: [""] });
    } else {
      if (selectedDialect.type !== "Central Igbo") {
        setDialect({
          type: "Specific Dialect",
          match: "",
          state: "",
          towns: [""],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="mt-12">
      <section className="max-w-2xl mx-auto">
        <HeaderText>
          Please select the Igbo dialect you want to learn
        </HeaderText>
        <p className=" mt-5 font-semibold text-secondary">
          Central Igbo is the shared, standard version you’ll hear in media,
          schools, and formal settings
        </p>
      </section>
      <div className="mt-10 flex flex-col gap-4">
        <PreDialectCard
          title="Central Igbo"
          selected={selectedDialect.type === "Central Igbo"}
          value="Central Igbo"
        />
        <PreDialectCard
          title="Specific Dialect"
          selected={selectedDialect.type === "Specific Dialect"}
          value="Specific Dialect"
        />
      </div>
    </div>
  );
}
interface IPreDialectCard {
  selected: boolean;
  title: string;
  value: string;
}
function PreDialectCard({ selected, title, value }: IPreDialectCard) {
  const {
    actions: { setDialect },
  } = useContext(DirectPayContext);
  return (
    <div
      className={`max-w-md w-full border-2 rounded-3xl flex gap-4  text-left transition-colors duration-200  cursor-pointer  py-6 px-4  mx-auto
          ${
            selected
              ? "bg-primary-opaque border-primary"
              : "bg-gray-50 border-gray-100"
          }
          `}
      onClick={() => {
        setDialect({ type: value, match: "", state: "", towns: [""] });
      }}
      data-test={`d2p${value}LanguageSkillCard`}
    >
      <span>
        <HeaderText>{title}</HeaderText>
        {/* <p className="font-medium text-lg leading-6">{description}</p> */}
      </span>
    </div>
  );
}

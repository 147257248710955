import React from "react";
import { OptionSelectProps } from "./types";

export default function OptionSelect({
  options,
  onOptionClick,
  selectedOption,
}: OptionSelectProps) {
  return (
    <>
      <div className="flex flex-wrap gap-2">
        {options.map((option) => (
          <button
            key={option.value}
            className={`${
              selectedOption === option.value
                ? "filter-button-active"
                : "filter-button-inactive"
            }`}
            onClick={() => onOptionClick(option.value)}
          >
            {option.label}
          </button>
        ))}
      </div>
    </>
  );
}

import clsx from "clsx";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";

export const Pagination = ({
  pageIndex,
  pageOptions,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
}: any) => {
  return (
    <div className="flex gap-4">
      <span className="text-primary">
        Page{" "}
        <strong>
          {pageIndex + 1} of {pageOptions.length}{" "}
        </strong>
      </span>
      <div className="flex gap-1">
        <button
          className={clsx({
            "bg-light-purple text-white px-2 py-1 rounded-lg cursor-pointer":
              canPreviousPage,
            " text-white bg-pale-purple-bg px-2 py-1 rounded-lg":
              !canPreviousPage,
          })}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <MdArrowBackIosNew className="text-white" />
        </button>
        <button
          className={clsx({
            "bg-light-purple text-white px-2 py-1 rounded-lg cursor-pointer":
              canNextPage,
            " text-white bg-pale-purple-bg px-2 py-1 rounded-lg": !canNextPage,
          })}
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <MdArrowForwardIos className="text-white" />
        </button>
      </div>
    </div>
  );
};

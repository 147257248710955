export const reasons = [
  {
    title: "1-ON-1 ADVANTAGE",
    subtitle:
      "We don't leave your learning goals up to an app alone. We connect you with a native-speaking tutor who will personally guide you along the way. Practice pronunciation, get clarification and affirmation in real time. It's the next best thing to immersive learning!",
  },
  {
    title: "CONTINUOUS LEARNING",
    subtitle:
      "To be successful, you'll need a plan designed just for you. Rather than a traditional generic template, we curate a learning plan designed to get you speaking on day one, on your road to fluency. Access your plan at any time outside lessons to help you stay consistent and solidify your learning.",
  },
  {
    title: "TECH-ENABLED SUPPORT",
    subtitle:
      "We leverage the strength of technology through an app that contains your custom learning plan, flashcards, games, reminders and other engaging and interactive features to keep you motivated in as little as 10 minutes each day.",
  },
];

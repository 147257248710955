import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "src/Routes";

export default function AuthButtons() {
  const navigate = useNavigate();
  return (
    <>
      <a
        href={routes.LOGIN.url}
        className="text-primary hover:text-white hover:bg-primary rounded-2xl hover:shadow-xl font-bold px-6 py-3 "
      >
        LOGIN
      </a>
      <NavLink to={routes.BOOK_A_LESSON_VIA_ADMIN.url}>
        <button type="button" className="pink-button font-bold ">
          REQUEST A LESSON
        </button>
      </NavLink>
    </>
  );
}

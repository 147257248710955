import { Navigate } from "react-router-dom";
import { useStateValue } from "src/context/StateProvider";

type TutorGuardProps = {
  children: any;
};

export const NonTutorGuard = ({ children }: TutorGuardProps) => {
  const [{ user }] = useStateValue();

  if (user?.type !== "tutor") {
    return <Navigate to="/" />;
  }

  return children;
};

import React from "react";
import { HiOutlineArrowRight, HiOutlineLockClosed } from "react-icons/hi";
import { formatDistanceToNow } from "date-fns";
import { ReactComponent as Book } from "src/assets/icons/book.svg";
import { Topic } from "src/interfaces/resources";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "src/Routes";
interface ICard {
  topic: Topic;
  practice?: boolean;
  level?: string;
  topicId?: string;
  curriculumId?: string;
  parentDivisionId?: string;
}
export default function Card({
  topic,
  practice,
  level,
  topicId,
  parentDivisionId,
  curriculumId,
}: ICard) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userId } = useParams();

  const handleClick = () => {
    if (topic?.isLocked) return;
    navigate(
      `${routes.STUDENT_DASHBOARD_LEARN.url}/${userId}/${level}/${topic.order}/${topic.title}/${topicId}`
    );
  };

  return (
    <div
      className={`w-full  min-h-[180px] border-[1px] ${
        topic.isLocked ? "border-gray-200" : "border-secondary"
      }  rounded-[15px] bg-white p-3 cursor-pointer hover:bg-pale-purple-bg  transition-all duration-200 flex flex-col justify-between ${
        topic.isLocked && "pointer-events-none"
      }`}
      onClick={handleClick}
    >
      <div className="flex flex-col gap-2">
        <h2 className=" text-lg font-semibold">{topic.title}</h2>
        {!topic?.isLocked && (
          <LastPracticedTime
            practice={practice}
            lastPracticedDate={topic.lastPracticed}
          />
        )}
      </div>
      {practice ? (
        <button className="flex gap-2 items-center rounded-[9px] bg-primary-pale py-3 px-2">
          <Book />
          <p className="text-primary font-medium">Practice Lesson</p>
        </button>
      ) : (
        <button className="border rounded-[9px] p-2 self-start">
          {topic?.isLocked ? <HiOutlineLockClosed /> : <HiOutlineArrowRight />}
        </button>
      )}
    </div>
  );
}

function LastPracticedTime({ lastPracticedDate, practice }: any) {
  if (!lastPracticedDate)
    return (
      <p
        className={`${
          practice ? "text-primary-gray" : "text-secondary"
        } font-medium `}
      >
        Practice!
      </p>
    );

  const timeAgo = formatDistanceToNow(new Date(lastPracticedDate), {
    addSuffix: true,
  });
  return (
    <p
      className={`${
        practice ? "text-primary-gray" : "text-secondary"
      } font-medium `}
    >
      Practiced {timeAgo}
    </p>
  );
}

import axios from "axios";

export async function getUserNotifications(userId: string): Promise<any | undefined[]> {
  try {
      const response  = await axios.get(`${process.env.REACT_APP_SERVER_URL}/notification/user-notifications-get/${userId}`);
      return response.data
    } catch (error) {
      console.log("caught error: ", error);
  }
}

export async function getUreadNotifications(userId: string): Promise<any | undefined[]> {
  try {
      const response  = await axios.get(`${process.env.REACT_APP_SERVER_URL}/notification/unread-notifications-get/${userId}`);
      return response.data
    } catch (error) {
      console.log("caught error: ", error);
  }
}

export async function setReadNotifications(userId: string, notificationId: string): Promise<any | undefined[]> {
  try {
      const response  = await axios.post(`${process.env.REACT_APP_SERVER_URL}/notification/read-notification/${notificationId}/${userId}`);
      return response.data
    } catch (error) {
      console.log("caught error: ", error);
  }
}

export async function markAllNotificationsAsRead(userId: string): Promise<any | undefined[]> {
  try {
      const response  = await axios.post(`${process.env.REACT_APP_SERVER_URL}/notification/read-all-notifications/${userId}`);
      return response.data
    } catch (error) {
      console.log("caught error: ", error);
  }
}
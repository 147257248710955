import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";
import { BiBarChartSquare } from "react-icons/bi";
import { routes } from "src/Routes";
import { useStateValue } from "src/context/StateProvider";

export default function Overview() {
  const location = useLocation();
  const [{ showMobileMenu }, dispatch] = useStateValue();

  const closeMobileMenu = async () => {
    await dispatch({
      type: "SET_SHOW_MOBILE_MENU",
      showMobileMenu: !showMobileMenu,
    });
  };

  return (
    <div>
      <NavLink to={routes.TUTOR_DASHBOARD_OVERVIEW.url}>
        <div
          className={clsx(
            {
              "menu-inactive":
                routes.TUTOR_DASHBOARD_OVERVIEW.url !== location.pathname,
            },
            {
              "menu-active":
                routes.TUTOR_DASHBOARD_OVERVIEW.url === location.pathname,
            }
          )}
          onClick={closeMobileMenu}
        >
          <div>
            <BiBarChartSquare className="text-lg" />
          </div>
          <div>{routes.TUTOR_DASHBOARD_OVERVIEW.label}</div>
        </div>
      </NavLink>
    </div>
  );
}

import axios from "axios";
import {
  ITutorEditableProfileDetails,
  ITutorSettings,
  ProvideLessonFeedback,
} from "src/interfaces/tutor";

export async function getTutorSearchResults(): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/tutor-search-results`
    );
    let searchResult = response.data.result;
    return searchResult;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getTutorProfileDetails(
  tutorId: string | undefined
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/tutor-profile-details/${tutorId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postTutorProfileDetails(
  tutorId: string | undefined,
  tutorDetails: ITutorEditableProfileDetails
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/tutor-profile-details`,
      tutorDetails
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postTutorAvailability(
  userId: string,
  tutorAvailability: any,
  desiredTeachingHours: any
): Promise<any> {
  try {
    const data = {
      tutorAvailability,
      desiredTeachingHours,
    };
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/update-tutor-availability/${userId}`,
      data
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postTutorBio(
  tutorId: string | undefined,
  tutorBio: string
): Promise<any | undefined[]> {
  try {
    const data = {
      bio: tutorBio,
    };
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/tutor-bio/${tutorId}`,
      data
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postTutorVideoShowcase(
  tutorId: string | undefined,
  videoUrl: string
): Promise<any | undefined[]> {
  try {
    const data = {
      videoShowcaseUrl: videoUrl,
    };
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/tutor-video_showcase/${tutorId}`,
      data
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postTutorAvatar(
  tutorId: string | undefined,
  imgData: any
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/tutor-info/upload-photo`,
      imgData
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postUpdateExerciseStatus(
  demoId: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/update-tutor-exercise-status/${demoId}`
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function markRatingsLinkAsCopied(
  lessonId: string,
  body: any
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/mark-lesson-ratings-link-as-copied/${lessonId}`,
      body
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postUpdateInterviewStatus(
  demoId: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/update-tutor-interview-status/${demoId}`
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getTutorAccountSettings(
  tutorId: string | undefined
): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/tutor-settings/${tutorId}`
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getTutorPaymentInfo(tutorId: string): Promise<any> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/tutor-payment-info/${tutorId}`
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postTutorAccountSettings(
  tutorId: string | undefined,
  settings: ITutorSettings
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/tutor-settings/${tutorId}`,
      settings
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getLessonRequests(
  tutorId: string | undefined
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/tutor-lesson-requests/${tutorId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getConfirmedLessons(
  tutorId: string | undefined
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/tutor-confirmed-lessons/${tutorId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getTutorAvailabilityAndBookings(
  tutorId: string
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/tutor-availability-and-booking/${tutorId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getTutorAvailabilityForGroupLesson(
  lesson: any
): Promise<any | undefined> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/tutor-availability-for-group-lesson`, lesson
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postBookingRequestResponse(
  bookingRequestId: string,
  body: any
): Promise<any | undefined> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/one-on-one-lesson/respond/${bookingRequestId}`,
      body
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getTutorReport(
  tutorId: string
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/tutor-report?tutorId=${tutorId}&getThisWeekLessons=true&getLastWeekLessons=true`
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getTutorPerformance(
  tutorId: string
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/tutor-performance?tutorId=${tutorId}`
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getTutorReportByDateRange(
  tutorId: string,
  startDate: Date,
  endDate: Date
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/tutor-report?tutorId=${tutorId}&startDate=${startDate}&endDate=${endDate}`
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getTutorFeedbackByDateRange(
  tutorId: string,
  startDate: Date,
  endDate: Date
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL
      }/tutor-report?tutorId=${tutorId}&startDate=${startDate}&endDate=${endDate}&feedback=${true}`
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getConfirmedLessonsViaAdmin(
  tutorId: string | undefined
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/tutor-confirmed-lessons-via-admin/${tutorId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export function getConfirmedLessonsViaAdminSSE(
  tutorId: string | undefined
): EventSource {
  const eventSource = new EventSource(
    `${process.env.REACT_APP_SERVER_URL}/tutor-confirmed-lessons-via-admin-sse/${tutorId}`
  );
  return eventSource;
}
export async function getConfirmedLessonsHistoryViaAdmin(
  tutorId: string | undefined
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/tutor-confirmed-past-lessons-via-admin/${tutorId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function provideLessonFeedback(
  body: ProvideLessonFeedback
): Promise<any | undefined> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_V2_SERVER_URL}/tutor/lesson-feedback`,
      {
        ...body,
        ...body.content,
        ...body.content.ratings,
      }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
    return error;
  }
}

export async function getLessonFeedback(
  lessonId?: string
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_V2_SERVER_URL}/user/lesson-feedback`,
      {
        params: {
          lessonId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function getFeedbacks(): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_V2_SERVER_URL}/tutor/lesson-feedback`
    );

    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function getStudentNotes(
  studentId: string
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/student-profile-notes/${studentId}`
    );

    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

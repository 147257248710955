import Overview from "./Overview";
import Lessons from "./Lessons";
import Students from "./Students";
import Availability from "./Availability";
import Messages from "./Messages";
import Profile from "./Profile";
import Notifications from "./Notifications";
import Homework from "./Homework";

export default function Menu() {
  return (
    <div>
      <p className="text-xs text-gray-400 px-4 mb-6">Referral Dashboard</p>
      <ul>
        <li>
          <Overview />
        </li>
        <li>
          <Profile />
        </li>
      </ul>
    </div>
  );
}

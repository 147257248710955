import React from "react";
import { FaStar } from "react-icons/fa";
import { ITutorReview, ITutorSearchResult } from "src/interfaces/tutor";
import ProgressBar from "@ramonak/react-progress-bar";
import clsx from "clsx";

type RatingBreakdownType = {
  reviews: ITutorReview[];
  ratings: any;
};

export default function RatingBreakdown({
  reviews,
  ratings,
}: RatingBreakdownType) {
  if (!reviews || !ratings?.numberOfRatings) {
    return null;
  }

  return (
    <div>
      {/* 5 Stars */}
      <div className="flex gap-2">
        {/* Star */}
        <div className="flex gap-1 items-center text-gray-purple">
          <FaStar />
          <p className="pt-1 w-3">{5}</p>
        </div>
        {/* Progress Bar */}
        <div className="pt-1.5">
          <ProgressBar
            completed={(ratings?.breakdown[5] / ratings?.numberOfRatings) * 100}
            isLabelVisible={false}
            className="w-40 text-primary"
            bgColor="#FEC108"
            height="17px"
            baseBgColor="#A094AF"
            borderRadius="7px"
            animateOnRender={true}
          />
        </div>
        <div>
          <p
            className={clsx({
              "text-secondary pt-1": ratings?.breakdown[5] > 0,
              "text-gray-purple pt-1": ratings?.breakdown[5] < 1,
            })}
          >
            {ratings?.breakdown[5]}
          </p>
        </div>
      </div>
      {/* 4 Stars */}
      <div className="flex gap-2">
        {/* Star */}
        <div className="flex gap-1 items-center text-gray-purple">
          <FaStar />
          <p className="pt-1 w-3">{4}</p>
        </div>
        {/* Progress Bar */}
        <div className="pt-1.5">
          <ProgressBar
            completed={(ratings?.breakdown[4] / ratings?.numberOfRatings) * 100}
            isLabelVisible={false}
            className="w-40 text-primary"
            bgColor="#FEC108"
            height="17px"
            baseBgColor="#A094AF"
            borderRadius="7px"
            animateOnRender={true}
          />
        </div>
        <div>
          <p
            className={clsx({
              "text-secondary pt-1": ratings?.breakdown[4] > 0,
              "text-gray-purple pt-1": ratings?.breakdown[4] < 1,
            })}
          >
            {ratings?.breakdown[4]}
          </p>
        </div>
      </div>
      {/* 3 Stars */}
      <div className="flex gap-2">
        {/* Star */}
        <div className="flex gap-1 items-center text-gray-purple">
          <FaStar />
          <p className="pt-1 w-3">{3}</p>
        </div>
        {/* Progress Bar */}
        <div className="pt-1.5">
          <ProgressBar
            completed={(ratings?.breakdown[3] / ratings?.numberOfRatings) * 100}
            isLabelVisible={false}
            className="w-40 text-primary"
            bgColor="#FEC108"
            height="17px"
            baseBgColor="#A094AF"
            borderRadius="7px"
            animateOnRender={true}
          />
        </div>
        <div>
          <p
            className={clsx({
              "text-secondary pt-1": ratings?.breakdown[3] > 0,
              "text-gray-purple pt-1": ratings?.breakdown[3] < 1,
            })}
          >
            {ratings?.breakdown[3]}
          </p>
        </div>
      </div>
      {/* 2 Stars */}
      <div className="flex gap-2">
        {/* Star */}
        <div className="flex gap-1 items-center text-gray-purple">
          <FaStar />
          <p className="pt-1 w-3">{2}</p>
        </div>
        {/* Progress Bar */}
        <div className="pt-1.5">
          <ProgressBar
            completed={(ratings?.breakdown[2] / ratings?.numberOfRatings) * 100}
            isLabelVisible={false}
            className="w-40 text-primary"
            bgColor="#FEC108"
            height="17px"
            baseBgColor="#A094AF"
            borderRadius="7px"
            animateOnRender={true}
          />
        </div>
        <div>
          <p
            className={clsx({
              "text-secondary pt-1": ratings?.breakdown[2] > 0,
              "text-gray-purple pt-1": ratings?.breakdown[2] < 1,
            })}
          >
            {ratings?.breakdown[2]}
          </p>
        </div>
      </div>
      {/* 1 Stars */}
      <div className="flex gap-2">
        {/* Star */}
        <div className="flex gap-1 items-center text-gray-purple">
          <FaStar />
          <p className="pt-1 w-3">{1}</p>
        </div>
        {/* Progress Bar */}
        <div className="pt-1.5">
          <ProgressBar
            completed={(ratings?.breakdown[1] / ratings?.numberOfRatings) * 100}
            isLabelVisible={false}
            className="w-40 text-primary"
            bgColor="#FEC108"
            height="17px"
            baseBgColor="#A094AF"
            borderRadius="7px"
            animateOnRender={true}
          />
        </div>
        <div>
          <p
            className={clsx({
              "text-secondary pt-1": ratings?.breakdown[1] > 0,
              "text-gray-purple pt-1": ratings?.breakdown[1] < 1,
            })}
          >
            {ratings?.breakdown[1]}
          </p>
        </div>
      </div>
    </div>
  );
}

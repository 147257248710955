import Tippy from "@tippyjs/react";
import React, { useContext, useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { useStateValue } from "src/context/StateProvider";
import { getWhatsappNumber } from "src/services/contact";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import stringToTitleCase from "src/utils/helper/stringToTitleCase";

interface IWhatsappLink {
  message?: string;
  phoneNumber?: string;
}
/**
 * Whatsapp Link to append messages
 */
const WhatsAppLink = ({ phoneNumber = "447888870126" }: IWhatsappLink) => {
  const {
    state: { subject, directPayUser },
  } = useContext(DirectPayContext);
  let message = directPayUser
    ? `Hi TopSet, I'm interested in starting my ${
        stringToTitleCase(subject) || "language"
      } learning journey!`
    : "";
  const formattedMessage = encodeURIComponent(message);
  const [{ clientIpCountry }] = useStateValue();
  const [phone, setPhone] = useState(phoneNumber);
  const whatsappLink = `https://api.whatsapp.com/send?phone=${phone}&text=${formattedMessage}`;

  useEffect(() => {
    if (clientIpCountry) {
      setPhone(getWhatsappNumber(clientIpCountry));
    }
  }, [clientIpCountry]);
  return (
    <Tippy content="Need Assistance? Chat Here!">
      <a href={whatsappLink.toString()} target="_blank" rel="noreferrer">
        <FaWhatsapp size={22} color="#fff" />
      </a>
    </Tippy>
  );
};

export default WhatsAppLink;

import { useState, useEffect, useContext, useCallback } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { DayPicker, getDefaultClassNames } from "react-day-picker";
import "react-day-picker/style.css";
import { numberStrings } from "src/utils/helper/numberString";
import { PiCalendarDotsFill } from "react-icons/pi";
import {
  getCurrentWeek,
  categorizeTutorAvailability,
} from "src/static-data/Calendar";
import { startOfWeek } from "date-fns";
/** currently getting tutorAvailability & tutorBookings data from satic data,
 * should get from back end */
import {
  syncAvailabilityWithBookings,
  createTutorAvailabilityCalendarData,
} from "src/static-data/Availability";
import { selectorTheme } from "src/static-data/SelectOptions";
import { aryIannaTimeZones } from "src/static-data/Timezones";
import { toast } from "react-toastify";

import { MomentInput } from "moment";
import moment from "moment";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { useStateValue } from "src/context/StateProvider";
import SelectDateAndContinue from "./SelectDateAndContinue";

toast.configure();

type CalendarProps = {
  tutorAvailability: any;
  tutorBookings: any;
  scrollToBookLesson: any;
  setLessonScheduleFromCalendar: any;
  selectedLessonScheduleId: number;
};

export default function Calendar({
  tutorAvailability,
  tutorBookings,
  scrollToBookLesson,
  setLessonScheduleFromCalendar,
  selectedLessonScheduleId,
}: CalendarProps) {
  const animatedComponents = makeAnimated();
  const [selected, setSelected] = useState<Date>();
  const [selectedAvailabilityTime, setSelectedAvailabilityTime] =
    useState<Date>();
  const [selectedDay, setSelectedDay] = useState<number>(0);
  console.log(selectedLessonScheduleId);
  const [timeZone, setTimeZone] = useState<any>({
    label: Intl.DateTimeFormat().resolvedOptions().timeZone,
    value: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [dateSelectionProperties, setDateSelectionProperties] = useState<any[]>(
    []
  );
  console.log(dateSelectionProperties);
  const [{ activeSubscription }] = useStateValue();
  const [weekDates, setWeekDates] = useState<any>(null);
  const [tutorAvailabilityDetails, setTutorAvailabilityDetails] =
    useState<any>(null);
  const [categorizedTutorAvailability, setCategorizedTutorAvailability] =
    useState<any>(null);

  const handleTimeZoneChange = (selectedTimeZone: unknown) => {
    setTimeZone(selectedTimeZone);
  };
  const [month, setMonth] = useState(new Date());

  const defaultClassNames = getDefaultClassNames();

  //get the day selected (0,6)
  const setSelectedDate = useCallback(
    (date) => {
      const isoDate = new Date(date);
      setSelectedDay(isoDate.getDay());
      setMonth(date);
    },
    [selected]
  );
  useEffect(() => {
    if (dateSelectionProperties.length > selectedLessonScheduleId) {
      setSelected(dateSelectionProperties[selectedLessonScheduleId].selected);
      setSelectedDay(
        dateSelectionProperties[selectedLessonScheduleId].selectedDay
      );
      setSelectedAvailabilityTime(
        dateSelectionProperties[selectedLessonScheduleId]
          .selectedAvailabilityTime
      );
    } else {
      setSelected(undefined);
      setSelectedDay(0);
      setSelectedAvailabilityTime(undefined);
    }
  }, [selectedLessonScheduleId]);
  useEffect(() => {
    setSelectedDate(selected);
  }, [selected]);

  const handleGetCurrentWeek = useCallback(
    async (isTimezoneChanged: any) => {
      let syncedAvailabilty = null;
      let categorizedAvailability = null;

      const today = new Date();
      const firstDay = startOfWeek(today);
      const dates = getCurrentWeek(firstDay);
      setWeekDates(dates);
      const avail = await createTutorAvailabilityCalendarData(
        tutorAvailability.availability,
        timeZone.value,
        isTimezoneChanged
      );
      syncedAvailabilty = await syncAvailabilityWithBookings(
        avail,
        tutorBookings,
        dates,
        timeZone.value
      );
      setTutorAvailabilityDetails([...syncedAvailabilty]);
      categorizedAvailability = categorizeTutorAvailability(syncedAvailabilty);
      setCategorizedTutorAvailability([...categorizedAvailability]);
    },
    [timeZone, tutorAvailability, tutorBookings]
  );

  function convertDateToTimezone(date: MomentInput, timezone: string) {
    const momentDate = moment(date);
    const timezoneDate = momentDate.tz(timezone);
    const timezoneOffset = timezoneDate.format("Z");
    const formattedDate = momentDate.format("ddd MMM DD YYYY HH:mm:ss");
    return `${formattedDate} GMT${timezoneOffset}`;
  }

  function adjustDateHours(dateString: any, hours: any) {
    const momentDate = moment(dateString);
    const adjustedDate = momentDate.add(hours, "hours");
    return adjustedDate.format("ddd MMM DD YYYY HH:mm:ss [GMT]Z");
  }

  function relativeDifference(a: any, b: any) {
    const difference = b - a;
    return difference;
  }

  function extractTimeZoneOffset(str: string) {
    // Extract the last 6 characters from the input string
    const offsetStr = str.slice(-6);

    // Parse the extracted string and convert it to a floating number
    const sign = offsetStr[0] === "+" ? 1 : -1;
    const hours = parseFloat(offsetStr.substring(1, 3));
    const minutes = parseFloat(offsetStr.substring(4, 6));

    // Convert the hours and minutes to a decimal and apply the sign
    const offset = sign * (hours + minutes / 60);

    return offset;
  }

  const setChosenDateAndTime = async (
    date: Date | undefined,
    time: Date,
    utc: Date
  ) => {
    setSelectedAvailabilityTime(time);
    if (dateSelectionProperties[selectedLessonScheduleId]) {
      let newDets = dateSelectionProperties;
      newDets[selectedLessonScheduleId] = {
        selected: selected,
        selectedDay: selectedDay,
        selectedAvailabilityTime: time,
      };

      setDateSelectionProperties([...newDets]);
    } else {
      setDateSelectionProperties([
        ...dateSelectionProperties,
        {
          selected: selected,
          selectedDay: selectedDay,
          selectedAvailabilityTime: time,
        },
      ]);
    }

    let dateAndTime = new Date(); // check date is not in the past
    if (date) {
      dateAndTime = new Date(date);
    }

    dateAndTime.setHours(time.getHours());
    dateAndTime.setMinutes(time.getMinutes());

    // Get the current date and time
    let currentDate = new Date();

    // Add 12 hours to the current date
    currentDate.setHours(currentDate.getHours() + 12);

    if (dateAndTime < new Date()) {
      toast.error("Select a future time", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    if (dateAndTime < currentDate) {
      toast.error("Please select a time at least 12 hours from now.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    // scrollToBookLesson();

    const timeStringOne = convertDateToTimezone(dateAndTime, timeZone.value);
    const timeStringtwo = convertDateToTimezone(
      dateAndTime,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const extractedTimeZoneOffsetTwo = extractTimeZoneOffset(timeStringtwo);
    const extractedTimeZoneOffsetOne = extractTimeZoneOffset(timeStringOne);
    const extractedTimeZoneOffset = relativeDifference(
      extractedTimeZoneOffsetOne,
      extractedTimeZoneOffsetTwo
    );
    const timeStringInSelectedTimezone = adjustDateHours(
      timeStringOne,
      extractedTimeZoneOffset
    );
    const utcDateAndTime = new Date(timeStringInSelectedTimezone);
    //this is the object
    const newSchedule = {
      date: dateAndTime,
      time: dateAndTime,
      utc: utcDateAndTime,
      timeZone: timeZone.value,
      duration:
        activeSubscription?.lessonSchedule?.length > 0 &&
        activeSubscription?.lessonSchedule[0]?.duration,
    };
    const lessonScheduleFromCalendar = {
      date: dateAndTime,
      time: dateAndTime,
      utc: utcDateAndTime,
      timeZone: timeZone.value,
      duration:
        activeSubscription?.lessonSchedule?.length > 0 &&
        activeSubscription?.lessonSchedule[0]?.duration,
    };
    setLessonScheduleFromCalendar(lessonScheduleFromCalendar);
  };

  useEffect(() => {
    handleGetCurrentWeek(true);
  }, [handleGetCurrentWeek, timeZone, tutorAvailability, tutorBookings]);

  if (!weekDates || !categorizedTutorAvailability || !tutorAvailability) {
    return null;
  }
  const today = new Date();

  const disabledDays = (date: Date) => {
    if (dateSelectionProperties.length > 0 && selectedLessonScheduleId > 0) {
      const selectedDayOfWeek = dateSelectionProperties[0].selected.getDay();
      const currentDayOfWeek = date.getDay();

      // Disable all days that match the predefined day of the week or are before today
      return date < today || currentDayOfWeek === selectedDayOfWeek;
    }

    // If no selection or conditions, just disable days before today
    return date < today;
  };

  // Custom function to check if a date is a weekend
  const isWeekend = (date: Date) => {
    const day = date.getDay();
    return day === 0 || day === 6; // 0 for Sunday, 6 for Saturday
  };

  const modifiersStyles = {
    weekend: {
      color: "#D33479",
    },
  };
  return (
    <div className="sm:w-[627px]">
      <div className="flex gap-4 items-center justify-between">
        <p className="text-primary text-[16px] md:text-xl font-semibold">
          Availability -{" "}
          <span>{numberStrings[selectedLessonScheduleId]} Lesson</span>
        </p>
        {/* Time Zone */}
        <div className="sm:w-[200px]">
          {" "}
          <Select
            value={timeZone}
            onChange={handleTimeZoneChange}
            options={aryIannaTimeZones}
            components={animatedComponents}
            placeholder="Time Zone"
            isMulti={false}
            theme={selectorTheme}
            isSearchable
            className="text-primary bg-secondary text-xs rounded-3xl border-secondary"
          />
        </div>
      </div>
      {/* Divider */}
      <div className="border-b-[1px] border-gray-200 w-[full] my-4"></div>
      {/* Text */}

      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 ">
          <DayPicker
            mode="single"
            selected={selected}
            onSelect={setSelected}
            month={month}
            disabled={disabledDays}
            modifiers={{
              weekend: isWeekend,
            }}
            modifiersStyles={modifiersStyles}
            styles={{
              root: { fontFamily: "Montserrat, sans-serif" },
            }}
            classNames={{
              day: `text-sm px-0 m-0 font-montserrat font-medium d2pCalendarDayButton`,
              month_caption: ` font-medium   pt-3 pb-3`,
              today: `bg-gray-100 rounded-3xl`, // Add a border to today's date
              selected: ` bg-primary-opaque border-amber-500 rounded-3xl text-primary`, // Highlight the selected day
              root: `${defaultClassNames.root}  text-sm font-montserrat`, // Add a shadow to the root element
              chevron: `fill-primary ml-0  p-1 text-xl border-[1px] border-gray-200 rounded-md bg-white`, // Change the color of the chevron,
              button_previous: `ml-4`,
              button_next: `d2pCalendarNextButton`
            }}
          />
        </div>
        <div className="w-full  md:w-1/2 md:border-l-[1px] md:ml-3  md:px-4  pt-2 border-gray-200">
          <p className="text-primary text-right md:text-left text-sm font-medium">
            Available time slots
          </p>
          <div className="border-b-[1px] border-gray-200 w-full my-2 mb-4"></div>
          {selectedDay >= 0 ? (
            <div className="grid grid-cols-3 gap-4 md:gap-2  md:h-[280px] md:overflow-y-scroll md:justify-between  flex-wrap justify-center">
              {tutorAvailabilityDetails[selectedDay].map(
                (schedule: any, index: number) =>
                  schedule.available ? (
                    <div
                      key={index}
                      className={`px-4 py-2 rounded-xl border-[1px]  text-primary border-gray-200 ${
                        schedule.time === selectedAvailabilityTime &&
                        "bg-secondary text-white"
                      }`}
                      onClick={() =>
                        setChosenDateAndTime(
                          selected,
                          schedule.time,
                          schedule.utc
                        )
                      }
                    >
                      <h3 className="text-[16px] text-center md:text-sm font-semibold cursor-pointer" data-test="d2pCalendarTimeButton">
                        {schedule.time.getHours() +
                          ":" +
                          schedule.time.getMinutes() +
                          (schedule.time.getMinutes() === 0 ? "0" : "")}
                      </h3>
                    </div>
                  ) : (
                    <div
                      key={index}
                      className="px-4 py-2 rounded-xl border-[1px] border-gray-100 "
                    >
                      <h3 className="text-[16px] text-center md:text-sm text-gray-300 font-semibold">
                        {schedule.time.getHours() +
                          ":" +
                          schedule.time.getMinutes() +
                          (schedule.time.getMinutes() === 0 ? "0" : "")}
                      </h3>
                    </div>
                  )
              )}
            </div>
          ) : (
            <SelectDateAndContinue />
          )}
        </div>
      </div>
    </div>
  );
}

import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";
import { FaRegBell } from "react-icons/fa";
import { routes } from "src/Routes";
import { useStateValue } from "src/context/StateProvider";

export default function Notifications() {
  const [{ unreadNotifications, showMobileMenu }, dispatch] = useStateValue();

  const closeMobileMenu = async () => {
    await dispatch({
      type: "SET_SHOW_MOBILE_MENU",
      showMobileMenu: !showMobileMenu,
    });
  };

  const location = useLocation();
  return (
    <div>
      <NavLink to={routes.STUDENT_DASHBOARD_NOTIFICATIONS.url}>
        <div
          className={clsx(
            {
              "menu-inactive":
                routes.STUDENT_DASHBOARD_NOTIFICATIONS.url !==
                location.pathname,
            },
            {
              "menu-active":
                routes.STUDENT_DASHBOARD_NOTIFICATIONS.url ===
                location.pathname,
            }
          )}
          onClick={closeMobileMenu}
        >
          <div className="flex gap-4">
            <div className="flex gap-4">
              <div>
                <FaRegBell className="text-lg" />
              </div>
              <div>{routes.STUDENT_DASHBOARD_NOTIFICATIONS.label}</div>
            </div>
            {unreadNotifications && (
              <div
                className={clsx({
                  "bg-secondary hover:bg-secondary-light text-xxs w-auto px-2.5 py-1 text-white rounded-full flex justify-between":
                    unreadNotifications?.length,
                  hidden: !unreadNotifications?.length,
                })}
              >
                {unreadNotifications?.length}
              </div>
            )}
          </div>
        </div>
      </NavLink>
    </div>
  );
}

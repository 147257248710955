import Lottie from "lottie-react";
import * as preppingDashboard from "src/assets/animations/preppingDashboard.json";
import LoadingMessages from "src/components/Shared/LoadingMessages";
import { prepDashboardMessages } from "src/static-data/LoadingMessages";
import FixedHeaderWithoutNav from "../Shared/FixedHeaderWithoutNav";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { useContext } from "react";

export default function PreppingDashboard({ isBookingLessons = false }) {
  const {
    state: { isBookingMoreLessons },
  } = useContext(DirectPayContext);
  return (
    <>
      <FixedHeaderWithoutNav />
      <div className="grid place-items-center h-[75vh]">
        <div className="flex flex-col items-center">
          <div className="-mb-10 pb-8">
            <h1 className="lg:text-2xl text-primary-light text-center">
              {isBookingMoreLessons || isBookingLessons
                ? "Booking your lessons"
                : "Preparing your personal dashboard"}
            </h1>
          </div>
          <Lottie
            animationData={preppingDashboard}
            style={{
              height: 250,
            }}
          />
          <LoadingMessages messages={prepDashboardMessages} />
        </div>
      </div>
    </>
  );
}

import { useContext, useMemo } from "react";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
interface IReasonCard {
  icon: string;
  text: string;
  dataTest?: string;
}
export default function ReasonCard({ icon, text, dataTest }: IReasonCard) {
  const {
    state: { reasonsForLearning },
    actions: { setPrimaryReason },
  } = useContext(DirectPayContext);

  const selected = useMemo(() => {
    return reasonsForLearning.includes(text) ? true : false;
  }, [reasonsForLearning, text]);

  return (
    <div
      className={`flex md:flex-col items-center gap-2 border-2 p-3 sm:py-7 sm:px-4 rounded-3xl transition-colors duration-200  cursor-pointer ${
        selected
          ? "bg-primary-pale border-primary"
          : "bg-gray-50 border-gray-100"
      }`}
      onClick={() => {
        setPrimaryReason(text);
      }}
    >
      <p className="text-2xl md:text-5xl w-fit md:w-auto hidden sm:block ">
        {icon}
      </p>
      <p data-test={dataTest} className=" font-bold text-lg sm:text-2xl">
        {text}
      </p>
    </div>
  );
}

import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";
import { routes } from "src/Routes";
import { useStateValue } from "src/context/StateProvider";

export default function Lessons() {
  const location = useLocation();
  const [{ showMobileMenu }, dispatch] = useStateValue();

  const closeMobileMenu = async () => {
    await dispatch({
      type: "SET_SHOW_MOBILE_MENU",
      showMobileMenu: !showMobileMenu,
    });
  };

  return (
    <div>
      {" "}
      <NavLink to={routes.TUTOR_DASHBOARD_LESSONS.url}>
        <p
          className={clsx("submenu-inactive", {
            "submenu-active":
              routes.TUTOR_DASHBOARD_LESSONS.url === location.pathname,
          })}
          onClick={closeMobileMenu}
        >
          {routes.TUTOR_DASHBOARD_LESSONS.label}
        </p>
      </NavLink>
    </div>
  );
}

import { useContext } from "react";
import CompetenceCard from "./CompetenceCard";
import HeaderText from "./HeaderText";
import {
  Context as DirectPayContext,
  proficiency,
} from "src/context/DirectPayContext";
export default function CompetenceForm() {
  const {
    state: { subject },
  } = useContext(DirectPayContext);

  return (
    <div className="mt-12">
      <section className="max-w-2xl mx-auto">
        <HeaderText>
          How confident are you in your{" "}
          {subject === "yoruba" ? "Yoruba" : "Igbo"} skills?
        </HeaderText>
        <p className="mt-3 font-semibold text-secondary">
          This helps us match you with the perfect tutor for your level!
        </p>
      </section>
      <div className="mt-10 flex flex-col gap-4">
        {proficiency.map((pro) => (
          <CompetenceCard
            title={pro.label}
            value={pro.value}
            key={pro.value}
            description={
              pro.label === "Beginner"
                ? "I know a few words but struggle to string sentences together. I struggle to follow the language when I hear it."
                : `I have a decent grasp of vocabulary, so I understand ${
                    subject === "yoruba" ? "Yoruba" : "Igbo"
                  } in most situations but there are important gaps in my pronunciation, grammar and/or fluency`
            }
            emoji={pro.label === "Beginner" ? "🐛" : "🦋"}
          />
        ))}
      </div>
    </div>
  );
}

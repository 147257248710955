const nilendri = `${process.env.REACT_APP_ASSET_CDN}/Nilendri.webp`;
const fiona = `${process.env.REACT_APP_ASSET_CDN}/fiona.webp`;
const tafadzwa = `${process.env.REACT_APP_ASSET_CDN}/tafadzwa.webp`;
const olayinka = `${process.env.REACT_APP_ASSET_CDN}/olayinka.webp`;
const Toyin = `${process.env.REACT_APP_ASSET_CDN}/Toyin.webp`;
const Silemusa = `${process.env.REACT_APP_ASSET_CDN}/Silemusa.webp`;

export const mobileTestimonials = [
  {
    imageSrc: Silemusa,
    name: "Silemusa",
    bio: "Physics Tutor",
    review:
      "This was the best decision we made for our 2 children. Their grades went very quickly from an average B and C's to A and A+.",
    reviewBy: "Chrissy",
    // when: "1 month ago",
  },
  {
    imageSrc: tafadzwa,
    name: "Tafadzwa",
    bio: "Chemistry Tutor",
    review:
      "My interactions with Topset Tutoring have been very lovely. They are always professional and offer a really valuable service.",
    reviewBy: "Rakhee",
    // when: "1 month ago",
  },
  {
    imageSrc: nilendri,
    name: "Nilendri",
    bio: "Maths Tutor",
    review:
      "I recommend my Tutor Nilendri she is always nice in our lessons and I like having Maths with her",
    reviewBy: "Zara",
    // when: "1 month ago",
  },
  {
    imageSrc: fiona,
    name: "Fiona",
    bio: "Maths Tutor",
    review:
      "Excellent teachers, and great customer service. The classes are extremely engaging for the kids, will use them again!",
    reviewBy: "Christiana",
    // when: "1 month ago",
  },
  {
    imageSrc: olayinka,
    name: "Olayinka",
    bio: "Yoruba Tutor",
    review:
      "I've had two lessons so far with Olayinka. He is a really vibrant and thoughtful teacher. He is very clear in how he explains what he's teaching and I thoroughly enjoy the lessons.",
    reviewBy: "Oyinkan",
    // when: "1 month ago",
  },
  {
    imageSrc: Toyin,
    name: "Toyin",
    bio: "Yoruba Tutor",
    review:
      "Topset Tutoring is a very professional educational tutoring platform. My experience was very topnotch and pleasant.",
    reviewBy: "Omolola",
    // when: "1 month ago",
  },
];

export const YorubaMobileTestimonials = [
  {
    imageSrc: olayinka,
    name: "Olayinka",
    bio: "Yoruba Tutor",
    review:
      "I've had two lessons so far with Olayinka. He is a really vibrant and thoughtful teacher. He is very clear in how he explains what he's teaching and I thoroughly enjoy the lessons.",
    reviewBy: "Oyinkan",
    // when: "1 month ago",
  },
  {
    imageSrc: Toyin,
    name: "Toyin",
    bio: "Yoruba Tutor",
    review:
      "Topset Tutoring is a very professional educational tutoring platform. My experience was very topnotch and pleasant.",
    reviewBy: "Omolola",
    // when: "1 month ago",
  },
  {
    imageSrc: Silemusa,
    name: "Silemusa",
    bio: "Physics Tutor",
    review:
      "This was the best decision we made for our 2 children. Their grades went very quickly from an average B and C's to A and A+.",
    reviewBy: "Chrissy",
    // when: "1 month ago",
  },
  {
    imageSrc: tafadzwa,
    name: "Tafadzwa",
    bio: "Chemistry Tutor",
    review:
      "My interactions with Topset Tutoring have been very lovely. They are always professional and offer a really valuable service.",
    reviewBy: "Rakhee",
    // when: "1 month ago",
  },
  {
    imageSrc: nilendri,
    name: "Nilendri",
    bio: "Maths Tutor",
    review:
      "I recommend my Tutor Nilendri she is always nice in our lessons and I like having Maths with her",
    reviewBy: "Zara",
    // when: "1 month ago",
  },
  {
    imageSrc: fiona,
    name: "Fiona",
    bio: "Maths Tutor",
    review:
      "Excellent teachers, and great customer service. The classes are extremely engaging for the kids, will use them again!",
    reviewBy: "Christiana",
    // when: "1 month ago",
  },
];

export const webTestimonials1 = [
  {
    imageSrc: Silemusa,
    name: "Silemusa",
    bio: "Physics Tutor",
    review:
      "This was the best decision we made for our 2 children. Their grades went very quickly from an average B and C's to A and A+.",
    reviewBy: "Chrissy",
    // when: "1 month ago",
  },
  {
    imageSrc: tafadzwa,
    name: "Tafadzwa",
    bio: "Chemistry Tutor",
    review:
      "My interactions with Topset Tutoring have been very lovely. They are always professional and offer a really valuable service.",
    reviewBy: "Rakhee",
    // when: "1 month ago",
  },
  {
    imageSrc: nilendri,
    name: "Nilendri",
    bio: "Maths Tutor",
    review:
      "I recommend my Tutor Nilendri she is always nice in our lessons and I like having Maths with her",
    reviewBy: "Zara",
    // when: "1 month ago",
  },
];

export const webTestimonials2 = [
  {
    imageSrc: fiona,
    name: "Fiona",
    bio: "Maths Tutor",
    review:
      "Excellent teachers, and great customer service. The classes are extremely engaging for the kids, will use them again!",
    reviewBy: "Christiana",
    // when: "1 month ago",
  },
  {
    imageSrc: olayinka,
    name: "Olayinka",
    bio: "Yoruba Tutor",
    review:
      "I've had two lessons so far with Olayinka. He is a really vibrant and thoughtful teacher. He is very clear in how he explains what he's teaching and I thoroughly enjoy the lessons.",
    reviewBy: "Oyinkan",
    // when: "1 month ago",
  },
  {
    imageSrc: Toyin,
    name: "Toyin",
    bio: "Yoruba Tutor",
    review:
      "Topset Tutoring is a very professional educational tutoring platform. My experience was very topnotch and pleasant.",
    reviewBy: "Omolola",
    // when: "1 month ago",
  },
];

export const YorubaWebTestimonials1 = [
  {
    imageSrc: olayinka,
    name: "Olayinka",
    bio: "Yoruba Tutor",
    review:
      "I've had two lessons so far with Olayinka. He is a really vibrant and thoughtful teacher. He is very clear in how he explains what he's teaching and I thoroughly enjoy the lessons.",
    reviewBy: "Oyinkan",
    // when: "1 month ago",
  },
  {
    imageSrc: tafadzwa,
    name: "Tafadzwa",
    bio: "Chemistry Tutor",
    review:
      "My interactions with Topset Tutoring have been very lovely. They are always professional and offer a really valuable service.",
    reviewBy: "Rakhee",
    // when: "1 month ago",
  },
  {
    imageSrc: nilendri,
    name: "Nilendri",
    bio: "Maths Tutor",
    review:
      "I recommend my Tutor Nilendri she is always nice in our lessons and I like having Maths with her",
    reviewBy: "Zara",
    // when: "1 month ago",
  },
];

export const YorubaWebTestimonials2 = [
  {
    imageSrc: Toyin,
    name: "Toyin",
    bio: "Yoruba Tutor",
    review:
      "Topset Tutoring is a very professional educational tutoring platform. My experience was very topnotch and pleasant.",
    reviewBy: "Omolola",
    // when: "1 month ago",
  },
  {
    imageSrc: Silemusa,
    name: "Silemusa",
    bio: "Physics Tutor",
    review:
      "This was the best decision we made for our 2 children. Their grades went very quickly from an average B and C's to A and A+.",
    reviewBy: "Chrissy",
    // when: "1 month ago",
  },
  {
    imageSrc: fiona,
    name: "Fiona",
    bio: "Maths Tutor",
    review:
      "Excellent teachers, and great customer service. The classes are extremely engaging for the kids, will use them again!",
    reviewBy: "Christiana",
    // when: "1 month ago",
  },
];

import axios from "axios";

export async function postWasSessionUseful(
  choice: string, userId: any, uniqueId: string, sessionId?: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/learning-app/was-session-useful`,
      { choice, userId, uniqueId, sessionId }
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postOptionalFeedback(
  message: string, uniqueId: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/learning-app/optional-feedback`,
      { message, uniqueId }
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postSaveFlashcardProgress(
  userId?: string, topicId?: any, wordsLearned?: any, wordsStillLearning?: any
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/learning-app/save-flashcard-progress`,
      { userId, topicId, wordsLearned, wordsStillLearning }
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postSessionStartedAuditLog(
  user: any, languageId?: string, levelId?: string, sessionId?: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/learning-app/audit-log/session-started`,
      { user, languageId, levelId, sessionId }
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postSessionCompleteAuditLog(
  userId: any, results: any, sessionId?: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/learning-app/audit-log/session-complete`,
      { userId, results, sessionId }
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getRandomWords(
  ageGroup: string, basicPOScategory: any, level: number, numberOfWords: number, topics: any, languageId: string
): Promise<any | undefined[]> {
  const language = languageId?.charAt(0)?.toUpperCase() + languageId.slice(1);
  const data = { ageGroup, basicPOScategory, level, numberOfWords, topics, language };
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/learning-app/get-random-words`,
      { params: data }
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getInteractiveSessions(
  topicId: string,
  userId: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/learning-app/get-interactive-sessions`,
      { params: { topicId, userId } }
    );
    console.log("response", response);
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getInteractiveSession(
  sessionId: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/learning-app/get-interactive-session`,
      { params: { sessionId } }
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getTopicVocabList(
  topicId?: string, userId?: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/learning-app/get-topic-vocab-list/${topicId}/${userId}`,

    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getPromptWords(
  wordBankIdObjects: any
): Promise<any | undefined[]> {
  console.log("wordlistIds", wordBankIdObjects);
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/learning-app/get-prompt-words`,
      wordBankIdObjects
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
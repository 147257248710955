import BecomeTutor from "src/components/Home/BecomeTutor";
import Footer from "src/components/Shared/Footer";
import GetStarted from "src/components/Home/GetStarted/GetStarted";
import Header from "src/components/Shared/Header";
import YorubaHero from "src/components/Home/YorubaHero";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import YorubaMobileTestimonials from "src/components/Home/Testimonials/YorubaMobileTestimonials";
import YorubaWebTestimonials from "src/components/Home/Testimonials/YorubaWebTestimonials";
import YorubaWhatYouWillLearn from "src/components/Home/YorubaWhatYouWillLearn";
import WhyLearnYoruba from "src/components/Home/WhyLearnYoruba";
import { Helmet } from "react-helmet";
import WeAreDifferent from "./WeAreDifferent";
import BragBar from "src/components/Home/BragBar";
import { useStateValue } from "src/context/StateProvider";
import useGetAddSource from "src/hooks/useGetAdSource";
import LanguageHero from "src/views/LanguageLanding/LanguageHero";
import WhatYouWillLearn from "./WhatYouWillLearn";
import WhyLearnLanguages from "./WhyLearnLanguages";
import WhyYoureHere from "src/components/Home/WhyYoureHere";
import InvestorLogos from "src/components/Shared/InvestorLogos";
import { routes } from "src/Routes";
import { useNavigate } from "react-router-dom";

function LanguageLanding() {
  const navigate = useNavigate();
  const [{}, dispatch] = useStateValue();
  const query = useGetAddSource();
  const gclid = query.get("gclid");
  const fbclid = query.get("fbclid");
  const instagram = query.get("utm_source") === "instagram";

  useEffect(() => {
    navigate(routes.LOGIN.url);
  }, []);

  useEffect(() => {
    // Set the source state using the gclid or fbclid values, if available
    if (gclid) {
      dispatch({
        type: "SET_AD_SOURCE",
        adSource: "gclid",
      });
    } else if (fbclid || instagram) {
      dispatch({
        type: "SET_AD_SOURCE",
        adSource: "fbclid",
      });
    }
  }, [gclid, fbclid]);
  return (
    <div className="font-montserrat">
      <Helmet>
        <title>Speak your mother tongue with pride!</title>
        <meta
          name="description"
          content="Fun online Yoruba and Igbo lessons with our native experts"
        />
        <meta name="keywords" content="Yoruba, language, tutoring" />
      </Helmet>
      <Header />
      <LanguageHero />

      <BragBar />
      <WeAreDifferent />
      <div className="lg:hidden mt-8">
        <YorubaMobileTestimonials />
      </div>
      <div className="hidden lg:block mt-4">
        <YorubaWebTestimonials />
      </div>
      <div>
        <WhatYouWillLearn />
      </div>
      <div>
        <WhyLearnLanguages />
      </div>
      <div>
        <WhyYoureHere />
      </div>
      <div>
        <GetStarted />
      </div>
      <div>
        <BecomeTutor />
      </div>
      <div>
        <InvestorLogos />
      </div>
      <div className="bg-primary">
        <Footer />
      </div>
    </div>
  );
}

export default LanguageLanding;

export const lessonResources = {
  Yoruba: {
    Child: {
      Beginner: {
        "Introduction & Emotions": {
          name: "Introduction & Emotions",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/gb/902046110/yoruba-kids-basic-emotion-flash-cards/?funnelUUID=06e5e6c0-1c13-4551-8376-6a11592ae2a4",
            practiceVideo: "",
            sentenceContruction: true,
          },
        },
        Greetings: {
          name: "Greetings",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/gb/901942163/yoruba-kids-level-1-topic-1-greetings-flash-cards/",
            practiceVideo:
              '<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
            sentenceContruction: true,
          },
        },
        Introductions: {
          name: "Introductions",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/gb/901961864/yoruba-kids-level-1-topic-2-introductions-flash-cards/?funnelUUID=7c123ca9-f7db-47bb-8163-9913266e0941",
            practiceVideo:
              '<iframe width="853" height="480" src="https://www.youtube.com/embed/RiJDQnXinvM" title="How do I introduce myself in Yorùbá?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
            sentenceContruction: true,
          },
        },
        Manners: {
          name: "Manners",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/gb/901958598/yoruba-kids-level-1-topic-3-manners-flash-cards/?funnelUUID=d9c569a7-5889-4993-8e16-a8f96cac8bb1",
            practiceVideo:
              '<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
            sentenceContruction: true,
          },
        },
        "Core Family": {
          name: "Core Family",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902000481/yoruba-kids-level-1-topic-4-core-family-flash-cards/?funnelUUID=ca3fd1ce-f4b9-4c95-894e-556b8a5639d6`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1Xb90RdPSYbthjXEGa1UWaB2I1x4N6AA3/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Recap (Session 1- 4)": {
          name: "Recap (Session 1- 4)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/916538560/yoruba-kids-level-consolidation-topic-1-4-flash-cards/?funnelUUID=7b7f5e4d-a05d-4a31-8f25-5c709b45592d`,
            practiceVideo: ``,
          },
        },
        "Close Family": {
          name: "Close Family",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902175974/yoruba-kids-level-1-topic-5-close-family-flash-cards/?funnelUUID=67112c27-3789-44b4-a644-6a312a663578`,
            practiceVideo: ``,
          },
        },
        Friends: {
          name: "Friends",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902192069/yoruba-kids-level-1-topic-6-friends-flash-cards/?funnelUUID=04c4b8ea-3a34-4957-be63-c8029b922bce`,
            practiceVideo: ``,
          },
        },
        School: {
          name: "School",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902197150/yoruba-kids-level-1-topic-7-school-flash-cards/?funnelUUID=271e4e1b-e772-4dc3-bd54-bf6c19135451`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Around the House": {
          name: "Around the House",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902372950/yoruba-kids-level-1-topic-8-around-the-house-flash-cards/?funnelUUID=a7d1dbf1-f59b-4a7f-902c-9eab264ef46d`,
            practiceVideo: ``,
          },
        },
        "Recap  (Session 5- 8)": {
          name: "Recap  (Session 5- 8)",
          resources: {
            grammarSpotlight: `
                `,
            quizletSet: `https://quizlet.com/gb/916539985/yoruba-kids-level-1-consolidation-set-topic-5-8-flash-cards/?funnelUUID=66c2d2cb-83f5-4e68-b412-9ac48d423608`,
            practiceVideo: ``,
          },
        },

        Colours: {
          name: "Colours",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902375327/yoruba-kids-level-1-topic-9-colours-flash-cards/?funnelUUID=68088d10-3eca-4f5f-b1ce-384786e09b5a`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Clothing: {
          name: "Clothing",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902377720/yoruba-kids-level-1-topic-10-clothing-flash-cards/?funnelUUID=9fbf80cf-f88f-4f46-8c25-102225f12db8`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/RiJDQnXinvM?si=o3APVllVl5FsRTT6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Numbers: {
          name: "Numbers",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902381645/yoruba-kids-level-1-topic-11-numbers-flash-cards/?funnelUUID=e080f238-722b-464b-818f-5626dce7eb56`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Parts of the Face": {
          name: "Parts of the Face",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902379633/yoruba-kids-level-1-topic-12-parts-of-the-face-flash-cards/?funnelUUID=7aa6abe8-b3af-448f-bfd7-4479e60bc3c2`,
            practiceVideo: ``,
          },
        },
        "Recap (Session 9- 12)": {
          name: "Recap (Session 9- 12)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/916542214/yoruba-kids-level-1-consolidation-set-topic-9-12-flash-cards/?new`,
            practiceVideo: ``,
          },
        },
        Food: {
          name: "Food",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902558510/yoruba-kids-level-1-topic-13-food-flash-cards/?funnelUUID=a1157ac9-8f7d-44b2-a7fa-33d3fce41be2`,
            practiceVideo: ``,
          },
        },
        Animals: {
          name: "Animals",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902578642/yoruba-kids-level-1-topic-14-animals-flash-cards/?funnelUUID=dba0cccf-bddb-4b05-a4b1-887c33fcb113`,
            practiceVideo: ``,
          },
        },
        Hobbies: {
          name: "Hobbies",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902588574/yoruba-kids-level-1-topic-15-hobbies-flash-cards/?funnelUUID=ef8bbba1-f58c-4a90-8383-8636a748a7d7`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Daily Activities": {
          name: "Daily Activities",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902625146/yoruba-kids-level-1-topic-16-daily-activities-flash-cards/?funnelUUID=b54df49a-fa50-4510-83dc-8536dc3486e8`,
            practiceVideo: ``,
          },
        },
        "Recap (Session 13- 16)": {
          name: "Recap (Session 13- 16)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/916543778/yoruba-kids-level-1-consolidation-set-topic-13-16-flash-cards/?new`,
            practiceVideo: ``,
          },
        },
      },
    },
    Adult: {
      Beginner: {
        Greetings: {
          name: "Greetings",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJOhv9E_A/DNZZ0ub5YdIdoF5HoCR4Qg/edit`,
            quizletSet:
              "https://quizlet.com/gb/816648315/ikini-ojoojumo-everyday-greetings-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="816" height="612" src="https://www.youtube.com/embed/-NKxDuJ4src" title="Topset Lesson Yoruba Beginner Session 1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Questions & Introduction": {
          name: "Questions & Introduction",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJOgR0u0I/vX7HnNhfgO8FZq5TzoXCmw/edit`,
            quizletSet:
              "https://quizlet.com/gb/822612173/words-with-gb-flash-cards/",
            practiceVideo:
              '<iframe width="853" height="480" src="https://www.youtube.com/embed/LvA1JNhSHDw" title="Topset Lesson Yoruba Beginner Session 2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Family: {
          name: "Family",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJO_ePbjM/G9UqcBSqZUUA4GVe-HPBvA/edit`,
            quizletSet:
              "https://quizlet.com/gb/841387880/session-3-family-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="853" height="480" src="https://www.youtube.com/embed/mC51sHWsNkM" title="Topset Lesson Yoruba Beginner Session 3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Around the House": {
          name: "Things around the house",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJO4xjngs/S48HS8lLnhgSWIuPrseXZA/edit`,
            quizletSet: `https://quizlet.com/gb/825840266/words-with-kp-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1Xb90RdPSYbthjXEGa1UWaB2I1x4N6AA3/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Recap (Session 1- 4)": {
          name: "Recap (Session 1- 4)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/896416123/test?answerTermSides=4&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },
        "Places and Transportation": {
          name: "Places and Transportation",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJO9psSUs/78Jb7fowU3aukb5MF2dBWA/edit`,
            quizletSet: `https://quizlet.com/gb/841436758/places-means-of-transportation-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/Sb2rTTafmiE" title="Topset Lesson Yoruba Beginner Session 5" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Clothing: {
          name: "Clothing",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJPDTLXSQ/SbSutre18cHxcrlk1gNs0Q/edit`,
            quizletSet: `https://quizlet.com/gb/817139138/colours-awo-flash-cards/?funnelUUID=565b2fbb-8a13-42c3-91bb-0a25db9f99eb`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/cEAxEop4nxc" title="Topset Lesson Yoruba Beginner Session 6" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Parts of the Body": {
          name: "Parts of the Body",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJPEU3ebA/0o1_ahqc97JcND2hnwP6Xw/edit`,
            quizletSet: `https://quizlet.com/gb/841470858/session-7-parts-of-the-body-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/k1TPhgAAIrg" title="Topset Lesson Yoruba Beginner Session 7" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Food: {
          name: "Food",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJPZmr5QQ/wVjoGCg_y2Z45i6YOv-FPw/edit`,
            quizletSet: `https://quizlet.com/gb/841474009/session-8-food-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/b4C41ZQO0cw" title="Topset Lesson Yoruba Beginner Session 8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Recap  (Session 5- 8)": {
          name: "Recap  (Session 5- 8)",
          resources: {
            grammarSpotlight: `
            `,
            quizletSet: `https://quizlet.com/854160071/test?funnelUUID=c59da0bd-dbb6-4000-81a9-f321d19f1cc8`,
            practiceVideo: ``,
          },
        },

        Animals: {
          name: "Animals",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJPXzd8jg/3hfxCmaNeTvSWuNcnd1bWg/edit`,
            quizletSet: `https://quizlet.com/gb/841479232/session-9-animals-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/APd82umoKNo" title="Topset Lesson Yoruba Beginner Session 9" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Numbers 1-10": {
          name: "Numbers 1-10",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJPVJeriw/8JsYDoRj9pACeWjOos57qw/edit`,
            quizletSet: `https://quizlet.com/gb/841482911/yoruba-level-1-session-11-numbers-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/vvXNwMwVIs0" title="Topset Lesson Yoruba Beginner Session 11" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Time: {
          name: "Time",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJPkV-jtw/LuXOfVbPmLeMFUqk8e5zsQ/edit`,
            quizletSet: `https://quizlet.com/gb/851843683/yoruba-level-1-session-10-time-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/SOC8KIq1lBs" title="Topset Lesson Yoruba Beginner Session 10" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Direction: {
          name: "Direction",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJOtsqiI8/Kv6yxNKraL7j7LOPYwkUNg/edit`,
            quizletSet: `https://quizlet.com/gb/850721641/yoruba-level-1-session-12-directions-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/I-U0zny0JAM" title="Topset Lesson Yoruba Beginner Session 12" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
      },
      Elementary: {
        Weddings: {
          name: "Weddings",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGM-ybT8c0/tSVptmch-jjitRqJTzCuZg/edit`,
            quizletSet:
              "https://quizlet.com/gb/846118792/session-1-wedding-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="853" height="480" src="https://www.youtube.com/embed/FMUpmKFdVQs" title="Topset Lesson Yoruba Elementary Session 1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Home & Family": {
          name: "Home & Family",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGMUNTHqcY/jyJSNo6TLVCx4xEgEZB5tQ/edit`,
            quizletSet:
              "https://quizlet.com/gb/847489849/yoruba-level-2-session-2-home-family-flash-cards/",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/1eGiDBjrgbXSiZV0NZGjay4NXxPXgM9FH/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        "Daily Activities": {
          name: "Daily Activities",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGL-2i3XL8/0LsI0kEUZzZWaMDhkiHZ0w/edit`,
            quizletSet:
              "https://quizlet.com/gb/848459447/session-3-daily-activities-flash-cards/?i=57u171&x=1qqt",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/18_arlOt5pHBymX3nYk0Z7LJvMf4Q8P3f/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        "Work and School": {
          name: "Work and School",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGMIPNTwQ4/cM8CzTVJ8YigxtC6iroehA/edit`,
            quizletSet:
              "https://quizlet.com/899112897/yoruba-level-2-topic-2-work-and-school-draft-flash-cards/",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/10zjN9h1eQNtzmBbtAcoK_18qhDeUA7E_/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        Health: {
          name: "Health",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGMJUUjggg/Wxt-Nt-0cr2SDvtNoiPRTg/edit`,
            quizletSet: `https://quizlet.com/gb/848974616/session-4-health-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1v7BHpbDEy1UBBRTG1ttWJM6D25Fe_IU2/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Consolidation Quiz (Session 1 - 4)": {
          name: "Consolidation Quiz (Session 1 - 4)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/876224154/test?answerTermSides=4&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },
        Shopping: {
          name: "Shopping",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGManzvJ5Q/FegFZJ-kqY4gCBSdAYrI9w/edit`,
            quizletSet: `https://quizlet.com/gb/849018157/session-5-shopping-flash-cards/?i=57u171&x=1qqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1MNWKDEYgrIRtJLjWvsZEQsDAphzcZmC7/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Restaurant: {
          name: "Restaurant",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGMgacb280/mtG8WqPQDdKmyVSWNUDZzA/edit`,
            quizletSet: `https://quizlet.com/gb/849555058/session-6-restaurant-flash-cards/?i=57u171&x=1qqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1EAXf91tPgp4kmo37JmRwCz4EOiM-u84B/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Travel: {
          name: "Travel",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGMgjvXAyg/MLXoQtxT7S0lFp-VhYKP6w/edit`,
            quizletSet: `https://quizlet.com/gb/849998134/session-7-travel-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/10dBtro6eeshxce90TNwQx-qMcEDZe6fi/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Numbers 11 - 20": {
          name: "Numbers 11 - 20",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGMhuq7lpA/3vsSJ-FdiZkMa_u5DokN1w/edit`,
            quizletSet: `https://quizlet.com/gb/850037062/session-8-numbers-11-20-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1i8ZZxHeVCfRqQuy4SAtvFtQE2XWGVJ_B/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Numbers 13 - 1000": {
          name: "Numbers 13 - 1000",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGMhuq7lpA/3vsSJ-FdiZkMa_u5DokN1w/edit`,
            quizletSet: `https://quizlet.com/899108975/yoruba-level-2-topic-5-numbers-13-1000-draft-flash-cards/?funnelUUID=e04951df-bfd3-4aa5-9895-132bce197299`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1i8ZZxHeVCfRqQuy4SAtvFtQE2XWGVJ_B/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Consolidation Quiz (Session 5 - 8)": {
          name: "Consolidation Quiz (Session 5 - 8)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/876224639/test?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },

        "Expressing Emotions": {
          name: "Expressing Emotions",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGMmtEh0dM/lnjM7OFDIG6cY73sKAuwNg/edit`,
            quizletSet: `https://quizlet.com/gb/850420482/session-9-expressing-emotions-flash-cards/?i=57u171&x=1qqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1wDevdWUzVrT1_Vt3a9iKpPTjNU810udr/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Culture: {
          name: "Culture",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/850442085/session-10-culture-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/10zjN9h1eQNtzmBbtAcoK_18qhDeUA7E_/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Weather: {
          name: "Weather",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGNFSlysRM/mwBfGawIPcsVHpVvRZ50Ng/edit`,
            quizletSet: `https://quizlet.com/gb/851307474/session-11-weather-flash-cards/?i=57u171&x=1qqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1RjW8pr0ijWFrBFvssq6TJruOKvy3_LvU/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Sports: {
          name: "Sports",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGM_ZQfqOM/hE1YxncH_nIuhW-nD9oTfw/edit`,
            quizletSet: `https://quizlet.com/gb/851362990/session-12-sports-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1acrjCdKp9R07K48xmq5Wp8NTP1ncgx1C/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Sports and Hobbies": {
          name: "Sports and Hobbies",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGM_ZQfqOM/hE1YxncH_nIuhW-nD9oTfw/edit`,
            quizletSet: `https://quizlet.com/gb/851362990/session-12-sports-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1acrjCdKp9R07K48xmq5Wp8NTP1ncgx1C/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Consolidation Quiz (Session 9 - 12)": {
          name: "Consolidation Quiz (Session 9 - 12)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/876224933/test?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },
      },
      Intermediate: {
        "Describing Yourself": {
          name: "Describing Yourself",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGUTWUxYIQ/zcmfhl7oC006cUx8huqejg/edit?ui=eyJIIjp7IkEiOnRydWV9fQ`,
            quizletSet:
              "https://quizlet.com/957500965/yoruba-level-3-topic-1-describing-yourself-flash-cards/",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/GApKVPcTjOI?si=__nwkDO5rRMu13NY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Household Chores": {
          name: "Household Chores",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGUZUxfFes/qrugEA2U-NnSy7EAcbAi8w/edit`,
            quizletSet:
              "https://quizlet.com/957525570/yoruba-level-3-topic-2-home-activities-flash-cards",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/3q_NmrrZuU8?si=2YTIxsBDdVSWH9Uz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Cooking: {
          name: "Cooking",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGUeV0fGKQ/oX_3AnPr2C7m6hPNv6uTWQ/edit`,
            quizletSet:
              "https://quizlet.com/957549329/yoruba-level-3-topic-3-cooking-flash-cards/",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/two_PJM1hbM?si=mYRFIy2baj9hlTCz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Body and Health": {
          name: "Body and Health",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGUfg3MIwY/nlowadnv49nH4IbSB5mBHw/edit?ui=eyJIIjp7IkEiOnRydWV9fQ`,
            quizletSet:
              "https://quizlet.com/957577000/yoruba-level-3-topic-4-body-and-health-flash-cards/",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/I-tRjJDnmXU?si=44-QbeRWr6klK7zZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Recap (Topic 1- 4)": {
          name: "Recap (Topic 1- 4)",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/962516423/test?funnelUUID=6175cf6c-4568-4033-aef0-36eb77ce9761",
            practiceVideo: "",
          },
        },
      },
    },
  },
  Igbo: {
    Child: {
      Beginner: {
        "Introduction & Manners": {
          name: "Introduction & Manners",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/gb/901969534/igbo-kids-level-1-topic-3-manners-flash-cards/?funnelUUID=1352b229-42e0-4aa6-b23d-652a91cad3e1",
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/hMu-KCPTOqw?si=2ZqO86AuLSTFiAt2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Greetings: {
          name: "Greetings",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/gb/901944233/igbo-kids-level-1-topic-2-greetings-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/pKclg25WHjw?si=gR8J8qP2WWzdj98e" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Introductions: {
          name: "Introductions",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/gb/901964983/igbo-kids-level-1-topic-3-introductions-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/GZMIjZFiXsg?si=UCUGMduqtxXyiuSQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Emotions: {
          name: "Emotions",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/gb/902325480/igbo-kids-level-1-topic-4-basic-emotions-flash-cards/?i=57u171&x=1jqt",
            practiceVideo: "",
          },
        },

        "Recap (Session 1-4)": {
          name: "Recap (Session 1-4)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/916581361/topic-1-4-consolidation-set-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: ``,
          },
        },
        "Core Family": {
          name: "Core Family",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/901978185/igbo-kids-level-1-topic-4-core-family-flash-cards/?funnelUUID=2ebddb49-5a63-4da8-a324-3cbb9957f2d2`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/EMbGvFOK7xk?si=hcoArW3BXMvS6IEB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Close Family": {
          name: "Close Family",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902328611/igbo-kids-level-1-topic-5-close-family-flash-cards/?funnelUUID=f925fb48-7c5e-4350-8add-4de5ab80145b`,
            practiceVideo: ``,
          },
        },
        Friends: {
          name: "Friends",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902337845/igbo-level-1-topic-6-friends-flash-cards/?funnelUUID=7a2f0ba5-6fd7-494d-9abe-2bffb0f7c870`,
            practiceVideo: ``,
          },
        },
        School: {
          name: "School",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902342185/igbo-kids-level-1-topic-7-school-flash-cards/?funnelUUID=a32c9ce1-241b-40c9-91b8-f54f508b6f47`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/HJF1Atm9NRo?si=2-NvMOBVlb6-gxp6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Around the House": {
          name: "Around the House",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902344794/igbo-kids-level-1-topic-8-around-the-house-flash-cards/?funnelUUID=342b0990-bd18-481f-90bb-b245c2c19a59`,
            practiceVideo: ``,
          },
        },
        "Recap  (Session 5- 8)": {
          name: "Recap  (Session 5- 8)",
          resources: {
            grammarSpotlight: `
                `,
            quizletSet: `https://quizlet.com/854160071/test?funnelUUID=c59da0bd-dbb6-4000-81a9-f321d19f1cc8`,
            practiceVideo: ``,
          },
        },

        Colours: {
          name: "Colours",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902354474/igbo-kids-level-1-topic-9-colours-flash-cards/?funnelUUID=ae56fc2c-f96c-4347-9ff1-b95a4f1bf0d7`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/HJF1Atm9NRo?si=tavYNWaJNCI09Qa4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Clothing: {
          name: "Clothing",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902357817/igbo-kids-level-1-topic-10-clothings-flash-cards/?funnelUUID=5050ccd1-5530-455f-a5c0-bb02bebfb5fb`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/RiJDQnXinvM?si=d1Ir6Gm6IoozbbTo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Numbers: {
          name: "Numbers",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902362263/igbo-kids-level-1-topic-11-numbers-flash-cards/?funnelUUID=dfa30895-94ba-4e45-8a41-1d82cb991e83`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/HJF1Atm9NRo?si=2iQU6ouWWFhBy-8E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Parts of the Face": {
          name: "Parts of the Face",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902365162/igbo-kids-level-1-topic-12-parts-of-the-face-flash-cards/?funnelUUID=ace91402-2e71-4938-8862-6535389f870b`,
            practiceVideo: ``,
          },
        },
        "Recap (Session 9- 12)": {
          name: "Recap (Session 9- 12)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/854157642/test?funnelUUID=4c694272-d2c6-4cb4-bed0-7ea267cfc0cd`,
            practiceVideo: ``,
          },
        },
        Food: {
          name: "Food",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902863705/igbo-kids-level-1-topic-13-food-flash-cards/?funnelUUID=8147fcf6-8d14-4207-90bf-a8cf7c4bcf7f`,
            practiceVideo: ``,
          },
        },
        Animals: {
          name: "Animals",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902868486/igbo-kids-level-1-topic-14-animals-flash-cards/?funnelUUID=c45fbcaf-6d96-488c-a574-5042334e9a64`,
            practiceVideo: ``,
          },
        },
        "Daily Activities": {
          name: "Daily Activities",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902877991/igbo-kids-level-1-topic-16-daily-activities-flash-cards/?funnelUUID=27a916cf-500f-49fe-b945-fd2dc9927384`,
            practiceVideo: ``,
          },
        },
        "Recap (Session 13- 16)": {
          name: "Recap (Session 13- 16)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/854160071/test?funnelUUID=c59da0bd-dbb6-4000-81a9-f321d19f1cc8`,
            practiceVideo: ``,
          },
        },
      },
    },
    Adult: {
      Beginner: {
        Greetings: {
          name: "Greetings",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJPR68klQ/CoYEZ7zclJlol7ZQOm6FCQ/edit?utm_content=DAGJPR68klQ&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet:
              "https://quizlet.com/gb/887429044/igbo-level-1-session-1-greetings-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/1MtBp8Gd4wp_CpjgAYbi3-DDmYgqbLvQ3/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        "Questions & Introduction": {
          name: "Questions & Introduction",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJPbLUp80/V09Q7ts73krbb_yVrHFOog/edit?utm_content=DAGJPbLUp80&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet:
              "https://quizlet.com/gb/880014874/igbo-level-1-session-2-questions-and-introduction-new-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/1biNNvflKapkXPQ_Rb4OhgbxsLPylgSEc/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        Family: {
          name: "Family",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJO2bLbb0/dbchIXiPNmsvQ5SCwdPh1g/edit?utm_content=DAGJO2bLbb0&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet:
              "https://quizlet.com/gb/842888567/session-3-family-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/U6Bn8gzN62Y?si=GdF21GKW55PEne3S" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Things Around the House": {
          name: "Things around the house",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJP3VsX1E/uZv7aKoe56GNIWl0Rpv1FA/edit?utm_content=DAGJP3VsX1E&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/892411898/igbo-level-1-topic-4-things-around-the-house-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1kuyxH3TcdXmKc7_YI9PZk6oP_LU9X0r9/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Consolidation Quiz (Session 1 - 4)": {
          name: "Consolidation Quiz (Session 1 - 4)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/896421741/test?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },
        "Places and Transportation": {
          name: "Places and Transportation",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJP2-urqo/5d4GuLCr0XP48p9zulIBzw/edit?utm_content=DAGJP2-urqo&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/843055507/session-5-places-and-transportation-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/mJ7YiOlTwAE?si=xzl-ozdXr71DjQIx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Clothing: {
          name: "Clothing",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJQDgLXfs/A14UQ8BNuz0Rhge_wcuAlQ/edit?utm_content=DAGJQDgLXfs&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/843066215/igbo-session-6-clothing-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/mtvTyG3mCA4?si=-V8II1OEVcGOWuJf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Parts of the Body": {
          name: "Parts of the Body",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJREVXnok/pgmsro_k-qBwGshweH3dhg/edit?utm_content=DAGJREVXnok&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/843539651/igbo-level-1-session-7-parts-of-the-body-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/4NCwH-Izp2g?si=WP81F5ZEGnq0V049" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Food: {
          name: "Food",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJREXHX8g/Dqm0l_qHQnOEi6XqksUcmA/edit?utm_content=DAGJREXHX8g&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/843545312/igbo-level-1-session-8-food-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/24618Fs2-fU?si=l87o7KBRFVS--wO1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Consolidation Quiz (Sessions 5- 8)": {
          name: "Consolidation Quiz (Sessions 5- 8)",
          resources: {
            grammarSpotlight: `
            `,
            quizletSet: `https://quizlet.com/test/igbo-level-1-session-5-8-consolidation-set-new-888528619?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },

        Animals: {
          name: "Animals",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJRdKR6oY/iGSiRt8gHxNFFjaa1fIw8g/edit?utm_content=DAGJRdKR6oY&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/843546614/igbo-level-1-session-9-animals-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/tb3QSfNOHNo?si=g3juXbTv-gwmZQus" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Numbers: {
          name: "Numbers",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGNHXzG70g/47ykDHdJuf2gEA-M1LvPjA/edit?utm_content=DAGNHXzG70g&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/843547517/igbo-level-1-session-11-numbers-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/RV4OXImgTeg?si=wfLCKtg33pdA0LPa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Time: {
          name: "Time",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJRYssVes/zNKXlduwI8dob7H967aSiA/edit?utm_content=DAGJRYssVes&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/851867549/igbo-level-1-session-10-time-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/nxRDK8xHpu4?si=OctxqkeHOE8YY22M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Direction: {
          name: "Direction",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGJRZhZ5Xs/CVm5EO1oX11Dpc7nQgMCtg/edit?utm_content=DAGJRZhZ5Xs&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/851865132/igbo-level-1-session-12-directions-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/3xWX0bBGrHo?si=7MuxsbhLnTY12DO9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Consolidation Quiz (Sessions 9- 12)": {
          name: "Consolidation Quiz (Sessions 9- 12)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/test/igbo-level-1-session-9-12-consolidation-set-new-888536409?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },
      },
      Elementary: {
        Wedding: {
          name: "Wedding",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGNWlVTrIU/SXsnZ_2DmVvrkKfjzKPdcw/edit?utm_content=DAGNWlVTrIU&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet:
              "https://quizlet.com/gb/849546452/igbo-level-2-session-1-wedding-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/1tbJL9pwDh6ULWY8-39DZV7YWsOyTbHJw/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        "Home & Family": {
          name: "Home & Family",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGMlcMpf68/PdcyLzSU1JE-UN2ae4Z35Q/edit?utm_content=DAGMlcMpf68&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet:
              "https://quizlet.com/gb/849546706/igbo-level-2-session-2-home-family-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/1fMNnVzVBfSuXzkAXvDSjp8M1nlZQdPRQ/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        Family: {
          name: "Family",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/gb/849546706/igbo-level-2-session-2-home-family-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/1fMNnVzVBfSuXzkAXvDSjp8M1nlZQdPRQ/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        "Daily Activities": {
          name: "Daily Activities",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGMbOKEEj0/BV1RNhuKUB3pNuUv4ZsAHg/edit?utm_content=DAGMbOKEEj0&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet:
              "https://quizlet.com/gb/849546801/igbo-level-2-session-3-daily-activities-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/1vnc9KS7LnuvRU97KJ4ZKwV3klmlMaxE1/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        "Work & School": {
          name: "Work & School",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGMcxVDQJA/strOsfILkASzpknHZdVDWg/edit?utm_content=DAGMcxVDQJA&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet:
              "https://quizlet.com/929247952/igbo-level-2-topic-2-work-and-school-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/JZM2Gc1YDZ0?si=YYLiu4grYKOtEvzA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Health: {
          name: "Health",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGMc-mWwMs/I56hSznG3PGhTnL0cmrwaQ/edit?utm_content=DAGMc-mWwMs&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/849546857/igbo-level-2-session-4-health-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1JOtT09Rj3hx67ES7PaBr4PW2BLY0xbSP/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Consolidation Quiz (Session 1 - 4)": {
          name: "Consolidation Quiz (Session 1 - 4)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/876228901/test?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },
        Shopping: {
          name: "Shopping",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGMmH6ucHs/GqRMsfiY90j7NVdYeEM7Aw/edit?utm_content=DAGMmH6ucHs&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/858060038/igbo-level-2-session-6-restaurant-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1BYpSyeZGg1VxyKrVqsjiqd2N6Jr4vK7E/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Restaurant: {
          name: "Restaurant",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGNFnqt4h4/-6WK69Z4isIXBo8QllznWg/edit?utm_content=DAGNFnqt4h4&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/858060038/igbo-level-2-session-6-restaurant-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1SEACVy_bbop6zc1xoAl3nYaDalMgKTuP/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Travel: {
          name: "Travel",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGNG-zei-M/nU4U0WyOJUsqy2ar06aY6w/edit?utm_content=DAGNG-zei-M&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/862007670/igbo-level-2-session-7-travel-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1V5RfMhmqMtBp-G2tdwRDirb-incxnQfh/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Numbers 11 - 20": {
          name: "Numbers 11 - 20",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAF5evgjozA/j_g-W67CsbFk_LMN_0Rb8w/edit?utm_content=DAF5evgjozA&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/862007736/igbo-level-2-session-8-numbers-11-20-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1B-Bwn2esZDxygCjSfaS5516hlzmlGkfu/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Numbers 11+": {
          name: "Numbers 11+",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/862007736/igbo-level-2-session-8-numbers-11-20-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1B-Bwn2esZDxygCjSfaS5516hlzmlGkfu/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Consolidation Quiz (Session 5 - 8)": {
          name: "Consolidation Quiz (Session 5 - 8)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/876229227/test?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },

        "Expressing Emotions": {
          name: "Expressing Emotions",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGNLT8Iyz0/syBLkBVhk0JPH263T54cIA/edit?utm_content=DAGNLT8Iyz0&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/862007837/igbo-level-2-session-9-expressing-emotions-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1_CqczGCSMNxOP8R_oXP6twftunocdzrc/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Culture: {
          name: "Culture",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAF5ej3ke6w/9gw_ZS6ZyAfmWPY6LLdMDg/edit?utm_content=DAF5ej3ke6w&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/862007892/igbo-level-2-session-10-culture-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1_2H9Q5CoekKpQriy7yhvbocHBTVCOwtW/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Weather: {
          name: "Weather",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGNoxW5yTs/UOgTmWHIIkxpnjfBSLh-Dg/edit?utm_content=DAGNoxW5yTs&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/862007944/igbo-level-2-session-11-weather-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1QJCGpc9_T_krstxdSSrb3GJZ0Vn1UY9T/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Sports: {
          name: "Sports",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAF5enhu6UQ/fgcSHF_CPRqJQQwS64Ca9g/edit?utm_content=DAF5enhu6UQ&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/862007990/igbo-level-2-session-12-sports-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1-0oSfrGDrWc8UdVI56eGQ2jOlKqTSRgp/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Sports and Hobbies": {
          name: "Sports and Hobbies",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGNd8MhQFk/uAOa8PICWSlIiQPFhIk-Cw/edit?utm_content=DAGNd8MhQFk&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/862007990/igbo-level-2-session-12-sports-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1-0oSfrGDrWc8UdVI56eGQ2jOlKqTSRgp/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Consolidation Quiz (Session 9 - 12)": {
          name: "Consolidation Quiz (Session 9 - 12)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/876229402/test?answerTermSides=2&promptTermSides=4&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },
      },
      Intermediate: {
        "Describing Yourself": {
          name: "Describing Yourself",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGUeNVGlSo/B3HooFrTFqkcOqmmiMyXjA/edit?utm_content=DAGUeNVGlSo&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet:
              "https://quizlet.com/955865381/igbo-level-3-topic-1-describing-yourself-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/KgyAy4v4-bI?si=0pue_puE0YyaCR2a" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Household Chores": {
          name: "Household Chores",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGUgpY1IvY/CIgcZNeRRxColZVuRBdXkQ/edit?utm_content=DAGUgpY1IvY&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet:
              "https://quizlet.com/955839902/igbo-level-3-topic-2-household-chores-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/-SVpeR5KKdA?si=uGGmn1RlqcKwLXQm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Cooking: {
          name: "Cooking",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGUklJlooM/ayaJ380763bH73t1ACLr7A/edit?utm_content=DAGUklJlooM&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet:
              "https://quizlet.com/955878490/igbo-level-3-topic-3-cooking-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/-_pOqinzCYg?si=-jB00WprKtnFKBXV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Body and Health": {
          name: "Body and Health",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGUmzQVZxo/ywG3g1AAtnYzkA-2oH6qQQ/edit?utm_content=DAGUmzQVZxo&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet:
              "https://quizlet.com/955898278/igbo-level-3-topic-4-body-and-health-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/ah2sxT6vjxs?si=ZSwuQimWHVcMqH4A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Consolidation Quiz (Topics 1 - 4)": {
          name: "Consolidation Quiz (Topics 1 - 4)",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/962610425/test?answerTermSides=4&promptTermSides=6&questionCount=40&questionTypes=4&showImages=true",
            practiceVideo: "",
          },
        },
      },
    },
  },
};

export interface LessonResources {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          name: string;
          resources: {
            grammarSpotlight: string;
            quizletSet: string;
            practiceVideo: string;
          };
        };
      };
    };
  };
}

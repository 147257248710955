import { useNavigate, useLocation } from "react-router-dom";
import { useStateValue } from "src/context/StateProvider";

type AuthGuardProps = {
  children: any;
};

export const AuthGuard = ({ children }: AuthGuardProps) => {
  const [{ user }] = useStateValue();
  const navigate = useNavigate();
  const location = useLocation();

  if (!user) {
    navigate("/login", { state: { from: location } });
    return null;
  }

  return children;
};

import { Navigate } from "react-router-dom";
import { routes } from "src/Routes";
import { useStateValue } from "src/context/StateProvider";

type TutorGuardProps = {
  children: any;
};

export const NewTutorGuard = ({ children }: TutorGuardProps) => {
  const [{ user }] = useStateValue();
  if (user?.onboardingStatus <= 2) {
    return <Navigate to="/tutor/onboarding/step0" />;
  }
  if (user?.onboardingStatus > 2) {
    return <Navigate to={routes.TUTOR_DASHBOARD_UPCOMING_LESSONS.url} />;
  }

  return children;
};

import { XIcon, MenuIcon } from "@heroicons/react/solid";
import { useState } from "react";
import clsx from "clsx";
import { useStateValue } from "src/context/StateProvider";

import AuthButtons from "./AuthButtons";
import NavMenu from "./NavMenu";
import Logo from "./Logo";
import UserProfile from "./UserProfile";

function Header() {
  const [{ user }] = useStateValue();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <header
      className="top-0 z-50 
    bg-pale-purple-bg py-5 sticky"
    >
      <div className="container flex justify-between items-center">
        {/* left */}
        <Logo />
        {/* middle - Nav */}
        <div className="hidden lg:flex justify-center flex-1 ">
          <NavMenu />
        </div>

        {/* right */}
        {/* Sign In/Up  */}
        <div
          className={clsx({
            block: !user,
            hidden: user,
          })}
        >
          <div className="lg:flex items-center justify-end space-x-3 hidden">
            <AuthButtons />
          </div>
        </div>
        {/* Logged In Profile  */}
        <div
          className={clsx({
            block: user,
            hidden: !user,
          })}
        >
          <div className="lg:flex items-center justify-end space-x-6 hidden">
            <UserProfile />
          </div>
        </div>
        {/* Burger Icon */}
        <div
          className={clsx({
            "flex gap-2": !showMobileMenu,
            hidden: showMobileMenu,
          })}
        >
          {user && (
            <div className="cursor-pointer lg:hidden" onClick={toggleMenu}>
              <UserProfile />
            </div>
          )}
          <MenuIcon
            onClick={toggleMenu}
            className="inline-flex h-10 w-10 text-black lg:hidden cursor-pointer"
          />
        </div>
        <div
          className={clsx({
            block: showMobileMenu,
            hidden: !showMobileMenu,
          })}
        >
          <XIcon
            onClick={toggleMenu}
            className="inline-flex h-10 w-10 text-black lg:hidden cursor-pointer"
          />
        </div>
      </div>
      {/* Mobile Menu */}
      <div
        className={clsx({
          block: showMobileMenu,
          hidden: !showMobileMenu,
        })}
      >
        <div className="flex flex-col w-full px-10 bg-pale-purple-bg absolute lg:hidden  h-screen">
          <div className="my-6">
            <NavMenu />
          </div>

          {/* Sign In/Up Buttons */}
          {!user && (
            <div className="flex flex-col gap-3">
              <AuthButtons />
            </div>
          )}
          {/* Logged In profile */}
          <div
            className={clsx({
              block: user,
              hidden: !user,
            })}
          >
            {/* <div className="lg:flex items-center justify-end space-x-6 hidden">
              <div>
                <MessageIcon />
              </div>
              <div>
                <FavoriteIcon />
              </div>
              <div>
                <NotificationIcon />
              </div>
              <div className="pl-3">
                <ProfileIcon />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;

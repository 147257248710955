import clsx from "clsx";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import FixedHeaderWithoutNav from "src/components/Shared/FixedHeaderWithoutNav";
import { useDirectPayContext } from "src/context/DirectPayContext";
import { routes } from "src/Routes";
import languages from "src/utils/helper/languages";
export default function ChooseLanguage() {
  const [language, setLanguage] = useState("");
  const {
    actions: { setSubject, setRefCode, setUserData },
  } = useDirectPayContext();
  const [query, setQuery] = useState(
    new URLSearchParams(window.location.search)
  );
  useEffect(() => {
    let Uquery = query;
    Uquery.set("page", "1");
    setQuery(Uquery);
    setRefCode(query.get("refCode") || "");
    setUserData("referrerCode", query.get("refCode") || "");
  }, []);
  return (
    <div className="h-screen flex flex-col text-primary">
      <FixedHeaderWithoutNav />
      <div className="flex-grow flex flex-col justify-center items-center p-4">
        <h1 className="text-xl font-semibold">
          Choose the language you want to learn
        </h1>
        <div
          // action={`${routes.DIRECT_TO_PAY_WELCOME.url}`}
          className="w-full max-w-md space-y-4 mt-4"
        >
          {languages.map((lang, index) => (
            <div key={index}>
              <input
                type="checkbox"
                id={lang}
                name="lang"
                value={lang}
                hidden
                onChange={(e) => {
                  setLanguage(e.target.value);
                  setSubject(e.target.value);
                  let Uquery = query;
                  Uquery.set("lang", e.target.value);
                  setQuery(Uquery);
                }}
                checked={language === lang}
              />
              <label
                htmlFor={lang}
                className={`w-full cursor-pointer  border p-2 sm:p-4 rounded-md flex justify-center items-center gap-2 sm:gap-5 font-medium ${
                  language === lang && "bg-primary-pale border-primary"
                }`}
              >
                {lang.toUpperCase()}
              </label>
            </div>
          ))}
          <br />
          <NavLink
            className={clsx({
              "mx-auto bg-primary hover:bg-primary-light text-white p-2 sm:px-4 sm:py-4 rounded-md flex flex-wrap justify-center min-w-full sm:gap-5 gap-2 items-center font-medium":
                language,
              "bg-gray-200 text-white  mx-auto p-2 sm:px-4 sm:py-4 rounded-md flex flex-wrap justify-center min-w-full sm:gap-5 gap-2 items-center font-medium":
                !language,
            })}
            to={`${routes.DIRECT_TO_PAY_WELCOME.url}?${query.toString()}`}
          >
            Submit
          </NavLink>
        </div>
      </div>
    </div>
  );
}

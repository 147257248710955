export const COLUMNS = [
  {
    Header: "Order ID",
    accessor: "_id",
  },
  {
    Header: "Invoice ID",
    accessor: "invoiceId",
  },
  {
    Header: "Status",
    accessor: "state",
  },
  {
    Header: "Amount",
    accessor: "amount",
    Cell: (row: any) => {
      return Intl.NumberFormat("en-US", {
        style: "currency",
        currency: row?.value?.currency,
      }).format(row?.value?.value);
    },
  },
];

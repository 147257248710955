import Subject from "./Subject";
import Tutor from "./Tutor";

export interface ICheckoutSummary {
	firstName: string;
	lastName: string;
	amount: number;
	currency: string;
	tutors?: [];
	subjects?: [];
	promo?: {
		title: string;
	};
	selectedTutor?: {
		firstName: string;
		lastName: string;
	};
	numberOfLessons: number;
	type: string;
	subject: string;
	lessonFrequency: number;
	perLessonRate: string;
	lessonStartDate: string;
}

export default function CheckoutSummary({
	details,
}: {
	details: ICheckoutSummary;
}) {
	return (
		<div className="white-card-rounded p-4 flex-1 ">
			<>
				<p className="text-gray-400 ">Customer</p>

				<h1 className="font-semibold text-xl">
					{details?.firstName} {details?.lastName}
				</h1>

				{details?.tutors && (
					<div>
						<div className="border border-gray-300 my-2"></div>

						<h1 className="font-bold text-lg">Potential Tutors</h1>
						{details?.tutors?.map((tutor: any) => (
							<Tutor key={tutor?.tutorId} tutor={tutor} />
						))}
					</div>
				)}

				<div>
					{details?.subjects && (
						<>
							<div className="border border-gray-300 my-4"></div>

							<h1 className="font-bold text-lg">Your Order</h1>

							{details?.subjects?.map((subject: any) => (
								<Subject key={subject.subjectId} subject={subject} />
							))}
						</>
					)}

					{details?.promo && (
						<div className="flex justify-between items-center gap-4 mt-2 flex-wrap">
							<p className="flex-1">Promos:</p>

							<p className="font-semibold flex-1 text-right">
								{details?.promo && details?.promo?.title}
							</p>
						</div>
					)}
				</div>

				<div className="border border-gray-300 my-2"></div>
			</>

			<>
				<p className="text-gray-400 mt-2">Lesson Details</p>

				{details?.selectedTutor ? (
					<h1 className="font-medium text-lg mt-2">{`${details?.subject} sessions with  ${details?.selectedTutor?.firstName} ${details?.selectedTutor?.lastName}`}</h1>
				) : (
					<h1 className="font-medium text-lg mt-2">{details?.subject}</h1>
				)}

				<h1 className="font-medium text-sm">
					{details?.type ? `${details?.type},` : " "}{" "}
					{details?.numberOfLessons &&
						details?.numberOfLessons *
							(details?.type === "Monthly Package"
								? details?.lessonFrequency
								: 1)}{" "}
					Lessons
				</h1>

				{details?.lessonStartDate && (
					<h1 className="font-medium text-sm">{`First Lesson: ${details?.lessonStartDate}`}</h1>
				)}

				<div className="border  border-gray-100 my-3"></div>

				<div>
					<h1 className="text-gray-purple text-sm">Payment plan</h1>

					<div className="flex justify-between items-center gap-4 mt-2 flex-wrap">
						<p className="font-semibold text-right text-lg">
							{details?.perLessonRate}
							/Session
						</p>
					</div>
				</div>
			</>

			<div className="flex justify-between items-center text-xl font-bold">
				<h1>Total</h1>

				<p>
					{details?.currency &&
						Intl.NumberFormat("en-US", {
							style: "currency",
							currency: details?.currency,
						}).format(details?.amount)}
				</p>
			</div>
		</div>
	);
}

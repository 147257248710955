import { useState, useEffect } from "react";
import LoadingScreen from "src/components/LottieAnimations/LoadingBarScreen";
import RequestsTable from "./RequestsTable";
import EmptyList from "src/components/Shared/EmptyList";
import { PaymentApi } from "src/api/payment.api";
import { useStateValue } from "src/context/StateProvider";

interface IHistory {}
export default function History({}: IHistory) {
  const [{ user }] = useStateValue();
  const [loading, setLoading] = useState(true);
  const [payment, setPayment] = useState([]);
  useEffect(() => {
    const getPayments = async () => {
      const response = await PaymentApi.getPaymentOrder(user.email);
      setPayment(response.data.map((data: any) => data._doc));
      setLoading(false);
    };
    getPayments();
  }, []);

  if (!loading && (!payment || payment.length === 0)) {
    return <EmptyList message="No Payment Records Found" />;
  }
  return (
    <div className="mt-12">
      {loading ? <LoadingScreen /> : <RequestsTable payments={payment} />}
    </div>
  );
}

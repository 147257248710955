import { useEffect, useState } from "react";
import { ITestimonials } from "src/components/DirectToPay/VideoPlayer";
import { useDirectPayContext } from "src/context/DirectPayContext";
import {
  igboTestimonials,
  yorubaTestimonials,
} from "src/static-data/direct-to-pay/testimonials";
export default function useTestimonials() {
  const {
    state: { subject },
  } = useDirectPayContext();
  const [testimonials, setTestimonials] = useState<ITestimonials[]>([]);
  const [isVideoModalOpen, setisVideoModalOpen] = useState(false);
  const [videoDetails, setVideoDetails] = useState<ITestimonials>({
    name: "",
    videoUrl: "",
  });
  useEffect(() => {
    if (subject === "yoruba") {
      setTestimonials([...yorubaTestimonials]);
    } else {
      setTestimonials([...igboTestimonials]);
    }
  }, [subject]);

  const handlePlayClick = (videoDets: ITestimonials) => {
    setVideoDetails(videoDets);
    setisVideoModalOpen(true);
  };
  return {
    testimonials,
    isVideoModalOpen,
    setisVideoModalOpen,
    videoDetails,
    handlePlayClick,
  };
}

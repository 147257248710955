import { useEffect, useState } from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { BiChevronUp, BiChevronDown, BiBookReader } from "react-icons/bi";
import { routes } from "src/Routes";

import UpcomingLessons from "./UpcomingLessons";
import LessonRequests from "./LessonRequests";
import PastLessons from "./PastLessons";
import AllFeedback from "./AllFeedback";
import Resources from "./Resources";

export default function Lessons() {
  const location = useLocation();
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };
  useEffect(() => {
    if (
      routes.STUDENT_DASHBOARD_UPCOMING_LESSONS.url === location.pathname ||
      routes.STUDENT_DASHBOARD_PAST_LESSONS.url === location.pathname ||
      routes.STUDENT_DASHBOARD_BOOKINGS.url === location.pathname ||
      routes.STUDENT_DASHBOARD_FEEDBACK.url === location.pathname ||
      location.pathname.includes(routes.STUDENT_DASHBOARD_FEEDBACK.url)
    ) {
      setShowProfileMenu(true);
    } else {
      setShowProfileMenu(false);
    }
  }, [location.pathname]);

  return (
    <>
      <div
        className={clsx("parent-menu-inactive", {
          "parent-menu-active":
            routes.STUDENT_DASHBOARD_UPCOMING_LESSONS.url ===
              location.pathname ||
            routes.STUDENT_DASHBOARD_PAST_LESSONS.url === location.pathname ||
            routes.STUDENT_DASHBOARD_BOOKINGS.url === location.pathname ||
            routes.STUDENT_DASHBOARD_FEEDBACK.url === location.pathname ||
            location.pathname.includes(routes.STUDENT_DASHBOARD_FEEDBACK.url),
        })}
        onClick={toggleProfileMenu}
      >
        <div className="flex gap-4">
          <div>
            <BiBookReader className="text-lg" />
          </div>
          <div>Lessons</div>
        </div>
        <div>
          <div
            className={clsx({
              hidden: !showProfileMenu,
              block: showProfileMenu,
            })}
          >
            <BiChevronUp size={23} />
          </div>
          <div
            className={clsx({
              block: !showProfileMenu,
              hidden: showProfileMenu,
            })}
          >
            <BiChevronDown size={23} />
          </div>
        </div>
      </div>
      {/* Sub Menu */}
      <div
        className={clsx({
          hidden: !showProfileMenu,
          "block bg-gray-50 rounded-lg py-4 mb-2": showProfileMenu,
        })}
      >
        <ul>
          <li>
            <UpcomingLessons />
          </li>
          <li>
            <PastLessons />
          </li>
          <li>
            <LessonRequests />
          </li>
          {/* <li>
            <Resources />
          </li> */}
          <li>
            <AllFeedback />
          </li>
        </ul>
      </div>
    </>
  );
}

import { useContext, useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import LoadingScreen from "src/components/LottieAnimations/LoadingBarScreen";
import { IClientCurrencyData } from "src/interfaces/currency";
import { useStateValue } from "src/context/StateProvider";

import { TutorApi } from "src/api/tutor.api";
import { routes } from "src/Routes";
import React from "react";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import Header from "src/layouts/FixedDashboardHeader";
import PreppingDashboard from "src/components/LottieAnimations/PreppingDashboard";
import NewAvailability from "src/components/NewAvailability";

export default function NewCalendarView() {
  const [tutorProfile, setTutorProfile] = useState<any>();

  const {
    state: {
      isLoading,

      lessonFrequency,
      lessonSchedule,
    },
    actions: {
      setLessonFrequency,
      setSelectedLessonScheduleId,
      setLessonSchedule,
    },
  } = useContext(DirectPayContext);

  const { tutorId } = useParams();
  const [{ user, clientIpCountry }] = useStateValue();
  const [tutorAvailability, setTutorAvailability] = React.useState<any>([]);

  const [tutorBookings, setTutorBookings] = React.useState<any>([]);

  const bookLessonRef = useRef(null);

  const scrollToBookLesson = () => {
    if (bookLessonRef.current) {
      const topPosition =
        (bookLessonRef.current as HTMLDivElement).getBoundingClientRect().top +
        window.pageYOffset;
      const offset = 100; // Adjust this value to whatever offset you need

      // Scroll to the bio section with the offset
      window.scrollTo({
        top: topPosition - offset, // Subtract the offset to scroll higher
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const getTutorProfile = async () => {
      const response = await TutorApi.getTutorProfileDetails('65eddda2e7a70f0003f6ea09');

      const res = await TutorApi.getTutorAvailabilityAndBookings('65eddda2e7a70f0003f6ea09');
      setTutorAvailability(res.tutorAvailability);
      setTutorBookings(res.tutorBookings);

      setTutorProfile(response);
    };
    getTutorProfile();
  }, [clientIpCountry, tutorId]);

  if (!user) {
    return <Navigate to={routes.LOGIN.url} />;
  }

  if (!tutorProfile || !tutorBookings) {
    // SKELETON LOADER GOES HERE
    return <LoadingScreen />;
  }

  if (isLoading) {
    return <PreppingDashboard />;
  }
  //proficiencyLevel
  return (
    <>
      <div>
        <Header />

        <div className="mt-2 pl-2">
          <NewAvailability
            tutorAvailability={tutorAvailability}
            tutorBookings={tutorBookings}
            scrollToBookLesson={scrollToBookLesson}
          />
        </div>
      </div>
    </>
  );
}

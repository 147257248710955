import { toast } from "react-toastify";
import {
  personality,
  preferences,
  useDirectPayContext,
} from "src/context/DirectPayContext";
import useHandleSignup from "src/hooks/useHandleSignup";
import { isValidEmail } from "src/services/shared";

interface IValidator {
  pageIndex: number;
  setPageIndex: (value: React.SetStateAction<number>) => void;
  formNum: number;
  setFormNum: (value: React.SetStateAction<number>) => void;
}
export default function useValidator({
  pageIndex,
  setPageIndex,
  formNum,
  setFormNum,
}: IValidator) {
  const {
    state: {
      whoLessonIsFor,
      ageGroup,
      reasonsForLearning,
      wordsThatDescribe,
      proficiencyLevel,
      subject,
      userData,
      numberOfUsers,
      selectedDialect,
    },
    actions: { setError, setDialect },
  } = useDirectPayContext();

  const { handleSignup } = useHandleSignup();

  const handleFormCancel = () => {
    if (pageIndex > 0) {
      setPageIndex((pageIndex) => pageIndex - 1);
      window.history.pushState({ pageIndex: pageIndex - 1 }, "", "");
    }
    switch (subject) {
      case "igbo":
        if (pageIndex === 6) {
          if (reasonsForLearning.includes("Other")) {
            setFormNum(formNum - 1);
          }
        }
        if (pageIndex === 5) {
          if (selectedDialect.type === "Central Igbo") {
            setPageIndex(3);
          }
        }
        break;
      case "yoruba":
        if (pageIndex === 4) {
          if (reasonsForLearning.includes("Other")) {
            setFormNum(formNum - 1);
          }
        }

        break;
      default:
        break;
    }
  };
  const handleNextPage = () => {
    setPageIndex((pageIndex) => pageIndex + 1);
    window.history.pushState({ pageIndex: pageIndex + 1 }, "", "");
    window.scrollTo(0, 100);
  };

  const validateWhoLessonIsFor = () => {
    if (!whoLessonIsFor) {
      toast.error("Please indicate whether you are a learner or not.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    return true;
  };

  const validateAgeGroup = () => {
    if (!ageGroup) {
      toast.error("Please select an age group.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    return true;
  };

  const validateReasonsForLearning = () => {
    if (reasonsForLearning.length === 0) {
      toast.error("Please select a reason for learning.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    return true;
  };

  const validatePersonality = () => {
    if (!personality.find((p) => wordsThatDescribe.includes(p.value))) {
      toast.error(
        numberOfUsers > 1
          ? "Please select your group's social personality type."
          : "Please select your social personality type.",
        { position: toast.POSITION.BOTTOM_RIGHT }
      );
      return false;
    }
    return true;
  };

  const validatePreferences = () => {
    if (
      wordsThatDescribe.filter((word) =>
        preferences.some((p) => p.value === word)
      ).length < 3
    ) {
      toast.error(
        numberOfUsers > 1
          ? "Please select 3 words that best describes your group."
          : "Please select 3 words that best describes you.",
        { position: toast.POSITION.BOTTOM_RIGHT }
      );
      return false;
    }
    return true;
  };

  const validateProficiencyLevel = () => {
    if (!proficiencyLevel) {
      toast.error("Please select a proficiency level.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    return true;
  };

  const validateUserData = () => {
    const errors = [];
    if (!userData?.email) {
      errors.push({
        message: "Please enter your email address.",
        field: "email",
      });
    } else if (!isValidEmail(userData?.email || "")) {
      errors.push({
        message: "Please enter a valid email address.",
        field: "email",
      });
    }
    if (!userData?.firstname) {
      errors.push({
        field: "firstname",
        message: "Please enter your first name.",
      });
    }
    if (!userData?.lastname) {
      errors.push({
        field: "lastname",
        message: "Please enter your last name.",
      });
    }
    if (!userData?.phone_number) {
      errors.push({
        field: "phone_number",
        message: "Please enter your phone number.",
      });
    }
    if (errors.length > 0) {
      setError({ field: "userData", errors });
      return false;
    }
    return true;
  };

  // Subject-specific validation functions
  const igboValidation = {
    3: () => {
      if (selectedDialect.type === "Central Igbo") {
        setPageIndex(4);
      }
      return true;
    }, // No specific validation for Igbo on page 3
    4: () => {
      if (!selectedDialect.match) {
        setDialect({ type: "", match: "", state: "", towns: [""] });
      }
      return true;
    }, // No specific validation for Igbo on page 3
    5: () => {
      if (!validateReasonsForLearning()) return false;
      if (reasonsForLearning.includes("Other")) {
        setFormNum((prev) => prev + 1);
      }
      return true;
    },
    6: () => {
      if (reasonsForLearning.includes("Other")) {
        return true;
      }
      return validatePersonality();
    },
    7: () => {
      if (reasonsForLearning.includes("Other")) {
        return validatePersonality();
      }
      return validatePreferences();
    },
    8: () => {
      if (reasonsForLearning.includes("Other")) {
        return validatePreferences();
      }
      return validateProficiencyLevel();
    },
    9: () => validateProficiencyLevel(),
  };

  const yorubaValidation = {
    3: () => {
      if (reasonsForLearning.includes("Other")) {
        setFormNum((prev) => prev + 1);
        return true;
      }
      return validateReasonsForLearning();
    },
    4: () => {
      if (reasonsForLearning.includes("Other")) {
        return true;
      }
      return validatePersonality();
    },
    5: () => {
      if (reasonsForLearning.includes("Other")) {
        return validatePersonality();
      }
      return validatePreferences();
    },
    6: () => {
      if (reasonsForLearning.includes("Other")) {
        return validatePreferences();
      }
      return validateProficiencyLevel();
    },
    7: () => {
      if (reasonsForLearning.includes("Other")) {
        return validatePreferences();
      }
      return true;
    },
  };
  const handleValidator = () => {
    setError({ field: null, errors: [] });

    const commonValidation = {
      1: validateWhoLessonIsFor,
      2: validateAgeGroup,
    };

    const subjectValidation =
      subject === "igbo" ? igboValidation : yorubaValidation;

    const validationMap = {
      ...commonValidation,
      ...subjectValidation,
    };

    if (pageIndex in validationMap) {
      if (!validationMap[pageIndex as keyof typeof validationMap]()) {
        return;
      }
    }

    if (pageIndex === formNum) {
      if (validateUserData()) {
        handleSignup();
      }
      return;
    }

    handleNextPage();
  };
  return { handleValidator, handleSignup, handleNextPage, handleFormCancel };
}

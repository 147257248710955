import React from "react";
import { VirtualImmersionsProps } from "../../types";
import { FiUsers } from "react-icons/fi";
import { FaRegCalendarAlt, FaRegClock } from "react-icons/fa";
import moment from "moment-timezone";
import FocusAreas from "./FocusAreas";

export default function SessionCard({
  session,
  setVirtualImmersionSession,
  setIsConfirmationModalOpen,
  enrolledLessons,
}: {
  session: VirtualImmersionsProps;
  setVirtualImmersionSession: any;
  setIsConfirmationModalOpen: any;
  enrolledLessons: any;
}) {
  const handleJoinSession = () => {
    setVirtualImmersionSession(session);
    setIsConfirmationModalOpen(true);
  };

  // check if user is already enrolled in the session
  const isEnrolled = enrolledLessons.some(
    (lesson: any) => lesson.groupLessonId == session._id
  );

  // check if space is available
  const isSpaceAvailable =
    session.maxStudents && session.enrolledStudents < session.maxStudents;

  if (!session) return null;

  return (
    <div className="white-card-rounded sm:w-[31rem] p-4">
      <div className="flex sm:flex-row flex-col gap-2">
        {/* Left Side */}
        <div className="">
          <img
            src={session.lessonImageUrl}
            alt={session.title}
            className="rounded-xl w-[12rem] h-[10rem] object-cover"
          />
        </div>
        {/* Right Side */}
        <div className="flex-1 pl-2">
          <p className="text-primary font-semibold text-lg">{session.title}</p>
          <p className="text-primary font-semibold text-xs">
            with <span className="text-secondary">{session.tutorName}</span>
          </p>
          <p className="text-primary mt-2 text-xs">
            {session.subjectLevel} level
          </p>
          <div className="text-primary mt-2 text-xs flex gap-2 align-center">
            <div className="mt-1">
              <FiUsers />
            </div>
            <div
              className={isSpaceAvailable ? "text-green-500" : "text-red-500"}
            >
              {isSpaceAvailable
                ? `${session.enrolledStudents ? session.enrolledStudents : 0}/${
                    session.maxStudents ? session.maxStudents : 6
                  } students`
                : "Session Full"}
            </div>
          </div>
          <div className="text-primary mt-2 text-xs flex gap-2 align-center">
            <div className="mt-1">
              <FaRegCalendarAlt />
            </div>
            <div>
              {moment(session.lessonDate).format("ddd, MMMM D, YYYY, hA z")}
            </div>
          </div>
          <div className="text-primary mt-2 text-xs flex gap-2 align-center">
            <div className="mt-1">
              <FaRegClock />
            </div>
            <div>{session.lessonSchedule.duration.label}</div>
          </div>
        </div>
      </div>
      {/* Bottom side */}
      <div className="mt-2">
        <p className="text-primary text-xs">{session.description}</p>
      </div>
      {/* Focus Areas */}
      <div className="mt-2">
        <FocusAreas areas={session.areasOfFocus.map((area) => area.label)} />
      </div>
      {/* Button */}
      <div className="mt-4">
        <button
          disabled={isEnrolled || !isSpaceAvailable}
          className={
            isEnrolled || !isSpaceAvailable
              ? "disabled-button font-bold w-full"
              : "purple-button font-bold w-full"
          }
          onClick={handleJoinSession}
        >
          {isEnrolled
            ? "Enrolled"
            : !isSpaceAvailable
            ? "Session Full"
            : "Join Session"}
        </button>
      </div>
    </div>
  );
}

import React, { Dispatch, useContext } from "react";
import { createDataContext } from "./createDataContext";
import { AuthApi } from "src/api/auth.api";
import {
  LearningPlan,
  Onboarding,
  LessonSchedule,
  Tutor,
  User,
  UserData,
  UserRole,
  LeadFunnel,
  PaymentStatus,
} from "src/interfaces/direct-pay";
import { OnBoardingApi } from "src/api/onboarding.api";
import { LessonApi } from "src/api/lesson.api";
import { IDialects } from "src/components/DirectToPay/CustomerInfo/DialectForm";

export const dialects = [
  { value: "ABIA_DIALECT", label: "Abia State" },
  { value: "ANAMBRA_DIALECT", label: "Anambra State" },
  { value: "CENTRAL_IGBO", label: "Central Igbo" },
  { value: "DELTA_IGBO_DIALECT", label: "Delta State" },
  { value: "EBONYI_DIALECT", label: "Ebonyi State" },
  { value: "ENUGU_DIALECT", label: "Enugu State" },
  { value: "IMO_DIALECT", label: "Imo State" },
];

export const personality = [
  { value: "Introverted", label: "Introvert" },
  { value: "Ambiverted", label: "Ambivert" },
  { value: "Extroverted", label: "Extrovert" },
];

export const ageGroups = [
  { label: "Child (5-12)", value: "children(<13)" },
  { label: "Teen (13-17)", value: "teens" },
  { label: "Adult (18 and Above)", value: "adults" },
];
export const proficiency = [
  {
    value: "Beginner level",
    label: "Beginner",
  },
  {
    value: "Intermediate or Above",
    label: "Intermediate",
  },
];

export const preferences = [
  {
    value: "Intellectually_Curious",
    label: "Intellectually Curious",
    category: ["adults", "teens"],
  },
  {
    value: "Well_traveled",
    label: "Well-traveled",
    category: ["adults", "teens"],
  },
  {
    value: "Articulate",
    label: "Articulate",
    category: ["adults", "teens", "children(<13)"],
  },
  { value: "Analytical", label: "Analytical", category: ["adults", "teens"] },
  {
    value: "Cerebral",
    label: "Cerebral",
    category: ["adults", "teens", "children(<13)"],
  },
  { value: "Cultured", label: "Cultured", category: ["adults", "teens"] },
  {
    value: "Sophisticated",
    label: "Sophisticated",
    category: ["adults", "teens"],
  },
  {
    value: "Down_to_earth",
    label: "Down-to-earth",
    category: ["adults", "teens"],
  },
  { value: "Grounded", label: "Grounded", category: ["adults", "teens"] },
  { value: "Resilient", label: "Resilient", category: ["adults", "teens"] },
  { value: "Resourceful", label: "Resourceful", category: ["adults", "teens"] },
  {
    value: "Self_sufficient",
    label: "Self-sufficient",
    category: ["adults", "teens"],
  },
  { value: "Industrious", label: "Industrious", category: ["adults", "teens"] },
  {
    value: "Unpretentious",
    label: "Unpretentious",
    category: ["adults", "teens"],
  },
  { value: "Curious", label: "Curious", category: ["children(<13)"] },
  { value: "Inquisitive", label: "Inquisitive", category: ["children(<13)"] },
  { value: "Capable", label: "Capable", category: ["children(<13)"] },
  { value: "Creative", label: "Creative", category: ["children(<13)"] },
  { value: "Witty", label: "Witty", category: ["children(<13)"] },
  { value: "Smart", label: "Smart", category: ["children(<13)"] },
  { value: "Respectful", label: "Respectful", category: ["children(<13)"] },
  { value: "Obedient", label: "Obedient", category: ["children(<13)"] },
  { value: "Tactile", label: "Tactile", category: ["children(<13)"] },
  { value: "Cheeky", label: "Cheeky", category: ["children(<13)"] },
  { value: "Funny", label: "Funny", category: ["children(<13)"] },
  { value: "Trustworthy", label: "Trustworthy", category: ["children(<13)"] },
];

export interface OnBoardingPayload {
  user?: User;
  onboarding?: Onboarding;
  tutors?: Tutor[];
  status?: string;
  paymentStatus?: PaymentStatus;
  selectedLessonSchedule?: LessonSchedule[];
  selectedTutor?: Tutor;
  learningPackage?: LearningPlan;
  lessonSchedule?: LessonSchedule[];
  lessonFrequency?: number;
}

type ErrorState = {
  field: keyof DirectPayState | null;
  errors: { message: string; field: string }[];
};
// interface IDialect {
//   value: string;
//   label: string;
// }
export interface DirectPayState {
  numberOfUsers: number;
  whoLessonIsFor: UserRole;
  ageGroup: string;
  reasonsForLearning: string[];
  otherReason: string;
  wordsThatDescribe: string[];
  personality: string;
  source: string;
  proficiencyLevel: string;
  userData: UserData;
  selectedDialect: IDialects;
  dialect: IDialects | string;
  subject: string;
  isSigningup: boolean;
  directPayUser: boolean;
  onBoarding: OnBoardingPayload;
  lessonSchedule: LessonSchedule[];
  isLoading: boolean;
  leadFunnel: LeadFunnel;
  adminBio: string[];
  lessonFrequency: number;
  selectedLessonScheduleId: number;
  tutorBioCategories: string[];
  isBookingMoreLessons: boolean;
  referrerCode: string;
  error?: ErrorState;
}

type DirectPayAction =
  | { type: "add_learner" }
  | { type: "remove_learner" }
  | { type: "set_user_role"; payload: UserRole }
  | { type: "set_age_group"; payload: string }
  | { type: "set_reason_for_learing"; payload: string }
  | { type: "set_reason_for_joining"; payload: string }
  | { type: "set_traits"; payload: string }
  | { type: "set_lesson_frequency"; payload: number }
  | { type: "set_selected_lesson_schedule_id"; payload: number }
  | { type: "set_personality"; payload: string }
  | { type: "set_subject"; payload: string }
  | { type: "set_referrer_code"; payload: string }
  | { type: "set_proficiency"; payload: string }
  | { type: "set_dialect"; payload: IDialects }
  | { type: "sign_up"; payload: any }
  | { type: "set_direct_pay_user"; payload: boolean }
  | { type: "set_onboarding_data"; payload: OnBoardingPayload }
  | { type: "signing_up"; payload: boolean }
  | { type: "set_is_loading"; payload: boolean }
  | { type: "set_is_booking_more_lessons"; payload: boolean }
  | { type: "set_lead_funnel"; payload: LeadFunnel }
  | { type: "set_lesson_schedule"; payload: LessonSchedule[] }
  | { type: "set_user_data"; payload: any }
  | { type: "set_error"; payload: ErrorState }
  | { type: "set_admin_bio"; payload: string[] }
  | { type: "set_tutor_bio_categories"; payload: string[] };

const directPayReducer: React.Reducer<DirectPayState, DirectPayAction> = (
  state,
  action
) => {
  switch (action.type) {
    case "add_learner":
      return { ...state, numberOfUsers: state.numberOfUsers + 1 };
    case "remove_learner":
      return { ...state, numberOfUsers: state.numberOfUsers - 1 };
    case "set_user_role":
      return { ...state, whoLessonIsFor: action.payload };
    case "set_lesson_schedule":
      return { ...state, lessonSchedule: action.payload };
    case "set_selected_lesson_schedule_id":
      return { ...state, selectedLessonScheduleId: action.payload };
    case "set_lesson_frequency":
      return { ...state, lessonFrequency: action.payload };
    case "set_lead_funnel":
      return { ...state, leadFunnel: action.payload };
    case "set_is_booking_more_lessons":
      return { ...state, isBookingMoreLessons: action.payload };
    case "set_age_group":
      return { ...state, ageGroup: action.payload };
    case "set_subject":
      return { ...state, subject: action.payload };
    case "set_referrer_code":
      return { ...state, referrerCode: action.payload };
    case "set_reason_for_learing":
      return {
        ...state,
        reasonsForLearning: [action.payload],
      };
    case "set_reason_for_joining":
      return { ...state, otherReason: action.payload };
    case "set_traits":
      return {
        ...state,
        wordsThatDescribe: state.wordsThatDescribe.includes(action.payload)
          ? state.wordsThatDescribe.filter(
              (reason) => reason !== action.payload
            )
          : personality.find((p) =>
              state.wordsThatDescribe.includes(p.value)
            ) && personality.some((p) => p.value === action.payload)
          ? [
              ...state.wordsThatDescribe.filter(
                (reason) => !personality.some((p) => p.value === reason)
              ),
              action.payload,
            ]
          : [...state.wordsThatDescribe, action.payload],
      };
    case "set_personality":
      return {
        ...state,
        personality: state.personality === action.payload ? "" : action.payload,
      };
    case "set_proficiency":
      return { ...state, proficiencyLevel: action.payload };
    case "sign_up":
      return {
        ...state,
      };
    case "set_direct_pay_user":
      return {
        ...state,
        directPayUser: action.payload,
      };
    case "signing_up":
      return {
        ...state,
        isSigningup: action.payload,
      };
    case "set_is_loading":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "set_onboarding_data":
      return {
        ...state,
        onBoarding: { ...state.onBoarding, ...action.payload },
      };
    case "set_dialect":
      return {
        ...state,
        selectedDialect: action.payload,
      };
    case "set_admin_bio":
      return {
        ...state,
        adminBio: action.payload,
      };
    case "set_tutor_bio_categories":
      return {
        ...state,
        tutorBioCategories: action.payload,
      };
    case "set_user_data":
      return {
        ...state,
        userData: {
          ...state.userData,
          [action.payload.field]: action.payload.value,
        },
      };
    case "set_error":
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

const actionCreators = {
  addLearner: (dispatch: Dispatch<DirectPayAction>) => () => {
    dispatch({ type: "add_learner" });
  },
  setUserData:
    (dispatch: Dispatch<DirectPayAction>) =>
    (field: keyof UserData, value: any) => {
      dispatch({ type: "set_user_data", payload: { field, value } });
    },
  setError: (dispatch: Dispatch<DirectPayAction>) => (payload: ErrorState) => {
    dispatch({ type: "set_error", payload });
  },
  setLeadFunnel:
    (dispatch: Dispatch<DirectPayAction>) => (value: LeadFunnel) => {
      dispatch({ type: "set_lead_funnel", payload: value });
    },
  removeLearner: (dispatch: Dispatch<DirectPayAction>) => () => {
    dispatch({ type: "remove_learner" });
  },
  setLessonFrequency:
    (dispatch: Dispatch<DirectPayAction>) => (value: number) => {
      dispatch({ type: "set_lesson_frequency", payload: value });
    },
  setUserRole: (dispatch: Dispatch<DirectPayAction>) => (role: UserRole) => {
    dispatch({ type: "set_user_role", payload: role });
  },
  setSubject: (dispatch: Dispatch<DirectPayAction>) => (subject: string) => {
    dispatch({ type: "set_subject", payload: subject });
  },
  setRefCode: (dispatch: Dispatch<DirectPayAction>) => (refCode: string) => {
    dispatch({ type: "set_referrer_code", payload: refCode });
  },
  setLessonSchedule:
    (dispatch: Dispatch<DirectPayAction>) =>
    (lessonSchedule: LessonSchedule[]) => {
      dispatch({ type: "set_lesson_schedule", payload: lessonSchedule });
    },
  setSelectedLessonScheduleId:
    (dispatch: Dispatch<DirectPayAction>) => (id: number) => {
      dispatch({ type: "set_selected_lesson_schedule_id", payload: id });
    },
  setAdminBio: (dispatch: Dispatch<DirectPayAction>) => (text: string[]) => {
    dispatch({ type: "set_admin_bio", payload: text });
  },
  setTutorBioCategories:
    (dispatch: Dispatch<DirectPayAction>) => (text: string[]) => {
      dispatch({ type: "set_tutor_bio_categories", payload: text });
    },
  setAgeGroup: (dispatch: Dispatch<DirectPayAction>) => (group: string) => {
    dispatch({ type: "set_age_group", payload: group });
  },
  setPrimaryReason: (dispatch: Dispatch<DirectPayAction>) => (text: string) => {
    dispatch({ type: "set_reason_for_learing", payload: text });
  },
  setOtherReason: (dispatch: Dispatch<DirectPayAction>) => (text: string) => {
    dispatch({ type: "set_reason_for_joining", payload: text });
  },
  setTrait: (dispatch: Dispatch<DirectPayAction>) => (text: string) => {
    dispatch({ type: "set_traits", payload: text });
  },
  setPersonality: (dispatch: Dispatch<DirectPayAction>) => (text: string) => {
    dispatch({ type: "set_personality", payload: text });
  },
  setProficieny: (dispatch: Dispatch<DirectPayAction>) => (text: string) => {
    dispatch({ type: "set_proficiency", payload: text });
  },
  setSigningup: (dispatch: Dispatch<DirectPayAction>) => (value: boolean) => {
    dispatch({ type: "signing_up", payload: value });
  },
  setIsBookingMoreLessons:
    (dispatch: Dispatch<DirectPayAction>) => (value: boolean) => {
      dispatch({ type: "set_is_booking_more_lessons", payload: value });
    },
  setIsLoading: (dispatch: Dispatch<DirectPayAction>) => (value: boolean) => {
    dispatch({ type: "set_is_loading", payload: value });
  },
  setDirectPayUser:
    (dispatch: Dispatch<DirectPayAction>) => (value: boolean) => {
      dispatch({ type: "set_direct_pay_user", payload: value });
    },
  signup:
    (dispatch: Dispatch<DirectPayAction>) => async (body: DirectPayState) => {
      try {
        dispatch({ type: "set_is_loading", payload: true });

        let p: any = body.wordsThatDescribe
          .find((word) => personality.find((pe) => pe.value === word))
          ?.toUpperCase();
        let payload: DirectPayState = {
          ...body,
          wordsThatDescribe: body.wordsThatDescribe
            .filter((word) => !personality.some((p) => p.value === word))
            .map((word) => word.toUpperCase()),
          personality: p,
          dialect: body.selectedDialect,
        };
        const response = await AuthApi.directPaySignup(payload);
        dispatch({
          type: "set_onboarding_data",
          payload: response.data,
        });
        dispatch({ type: "set_is_loading", payload: false });

        return response;
      } catch (error) {
        dispatch({ type: "set_is_loading", payload: false });

        throw error;
      }
    },
  getOnboarding:
    (dispatch: Dispatch<DirectPayAction>) => async (userId: string) => {
      try {
        //when the api is ready
        const response = await OnBoardingApi.getOnBoardingDetails(userId);
        let payload = {
          onboarding: response?.data,
          selectedTutor: response?.data?.selectedTutor,
          tutors: response?.data?.tutors,
          status: response?.data?.status,
          lessonSchedule: response?.data?.lessonSchedule,
        };
        if (payload.lessonSchedule) {
          dispatch({
            type: "set_lesson_schedule",
            payload: payload.lessonSchedule,
          });
        }
        if (payload.onboarding?.lessonFrequency) {
          dispatch({
            type: "set_lesson_frequency",
            payload: payload.onboarding?.lessonFrequency,
          });
        }
        if (payload.onboarding?.leadFunnel) {
          dispatch({
            type: "set_lead_funnel",
            payload: payload.onboarding?.leadFunnel,
          });
        }

        dispatch({
          type: "set_onboarding_data",
          payload,
        });
        return response;
      } catch (error) {
        throw error;
      }
    },
  updateOnboarding:
    (dispatch: Dispatch<DirectPayAction>) =>
    async (body: any, onboardingId?: string, provider?: string) => {
      try {
        dispatch({ type: "set_is_loading", payload: true });
        let payload: any = body;
        if (Object.keys(body)[0] === "onboarding") {
          if (provider === "google") {
            dispatch({
              type: "set_onboarding_data",
              payload: payload,
            });
            dispatch({ type: "set_is_loading", payload: false });

            return;
          }
          payload = {
            ageGroup: body?.onboarding?.ageGroup,
            otherReason: body?.onboarding?.otherReason,
            wordsThatDescribe: body?.onboarding?.wordsThatDescribe,
            numberOfUsers: body?.onboarding?.numberOfUsers,
            whoLessonIsFor: body?.onboarding?.whoLessonIsFor,
            reasonsForLearning: body?.onboarding?.reasonsForLearning,
            proficiencyLevel: body?.onboarding?.proficiencyLevel,
            subject: body?.onboarding?.subject,
            dialect: body?.onboarding?.dialect,
            personality: body?.onboarding?.personality,
            leadFunnel: body?.onboarding?.leadFunnel,
          };

          let p: any = body?.onboarding?.wordsThatDescribe
            ?.find((word: any) => personality.find((pe) => pe.value === word))
            ?.toUpperCase();
          payload = {
            ...payload,
            wordsThatDescribe: payload.wordsThatDescribe
              .filter((word: any) => !personality.some((p) => p.value === word))
              .map((word: any) => word.toUpperCase()),
            personality: p,
          };

          const response = await OnBoardingApi.updateOnBoardingDetails(
            payload,
            onboardingId
          );
          payload = response.data;
          dispatch({
            type: "set_onboarding_data",
            payload: { onboarding: payload, ...payload },
          });
        } else if (Object.keys(body)[0] === "learningPackage") {
          await OnBoardingApi.updateOnBoardingDetails(body, onboardingId);
          dispatch({
            type: "set_onboarding_data",
            payload: payload,
          });
        } else if (Object.keys(body)[0] === "selectedTutor") {
          OnBoardingApi.updateOnBoardingDetails(body, onboardingId);
          dispatch({
            type: "set_onboarding_data",
            payload: payload,
          });
        } else if (Object.keys(body)[0] === "lessonSchedule") {
          OnBoardingApi.updateOnBoardingDetails(body, onboardingId);
          dispatch({
            type: "set_onboarding_data",
            payload: payload,
          });
        } else {
          dispatch({
            type: "set_onboarding_data",
            payload: payload,
          });
        }
        dispatch({ type: "set_is_loading", payload: false });

        // return response;
      } catch (error) {
        dispatch({ type: "set_is_loading", payload: false });

        throw error;
      }
    },
  setDialect:
    (dispatch: Dispatch<DirectPayAction>) => async (value: IDialects) => {
      dispatch({ type: "set_dialect", payload: value });
    },
  bookLessons:
    (dispatch: Dispatch<DirectPayAction>) =>
    async (subscriptionId: string, body: OnBoardingPayload, rec?: any) => {
      dispatch({ type: "set_is_loading", payload: true });
      try {
        let s;
        if (body!.onboarding?.subject) {
          s =
            body!.onboarding.subject[0].toUpperCase() +
            body!.onboarding.subject.slice(1);
        }
        let learningPackage =
          body?.learningPackage || body?.onboarding?.learningPackage;
        const response = await OnBoardingApi.updateOnBoardingDetails(
          { status: "COMPLETE", paymentStatus: "COMPLETE" },
          body!.onboarding?._id
        );
        dispatch({
          type: "set_onboarding_data",
          payload: { onboarding: response.data, ...response.data },
        });
        await LessonApi.autoBookLesson({
          subscriptionId,
          lessonPrice: {
            currencyCode: learningPackage?.currencyCode,
            value: learningPackage?.perLessonRate.slice(1),
          },
          recurringDates: body!.selectedLessonSchedule || rec,
          studentId: body!.onboarding?.userId,
          tutorId: body!.selectedTutor?.id,
          subject: s,
          selectedLevelOption: {
            value:
              body!.onboarding?.ageGroup === "children(<13)"
                ? "Child Level"
                : "Adult Level",
            label:
              body!.onboarding?.ageGroup === "children(<13)"
                ? "Child Level"
                : "Adult Level",
          },
        });
        dispatch({ type: "set_is_loading", payload: false });
      } catch (error) {
        dispatch({ type: "set_is_loading", payload: false });
        throw error;
      }
      // dispatch({ type: "book_lessons", payload: value });
    },
} as const;

const initialState: DirectPayState = {
  numberOfUsers: 1,
  whoLessonIsFor: null,
  ageGroup: "",
  reasonsForLearning: [],
  otherReason: "",
  wordsThatDescribe: [],
  proficiencyLevel: "",
  userData: {
    firstname: "",
    lastname: "",
    phone_number: "",
    email: "",
    howYouHeard: "",
    locationInfo: "",
    password: "",
    referralCode: "",
    role: "student",
    timeZone: "",
    welcomeEmailUrl: "",
    leadFunnel: null,
  },
  lessonSchedule: [{}],
  subject:
    new URLSearchParams(window.location.search).get("lang")?.toLowerCase() ||
    "",
  source:
    new URLSearchParams(window.location.search).get("source")?.toLowerCase() ||
    "",
  referrerCode:
    new URLSearchParams(window.location.search).get("refCode")?.toLowerCase() ||
    "",
  isSigningup: false,
  directPayUser: false,
  onBoarding: {},
  selectedDialect: {
    towns: [],
    type: "",
    match: "",
    state: "",
  },
  dialect: "",
  personality: "",
  isLoading: false,
  leadFunnel: null,
  adminBio: [],
  tutorBioCategories: [],
  lessonFrequency: 1,
  selectedLessonScheduleId: 0,
  isBookingMoreLessons: false,
};
// Type to infer the return type of a curried dispatch function
type InferDispatchFunction<T> = T extends (dispatch: any) => infer R
  ? R
  : never;

// Type to get all function types from an object of dispatch creators
type InferDispatchFunctions<T> = {
  [K in keyof T]: T[K] extends Function ? InferDispatchFunction<T[K]> : never;
};

type DirectPayFunctions = InferDispatchFunctions<typeof actionCreators>;

export const { Context, Provider } = createDataContext<
  DirectPayState,
  DirectPayAction,
  DirectPayFunctions
>({
  reducer: directPayReducer,
  actions: actionCreators,
  initialState,
});

export const useDirectPayContext = () => {
  return useContext(Context);
};

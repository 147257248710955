import { motion } from "framer-motion";
import { TypingText } from "src/components/Shared/CustomTexts";
import styles from "src/styles";
import { staggerContainer } from "src/utils/motion";
import { reasons } from "./reasons";
import ReasonCard from "src/components/Home/TheTopsetTreatment/ReasonCard";

export default function Yoruba() {
  return (
    <div className="container ">
      <motion.div
        // @ts-ignore
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto ${styles.flexCenter} flex-col`}
      >
        <div className="text-primary font-bold text-2xl lg:text-3xl px-4 mt-2 flex gap-2 text-center">
          We're Different From Every Other Language Service
        </div>
      </motion.div>
      {/* cards */}

      <motion.div
        // @ts-ignore
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto flex flex-col flex-1`}
      >
        <div className="mt-[50px] grid  grid-cols-1 md:grid-cols-3 gap-[30px]">
          {reasons.map((item, index) => (
            <ReasonCard key={`reason-${index}`} {...item} index={index + 1} />
          ))}
        </div>
      </motion.div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import WordBank from "./Wordbank";
import SentenceArea from "./SentenceArea";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import clsx from "clsx";

toast.configure();

export default function SentenceBuilder({ question, handleSubmit }: any) {
  const [sentence, setSentence] = useState<any[]>([]);
  const [targetSentences, setTargetSentences] = useState<string[]>([]);
  const [sourceSentence, setSourceSentence] = useState<string>();
  const [wordBank, setWordBank] = useState<any[]>([]);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(true);

  useEffect(() => {
    if (!question) return;
    setTargetSentences(question.targetSentences);
    setSourceSentence(question.sourceSentence);
    setWordBank(question.wordBank);
    setSentence([]);
  }, [question]);

  useEffect(() => {
    if (sentence.length === 0) {
      setIsSubmitButtonDisabled(true);
      return;
    } else {
      setIsSubmitButtonDisabled(false);
    }
  }, [sentence]);

  const handleWordBankUpdate = (word: any, add: boolean) => {
    if (add) {
      // Add the word back to the word bank
      const wordExists = wordBank.find((w) => w.wordBankId === word.wordBankId);
      if (wordExists) return;
      setWordBank((prevWordBank) => [...prevWordBank, word]);
    } else {
      // Remove the word from the word bank by wordBankId
      setWordBank((prevWordBank) =>
        prevWordBank.filter((w) => w.wordBankId !== word.wordBankId)
      );
    }
  };

  const handleWordClick = (word: any) => {
    // Remove the word from the word bank by wordBankId
    setWordBank((prevWordBank) =>
      prevWordBank.filter((w) => w.wordBankId !== word.wordBankId)
    );
    // Add the word to the sentence
    setSentence((prevSentence) => [...prevSentence, word]);
  };

  const handleAddWordToBank = (word: any) => {
    handleWordBankUpdate(word, true); // Re-add the word to the word bank
  };

  return (
    <div>
      <div>
        <h2 className="text-l font-bold text-center text-primary mt-6 mb-4">
          Translate the sentence below into {question?.targetLanguage}
        </h2>
      </div>
      <div className="px-12 pb-12">
        <h2 className="text-xl font-bold text-secondary text-center">
          {sourceSentence}
        </h2>
      </div>
      <SentenceArea
        sentence={sentence}
        setSentence={setSentence}
        onWordRemove={handleWordBankUpdate}
      />
      <div className="mt-4">
        <WordBank
          words={wordBank}
          onDragStart={(word) => handleWordBankUpdate(word, false)}
          onDrop={handleAddWordToBank}
          onWordClick={handleWordClick}
        />
      </div>

      <div>
        <button
          className={clsx({
            "purple-button w-full mt-4": !isSubmitButtonDisabled,
            "disabled-button w-full mt-4": isSubmitButtonDisabled,
          })}
          onClick={() =>
            handleSubmit(question.type, { sentence, targetSentences })
          }
          disabled={isSubmitButtonDisabled}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

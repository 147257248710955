import React from "react";
import { BiSolidCoffeeBean } from "react-icons/bi";

export default function RewardCard({ reward, thisLessonsRewards }: any) {
  const isAssigned = thisLessonsRewards.some(
    (thisReward: any) => thisReward.lessonRewardSpecId === reward._id
  );

  return (
    <div
      className={
        isAssigned
          ? "shadow-md border-[1px] border-secondary rounded-2xl bg-pink-200 p-4 w-[150px] justify-center transition-transform transform"
          : "white-card-rounded p-4 w-[150px] justify-center cursor-pointer transition-transform transform hover:scale-105"
      }
    >
      <img src={reward.image} alt={reward.name} className="w-16 py-4 mx-auto" />
      <p className="text-primary font-semibold text-xs text-center">
        {reward.name}
      </p>
      <p className="text-primary-gray text-xxs text-center">
        {reward.description}
      </p>
      <p className="text-primary font-semibold text-xs text-center mt-4 flex justify-center gap-1">
        {}
        <BiSolidCoffeeBean className="text-yellow-500 mt-[3px]" />+
        {reward.cowries}
      </p>
    </div>
  );
}

import axios from "axios";
import { IBookingSchedule, ITutorLessonPrice } from "src/interfaces/tutor";

export async function getBookingPrice(
  lessonSchedule: IBookingSchedule[],
  tutorPrice: ITutorLessonPrice
): Promise<any | undefined[]> {
  try {
    let lessonHours = 0;
    lessonSchedule.forEach((schedule) => {
      lessonHours = schedule.duration.value + lessonHours;
    });
    const price = lessonHours * tutorPrice.value;
    return {
      value: price,
      currencyCode: tutorPrice.currencyCode,
    };
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export function getStandardLessonPrice(clientIpCountry: string | undefined) {
  try {
    if (clientIpCountry?.toLowerCase() === "ng") {
      return "₦12,000";
    }
    if (clientIpCountry?.toLowerCase() === "gb") {
      return "£25";
    }
    if (clientIpCountry?.toLowerCase() === "us") {
      return "$20";
    }
    return "$20";
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export function getYorubaLessonPrice(clientIpCountry: string | undefined) {
  console.log(clientIpCountry);
  try {
    if (clientIpCountry?.toLowerCase() === "ng") {
      return "₦8,000";
    }
    if (clientIpCountry?.toLowerCase() === "gb") {
      return "£10";
    }
    if (clientIpCountry?.toLowerCase() === "us") {
      return "$15";
    }

    if (clientIpCountry?.toLowerCase() === "ca") {
      return "$20";
    }
    return "$15";
  } catch (error) {
    console.log("caught error: ", error);
  }
}

import { format } from "date-fns";
import moment from "moment-timezone";

//function to keep date even with daylight savings
export const incrementDateWithSameLocalTime = (
  startDate: string | Date,
  numberOfWeeks: number,
  timezone: string
) => {
  let initialDate = moment.tz(startDate, timezone);
  let initialHour = initialDate.hour();

  // Create a new date by adding weeks to the initial date
  let newDate = moment.tz(startDate, timezone).add(numberOfWeeks, "weeks");

  // Set the hour of the new date to match the initial hour, preserving local time
  newDate.hour(initialHour);

  return new Date(newDate.format());
};

export const formatDateToNeutral = (dateString: any, timeString: any) => {
  const date = new Date(dateString);
  const time = timeString ? new Date(timeString) : null;

  const formattedDate = format(date, "eeee, dd MMMM");
  const formattedTime = time ? format(time, "hh:mm a") : "";

  return `${formattedDate} at ${formattedTime}`;
};

export const updateLessonSchedulesForDpMoreLessons: any = (
  lessonSchedules: any[],
  mostRecentLessonSchedule: any
) => {
  return lessonSchedules?.map((schedule) => {
    const newSchedule = new Date(mostRecentLessonSchedule?.lessonDate);
    const currentSchedule = new Date(schedule?.date);

    // Set the hours and minutes from the current schedule to the new schedule
    newSchedule.setHours(currentSchedule.getHours());
    newSchedule.setMinutes(currentSchedule.getMinutes());
    newSchedule.setSeconds(currentSchedule.getSeconds());
    newSchedule.setMilliseconds(currentSchedule.getMilliseconds());

    // Find the next date with the same day of the week
    while (
      newSchedule <= new Date(mostRecentLessonSchedule?.lessonDate) ||
      newSchedule.getDay() !== currentSchedule.getDay()
    ) {
      newSchedule.setDate(newSchedule.getDate() + 1);
    }

    return {
      ...schedule,
      date: newSchedule,
      time: newSchedule,
      utc: newSchedule,
    };
  });
};

interface IGetStartedLanding {
  onClick: () => void;
}
export default function AltGetStartedLanding({ onClick }: IGetStartedLanding) {
  return (
    <div className="p-8 text-primary overflow-hidden relative h-screen ">
      <section className="text-center z-10 absolute top-1/2 -translate-y-1/2 left-8 right-8 ">
        <h1
          style={{
            textShadow: `
                -1px -1px 0 #fff,  
         1px -1px 0 #fff,
        -1px  1px 0 #fff,
         1px  1px 0 #fff
                `,
          }}
          className="font-bold text-2xl sm:text-2xl leading-10 max-w-3xl mx-auto"
        >
          Let’s find a perfect tutor for you
        </h1>
        <p
          className="font-medium text-l leading-7 max-w-xl mx-auto mt-2 "
          style={{
            textShadow: `
            -1px -1px 0 #fff,  
     1px -1px 0 #fff,
    -1px  1px 0 #fff,
     1px  1px 0 #fff
            `,
            maxWidth: "264px",
            width: "auto",
          }}
        >
          We just need a bit more information to get your lessons booked!
        </p>
        <button onClick={onClick} className="pink-button px-20 mt-3">
          Continue
        </button>
      </section>
      <div className="absolute z-0 -left-16 md:left-28 lg:left-40 top-14">
        <img
          src={`${process.env.REACT_APP_ASSET_CDN}/direct-pay-landing-image4.webp`}
          alt="direct-pay-landing-image4"
          className=" rounded-full w-32 h-32 object-cover border-primary border-2 p-1 border-dashed"
        />
      </div>
      <div className="absolute -right-16 md:right-24 lg:right-48 top-16">
        <img
          src={`${process.env.REACT_APP_ASSET_CDN}/direct-pay-landing-image1.webp`}
          alt="direct-pay-landing-image1"
          className=" rounded-full w-32 h-32 object-cover border-primary border-2 p-1 border-dashed"
        />
      </div>
      <div className="absolute  left-2 bottom-24 lg:left-32">
        <img
          src={`${process.env.REACT_APP_ASSET_CDN}/direct-pay-landing-image2.webp`}
          alt="direct-pay-landing-image2"
          className=" rounded-full w-28 h-28 object-cover border-primary border-2 p-1 border-dashed"
        />
      </div>
      <div className="absolute  right-2 bottom-12 lg:right-32 ">
        <img
          src={`${process.env.REACT_APP_ASSET_CDN}/direct-pay-landing-image3.webp`}
          alt="direct-pay-landing-image3"
          className=" rounded-full w-28 h-28 object-cover border-primary border-2 p-1 border-dashed"
        />
      </div>
    </div>
  );
}

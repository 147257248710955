import { useEffect, useState } from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { FaRegUserCircle } from "react-icons/fa";
import { routes } from "src/Routes";

import Bio from "./Bio";
import Avatar from "./Avatar";
import Onboarding from "./Onboarding";
import Settings from "./Settings";
import { useStateValue } from "src/context/StateProvider";
import PaymentInfo from "./PaymentInfo";

export default function Profile() {
  const location = useLocation();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [{ showMobileMenu }, dispatch] = useStateValue();

  const toggleProfileMenu = async () => {
    setShowProfileMenu(!showProfileMenu);
  };

  useEffect(() => {
    if (
      routes.TUTOR_DASHBOARD_BIO.url === location.pathname ||
      routes.TUTOR_DASHBOARD_AVATAR.url === location.pathname ||
      routes.TUTOR_DASHBOARD_ONBOARDING.url === location.pathname ||
      routes.TUTOR_PAYMENT_INFO.url === location.pathname ||
      routes.TUTOR_DASHBOARD_SETTINGS.url === location.pathname
    ) {
      setShowProfileMenu(true);
    } else {
      setShowProfileMenu(false);
    }
  }, [location.pathname]);

  return (
    <>
      <div
        className={clsx("parent-menu-inactive", {
          "parent-menu-active":
            routes.TUTOR_DASHBOARD_BIO.url === location.pathname ||
            routes.TUTOR_DASHBOARD_AVATAR.url === location.pathname ||
            routes.TUTOR_DASHBOARD_ONBOARDING.url === location.pathname ||
            routes.TUTOR_PAYMENT_INFO.url === location.pathname ||
            routes.TUTOR_DASHBOARD_SETTINGS.url === location.pathname,
        })}
        onClick={toggleProfileMenu}
        data-test="tutorProfileDropdown"
      >
        <div className="flex gap-4">
          <div>
            <FaRegUserCircle className="text-lg" />
          </div>
          <div>Profile</div>
        </div>
        <div>
          <div
            className={clsx({
              hidden: !showProfileMenu,
              block: showProfileMenu,
            })}
          >
            <BiChevronUp size={23} />
          </div>
          <div
            className={clsx({
              block: !showProfileMenu,
              hidden: showProfileMenu,
            })}
          >
            <BiChevronDown size={23} />
          </div>
        </div>
      </div>
      {/* Sub Menu */}
      <div
        className={clsx({
          hidden: !showProfileMenu,
          "block bg-gray-50 rounded-lg py-4 mb-2": showProfileMenu,
        })}
      >
        <ul>
          <li>
            <Bio />
          </li>
          <li>
            <Onboarding />
          </li>
          <li>
            <PaymentInfo />
          </li>
          <li>
            <Settings />
          </li>
        </ul>
      </div>
    </>
  );
}

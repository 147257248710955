export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 900 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 900, min: 550 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 550, min: 0 },
    items: 1,
  },
};
export const responsiveB = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 900 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 900, min: 550 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 550, min: 0 },
    items: 1,
  },
};

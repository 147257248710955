export const adjustPackagePriceByLessonFrequency = (
  lessonFrequency: number,
  learningPackageAmount?: number,
  learningPackageType?: string
) => {
  if (!learningPackageAmount) return;
  return learningPackageType === "Monthly Package"
    ? learningPackageAmount * lessonFrequency
    : learningPackageAmount;
};

export function capitalizeFirstCharacter(str: string) {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

export function isVideoFile(file: File | string) {
  const allowedExtensions = [
    ".mp4",
    ".mov",
    ".avi",
    ".flv",
    ".webm",
    ".mkv",
    ".gif",
    ".3gp",
    ".3g2",
  ];
  let fileExtension;
  if (typeof file === "object") {
    fileExtension = file?.name
      ?.toLowerCase()
      .slice(file?.name.lastIndexOf("."));
  } else {
    fileExtension = file?.toLowerCase().slice(file.lastIndexOf("."));
  }
  return allowedExtensions.includes(fileExtension);
}

const MAX_VIDEO_SIZE = 100 * 1024 * 1024; // 100MB

export const isVideoLessThanLimit = (file: File) => {
  if (file && file.size > MAX_VIDEO_SIZE) {
    return false;
  }
  return true;
};

export function generateRandomHash(length: number): string {
  if (!length) length = 20;

  let result: string = "";
  const characters: string =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength: number = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

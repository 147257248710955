import Lottie from "lottie-react";
import * as check from "src/assets/animations/check.json";

export default function ThankYouAnimation() {
  return (
    <Lottie
      animationData={check}
      loop={false}
      style={{
        height: "200px",
      }}
    />
  );
}

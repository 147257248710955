import React from "react";

export const GlobalFilter = ({ filter, setFilter }: any) => {
  return (
    <span>
      <input
        className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-xl focus:border-light-purple-light focus:outline-none focus:ring"
        style={{
          maxWidth: "15rem",
        }}
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </span>
  );
};

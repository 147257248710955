import React from "react";
import StarRatings from "react-star-ratings";
import { ITutorReview, ITutorSearchResult } from "src/interfaces/tutor";

type RatingBreakdownType = {
  reviews: ITutorReview[];
  ratings: any;
};

export default function RatingSummary({
  reviews,
  ratings,
}: RatingBreakdownType) {
  if (!reviews || !reviews.length) {
    return null;
  }

  function getAverageRating(reviews: ITutorReview[]): number {
    if (reviews.length === 0) {
      return 0; // Return 0 if there are no reviews
    }

    const totalRating = reviews.reduce(
      (accumulator, review) => accumulator + review.rating,
      0
    );
    const averageRating = totalRating / reviews.length;

    return averageRating;
  }

  const averageRating = getAverageRating(reviews);

  return (
    <div>
      <p className="text-primary text-[7rem] -mt-4">
        {ratings?.rating?.toFixed(1)}
      </p>
      <div className="flex gap-4 items-end -mt-6">
        <StarRatings
          rating={ratings?.rating}
          starHoverColor="#FEC108"
          starRatedColor="#FEC108"
          numberOfStars={5}
          name="rating"
          starDimension="21px"
          starSpacing="1px"
        />
        <p className="text-sm text-primary">{`${ratings?.numberOfRatings} reviews`}</p>
      </div>
    </div>
  );
}

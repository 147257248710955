import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import * as completeAnimation from "src/assets/animations/complete.json";
import { NavLink, useParams } from "react-router-dom";
import { routes } from "src/Routes";
import FeedbackForm from "../FeedbackForm";
import clsx from "clsx";
import { LearningAppApi } from "src/api/learning-app";
import { useStateValue } from "src/context/StateProvider";

interface ResultsPageProps {
  elapsedTime: string;
  score: number;
  session: any;
  order: number;
}

export default function ResultsPage({
  elapsedTime,
  score,
  session,
  order,
}: ResultsPageProps) {
  const [{ user }] = useStateValue();
  const { userId, languageId, levelId, sessionId } = useParams();
  const [showFeedbackForm, setShowFeedbackForm] = useState<boolean>(false);
  const [isNextButtonEnabled, setIsNextButtonEnabled] =
    useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setShowFeedbackForm(true);
    }, 3000);
    LearningAppApi.postSessionCompleteAuditLog(
      userId,
      { timeTaken: elapsedTime, score: score },
      sessionId
    );
  }, []);

  return (
    <>
      <div className="flex justify-center">
        <div className="flex flex-col items-center max-w-[350px]">
          <div className="bg-pale-purple-bg w-full pt-2 rounded-xl mb-4">
            <h1 className="lg:text-2xl font-semibold mb-2 text-primary-light text-center">
              Session Complete
            </h1>
          </div>
          <div className="white-card-rounded h-[350-px] w-[350px]">
            {!showFeedbackForm && (
              <Lottie
                animationData={completeAnimation}
                style={{
                  height: 350,
                  width: 350,
                }}
                loop={false}
              />
            )}
            {showFeedbackForm && (
              <FeedbackForm
                setIsNextButtonEnabled={setIsNextButtonEnabled}
                isNextButtonEnabled={isNextButtonEnabled}
                session={session}
              />
            )}
          </div>
          <div className="bg-pale-purple-bg w-full pt-2 rounded-xl mt-4">
            <h1 className="lg:text-l font-semibold mb-2 text-primary-light text-center">
              {`Level ${session.level}, Topic ${session.topic}`}
            </h1>
          </div>
          <div className="white-card-rounded w-full flex pt-2 mt-4 justify-between px-4">
            <h1 className="lg:text-l font-semibold mb-2 text-secondary-light text-center">
              Time Elapsed:
            </h1>
            <h1 className="lg:text-l font-semibold mb-2 text-secondary-light text-center">
              {elapsedTime}
            </h1>
          </div>
          <div className="white-card-rounded w-full flex pt-2 mt-2 justify-between px-4">
            <h1 className="lg:text-l font-semibold mb-2 text-green-500 text-center">
              Percentage:
            </h1>
            <h1 className="lg:text-l font-semibold mb-2 text-green-600 text-center">
              {score}%
            </h1>
          </div>
          <NavLink
            to={`${routes.STUDENT_DASHBOARD_LEARN.url}/${userId}/${session.level}/${order}/${session.topic}/${session.topicId}${routes.SENTENCE_CONSTRUCTION_SELECTION.url}`}
          >
            <button
              className={clsx({
                "bg-primary-light hover:bg-primary rounded-xl lg:min-w-[250px] min-w-[150px] px-4 py-1 mt-8":
                  isNextButtonEnabled,
                "bg-gray-300  rounded-xl lg:min-w-[250px] min-w-[150px] px-4 py-1 mt-8":
                  !isNextButtonEnabled,
              })}
              disabled={!isNextButtonEnabled}
            >
              <h1 className="lg:text-l font-semibold py-2 text-white text-center">
                Next
              </h1>
            </button>
          </NavLink>
        </div>
      </div>
    </>
  );
}

import { useContext } from "react";
import HeaderText from "./HeaderText";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
interface IOtherReason {
  alt?: boolean;
  onClick?: () => void;
}
export default function OtherReason({ alt, onClick }: IOtherReason) {
  const {
    state: { otherReason, subject },
    actions: { setOtherReason },
  } = useContext(DirectPayContext);

  const handleValidator = () => {
    if (onClick) onClick();
  };
  return (
    <div className="mt-8">
      <section className="max-w-xl mx-auto">
        <HeaderText>
          Please state your reason for learning{" "}
          {subject === "yoruba" ? "Yoruba" : "Igbo"}
        </HeaderText>
        <p className="mt-3  font-semibold text-secondary">
          This helps our tutors ensure that your primary learning goals are met
        </p>
        <input
          className="mt-7 w-full rounded-2xl border-gray-100 bg-gray-50 outline-none py-4 px-4"
          style={{ borderWidth: "3px" }}
          type="text"
          value={otherReason}
          onChange={(e) => {
            setOtherReason(e.target.value);
          }}
          data-test="d2pReasonForLearningInput"
        />
      </section>
      {alt && (
        <>
          <button onClick={handleValidator} className="pink-button px-20 mt-14">
            Continue
          </button>

          <button
            onClick={handleValidator}
            className="bg-gray-100 text-black ml-5  p-3 rounded-full mt-14 font-semibold "
          >
            Skip
          </button>
        </>
      )}
    </div>
  );
}

import React from "react";
import Lottie from "lottie-react";
import * as loadingAnimation from "src/assets/animations/rose-loader.json";

export default function LoadingScreen() {
  return (
    <div>
      <div className="grid place-items-center h-screen">
        <Lottie
          animationData={loadingAnimation}
          style={{
            height: 150,
            width: 150,
          }}
        />
      </div>
    </div>
  );
}

import Lottie from "lottie-react";
import * as loadingAnimation from "src/assets/animations/loading-bar.json";

export default function LoadingBarScreen() {
  return (
    <div>
      <div className="grid place-items-center h-[75vh]">
        <Lottie
          animationData={loadingAnimation}
          style={{
            height: 200,
            width: 200,
          }}
        />
      </div>
    </div>
  );
}

import React from "react";
import SessionCard from "./SessionCard/SessionCard";
import { VirtualImmersionsProps } from "../types";
import ConfirmationModal from "./ConfirmationModal";

export default function SessionList({
  sessions,
  enrolledLessons,
  getVirtualImmersions,
}: {
  sessions: VirtualImmersionsProps[];
  enrolledLessons: any;
  getVirtualImmersions: any;
}) {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    React.useState(false);
  const [virtualImmersionSession, setVirtualImmersionSession] =
    React.useState(null);
  return (
    <>
      <div>
        <h3 className="text-primary font-bold text-xl mb-5">
          {sessions.length > 0
            ? "Here are available virtual immersions for you to join!"
            : "Sorry, No sessions match your search criteria"}
        </h3>
        <div className="flex gap-6 flex-wrap justify-between">
          {sessions &&
            sessions.map((session: any, index: any) => (
              <SessionCard
                key={index}
                session={session}
                setVirtualImmersionSession={setVirtualImmersionSession}
                setIsConfirmationModalOpen={setIsConfirmationModalOpen}
                enrolledLessons={enrolledLessons}
              />
            ))}
        </div>
      </div>
      <div>
        <ConfirmationModal
          isConfirmationModalOpen={isConfirmationModalOpen}
          setIsConfirmationModalOpen={setIsConfirmationModalOpen}
          virtualImmersionSession={virtualImmersionSession}
          getVirtualImmersions={getVirtualImmersions}
        />
      </div>
    </>
  );
}

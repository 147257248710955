import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { routes } from "src/Routes";
import styles from "src/styles";
import "src/styles/blur.css";
import { fadeIn, staggerContainer } from "src/utils/motion";
import ConsistencyCard from "./ConsistencyCard";
import PeaceOfMindCard from "./PeaceOfMindCard";
import SupportCard from "./SupportCard";

export default function WhyYoureHere() {
  const navigate = useNavigate();

  return (
    <div className="relative">
      <div className="container mt-16">
        <motion.div
          // @ts-ignore
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.25 }}
          className={`${styles.innerWidth} mx-auto ${styles.flexCenter} flex-col`}
        >
          <motion.div
            variants={fadeIn("up", "tween", 0.2, 1)}
            className="mt-[8px] font-normal sm:text-[32px] text-[20px] text-center text-secondary-white"
          >
            <div className="text-primary font-bold text-2xl mt-4 px-4 flex gap-2">
              Our Services
            </div>
          </motion.div>
        </motion.div>

        <div className="flex gap-6 flex-col lg:flex-row justify-between mt-12">
          <PeaceOfMindCard />
          <SupportCard />
          <ConsistencyCard />
        </div>
        <button
          type="button"
          className="mt-10 flex justify-between gap-2 items-center bg-secondary hover:bg-secondary-light hover:shadow-xl text-white rounded-2xl
          px-12 py-3 z-10 mx-auto"
          onClick={() => navigate(routes.SERVICES.url)}
        >
          LEARN MORE
        </button>
      </div>{" "}
      {/* <div
        className="hidden lg:block blur s-blur1 w-60 h-60 rounded-full left-60 top-60"
        style={{ background: "#fccfe2" }}
      ></div> */}
    </div>
  );
}

import React from "react";
import Lottie from "lottie-react";
import * as loadingAnimation from "src/assets/animations/circle-loader.json";

export default function DropDownLoader() {
  return (
    <div>
      <div className="w-full h-10 flex items-center justify-center">
        <Lottie
          animationData={loadingAnimation}
          style={{
            height: 300,
            width: 300,
          }}
        />
      </div>
    </div>
  );
}

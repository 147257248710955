import SearchBar from "./SearchBar";

export default function SessionFilter({ sessions, setFilteredSessions }: any) {
  return (
    <div>
      <SearchBar
        sessions={sessions}
        setFilteredSessions={setFilteredSessions}
      />
    </div>
  );
}

import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { LearningAppApi } from "src/api/learning-app";
import { useStateValue } from "src/context/StateProvider";
import { v4 as uuidv4 } from "uuid";

export default function FeedbackForm({
  setIsNextButtonEnabled,
  isNextButtonEnabled,
  session,
}: any) {
  const [{ user }] = useStateValue();
  const { userId, sessionId } = useParams();
  const [message, setMessage] = useState<string>("");
  const [uniqueId, setUniqueId] = useState<string>(uuidv4());
  const [isFeedbackSent, setIsFeedbackSent] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  console.log("FeedbackForm userId", userId);
  console.log("FeedbackForm sessionId", sessionId);

  useEffect(() => {
    setUniqueId(uuidv4()); // [2]
  }, [sessionId]); // [3]

  const handleSendWasSessionUseful = async (choice: string) => {
    await LearningAppApi.postWasSessionUseful(
      choice,
      userId,
      uniqueId,
      sessionId
    );
    setIsNextButtonEnabled(true);
  };

  const handleSendOptionalfeedback = async () => {
    await LearningAppApi.postOptionalFeedback(message, uniqueId);
    setMessage("");
    setIsFeedbackSent(true);
  };

  return (
    <div className="p-4 min-w-[350px] min-h-[350px]">
      <h2 className="text-center font-semibold text-sm text-secondary">
        Did you find this useful?
      </h2>
      <div className="flex gap-4 mt-4 justify-between items-center">
        <div className="flex-1">
          <button
            className={clsx({
              "bg-red-500 w-full text-white rounded-lg px-4 py-2":
                !isNextButtonEnabled,
              "bg-gray-200 w-full text-white rounded-lg px-4 py-2":
                isNextButtonEnabled,
            })}
            disabled={isNextButtonEnabled}
            onClick={() => handleSendWasSessionUseful("No")}
          >
            No
          </button>
        </div>
        <div className="flex-1">
          <button
            className={clsx({
              "bg-green-500 w-full  text-white rounded-lg px-4 py-2":
                !isNextButtonEnabled,
              "bg-gray-200 w-full text-white rounded-lg px-4 py-2":
                isNextButtonEnabled,
            })}
            disabled={isNextButtonEnabled}
            onClick={() => handleSendWasSessionUseful("Yes")}
          >
            Yes
          </button>
        </div>
      </div>
      <div className="mt-4">
        <textarea
          className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
          onChange={handleChange}
          rows={6}
          disabled={isFeedbackSent}
          placeholder={
            isFeedbackSent
              ? "Thank you for your feedback"
              : "Optional: We'd love to hear why! Please share your insights or suggest new features you'd like to see."
          }
          name="message"
          value={message}
        />
        <div>
          <button
            className={clsx({
              "bg-secondary-light w-full hover:bg-secondary text-white rounded-lg lg:min-w-[250px] min-w-[150px] px-4 py-1 mt-8":
                !isFeedbackSent,
              "bg-gray-200 text-white  rounded-xl w-full min-w-[150px] px-4 py-1 mt-8":
                isFeedbackSent,
            })}
            disabled={isFeedbackSent}
            onClick={handleSendOptionalfeedback}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { ITutorReview } from "src/interfaces/tutor";
import ReviewItem from "./ReviewItem";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";

type ReviewItemType = {
  reviews: ITutorReview[];
};

export default function ReviewList({ reviews }: ReviewItemType) {
  const [showReviews, setShowReviews] = useState(false);
  const [reviewsToShow, setReviewsToShow] = useState(3);
  const shouldShowMoreReviews = reviews.length >= 4;
  const handleShowMoreReviews = () => {
    setShowReviews(true);
    setReviewsToShow(reviewsToShow + reviews.length);
  };

  const handleShowLessReviews = () => {
    setShowReviews(false);
    setReviewsToShow(3);
  };

  const handleClick = () => {
    if (showReviews) {
      handleShowLessReviews();
    } else {
      handleShowMoreReviews();
    }
  };

  return (
    <>
      <div>
        {reviews.slice(0, reviewsToShow).map((review, index) => (
          <ReviewItem key={index} review={review} />
        ))}
      </div>
      {shouldShowMoreReviews && (
        <div
          className="flex gap-4 items-center -mt-2 cursor-pointer"
          onClick={handleClick}
        >
          <p className="text-secondary text-sm font-medium">
            {showReviews ? "Show less reviews" : "Show more reviews"}
          </p>
          {showReviews ? (
            <AiOutlineCaretUp className="text-secondary" />
          ) : (
            <AiOutlineCaretDown className="text-secondary" />
          )}
        </div>
      )}
    </>
  );
}

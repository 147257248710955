
const step1 = `${process.env.REACT_APP_ASSET_CDN}/step_1.svg`;
const step2 = `${process.env.REACT_APP_ASSET_CDN}/step_2.svg`;
const step3 = `${process.env.REACT_APP_ASSET_CDN}/step_3.svg`;

export const steps = [
  {
    imageSrc: step1,
    title: "1. Select",
    text: "Choose from our wide range of lessons",
  },
  {
    imageSrc: step2,
    title: "2. Book",
    text: "Book a time that suits you",
  },
  {
    imageSrc: step3,
    title: "3. Learn",
    text: "We'll connect you with your tutor",
  },
];

import React, { useState, useEffect } from "react";

const LoadingMessages = ({ messages }: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 3000); // Change the message every 3 seconds

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [messages.length]);

  return (
    <div>
      <p className="text-secondary text-xs text-center px-6">
        {messages[currentIndex]}
      </p>
    </div>
  );
};

export default LoadingMessages;

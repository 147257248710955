import { motion } from "framer-motion";

import "src/styles/react-select.css";

import Modal from "react-modal";
import "src/styles/react-modal.css";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";

import { useStateValue } from "src/context/StateProvider";
import { getYorubaLessonPrice } from "src/services/lesson-price";
import ReactGA from "react-ga4";
import BookALessonSearchBar from "./BookALessonSearchBar";
const greenCheck = `${process.env.REACT_APP_ASSET_CDN}/green-check.svg`;
const yellowMortarboard = `${process.env.REACT_APP_ASSET_CDN}/yellow-mortarboard.svg`;
const pinkPeople = `${process.env.REACT_APP_ASSET_CDN}/pink-people.svg`;
Modal.setAppElement("#root");

function LanguageHero() {
  const [{ clientIpCountry }] = useStateValue();
  const [lessonPrice, setLessonPrice] = useState<string | undefined>("$15");
  const [isVideoTestimonialModalOpen, setIsVideoTestimonialModalOpen] =
    useState(false);
  const [isMobilePlayerOpen, setIsMobilePlayerOpen] = useState(false);
  const [isWebPlayerOpen, setIsWebPlayerOpen] = useState(false);

  useEffect(() => {
    if (clientIpCountry) {
      setLessonPrice(getYorubaLessonPrice(clientIpCountry));
    }
  }, [clientIpCountry]);

  const openVideoModal = (platform: string) => {
    setIsVideoTestimonialModalOpen(true);
    if (platform === "mobile") {
      setIsMobilePlayerOpen(true);
    }
    if (platform === "web") {
      setIsWebPlayerOpen(true);
    }
    ReactGA.event({
      category: "Yoruba video intro",
      action: "Clicked on Yoruba video intro",
    });
  };

  const closeVideoModal = () => {
    setIsVideoTestimonialModalOpen(false);
    setIsMobilePlayerOpen(false);
    setIsWebPlayerOpen(false);
  };

  const videoTestimonialModal = (
    <Modal
      isOpen={isVideoTestimonialModalOpen}
      onRequestClose={closeVideoModal}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          zIndex: 1000,
        },
        content: {
          position: "absolute",
          background: "rgba(0, 0, 0, 0)",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          outline: "none",
          padding: "20px",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          border: "none",
          zIndex: 1000,
        },
      }}
    >
      <div>
        <div className="hidden lg:block">
          <ReactPlayer
            url="https://tutoring-app.s3.us-east-2.amazonaws.com/Yoruba+replacement.mp4"
            controls={true}
            playing={isWebPlayerOpen}
            style={{
              borderRadius: "60px",
            }}
          />
        </div>
        <div className="lg:hidden">
          <ReactPlayer
            url="https://tutoring-app.s3.us-east-2.amazonaws.com/Yoruba+replacement.mp4"
            controls={true}
            playing={isMobilePlayerOpen}
            width="320px"
            height="200px"
            style={{
              borderRadius: "60px",
            }}
          />
        </div>
      </div>
    </Modal>
  );

  return (
    <>
      {videoTestimonialModal}
      <div className="bg-pale-purple-bg ">
        <div className="container flex-col p-8 pb-28 pt-16 lg:flex-row flex lg:justify-between  items-center">
          {/* Left Div */}
          <div className="flex flex-col mt-8 lg:mt-0 text-center lg:text-left justify-center">
            {/* Main Heading */}
            <div className="text-2xl lg:text-4xl  font-bold lg:font-extrabold text-primary mt-8">
              Speak your mother <br />
              tongue with pride!
            </div>
            <div className="lg:hidden">
              <BookALessonSearchBar />
            </div>
            {/* Sub Heading */}
            <div className="text-light-purple font-bold hidden mt-4 lg:block">
              Fun online Yoruba, and Igbo lessons with our native experts
            </div>
            <div className="text-light-purple hidden mt-4 lg:block">
              For Adults and Kids aged 5 and up
            </div>
            <div className="text-light-purple text-xl mt-9 lg:hidden">
              For Adults and Kids aged 5 and up
            </div>
            {/* Search bar */}
            <div className="hidden lg:block">
              <BookALessonSearchBar />
            </div>
          </div>
          {/* Right Div */}
          {/* Web Version */}

          <div className="relative  hidden lg:flex flex-col items-center gap-4">
            {/* Image */}
            <div className="flex flex-col  items-center justify-between">
              <img
                src={`${process.env.REACT_APP_ASSET_CDN}/languageHero.webp`}
                alt=""
                width={400}
                height={400}
              />
            </div>
            {/* Top Left Bubble */}
            <motion.div
              className="absolute bg-white  h-28 w-28 flex flex-col items-center 
            justify-around rounded-2xl -top-2 py-3 text-sm -left-4 shadow-2xl"
              animate={{ scale: 0.9 }}
              initial={{ scale: 0 }}
              whileHover={{
                scale: 1,
                transition: { duration: 1, delay: 0 },
              }}
              transition={{ ease: "easeOut", duration: 1, delay: 1 }}
            >
              <div>
                <img src={greenCheck} alt="" width={45} height={45} />
              </div>
              <div className="text-center text-primary leading-5 mt-1">
                Lessons from <br />
                {lessonPrice} / hr
              </div>
            </motion.div>
            {/* Top Right Bubble */}
            <motion.div
              className="absolute bg-white h-36 w-30 flex flex-col items-center 
            justify-around rounded-2xl top-14 py-3 px-3 text-sm -right-12 shadow-2xl hover:scale-0"
              animate={{
                scale: 0.9,
                transition: { ease: "easeOut", duration: 0.7, delay: 0.5 },
              }}
              initial={{ scale: 0 }}
              whileHover={{
                scale: 1,
                transition: { duration: 1, delay: 0 },
              }}
            >
              <div>
                <img src={yellowMortarboard} width={55} height={55} alt="" />
              </div>
              <div className="text-center text-primary leading-5">
                Native-speaking <br />
                tutors
              </div>
            </motion.div>
            {/* Bottom Right Bubble */}
            <motion.div
              className="absolute bg-white h-45 w-30 flex flex-col items-center 
            justify-around rounded-2xl -bottom-20 text-sm pt-3 px-3 -right-2 shadow-2xl scale-0"
              animate={{ scale: 0.9 }}
              initial={{ scale: 0 }}
              transition={{ ease: "easeOut", duration: 0.5, delay: 1 }}
              whileHover={{
                scale: 1,
                transition: { duration: 1, delay: 0 },
              }}
            >
              <div className="mt-2">
                <img src={pinkPeople} width={55} height={55} alt="" />
              </div>
              <div className="text-center text-primary leading-5 mt-2">
                <p>
                  <span className="font-bold">1000's</span> of lessons
                </p>
                <p>taught</p>
              </div>
              <div className="pb-3">
                <img
                  src={`${process.env.REACT_APP_ASSET_CDN}/three-avatars.webp`}
                  width={75}
                  height={55}
                  alt=""
                />
              </div>
            </motion.div>
            {/* Bottom Left Bubble */}
            <motion.div
              className="absolute bg-white h-45 w-30 flex flex-col items-center 
            justify-around rounded-2xl bottom-5 py-2 px-4 text-sm text-center -left-24 shadow-2xl"
              animate={{ scale: 0.9 }}
              initial={{ scale: 0 }}
              transition={{ ease: "easeOut", duration: 0.7, delay: 0.8 }}
              whileHover={{
                scale: 1,
                transition: { duration: 1, delay: 0 },
              }}
            >
              <div className="text-primary leading-5 mt-2 pb-2">
                <div className="flex gap-3">
                  <div className="bg-yellow-400 h-2.5 w-2.5 mt-1.5 text-left rounded-full"></div>
                  Speak your Nigerian <br /> language with confidence.
                </div>

                <div className="mt-4 flex gap-3">
                  <div className="bg-yellow-400 h-2.5 w-2.5 mt-1.5 rounded-full"></div>
                  <p>Average 5 ⭐ reviews</p>
                </div>
              </div>
            </motion.div>
          </div>
          {/* Mobile Version */}
          <div className="flex  lg:hidden justify-center mt-10">
            <div className="relative flex flex-col items-center">
              {/* Image */}
              <div className="mt-12">
                <img
                  src={`${process.env.REACT_APP_ASSET_CDN}/languageHero.webp`}
                  alt=""
                  width={270}
                  height={270}
                />
              </div>
              {/* Top Left Bubble */}
              <motion.div
                className="absolute bg-white  h-20 w-24 flex flex-col items-center 
              justify-around rounded-2xl top-8 py-2 text-sm left-0 shadow-2xl"
                animate={{ scale: 1 }}
                initial={{ scale: 0 }}
                transition={{ ease: "easeOut", duration: 0.7, delay: 0.8 }}
              >
                <div>
                  <img src={greenCheck} alt="" width={25} height={25} />
                </div>
                <div className="text-center mt-1 text-xs text-primary leading-0">
                  Lessons from <br />
                  {lessonPrice} / hr
                </div>
              </motion.div>
              {/* Top Right Bubble */}
              <motion.div
                className="absolute bg-white h-28 w-30 flex flex-col items-center 
              justify-around rounded-2xl -bottom-14 px-3 py-2 text-sm right-8 shadow-2xl"
                animate={{ scale: 1 }}
                initial={{ scale: 0 }}
                transition={{ ease: "easeOut", duration: 0.5, delay: 1 }}
              >
                <div>
                  <img src={yellowMortarboard} width={45} height={45} alt="" />
                </div>
                <div className="text-center text-xs text-primary">
                  Experienced & effective <br />
                  tutors
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LanguageHero;

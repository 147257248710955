import { format } from "date-fns";
import React from "react";
import StarRatings from "react-star-ratings";
import { ITutorReview } from "src/interfaces/tutor";

type ReviewItemType = {
  review: ITutorReview;
};

export default function ReviewItem({ review }: ReviewItemType) {
  function getInitials(fullName: string): string {
    const nameParts = fullName.split(" ");

    if (nameParts.length < 2) {
      throw new Error("Full name must include both first name and last name");
    }

    const firstNameInitial = nameParts[0].charAt(0).toUpperCase();
    const lastNameInitial = nameParts[1].charAt(0).toUpperCase();

    return firstNameInitial + lastNameInitial;
  }

  return (
    <>
      <div className="flex gap-4">
        {/* Initials */}
        <div>
          <div className="flex items-center justify-center font-medium h-12 w-12 rounded-full bg-red-400 text-white uppercase">
            {getInitials(review.firstName)}
          </div>
        </div>
        {/* Stars, Name, & Review */}
        <div className="w-full">
          {/* Stars */}
          <div>
            <StarRatings
              rating={review.rating}
              starHoverColor="#FEC108"
              starRatedColor="#FEC108"
              numberOfStars={5}
              name="rating"
              starDimension="21px"
              starSpacing="1px"
            />
          </div>
          {/* Name, Location & Date */}
          <div className="sm:flex justify-between">
            {/* Name & Location */}
            <div className="flex gap-8 mt-2 items-end">
              <p className="font-semibold text-primary">{review.firstName}</p>
              <p className="text-primary text-xs">
                {review.user_type.charAt(0).toUpperCase() +
                  review.user_type.slice(1)}
              </p>
            </div>
            {/* Date */}
            <div className="flex mt-2 items-end">
              <p className="text-primary text-xs">
                {format(new Date(review.createdAt), "dd MMM yyyy")}
              </p>
            </div>
          </div>
          {/* Review */}
          <div className="mt-2">
            <p className="text-sm text-primary">{review.review}</p>
          </div>
        </div>
      </div>
      {/* Divider */}
      <div className="border-b-[1px] border-gray-200 w-[full] my-6"></div>
    </>
  );
}

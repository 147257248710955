import React from "react";
import { useParams } from "react-router-dom";
import "./GamePage.css";

export default function GamePage() {
  const { projectId } = useParams();

  return (
    <div className="responsive-container">
      <iframe
        src={`https://scratch.mit.edu/projects/${projectId}/embed`}
        allowTransparency={true}
        frameBorder="0"
        scrolling="no"
        allowFullScreen={true}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        className="responsive-iframe"
      ></iframe>
    </div>
  );
}

import { useContext, useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import LoadingScreen from "src/components/LottieAnimations/LoadingBarScreen";
import TopInfoSection from "./TopInfoSection";
import { IClientCurrencyData } from "src/interfaces/currency";
import { useStateValue } from "src/context/StateProvider";
import AboutMe from "./AboutMe";
import Availability from "./Availability";
import { toast } from "react-toastify";
import { TutorApi } from "src/api/tutor.api";
import { routes } from "src/Routes";
import PaymentPlan from "src/components/DirectToPay/PaymentPlans/PaymentPlan";
import React from "react";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import Header from "src/layouts/FixedDashboardHeader";
import PreppingDashboard from "src/components/LottieAnimations/PreppingDashboard";
import Reviews from "./Reviews";
import NewAvailability from "src/components/NewAvailability";
import { LessonSchedule } from "src/interfaces/direct-pay";
toast.configure();
export default function ViewTutorProfile() {
  const [tutorProfile, setTutorProfile] = useState<any>();

  const {
    state: {
      proficiencyLevel,
      adminBio,
      isLoading,
      tutorBioCategories,
      selectedLessonScheduleId,
      leadFunnel,
      lessonFrequency,
      lessonSchedule,
      onBoarding,
    },
    actions: {
      setLessonFrequency,
      setSelectedLessonScheduleId,
      setLessonSchedule,
    },
  } = useContext(DirectPayContext);

  const { tutorId } = useParams();
  const [{ user, clientIpCountry }] = useStateValue();
  const [tutorAvailability, setTutorAvailability] = React.useState<any>([]);

  const [tutorBookings, setTutorBookings] = React.useState<any>([]);

  const [currency] = useState<IClientCurrencyData | null>(null);
  const [activeTab, setActiveTab] = useState("overview");
  const [isPaymentPlan, setPaymentPlan] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const bookLessonRef = useRef(null);
  const [lessonScheduleFromCalendar, setLessonScheduleFromCalendar] =
    useState<LessonSchedule>();
  console.log(lessonScheduleFromCalendar, "Lessons schedule from calendar");
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    const tabContent = document.getElementById(tab);
    if (tabContent) {
      tabContent.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const scrollToBookLesson = () => {
    if (bookLessonRef.current) {
      const topPosition =
        (bookLessonRef.current as HTMLDivElement).getBoundingClientRect().top +
        window.pageYOffset;
      const offset = 100; // Adjust this value to whatever offset you need

      // Scroll to the bio section with the offset
      window.scrollTo({
        top: topPosition - offset, // Subtract the offset to scroll higher
        behavior: "smooth",
      });
    }
  };

  const handleChangeFreq = (sign: string) => {
    let value = 0;
    if (sign === "+") {
      if (lessonFrequency < 2) {
        setLessonFrequency(lessonFrequency + 1);
        value = lessonFrequency + 1;
      }
    } else {
      if (lessonFrequency > 1) {
        setLessonFrequency(lessonFrequency - 1);
        value = lessonFrequency - 1;
      }
    }

    const updatedSchedule = lessonSchedule.filter((_, index) => index < value);
    if (updatedSchedule.length > 1) {
      setSelectedLessonScheduleId(value - 1);
    } else {
      setSelectedLessonScheduleId(0);
    }
    setLessonSchedule(updatedSchedule);
  };

  useEffect(() => {
    const getTutorProfile = async () => {
      const response = await TutorApi.getTutorProfileDetails(tutorId);
      if (tutorId) {
        const res = await TutorApi.getTutorAvailabilityAndBookings(tutorId);
        setTutorAvailability(res.tutorAvailability);
        setTutorBookings(res.tutorBookings);
      }

      setTutorProfile(response);
    };
    getTutorProfile();
  }, [clientIpCountry, tutorId]);

  useEffect(() => {
    if (lessonScheduleFromCalendar) {
      if (
        lessonSchedule.find(
          (schedule, index) =>
            index !== selectedLessonScheduleId &&
            lessonScheduleFromCalendar.date &&
            typeof lessonScheduleFromCalendar.date == "object" &&
            new Date(schedule?.date || "").getDay() ===
              lessonScheduleFromCalendar.date.getDay()
        )
      ) {
        toast.error("Select a different day", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      } else {
        lessonSchedule[selectedLessonScheduleId] = lessonScheduleFromCalendar;
        setLessonSchedule(lessonSchedule);
      }
    }
    if (selectedLessonScheduleId < Number(lessonFrequency) - 1) {
      setSelectedLessonScheduleId(selectedLessonScheduleId + 1);
    }
    document
      ?.querySelectorAll(`#video-booking-card`)
      ?.forEach((el) =>
        el?.scrollIntoView({ behavior: "smooth", block: "center" })
      );
    // scrollToBookLesson();
  }, [lessonScheduleFromCalendar]);

  if (!user) {
    return <Navigate to={routes.LOGIN.url} />;
  }

  if (!tutorProfile || !tutorBookings) {
    // SKELETON LOADER GOES HERE
    return <LoadingScreen />;
  }

  if (
    isLoading &&
    (leadFunnel === "direct-to-pay-flow-2" ||
      leadFunnel === "direct-to-pay-flow-4")
  ) {
    return <PreppingDashboard />;
  }
  //proficiencyLevel
  return (
    <>
      <div>
        <Header />

        {!isPaymentPlan && (
          <div>
            <div className="text-primary pb-10  relative min-h-screen h-full">
              <div className=" bg-pale-purple-bg p-4">
                {/* Button */}
                {/* <div className="hidden md:block max-w-xs">
                  <NavLink to={`${routes.DIRECT_TO_PAY_MEET_TUTORS.url}`}>
                    <button className="gray-button-2 flex  items-center space-x-2  mt-7">
                      <FaChevronLeft className="w-4 h-4" />
                      <span>Back to Tutors</span>
                    </button>
                  </NavLink>
                </div> */}
              </div>

              {/* Top Section */}

              <div className=" bg-pale-purple-bg">
                <div className="container px-5 md:px-8 pt-5 pb-4">
                  <TopInfoSection
                    tutorProfile={tutorProfile.info}
                    currency={currency}
                    setisPayment={setPaymentPlan}
                    bookLessonRef={bookLessonRef}
                    ratings={tutorProfile.ratings}
                  />
                  <div className=" py-4 max-w-[680px]">
                    {/* Title */}
                    <div className="flex gap-10 items-end">
                      <button
                        className={`tab-button ${
                          activeTab === "overview"
                            ? "active-tab-menu"
                            : "inactive-tab-menu"
                        }`}
                        onClick={() => handleTabClick("overview")}
                      >
                        Overview
                      </button>
                      <button
                        className={`tab-button ${
                          activeTab === "availability"
                            ? "active-tab-menu"
                            : "inactive-tab-menu"
                        }`}
                        onClick={() => handleTabClick("availability")}
                      >
                        Availability
                      </button>
                      <button
                        className={`tab-button ${
                          activeTab === "reviews"
                            ? "active-tab-menu"
                            : "inactive-tab-menu"
                        }`}
                        onClick={() => handleTabClick("reviews")}
                      >
                        Reviews
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* About Me */}

              <div className="container pt-6">
                {/* Tab Buttons */}

                {/* Tab Content */}
                <div className="pb-4" ref={contentRef}>
                  <div className={`tab-content`} id="overview">
                    <div>
                      <AboutMe
                        tutorBio={tutorProfile.info.bio}
                        tutorProfile={tutorProfile.info}
                        adminBio={adminBio}
                        tutorBioCategories={tutorBioCategories}
                      />
                    </div>
                  </div>
                </div>
                {(onBoarding.onboarding?.leadFunnel ===
                  "direct-to-pay-flow-1" ||
                  onBoarding.onboarding?.leadFunnel ===
                    "direct-to-pay-flow-3") && (
                  <div className="pb-4">
                    <div className={`tab-content`} id="lesson-frequency">
                      <div className="md:shadow-md md:border-[1px] md:border-gray-200 md:rounded-2xl lg:ml-2 p-4 px-6 max-w-[680px] mt-12">
                        <h3 className="text-primary-light font-semibold">
                          How many lessons do you want per week?
                        </h3>
                        <div className="mt-4 flex items-center gap-6 justify-center">
                          <button
                            className="block border-2 p-2 rounded-l-3xl rounded-r-lg"
                            style={{ borderColor: "#F4F4F4" }}
                            onClick={() => handleChangeFreq("-")}
                            disabled={lessonFrequency === 1}
                          >
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 51 54"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.4121 27.0468H38.4008"
                                stroke="#341A64"
                                opacity={lessonFrequency === 1 ? 0.5 : 1}
                                strokeWidth="6.40735"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <p className="font-bold text-3xl">
                            {lessonFrequency}
                          </p>
                          <button
                            className="block border-2 p-2 rounded-r-3xl rounded-l-lg"
                            style={{ borderColor: "#F4F4F4" }}
                            onClick={() => handleChangeFreq("+")}
                            disabled={lessonFrequency === 2}
                          >
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 51 54"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.3105 27.0468H38.2992"
                                stroke="#341A64"
                                strokeWidth="6.40735"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                opacity={lessonFrequency === 2 ? 0.5 : 1}
                              />
                              <path
                                d="M25.8052 40.1818V13.9117"
                                stroke="#341A64"
                                strokeWidth="6.40735"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                opacity={lessonFrequency === 2 ? 0.5 : 1}
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className={`tab-content`} id="availability">
                  <div className="mt-2 pl-2">
                    <NewAvailability
                      tutorAvailability={tutorAvailability}
                      tutorBookings={tutorBookings}
                      scrollToBookLesson={scrollToBookLesson}
                      setLessonScheduleFromCalendar={
                        setLessonScheduleFromCalendar
                      }
                      selectedLessonScheduleId={selectedLessonScheduleId}
                    />
                  </div>
                </div>
                <div className={`tab-content`} id="reviews">
                  <div className="mt-8 pl-2">
                    <Reviews
                      reviews={tutorProfile.reviews}
                      ratings={tutorProfile.ratings}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isPaymentPlan && (
          <PaymentPlan
            setisPayment={setPaymentPlan}
            proficiencyLevel={proficiencyLevel}
          />
        )}
      </div>
    </>
  );
}

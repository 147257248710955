import { getDependantAccounts, getUserDependants, postContactUs, signInAsUser } from "src/server/endpoints/customer";

export class CustomerApi {
    static getUserDependants(userId: string): Promise<any> {
        return getUserDependants(userId);
    }
    static getDependantAccounts(userId: string): Promise<any> {
        return getDependantAccounts(userId);
    }
    static postContactUs(values: any): Promise<any> {
        return postContactUs(values);
    }
    static signInAsUser(userId: any): Promise<any> {
        return signInAsUser(userId);
    }
}
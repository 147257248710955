import Carousel from "../Carousel";
import LazyLoad from "react-lazyload";
import Lottie from "lottie-react";
import * as missionBoy from "src/assets/animations/growth-animation.json";
import styles from "src/styles";

const techstars = `${process.env.REACT_APP_ASSET_CDN}/techstars.webp`;
const googleforstartups = `${process.env.REACT_APP_ASSET_CDN}/googleforstartups.webp`;
const voltron = `${process.env.REACT_APP_ASSET_CDN}/voltron.webp`;
const kickoff = `${process.env.REACT_APP_ASSET_CDN}/kickoff.webp`;
const consonance = `${process.env.REACT_APP_ASSET_CDN}/consonance.webp`;
const metis = `${process.env.REACT_APP_ASSET_CDN}/metis.webp`;

export default function InvestorLogos() {
  return (
    <div className="container mt-16">
      <div className={`${styles.flexCenter} flex-col text-primary`}>
        <div className="flex gap-0 mt-8 flex-col md:flex-row">
          <h2 className="text-primary text-center font-bold text-4xl mt-4">
            Backed by
          </h2>
          <Lottie
            animationData={missionBoy}
            className="mx-auto w-[200px] h-[200px] sm:w-[100px] sm:h-[100px] sm:-mt-3"
          />
        </div>
      </div>
      <div className="-mt-10">
        <Carousel>
          <div className="mx-6">
            <LazyLoad>
              <img src={techstars} alt="" width="200px" />
            </LazyLoad>
          </div>
          <div className="mx-6">
            <LazyLoad>
              <img src={googleforstartups} alt="" width="300px" />
            </LazyLoad>
          </div>
          <div className="mx-6">
            <LazyLoad>
              <img src={voltron} alt="" width="200px" />
            </LazyLoad>
          </div>
          <div className="mx-6">
            <LazyLoad>
              <img src={consonance} alt="" width="200px" />
            </LazyLoad>
          </div>
          <div className="mx-6">
            <LazyLoad>
              <img src={kickoff} alt="" width="80px" />
            </LazyLoad>
          </div>
          <div className="mx-6">
            <LazyLoad>
              <img src={metis} alt="" width="250px" />
            </LazyLoad>
          </div>
        </Carousel>
      </div>
    </div>
  );
}

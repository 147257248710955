import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumb from "src/components/StudentDashboard/Learn/Breadcrumb";
import { useStateValue } from "src/context/StateProvider";
import {
  LessonResources,
  lessonResources,
} from "src/static-data/lessonResources";
import { VideoComponent } from "./VideoComponent";
import Card from "src/components/StudentDashboard/Learn/Card";
import CardContainer from "src/components/StudentDashboard/Learn/CardContainer";
import Group from "src/components/StudentDashboard/Learn/Group";

export default function SessionDetails() {
  const { state }: any = useLocation();
  const { level, topic, topicId } = useParams();

  const [{ userLessonProgress }] = useStateValue();
  const prerequisites = userLessonProgress?.unordered?.find(
    (t: any) => t?.topicId === topicId
  )?.prerequisites;
  const practiceVideo = (lessonResources as LessonResources)?.[
    userLessonProgress?.curriculum
  ]?.[userLessonProgress.ageGroup]?.[level as any]?.[topic as any]?.resources
    .practiceVideo;
  const grammarSpotlight = (lessonResources as LessonResources)?.[
    userLessonProgress?.curriculum
  ]?.[userLessonProgress.ageGroup]?.[level as any]?.[topic as any]?.resources
    .grammarSpotlight;

  console.log(prerequisites);

  const groupedPrerequisites = prerequisites?.reduce((acc: any, curr: any) => {
    if (acc[curr?.level]) {
      acc[curr?.level].push(curr);
    } else {
      acc[curr?.level] = [curr];
    }
    return acc;
  }, {});

  return (
    <section className="text-primary">
      <Breadcrumb />
      {state?.id === 3 && <VideoComponent practiceVideo={practiceVideo} />}
      {state?.id === 1 && <GrammarSpotlight content={grammarSpotlight} />}
      {state?.id === 1 && <GrammarSpotlight content={grammarSpotlight} />}
      {state?.id === 6 && groupedPrerequisites && (
        <div className="mt-4">
          {Object.entries(groupedPrerequisites)?.map(([level, topics]: any) => (
            <Group title={level} key={level}>
              {topics?.map((t: any) => {
                return (
                  <Card
                    key={t?.title}
                    topic={t}
                    level={t?.level}
                    topicId={t?._id}
                    curriculumId={t?.curriculumId}
                    parentDivisionId={t?.parentDivisionId}
                  />
                );
              })}
            </Group>
          ))}
        </div>
      )}
    </section>
  );
}

const GrammarSpotlight = ({ content }: any) => {
  // Function to convert markdown-style formatting to HTML
  const formatContent = (text: string) => {
    return text
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line !== "")
      .map((line) => {
        // Convert **bold** to <strong>bold</strong>
        line = line.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
        return `<p>${line}</p>`;
      })
      .join("");
  };

  const formattedContent = formatContent(content);

  return (
    <div className="max-w-4xl mx-auto mt-4 p-4 ">
      {/* <h2 className="text-xl font-bold mb-2">Grammar Spotlight</h2> */}
      <div
        className="prose"
        dangerouslySetInnerHTML={{ __html: formattedContent }}
      />
    </div>
  );
};

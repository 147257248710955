import { useEffect, useState } from "react";

import LoadingBarScreen from "src/components/LottieAnimations/LoadingBarScreen";
export const VideoComponent = ({ practiceVideo }: any) => {
  const [loading, setLoading] = useState(true);
  const [embedUrl, setEmbedUrl] = useState("");
  const [embedType, setEmbedType] = useState("");

  useEffect(() => {
    const processVideoUrl = (iframeString: string) => {
      const youtubeMatch = iframeString.match(/youtube\.com\/embed\/([^"]+)"/);
      const googleDriveMatch = iframeString.match(
        /drive\.google\.com\/file\/d\/([^/]+)/
      );

      if (youtubeMatch) {
        setEmbedUrl(`https://www.youtube.com/embed/${youtubeMatch[1]}`);
        setEmbedType("youtube");
      } else if (googleDriveMatch) {
        setEmbedUrl(
          `https://drive.google.com/file/d/${googleDriveMatch[1]}/preview`
        );
        setEmbedType("googledrive");
      } else {
        console.error("Unsupported video source");
        setEmbedUrl("");
        setEmbedType("");
      }
    };

    processVideoUrl(practiceVideo);

    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust this value as needed
    return () => clearTimeout(timer);
  }, [practiceVideo]);

  if (!embedUrl) {
    return <div>Invalid video URL</div>;
  }

  return (
    <div className="max-w-4xl mx-auto mt-4">
      <div className="relative w-full pt-[56.25%]">
        {loading ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <LoadingBarScreen />
          </div>
        ) : null}
        <iframe
          className={`absolute top-0 left-0 w-full h-full ${
            loading ? "invisible" : "visible"
          }`}
          src={embedUrl}
          title={
            embedType === "youtube"
              ? "YouTube video player"
              : "Google Drive video player"
          }
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          onLoad={() => setLoading(false)}
        ></iframe>
      </div>
    </div>
  );
};

import { useEffect, useRef, useState } from "react";
import { StudentApi } from "src/api/student.api";
import { useStateValue } from "src/context/StateProvider";
import EmptyList from "src/components/Shared/EmptyList";
import LoadingScreen from "src/components/LottieAnimations/LoadingBarScreen";
import Leader from "./Leader";
import { format } from "date-fns";

export interface ILeaderboard {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  avatar: string;
  cowries: number;
}
export default function Leaderboard() {
  const [{ user }] = useStateValue();
  console.log(user);
  const [leaderboardData, setLeaderboardData] = useState<ILeaderboard[]>([]);
  const [isLoading, setisLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState<string>('');

  const getLeaderboardData = async () => {
    setisLoading(true);
    const response = await StudentApi.getLeaderboard();
    setisLoading(false);
    const leaders: ILeaderboard[] = response.data.leaderboard;
    if (!leaders.find((leader) => leader.id === user._id)) {
      leaders.push({
        id: user._id,
        firstName: user.firstname,
        lastName: user.lastname,
        avatar: user.tutoringAppAvatar,
        name: `${user.firstname} ${user.lastname}`,
        cowries: 0,
      });
    }
    setLeaderboardData(leaders.filter((leader) => leader.firstName));
    const lastupdated =format(new Date(response.data.createdAt), "dd MMM yyyy, hh:mm:ss a");

    setLastUpdated(lastupdated)
  };

  useEffect(() => {
    getLeaderboardData();
  }, []);
  const userRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (userRef.current) {
        userRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  if (isLoading) return <LoadingScreen />;

  if (!leaderboardData || leaderboardData.length === 0) {
    return <EmptyList message="No leaderboard" />;
  }

  return (
    <>
      <div className="mb-8">
        <h1 className="pl-4 text-primary max-w-[200px] md:max-w-full  text-left  md:text-center font-semibold text-2xl mb-1">
           Cowry Leaderboard
        </h1>
        <p className="text-secondary capitalize font-medium text-left  md:text-center text-lg">
          {`${format(new Date(), "MMMM")} `}
        </p>
        <p className="text-primary-pale capitalize font-medium text-left  md:text-center text-xs">
       last updated: {lastUpdated}
        </p>
      </div>

      <div className="flex flex-row justify-center">
        <div className="white-card-rounded  pt-2 rounded-2xl w-full max-w-lg flex flex-col  gap-0 ">
          {leaderboardData.map((leader, index) => (
            <div key={index} ref={user._id == leader.id ? userRef : null}>
              <Leader leader={leader} position={index + 1} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

import axios, { AxiosResponse } from "axios";
import { DirectPayState } from "src/context/DirectPayContext";
import { IGoogleUser } from "src/interfaces/auth";
// axios.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   },
//   function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     let errorMessage = "An error occurred";
//     if (axios.isAxiosError(error)) {
//       if (error.response) {
//         errorMessage = error.response.data.message || error.message;
//       } else if (error.request) {
//         errorMessage = "Network error";
//       } else {
//         errorMessage = error.message;
//       }
//     }
//     toast.error(errorMessage, { position: toast.POSITION.BOTTOM_RIGHT });
//     return Promise.reject(error);
//   }
// );
export async function directPaySignup(
  body: DirectPayState
): Promise<AxiosResponse> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_V2_SERVER_URL}/auth/signup`,
      body
    );
    return response.data;
  } catch (error: any) {
    console.log("caught error: ", error);
    throw error;
  }
}
export async function googleSignIn(user: IGoogleUser): Promise<AxiosResponse> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_V2_SERVER_URL}/auth/google`,
      user
    );
    return response;
  } catch (error: any) {
    console.log("caught error: ", error);
    throw error;
  }
}

import React from "react";
import { FaBookReader } from "react-icons/fa";

export default function ConsistencyCard() {
  return (
    <div className="rounded-2xl shadow-lg py-8 px-6 bg-white">
      <div className="bg-yellow-100 p-2 rounded-full w-14">
        <FaBookReader className="text-yellow-400 mx-auto" size={35} />
      </div>
      <div>
        <h3 className="text-secondary font-semibold text-2xl mt-4 flex gap-2">
          Exam Prep
        </h3>
      </div>
      <div className="text-primary mt-3 lg:block">
        We stay on top of latest exam skills and strategies so you don’t have
        to. We cover GCSE, IGCSE, SATs & IB.
      </div>
    </div>
  );
}

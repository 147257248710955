import { AiFillStar } from "react-icons/ai";
import LazyLoad from "react-lazyload";

type TestimonialCardProps = {
  imageSrc: string;
  name: string;
  bio: string;
  review: string;
  reviewBy: string;
  when?: string;
};

export default function TestimonialCard(props: TestimonialCardProps) {
  return (
    <>
      <div className="rounded-2xl shadow-lg py-8 px-4 bg-white">
        <div className="flex justify-around ">
          <LazyLoad>
            <img
              className="rounded-full"
              width={75}
              src={props.imageSrc}
              alt="tesimonial-dp"
            />
          </LazyLoad>
          <div className="flex flex-col justify-center gap-0">
            <p className="text-primary font-bold text-md">{props.name}</p>
            <p className="text-primary font-bold text-sm">{props.bio}</p>
            <div className="flex -ml-1">
              <AiFillStar size={22} color="#D33479" />
              <AiFillStar size={22} color="#D33479" />
              <AiFillStar size={22} color="#D33479" />
              <AiFillStar size={22} color="#D33479" />
              <AiFillStar size={22} color="#D33479" />
            </div>
          </div>
        </div>
        <div className="text-primary w-48 font-medium text-sm ml-2 mt-6 mr-7">
          {props.review}
        </div>
        <div className="text-primary flex font-bold text-sm ml-2 mt-6 mr-7">
          {`${props.reviewBy}${props.when ? ", " + props.when : ""}`}
        </div>
      </div>
    </>
  );
}

import axios from "axios";
import { IAutoBookLesson, SendLessonFeedback } from "src/interfaces/lesson";

export async function postLessonViaAdminRequest(
  payload: any
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/lesson-request-via-admin`,
      payload
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postAssignLessonReward(
  reward: any,
  state: any
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_V2_SERVER_URL}/lessons/reward`,
      {
        lessonId: state.lessonId,
        studentId: state.studentId,
        tutorId: state.tutorId,
        rewardSpecId: reward._id,
      }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getLessonRewardsByLesson(
  lessonId: any
): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_V2_SERVER_URL}/user/lesson-rewards/${lessonId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postEnrollStudentInVirtualImmersion(
  payload: any
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/enroll-student-in-virtual-immersion`,
      payload
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postCancelStudentInVirtualImmersion(
  payload: any
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/cancel-student-in-virtual-immersion`,
      payload
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postLessonViaAdminRequestNoAuth(
  payload: any
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/lesson-request-via-admin-no-auth`,
      payload
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postRescheduleConfirmedLessonViaAdmin(
  newSchedule: any,
  confirmedLessonrequestId: string,
  user?: any
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/reschedule-lesson-request-via-admin/${confirmedLessonrequestId}`,
      newSchedule,
      user
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postRescheduleGroupLessonViaAdmin(
  newSchedule: any,
  confirmedLessonrequestId: string,
  user?: any
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/reschedule-group-lesson/${confirmedLessonrequestId}`,
      newSchedule,
      user
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getTutorZoomLink(
  tutorId: string,
  groupLessonId: string,
  user?: any,
  lesson?: any
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/get-tutor-zoom-link/${tutorId}`,
      { groupLessonId, user, lesson }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getGroupLessonFeedbackLink(
  lessonId: string,
  url?: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/get-group-lesson-feedback-links/${lessonId}`,
      { url }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getGroupLessonInstances(
  groupLessonId: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/group-lesson/get-lesson-instances/${groupLessonId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function sendFeedback(
  body: SendLessonFeedback
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/lesson-feedback/${body.lessonId}`,
      {
        ...body,
      }
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function getLesson(lessonId?: string): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/lesson/get-lesson/${lessonId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getVirtualImmersions(
  subject: string,
  userId: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/get-virtual-immersions-by-id/${subject}/${userId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postStudentLessonFeedback(
  lessonId: string,
  rating: number,
  lesson?: any
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/student-lesson-feedback-post/${lessonId}`,
      { rating, lesson }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function postStudentLessonFeedbackComment(
  lessonId: string,
  lesson?: any,
  comment?: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/student-lesson-feedback-comment-post/${lessonId}`,
      { lesson, comment }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function getStudentLessonFeedback(
  studentId: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/student-past-feedback-lessons/${studentId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function getStudentLessonDetails(
  studentId: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/student-lesson-details/${studentId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function getStudentNewRewards(
  studentId: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_V2_SERVER_URL}/user/new-rewards/${studentId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function getTutorLessonFeedback(
  tutorId: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/tutor-past-feedback-lessons/${tutorId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function getLessonRewards(): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_V2_SERVER_URL}/lessons/rewards`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function markFeedbackAsRead(
  lessonId: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/mark-feedback-as-read/${lessonId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function autoBookLesson(
  body: IAutoBookLesson
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_V2_SERVER_URL}/lessons/book-lesson`,
      body
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
    throw error;
  }
}
export async function rescheduleDpLessons(
  studentId: string,
  subscriptionId: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_V2_SERVER_URL}/lessons/reschedule-lesson`,
      { studentId, subscriptionId }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
    throw error;
  }
}
export async function updateLessonProgress(
  studentId: string,
  body: any
): Promise<any | undefined[]> {
  try {
    let response = await axios.patch(
      `${process.env.REACT_APP_V2_SERVER_URL}/lessons/lesson-progress/${studentId}`,
      body
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
    throw error;
  }
}

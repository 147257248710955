import { ageGroups } from "src/context/DirectPayContext";
import Dropdown from "./Dropdown";
import HeaderText from "./HeaderText";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { useContext } from "react";
import { toast } from "react-toastify";
interface IAgeGroupForm {
  alt?: boolean;
  onClick?: () => void;
}
export default function AgeGroupForm({ alt, onClick }: IAgeGroupForm) {
  const {
    state: { ageGroup },
    actions: { setAgeGroup },
  } = useContext(DirectPayContext);
  const handleValidator = () => {
    if (!ageGroup) {
      toast.error("Please select an age group.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    if (onClick) onClick();
  };
  return (
    <>
      <div className="flex  flex-col items-center">
        <HeaderText>What age group is the learner in?</HeaderText>
        <div className="max-w-sm w-full">
          <Dropdown
            items={ageGroups}
            selectedItem={ageGroup}
            setSelectedItem={setAgeGroup}
          />
        </div>
      </div>
      {alt && (
        <button data-test="d2pContinueButton" onClick={handleValidator} className="pink-button px-20 mt-14">
          Continue
        </button>
      )}
    </>
  );
}

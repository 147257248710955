import React from "react";
import { IClientCurrencyData } from "src/interfaces/currency";
import { ITutorSearchResult } from "src/interfaces/tutor";

type TopInfoSectionType = {
  tutorProfile: ITutorSearchResult;
  currency: IClientCurrencyData | null;
};

export default function AvatarPrice({
  tutorProfile,
  currency,
}: TopInfoSectionType) {
  return (
    <div className="">
      <img
        src={tutorProfile.avatar}
        alt={tutorProfile.firstname}
        className="rounded-xl w-[13rem] h-[12rem] object-cover object-top"
      />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { content } from "../Content/content";
import SentenceBuilder from "./SentenceBuilder/SentenceBuilder";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressBar from "./ProgressBar";
import Header from "src/layouts/DashboardHeader";
import ResultsPage from "./ResultsPage";
import BetaTag from "src/components/BetaTag";
import { NavLink, useParams } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { set } from "lodash";
import { useStateValue } from "src/context/StateProvider";
import { LearningAppApi } from "src/api/learning-app";

toast.configure();

export default function LearningApp() {
  const [{ user }] = useStateValue();
  const { languageId, levelId, sessionId } = useParams();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [session, setSession] = useState<any>(null);
  const [sessionTracker, setSessionTracker] = useState<any>(null);
  const [numberOfQuestions, setNumberOfQuestions] = useState<number>(0);
  const [question, setQuestion] = useState<any>();
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const correctAnswerSound = new Audio("/ding.mp3");

  // get content where languageId, levelId, and sessionId match
  useEffect(() => {
    const newSession = content.find(
      (item) =>
        item.language === languageId &&
        item.level === Number(levelId) &&
        item.session === Number(sessionId)
    );
    setSession(newSession);
    setSessionTracker(
      newSession ? JSON.parse(JSON.stringify(newSession)) : null
    );
    LearningAppApi.postSessionStartedAuditLog(
      user,
      languageId,
      levelId,
      sessionId
    );
  }, []);

  useEffect(() => {
    if (!session) return;
    // Fetch the questions from the API
    setQuestion(session.questions[currentIndex]);
    setNumberOfQuestions(session.questions.length);
  }, [session, question]);

  // Start timer
  useEffect(() => {
    const newTimer = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1);
    }, 1000);

    setTimer(newTimer);

    return () => {
      if (timer) clearInterval(timer);
    };
  }, []);

  // Stop timer on quiz completion
  useEffect(() => {
    if (isComplete && timer) {
      clearInterval(timer);
      setTimer(null); // Stop the timer
    }
  }, [isComplete, timer]);

  // Format time from seconds to MM:SS
  const formatTime = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const normalizeSentence = (sentenceArray: any[]) => {
    return sentenceArray
      .map((item) =>
        item.word
          .trim()
          .toLowerCase()
          .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
      )
      .filter((word) => word); // This filter removes any empty strings that might result from the normalization
  };

  const evaluateSentenceConstruction = (
    userSentence: any[],
    targetSentences: any
  ) => {
    // Normalize the user's sentence array of objects to a string
    const normalizedUserSentence = normalizeSentence(userSentence).join(" ");

    // Normalize each correct sentence and check if any matches the user's sentence
    const isCorrect = targetSentences
      .map((sentence: string) =>
        sentence
          .toLowerCase()
          .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
          .trim()
      )
      .includes(normalizedUserSentence);

    if (isCorrect) {
      return { isValid: true, feedback: "Correct sentence!" };
    } else {
      return {
        isValid: false,
        feedback: `The sentence is incorrect. Try again!`,
      };
    }
  };

  const handleSubmit = (questionType: string, value: any) => {
    let evaluateAnswer = null;
    if (questionType === "sentence-construction") {
      evaluateAnswer = evaluateSentenceConstruction;
    }
    if (!evaluateAnswer) return;

    const { isValid, feedback } = evaluateAnswer(
      value.sentence,
      value.targetSentences
    );

    // track score
    if (currentIndex < sessionTracker.questions.length) {
      sessionTracker.questions[currentIndex].score = isValid ? 1 : 0;
      setSessionTracker(sessionTracker);
    }

    if (isValid) {
      setTimeout(() => {
        correctAnswerSound.play();
      }, 300);
      toast.success(`Correct: ${value.targetSentences[0]}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    } else {
      toast.error(`Incorrect: ${value.targetSentences[0]}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
      // Add the question back to the end of the sessionTracker
      const newSession = { ...session };
      newSession.questions.push(question);
      setSession(newSession);
    }

    const onToNextQuestion = () => {
      const nextIndex = currentIndex + 1;
      setCurrentIndex(nextIndex);
      if (nextIndex < numberOfQuestions) {
        setQuestion(session[nextIndex]);
      } else {
        if (isValid) {
          setIsComplete(true);
          toast.success(`You have completed this session`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        }
      }
    };

    setTimeout(onToNextQuestion, 2000);
  };

  const calculateScorePercentage = () => {
    let totalScore = 0;
    sessionTracker.questions.forEach((question: any) => {
      totalScore += question.score;
    });
    return Math.ceil((totalScore / sessionTracker.questions.length) * 100);
  };

  if (!question) return null;

  return (
    <>
      <div className="z-10">
        <Header />
      </div>
      <div className="px-4 relative">
        <div className="-mt-1">
          <ProgressBar
            currentIndex={currentIndex}
            numberOfQuestions={numberOfQuestions}
          />
        </div>
        <div className="flex justify-between items-center py-4">
          <NavLink to={`/learn/${languageId}/${levelId}`}>
            <div className="text-gray-purple cursor-pointer items-center flex gap-2">
              <div>
                <AiOutlineCloseCircle />
              </div>
              close
            </div>
          </NavLink>
          <div className="max-w-[150px]">
            <BetaTag />
          </div>
        </div>
        {/* <div className="text-primary-medium font-semibold p-2">
        {formatTime(elapsedTime)}
      </div> */}
        {!isComplete && (
          <div>
            {question.type === "sentence-construction" && (
              <SentenceBuilder
                question={question}
                handleSubmit={handleSubmit}
              />
            )}
          </div>
        )}
        {isComplete && (
          <div>
            <ResultsPage
              elapsedTime={formatTime(elapsedTime)}
              score={calculateScorePercentage()}
            />
          </div>
        )}
      </div>
    </>
  );
}

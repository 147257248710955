import Menu from "./Menu";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

export default function index() {
  return (
    <div className="hidden border-r-2 justify-between fixed w-64 h-screen lg:flex flex-col py-10">
      <PerfectScrollbar className="px-6">
        <div className="pb-12">
          <Menu />
        </div>
      </PerfectScrollbar>
    </div>
  );
}

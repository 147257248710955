import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { routes } from "src/Routes";
import MeetMatchedTutors from "src/components/DirectToPay/MeetMatchedTutors";
import EmptyList from "src/components/Shared/EmptyList";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { useStateValue } from "src/context/StateProvider";
import Header from "src/layouts/FixedDashboardHeader";
import { tutorDescription } from "src/static-data/direct-to-pay/tutorDescription";

export default function MeetTutors() {
  const {
    state: { onBoarding },
    actions: { setDirectPayUser, setLessonSchedule },
  } = useContext(DirectPayContext);
  const [{ user }] = useStateValue();
  const [character, setCharacter] = useState<string>("");
  //Validate direct pay user
  useEffect(() => {
    setDirectPayUser(true);
    setCharacter(user?.character);
    //clear lesson schedule
    setLessonSchedule([]);
    // return () => {
    //   setDirectPayUser(false);
    // };
  }, []);
  if (!user) {
    return <Navigate to={routes.LOGIN.url} />;
  }

  return (
    <>
      <Header />
      <div className="text-primary p-4 md:p-8 relative min-h-screen h-full bg-white">
        {!onBoarding.tutors ? (
          <EmptyList message="No tutors found" />
        ) : (
          <>
            <section className="  text-center z-10 container mt-6 px-4 md:px-8  left-8 right-8 ">
              <h1 className="font-bold text-3xl leading-10 max-w-4xl mx-auto ">
                Here are a few tutors we think you would love!
              </h1>
              <p className="text-m font-semibold text-secondary text-sm leading-7 mt-1 max-w-4xl mx-auto ">
                {" "}
                Select a tutor, explore their profile, and choose the one who
                resonates most with your learning goals.
              </p>
              <p className="text-m font-medium text-primary leading-7 mt-4 max-w-4xl mx-auto ">
                {onBoarding.onboarding &&
                  onBoarding.onboarding.personality &&
                  character &&
                  tutorDescription[
                    `${character}_${onBoarding.onboarding.personality}`
                  ]}
              </p>
            </section>

            <MeetMatchedTutors tutors={onBoarding.tutors} />
          </>
        )}
      </div>
    </>
  );
}

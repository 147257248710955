import React, { ReactNode } from "react";

interface IChatBubble {
  children: ReactNode;
}
const ChatBubble = ({ children }: IChatBubble) => {
  return (
    <div className="z-50 fixed bottom-8 right-8 bg-green-500 rounded-full w-10 h-10 flex justify-center items-center cursor-pointer shadow-xl">
      {children}
    </div>
  );
};

export default ChatBubble;

import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Header from "src/layouts/DashboardHeader";
import { content } from "../Content/content";
import { routes } from "src/Routes";
import BetaTag from "src/components/BetaTag";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

export default function LanguageLanguageLanding() {
  const { languageId, levelId } = useParams();
  const [sessionIds, setSessionIds] = useState<any[]>([]);

  const findUniqueSessionIds = (
    content: any,
    languageId: string | undefined
  ) => {
    const uniqueSessionIds = new Set();
    content.forEach(
      (item: { session: number; language: string; topic: string }) => {
        if (item.language === languageId) {
          uniqueSessionIds.add({ session: item.session, topic: item.topic });
        }
      }
    );
    return Array.from(uniqueSessionIds);
  };

  useEffect(() => {
    const languageLevels = findUniqueSessionIds(content, languageId);
    setSessionIds(languageLevels);
  }, []);

  return (
    <>
      <Header />

      <div className="mt-4 px-4">
        <div className="flex justify-between items-center py-4">
          <NavLink to={`/learn/${languageId}`}>
            <div className="text-gray-purple cursor-pointer items-center flex gap-2">
              <div>
                <MdOutlineKeyboardBackspace />
              </div>
              back to levels
            </div>
          </NavLink>
          <div className="max-w-[150px]">
            <BetaTag />
          </div>
        </div>
        <h1 className="lg:text-2xl font-semibold mb-2 text-primary-light text-center">
          {`${languageId?.charAt(0).toUpperCase()}${languageId?.slice(
            1
          )}, Level ${levelId}`}
        </h1>
      </div>
      <div className="flex flex-col gap-4 lg:px-4 mt-4">
        {sessionIds.map((sessionId: any, index: number) => (
          <NavLink
            key={index}
            to={`${
              routes.LEARNING_APP.url
            }/${languageId?.toLowerCase()}/${levelId}/${sessionId.session}`}
          >
            <div className="white-card-rounded">
              <h1 className="lg:text-l font-semibold my-2 text-secondary-light text-center">
                {`Session ${sessionId.session}: ${sessionId.topic}`}
              </h1>
            </div>
          </NavLink>
        ))}
      </div>
    </>
  );
}

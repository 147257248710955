import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import FadeInDown from "src/components/MotionDivs/FadeInDown";
import "react-toastify/dist/ReactToastify.css";
import { LessonApi } from "src/api/lesson.api";
import { BiSolidCoffeeBean } from "react-icons/bi";
import { toast } from "react-toastify";

toast.configure();

type DetailsModalProps = {
  isConfirmationModalOpen: boolean;
  setIsConfirmationModalOpen: any;
  selectedReward: any;
  state: any;
  getRewardsData: any;
};

export default function ConfirmationModal({
  selectedReward,
  isConfirmationModalOpen,
  setIsConfirmationModalOpen,
  state,
  getRewardsData,
}: DetailsModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  if (!isConfirmationModalOpen) return null;

  const handleAssignReward = async () => {
    setIsSubmitting(true);
    const response = await LessonApi.postAssignLessonReward(
      selectedReward,
      state
    );
    getRewardsData();
    setIsConfirmationModalOpen(false);
    if (response.status === 200) {
      toast.success("Reward Granted Successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error("Reward Granting Failed!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    setIsSubmitting(false);
  };

  const handleCloseModal = () => {
    setIsConfirmationModalOpen(false);
  };

  return (
    <div className="modalOverlay">
      <FadeInDown>
        <div className="bg-white flex flex-col justify-center items-center p-4 rounded-md relative max-w-[250px] overflow-scroll">
          <div className="absolute right-3 top-3">
            <AiOutlineClose
              className="text-gray-400 hover:text-red-400 cursor-pointer"
              onClick={() => setIsConfirmationModalOpen(false)}
            />
          </div>
          <h3 className="text-primary text-xl text-center font-semibold flex justify-center mt-4 mb-2">
            {`Grant Reward to ${state.studentName}?`}
          </h3>
          <img
            src={selectedReward.image}
            alt={selectedReward.name}
            className="w-16 py-4 mx-auto"
          />
          <p className="text-primary font-semibold text-xs text-center">
            {selectedReward.name}
          </p>
          <p className="text-primary-gray text-xxs text-center">
            {selectedReward.description}
          </p>
          <p className="text-primary font-semibold text-xs text-center my-2 flex gap-1">
            {}
            <BiSolidCoffeeBean className="text-yellow-500 mt-1" />+
            {selectedReward.cowries}
          </p>
          <p className="text-primary flex text-sm text-center justify-center mt-3"></p>
          <button
            className={
              isSubmitting
                ? "flex gap-2 justify-center items-center  bg-gray-200 p-2 w-40 rounded-lg"
                : "flex gap-2 justify-center items-center cursor-pointer bg-secondary hover:bg-secondary-light p-2 w-40 rounded-lg"
            }
            onClick={handleAssignReward}
            disabled={isSubmitting}
          >
            <p className="text-white text-xs font-semibold">Confirm</p>
          </button>
          <button
            className="flex gap-2 justify-center items-center mt-2 mb-4 cursor-pointer bg-pale-purple-bg p-2 w-40 rounded-lg"
            onClick={() => handleCloseModal()}
          >
            <p className="text-primary text-xs font-semibold">Cancel</p>
          </button>
        </div>
      </FadeInDown>
    </div>
  );
}

import React from "react";
import { BiSolidCoffeeBean } from "react-icons/bi";
import moment from "moment";
import { ILeaderboard } from ".";
import { FaCrown, FaUserCircle } from "react-icons/fa";
import SVGLinearGradient from "src/components/Shared/SVGLinearGradient";
import { HiBadgeCheck } from "react-icons/hi";
import Tippy from "@tippyjs/react";
import { PayerTypeKey } from "src/static-data/direct-to-pay/payerType";
import { LuCrown } from "react-icons/lu";
import { useStateValue } from "src/context/StateProvider";
export type LeaderKey = keyof typeof leaderColours;

export const leaderColours = {
  "2": {
    light: "#C0C0C0",
    dark: "#808080",
  },
  "3":{
    light: "#FFCDD2",
    dark: "#965424",
  },
  "1": {
    light: "#FFD700",
    dark: "#FFB800",
  },
};
export default function Leader({
  leader,
  position,
}: {
  leader: ILeaderboard;
  position: number;
}) {
  const [{ user }] = useStateValue();
  const shortName = `${leader.firstName} ${leader.lastName.charAt(0)}`;
  return (
    <>
      <div className={`flex py-[10px]  border-b-2 px-3 items-center justify-between ${user._id === leader.id ? "bg-[#EFE6FF]" : "bg-white"}`}>
        <div className="flex items-center gap-3">
          <p className="text-primary font-bold text-lg">{position}.</p>
          <div
            className={`p-[0.15rem]  rounded-full relative`}
            style={{
              backgroundImage:
                [1, 2, 3].includes(position) ?
              '' :  `linear-gradient(to right, ${
                leaderColours[position.toString() as LeaderKey]?.light
              }, ${leaderColours[position.toString() as LeaderKey]?.dark})`,
              backgroundColor: [1, 2, 3].includes(position)
                ? leaderColours[position.toString() as LeaderKey]?.dark
                : "#9747FF",
            }}
          >
            {leader.avatar ? (
              <img
                className="w-10 h-10 rounded-full object-cover"
                src={leader.avatar}
                alt=""
                referrerPolicy="no-referrer"
              />
            ) : (
              <FaUserCircle
                size={40}
                className={`${position ? "text-white" : "text-light-purple"}  `}
              />
            )}

            <div className="bg-white absolute -right-2 -bottom-1 rounded-full">
              {![1, 2, 3].includes(position) && (
                <SVGLinearGradient
                  id={`mobile-badge-gradient-${position}`}
                  to={leaderColours[position.toString() as LeaderKey]?.light}
                  from={leaderColours[position.toString() as LeaderKey]?.dark}
                />
              )}
              <div>
                
              </div>
             {[1, 2, 3].includes(position) && (
              <div style={{backgroundColor : leaderColours[position.toString() as LeaderKey]?.dark}} className={`rounded-full p-1`}>
                <FaCrown
                size={17}
                className="rounded-full text-white"
                
              />
              </div>
             )}
            </div>
          </div>

          <p className="text-primary capitalize font-medium text-lg text-center">
            {shortName.toLowerCase()}
          </p>
        </div>
        <div className="flex gap-1 items-center">
          <img
            className="w-6 h-6 rounded-full object-cover"
            src="https://storage.googleapis.com/topset-app-assets/cowry_icon.png"
            alt="cowry"
          />
          <p className="text-primary  font-bold text-lg text-center">
            {leader.cowries}
          </p>
        </div>
      </div>
    </>
  );
}

import React from "react";

import { MdVerified } from "react-icons/md";

export default function VettedByTopsetBadge() {
  return (
    <div className="flex -ml-1 gap-4 max-w-[230px] py-2 px-2 items-center rounded-xl bg-very-light-green">
      <div>
        <MdVerified className="text-green-500" size={24} />
      </div>
      <div>
        <p className="text-xxs text-primary font-semibold">
          Personally interviewed by Topset
        </p>
      </div>
    </div>
  );
}

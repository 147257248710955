import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";

interface IMultiSelectDropdown {
  selectedOptions: string[];
  onOptionClick: (value: string) => void;
}

export default function SelectedOptions({
  selectedOptions,
  onOptionClick,
}: IMultiSelectDropdown) {
  return (
    <>
      <div className="flex flex-wrap gap-2">
        {selectedOptions.map((option) => (
          <div
            key={option}
            className="bg-primary text-sm font-medium text-white uppercase px-3 py-1 rounded-lg flex gap-2"
          >
            {option}
            <span
              className="text-gray-200 mt-1 cursor-pointer"
              onClick={() => onOptionClick(option)}
            >
              <AiFillCloseCircle size={15} />
            </span>
          </div>
        ))}
      </div>
    </>
  );
}

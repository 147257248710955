import { ReactNode } from "react";

interface IDropdownContent {
  children: ReactNode;
  open: boolean;
}

export default function DropdownContent({ children, open }: IDropdownContent) {
  return (
    <>
      {open && (
        <div
          className={` dropdown ${
            open ? "fadeIn" : ""
          } flex text-left flex-col gap-6 py-4 px-3 border rounded-3xl mt-4 absolute`}
          style={{
            backgroundColor: "#F8F8F8",
            borderColor: "#F4F4F4",
            maxHeight: "300px", // Adjust the maximum height as needed
            overflowY: "auto", // Enable vertical scrolling
          }}
        >
          {children}
        </div>
      )}
    </>
  );
}

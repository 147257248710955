import { Navigate } from "react-router-dom";
import { routes } from "src/Routes";
import { useStateValue } from "src/context/StateProvider";
type GuestGuardProps = {
  children: any;
};

export const GuestGuard = ({ children }: GuestGuardProps) => {
  const [{ user }] = useStateValue();
  // const {
  //   state: { onBoarding },
  // } = useContext(DirectPayContext);
  // if (user && onBoarding.status && !onBoarding?.onboarding?.isLegacyCustomer) {
  //   if (onBoarding.status !== "COMPLETE") {
  //     if (onBoarding?.onboarding?.leadFunnel === "direct-to-pay-flow-1" ||
  //       onBoarding?.onboarding?.leadFunnel === "direct-to-pay-flow-3"
  //     ) {
  //       if (onBoarding?.tutors && onBoarding?.tutors?.length > 0) {
  //         return <Navigate to={routes.DIRECT_TO_PAY_MEET_TUTORS.url} />;
  //       }
  //     } else {
  //       if (onBoarding?.onboarding?.paymentStatus === "COMPLETE") {
  //         if (!onBoarding.onboarding.ageGroup) {
  //           return (
  //             <Navigate
  //               to={
  //                 routes.DIRECT_TO_PAY_GET_STARTED_B.url +
  //                 `?lang=${onBoarding.onboarding.subject}`
  //               }
  //             />
  //           );
  //         } else {
  //           return <Navigate to={routes.DIRECT_TO_PAY_MEET_TUTORS_B.url} />;
  //         }
  //       } else {
  //         return (
  //           <Navigate
  //             to={routes.DIRECT_TO_PAY_WELCOME_B.url + "/payment-plans"}
  //           />
  //         );
  //       }
  //     }
  //   }
  // }
  if (user) {
    if (user?.type === "student" && !user?.phone_number) {
      return <Navigate to={routes.STUDENT_DASHBOARD_BIO.url} />;
    }
    return <Navigate to={routes.STUDENT_DASHBOARD_UPCOMING_LESSONS.url} />;
  }

  return children;
};

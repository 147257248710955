import { CountryData } from "react-phone-input-2";

export const removeZeroPrefix = (
  phone: string,
  country: CountryData
): { phoneFormat: string } => {
  let phoneFormat = phone;
  if (phoneFormat[country.dialCode.length] === "0" && phoneFormat[1]) {
    phoneFormat = phoneFormat.replace("0", "");
  }
  return { phoneFormat };
};

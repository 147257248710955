import React from "react";
import { RiLogoutBoxRLine } from "react-icons/ri";

export default function Logout() {
  const handleLogout = () => {
    window.localStorage.removeItem("accessToken");
    window.open(`${process.env.REACT_APP_SERVER_URL}/logout`, "_self");
  };

  return (
    <div
      className="text-red-500 flex gap-3 items-center lg:hidden hover:text-red-700 cursor-pointer"
      onClick={handleLogout}
    >
      <RiLogoutBoxRLine />
      <h3 className="text-lg font-semibold ">Logout</h3>
    </div>
  );
}

import Lottie from "lottie-react";
import * as error from "src/assets/animations/error.json";

export default function ThankYouAnimation() {
  return (
    <Lottie
      animationData={error}
      loop={false}
      style={{
        height: "200px",
      }}
    />
  );
}

import { FiSearch } from "react-icons/fi";

export default function SearchBar({ sessions, setFilteredSessions }: any) {
  const handleFilterImmersions = (e: any) => {
    const filteredSessions = sessions.filter((session: any) =>
      session.title.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredSessions(filteredSessions);
  };

  return (
    <div className="white-card-rounded">
      <div className="flex px-2 py-2 justify-between">
        <div className="pl-2 flex gap-6 items-center flex-1">
          <FiSearch size={25} className="text-gray-purple" />
          <input
            type="text"
            placeholder="Search virtual immersions"
            className="w-full  text-lg text-gray-purple no-focus-outline"
            onInput={(e) => handleFilterImmersions(e)}
          />
          <button className="pink-button">Search</button>
        </div>
      </div>
    </div>
  );
}

import { IClientCurrencyData } from "src/interfaces/currency";

export const getTutorCurrency = (countryCode: string) => {
  if (countryCode === "ng") {
    return {
      symbol: "₦",
      toDollarValue: 0.0024,
      toPoundValue: 0.0018,
      currencyCode: "NGN",
      incrementValue: 500,
      recommendedValue: 5000,
    };
  }

  if (countryCode === "gb") {
    return {
      symbol: "£",
      toDollarValue: 1.35,
      toNairaValue: 562,
      currencyCode: "GBP",
      incrementValue: 1,
      recommendedValue: 25,
    };
  }

  if (countryCode !== "ng" && countryCode !== "uk") {
    return {
      symbol: "$",
      toNairaValue: 415.88,
      toPoundValue: 0.74,
      currencyCode: "USD",
      incrementValue: 1,
      recommendedValue: 25,
    };
  }
};

export const getClientCurrency = (countryCode: string): IClientCurrencyData => {
  if (countryCode?.toLowerCase() === "ng") {
    return {
      symbol: "₦",
      fromDollarValue: 500,
      fromPoundValue: 580,
      fromNairaValue: 1,
      currencyCode: "NGN",
    };
  }

  if (countryCode?.toLowerCase() === "gb") {
    return {
      symbol: "£",
      fromDollarValue: 0.87,
      fromNairaValue: 0.0018,
      fromPoundValue: 1,
      currencyCode: "GBP",
    };
  }
  if (countryCode?.toLowerCase() === "ca") {
    return {
      symbol: "$",
      fromNairaValue: 0.00086,
      fromPoundValue: 1.71617,
      fromDollarValue: 1.36,
      currencyCode: "CAD",
    };
  }

  return {
    symbol: "$",
    fromNairaValue: 0.0024,
    fromPoundValue: 1.34,
    fromDollarValue: 1,
    currencyCode: "USD",
  };
};

export const getFX = (
  currencyCode: string,
  clientIpCountry: string
): number => {
  const clientCurrency = getClientCurrency(clientIpCountry);
  if (currencyCode === "NGN") {
    return clientCurrency.fromNairaValue;
  }
  if (currencyCode === "USD") {
    return clientCurrency.fromDollarValue;
  }
  if (currencyCode === "GBP") {
    return clientCurrency.fromPoundValue;
  }
  return 1;
};

export const getLessonPrice = (clientIpCountry: string): any => {
  const clientCurrency = getClientCurrency(clientIpCountry);
  if (clientCurrency.symbol === "₦") {
    return {
      lowerPrice: 12000,
      upperPrice: 21000,
      currencySymbol: clientCurrency.symbol,
    };
  }
  if (clientCurrency.symbol === "$") {
    return {
      lowerPrice: 15,
      upperPrice: 35,
      currencySymbol: clientCurrency.symbol,
    };
  }
  if (clientCurrency.symbol === "£") {
    return {
      lowerPrice: 15,
      upperPrice: 35,
      currencySymbol: clientCurrency.symbol,
    };
  }
  return 1;
};

import { loadStripe } from "@stripe/stripe-js";

const initStripe = () => {
	let stripePromise;
	if (!stripePromise) {
		stripePromise = loadStripe(
			process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
		);
	}
	return stripePromise;
};

export default initStripe;

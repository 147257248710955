import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { LearningAppApi } from "src/api/learning-app";
import BackToResources from "src/components/BackToResources";
import Flashcard from "src/components/Flashcard/Flashcard";
import { FaArrowRight, FaCheck } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";
import Switch from "react-switch";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import ResultsPage from "./ResultsPage";
import { set } from "lodash";
import EmptyList from "src/components/Shared/EmptyList";
import LoadingBarScreen from "src/components/LottieAnimations/LoadingBarScreen";
import { LuUndo2 } from "react-icons/lu";
import Tippy from "@tippyjs/react";

export default function FlashcardsSession() {
  const { userId, level, topic, order, topicId } = useParams();
  const [userFlashcardProgress, setUserFlashcardProgress] = useState<any>(null);
  const [vocabList, setVocabList] = useState<any>(null);
  const [sessionIndex, setSessionIndex] = useState(0);
  const [isTrackingProgress, setIsTrackingProgress] = useState(true);
  const [isSessionComplete, setIsSessionComplete] = useState(false);
  const [wordsStillLearning, setWordsStillLearning] = useState<any>([]);
  const [wordsLearned, setWordsLearned] = useState<any>([]);
  const [
    unlearnedWordsFromPreviousSession,
    setUnlearnedWordsFromPreviousSession,
  ] = useState([]);
  const [isSessionReady, setIsSessionReady] = useState(false);

  console.log("level", level);
  console.log("topic", topic);
  console.log("order", order);
  console.log("topicId", topicId);

  const getTopicVocabList = useCallback(async () => {
    const vocabListResponse = await LearningAppApi.getTopicVocabList(
      topicId,
      userId
    );
    console.log("vocabListResponse", vocabListResponse);
    setVocabList(vocabListResponse.data.vocabList);
    setUserFlashcardProgress(vocabListResponse.data.userFlashcardProgress);
  }, [userId]);

  console.log("vocabList", vocabList);

  useEffect(() => {
    getTopicVocabList();
  }, [getTopicVocabList]);

  const getUnlearnedWords = useCallback(() => {
    if (!vocabList || !userFlashcardProgress) return;
    const unlearnedWords = vocabList.filter((item: any) =>
      userFlashcardProgress.some(
        (word: any) => word.wordId === item._id && word.isLearned === false
      )
    );
    console.log("unlearnedWords", unlearnedWords);
    setUnlearnedWordsFromPreviousSession(unlearnedWords);
    if (unlearnedWords.length === 0) {
      setIsSessionReady(true);
    }
  }, [vocabList, userFlashcardProgress]);

  useEffect(() => {
    getUnlearnedWords();
  }, [getUnlearnedWords]);

  const handleChangeIsTrackingProgress = () => {
    setIsTrackingProgress(!isTrackingProgress);
  };

  const handlePrevious = () => {
    if (sessionIndex > 0) {
      setSessionIndex(sessionIndex - 1);
    }
  };

  const handleNext = () => {
    if (sessionIndex < vocabList.length - 1) {
      setSessionIndex(sessionIndex + 1);
      // remove word from wordsStillLearning if it exists
      const updatedWordsStillLearning = wordsStillLearning.filter(
        (word: any) => word !== vocabList[sessionIndex]
      );
      setWordsStillLearning(updatedWordsStillLearning);
    } else {
      setIsSessionComplete(true);
      const learnedWords = vocabList.filter(
        (word: any) => !wordsStillLearning.includes(word)
      );
      console.log("learnedWords", learnedWords);
      setWordsLearned(learnedWords);
      console.log("session completed");
    }
  };

  const handleStillLearning = () => {
    console.log("still learning");
    setWordsStillLearning([...wordsStillLearning, vocabList[sessionIndex]]);
    if (sessionIndex < vocabList.length - 1) {
      setSessionIndex(sessionIndex + 1);
    } else {
      setIsSessionComplete(true);
      const learnedWords = vocabList.filter(
        (word: any) => !wordsStillLearning.includes(word)
      );
      console.log("learnedWords", learnedWords);
      setWordsLearned(learnedWords);
      console.log("session completed");
    }
  };

  const handleContinueLearning = () => {
    console.log("continue learning");
    setVocabList(unlearnedWordsFromPreviousSession);
    setSessionIndex(0);
    setIsSessionComplete(false);
    setIsSessionReady(true);
  };

  const handleStartNewSession = () => {
    console.log("start new session");
    setVocabList(vocabList);
    setSessionIndex(0);
    setIsSessionComplete(false);
    setIsSessionReady(true);
  };

  console.log("vocabList", vocabList);

  if (!vocabList) {
    return <LoadingBarScreen />;
  }

  if (vocabList.length === 0) {
    return <EmptyList message="Flashcards coming soon" />;
  }

  return (
    <>
      <div>
        <BackToResources isBetaVersion={true} sessionType={"Flashcards"} />
      </div>
      {!isSessionReady && unlearnedWordsFromPreviousSession.length > 0 && (
        <div>
          <h1 className="text-primary font-semibold mt-8">
            You have {unlearnedWordsFromPreviousSession.length} words to review
            from the previous session
          </h1>
          <div className="flex gap-4 mt-4">
            {/* ask user to continue learning from previous session or start a new session */}
            <button
              className="p-3 border-2 border-primary rounded-lg"
              onClick={handleContinueLearning}
            >
              Continue from previous session
            </button>
            <button
              className="p-3 border-2 border-primary rounded-lg"
              onClick={handleStartNewSession}
            >
              Start a new session
            </button>
          </div>
        </div>
      )}

      {isSessionReady && !isSessionComplete && (
        <>
          <div className="mt-8">
            <Flashcard word={vocabList?.[sessionIndex]} />
          </div>
          <div className="justify-center flex gap-2 mt-8">
            {Array.from({ length: vocabList.length }).map((_, index) => (
              <div
                key={index}
                className={`w-3 h-3 rounded-full ${
                  index === sessionIndex ? "bg-primary" : "bg-gray-300"
                }`}
              ></div>
            ))}
          </div>
          <div className="mt-4 flex gap-6 justify-between">
            {/* <div className="flex gap-2">
              <p className="text-secondary py-3 font font-semibold">
                Track Progress
              </p>
              <div className="py-3">
                <Switch
                  onChange={handleChangeIsTrackingProgress}
                  checked={isTrackingProgress}
                  uncheckedIcon={false}
                  height={25}
                  width={50}
                  offColor="#A094AF"
                />
              </div>
            </div> */}
            {!isTrackingProgress && (
              <div className="flex gap-6">
                <button
                  className={
                    sessionIndex > 0
                      ? "p-3 border-2 border-primary rounded-lg"
                      : "p-3 border-2 border-gray-300 text-gray-300 rounded-lg"
                  }
                  onClick={handlePrevious}
                >
                  <FaArrowLeft />
                </button>
                <p className="py-3 pl-6 w-20">{`${sessionIndex + 1} / ${
                  vocabList.length
                }`}</p>
                <button
                  className="p-3 border-2 border-primary rounded-lg"
                  onClick={handleNext}
                >
                  <FaArrowRight />
                </button>
              </div>
            )}
            {isTrackingProgress && (
              <div className="flex gap-6 mx-auto">
                <Tippy content="Undo" delay={500} placement="top">
                  <button
                    className={
                      sessionIndex > 0
                        ? "p-3 border-2 border-primary rounded-lg"
                        : "p-3 border-2 border-gray-300 text-gray-300 rounded-lg"
                    }
                    onClick={handlePrevious}
                  >
                    <LuUndo2 />
                  </button>
                </Tippy>
                <Tippy content="Still Learning" delay={500} placement="top">
                  <button
                    className="p-3 border-2 text-red-500 border-primary rounded-lg"
                    onClick={handleStillLearning}
                  >
                    <MdClose />
                  </button>
                </Tippy>
                <p className="py-3 pl-6 w-20">{`${sessionIndex + 1} / ${
                  vocabList.length
                }`}</p>
                <Tippy content="Learned" delay={500} placement="top">
                  <button
                    className="p-3 border-2 text-green-500 border-primary rounded-lg"
                    onClick={handleNext}
                  >
                    <FaCheck />
                  </button>
                </Tippy>
              </div>
            )}
          </div>
        </>
      )}
      {isSessionReady && isSessionComplete && (
        <>
          <div>
            <ResultsPage
              wordsLearned={wordsLearned}
              wordsStillLearning={wordsStillLearning}
            />
          </div>
        </>
      )}
    </>
  );
}

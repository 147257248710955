import React from "react";
import { BiSolidCoffeeBean } from "react-icons/bi";
import moment from "moment";
export default function RewardCard({ reward }: any) {
  return (
    <>
      <div className="white-card-rounded px-4 w-[150px] justify-center transition-transform transform hover:scale-105 relative">
        <img
          src={reward.image}
          alt={reward.name}
          className="w-16 py-4 mx-auto"
        />
        <p className="text-primary font-semibold text-xs text-center">
          {reward.name}
        </p>
        <p className="text-primary-gray text-xxs text-center">
          {reward.description}
        </p>
        <p className="text-primary font-semibold text-xs text-center mt-4 flex justify-center gap-1">
          {}
          <BiSolidCoffeeBean className="text-yellow-500 mt-[3px]" />+
          {reward.cowries}
        </p>
        <div className="flex justify-end min-h-[23px]">
          {reward.count > 1 && (
            <p className="text-white text-xxs font-semibold text-center rounded-lg bg-light-purple-light p-2 absolute bottom-0 left-0">
              x{reward.count}
            </p>
          )}
        </div>
      </div>
      <div className="flex gap-4 mt-2 justify-center">
        {reward.isRead === false && reward.count === 1 && (
          <div className="text-white px-1 rounded-lg bg-green-500 text-xxs text-center w-[50px]">
            New
          </div>
        )}
      </div>
      <p className="text-primary-gray text-xxs text-center mt-1">
        First received:{" "}
        <span className="font-semibold">
          {moment(reward.date).format("MMMM Do YYYY")}
        </span>
      </p>
    </>
  );
}

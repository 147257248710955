import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { VirtualImmersionsProps } from "./types"; // Add missing import statement
import { LessonApi } from "src/api/lesson.api";
import SessionList from "./SessionList";
import SessionFilter from "./SessionFilter";
import { useStateValue } from "src/context/StateProvider";
import LoadingScreen from "src/components/LottieAnimations/LoadingBarScreen";

export default function VirtualImmersions() {
  const { subjectId } = useParams();
  const [{ user }] = useStateValue();
  const [sessions, setSessions] = useState<VirtualImmersionsProps[]>();
  const [enrolledLessons, setEnrolledLessons] = useState<any>();
  const [filteredSessions, setFilteredSessions] =
    useState<VirtualImmersionsProps[]>();

  const getVirtualImmersions = async () => {
    if (!subjectId) return;
    const response = await LessonApi.getVirtualImmersions(subjectId, user._id);
    setSessions(response.virtualImmersions);
    setEnrolledLessons(response.enrolledLessons);
    setFilteredSessions(response.virtualImmersions);
  };

  useEffect(() => {
    getVirtualImmersions();
  }, []);

  if (!filteredSessions) return <LoadingScreen />;

  return (
    <>
      <div className="bg-pale-purple-bg">
        <div className="container">
          <div className="pt-10 pl-4 pb-8">
            <SessionFilter
              sessions={sessions}
              setFilteredSessions={setFilteredSessions}
            />
          </div>
        </div>
      </div>
      <div className="container mt-6 px-8">
        <SessionList
          sessions={filteredSessions}
          enrolledLessons={enrolledLessons}
          getVirtualImmersions={getVirtualImmersions}
        />
      </div>
    </>
  );
}

import { useState } from "react";
import Slider from "src/components/Shared/SliderMenu/Slider";
import PaymentMethod from "./PaymentMethod";
import History from "./History";

export default function StudentPayment() {
  const [selected, setSelected] = useState<number>(0);
  const handleSelect = (id: number) => {
    setSelected(id);
  };
  return (
    <div className="text-primary">
      <h3 className=" font-semibold text-xl">Manage Payment</h3>
      <Slider
        items={["Payment method", "History"]}
        selected={selected}
        setSelected={setSelected}
        handleSelect={handleSelect}
      />
      {selected === 0 ? <PaymentMethod /> : <History />}
    </div>
  );
}

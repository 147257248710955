import { useEffect, useState } from "react";
import { StudentApi } from "src/api/student.api";
import { useStateValue } from "src/context/StateProvider";
import RewardCard from "./RewardCard";
import EmptyList from "src/components/Shared/EmptyList";
import LoadingScreen from "src/components/LottieAnimations/LoadingBarScreen";

export default function Rewards() {
  const [{ user, userNewRewards }] = useStateValue();
  const [rewards, setRewards] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const getRewardsData = async () => {
    setisLoading(true);
    const response = await StudentApi.getLessonRewards(user._id);
    setisLoading(false);
    setRewards(response.data);
    if (userNewRewards && userNewRewards.length > 0) {
      await StudentApi.postMarKLessonRewardsAsRead(user._id);
    }
  };

  useEffect(() => {
    getRewardsData();
  }, [userNewRewards]);

  if (isLoading) return <LoadingScreen />;

  if (!rewards || rewards.length === 0) {
    return <EmptyList message="You have no lesson rewards" />;
  }

  return (
    <>
      <h1 className="text-primary text-center font-semibold text-2xl mb-8">
        Your Lesson Rewards
      </h1>
      <div className="flex gap-4 flex-wrap justify-center">
        {rewards.map((reward: any) => (
          <div key={reward._id}>
            <RewardCard reward={reward} />
          </div>
        ))}
      </div>
    </>
  );
}

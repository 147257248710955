import StepCard from "./StepCard";
import { steps } from "src/static-data/GetStartedSteps";
import { useNavigate } from "react-router-dom";
import { routes } from "src/Routes";
import { useState } from "react";
import { useStateValue } from "src/context/StateProvider";
import LoginModal from "src/components/Shared/LoginModal";
import SignupModal from "src/components/Shared/SignupModal";

export default function GetStarted() {
  const [{ user }] = useStateValue();
  const navigate = useNavigate();
  const [loginIsOpen, setLoginIsOpen] = useState<boolean>(false);
  const [signupIsOpen, setSignupIsOpen] = useState<boolean>(false);

  const handleFindTutorClick = () => {
    // if (user) {
    //   navigate(routes.BOOK_A_LESSON_VIA_ADMIN.url);
    //   return;
    // } else {
    //   setLoginIsOpen(true);
    // }
    navigate(routes.BOOK_A_LESSON_VIA_ADMIN.url);
  };

  return (
    <>
      <div className="container mt-6 pb-24 py-12 flex-col-reverse flex lg:flex-row gap-24 items-center">
        {/* Left Side */}
        <div className="flex flex-col gap-4 justify-center items-center">
          {steps.map((step, index) => (
            <StepCard
              key={index}
              imageSrc={step.imageSrc}
              title={step.title}
              text={step.text}
            />
          ))}
          <button
            type="button"
            className="mt-8  flex lg:hidden w-52 text-center justify-center gap-2 items-center bg-secondary hover:bg-secondary-light hover:shadow-xl text-white rounded-2xl
          px-8 py-3 z-10"
            onClick={handleFindTutorClick}
          >
            GET STARTED
          </button>
        </div>
        {/* Right SIde */}
        <div>
          <h2 className="text-primary font-bold text-3xl mt-4 flex gap-2">
            Get started in 3 easy steps
          </h2>
          <p className="text-primary text-lg mt-8 flex gap-2">
            Start your lessons in 3 simple steps. Select a lesson, book a time
            according to your schedule and start learning!
          </p>
          {/* Button */}
          <button
            type="button"
            className="mt-10 hidden lg:flex justify-between gap-2 items-center bg-secondary hover:bg-secondary-light hover:shadow-xl text-white rounded-2xl
          px-12 py-3 z-10"
            onClick={handleFindTutorClick}
          >
            GET STARTED
          </button>
        </div>
      </div>
      <LoginModal
        loginIsOpen={loginIsOpen}
        setLoginIsOpen={setLoginIsOpen}
        setSignupIsOpen={setSignupIsOpen}
      />
      <SignupModal
        signupIsOpen={signupIsOpen}
        setSignupIsOpen={setSignupIsOpen}
        setLoginIsOpen={setLoginIsOpen}
      />
    </>
  );
}

import { FaRegBell } from "react-icons/fa";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import clsx from "clsx";
import { useStateValue } from "src/context/StateProvider";
import { useNavigate } from "react-router-dom";
import { routes } from "src/Routes";

export default function NotificationIcon() {
  const [{ user, unreadNotifications }] = useStateValue();
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (user.type === "student") {
      navigate(routes.STUDENT_DASHBOARD_NOTIFICATIONS.url);
    }
    if (user.type === "tutor") {
      navigate(routes.TUTOR_DASHBOARD_NOTIFICATIONS.url);
    }
  };

  return (
    <Tippy content="Notifications" delay={1000}>
      <div className="relative cursor-pointer" onClick={handleNavigate}>
        <FaRegBell className="text-primary" size={25} />
        {unreadNotifications && (
          <div
            className={clsx({
              "absolute bg-secondary hover:bg-secondary-light text-xs w-auto px-2.5 py-1 text-white rounded-full flex justify-between -top-3 left-3.5":
                unreadNotifications?.length,
              hidden: !unreadNotifications?.length,
            })}
          >
            {unreadNotifications?.length}
          </div>
        )}
      </div>
    </Tippy>
  );
}

import React from "react";
import { FaCheckCircle } from "react-icons/fa";

export default function PasswordValidation({ passwordCriteria }: any) {
  return (
    <div className="white-card-rounded p-4">
      <div className="flex gap-4 ">
        <div className="mt-1">
          <FaCheckCircle
            className={
              passwordCriteria.length ? "text-green-500" : "text-gray-400"
            }
          />
        </div>
        <div className="text-primary">8 characters minimum</div>
      </div>
      <div className="flex gap-4 mt-2">
        <div className="mt-1">
          <FaCheckCircle
            className={
              passwordCriteria.uppercase ? "text-green-500" : "text-gray-400"
            }
          />
        </div>
        <div className="text-primary">1 capital letter</div>
      </div>
      <div className="flex gap-4 mt-2">
        <div className="mt-1">
          <FaCheckCircle
            className={
              passwordCriteria.lowercase ? "text-green-500" : "text-gray-400"
            }
          />
        </div>
        <div className="text-primary">1 lowercase letter</div>
      </div>
      <div className="flex gap-4 mt-2">
        <div className="mt-1">
          <FaCheckCircle
            className={
              passwordCriteria.digit ? "text-green-500" : "text-gray-400"
            }
          />
        </div>
        <div className="text-primary">1 numerical character</div>
      </div>
      <div className="flex gap-4 mt-2">
        <div className="mt-1">
          <FaCheckCircle
            className={
              passwordCriteria.specialChar ? "text-green-500" : "text-gray-400"
            }
          />
        </div>
        <div className="text-primary">1 special character</div>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { routes } from "src/Routes";

import DependantResources from "./DependantResources";
import MyResources from "./MyResources";

import { IDependantInfo } from "src/interfaces/student";
import { LuBrain } from "react-icons/lu";

interface IParentResources {
  dependants: [];
}
export default function ParentResources({ dependants }: IParentResources) {
  const location = useLocation();
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  useEffect(() => {
    if (
      location.pathname.includes(routes.STUDENT_DASHBOARD_LEARN.url) ||
      location.pathname.includes(routes.DEPENDANT_DASHBOARD_LEARN.url)
    ) {
      setShowProfileMenu(true);
    } else {
      setShowProfileMenu(false);
    }
  }, [location.pathname]);

  return (
    <>
      <div
        className={clsx("parent-menu-inactive", {
          "parent-menu-active":
            location.pathname.includes(routes.STUDENT_DASHBOARD_LEARN.url) ||
            location.pathname.includes(routes.DEPENDANT_DASHBOARD_LEARN.url),
        })}
        onClick={toggleProfileMenu}
      >
        <div className="flex gap-4">
          <div>
            <LuBrain className="text-lg" />
          </div>
          <div>{routes.STUDENT_DASHBOARD_LEARN.label}</div>
        </div>
        <div>
          <div
            className={clsx({
              hidden: !showProfileMenu,
              block: showProfileMenu,
            })}
          >
            <BiChevronUp size={23} />
          </div>
          <div
            className={clsx({
              block: !showProfileMenu,
              hidden: showProfileMenu,
            })}
          >
            <BiChevronDown size={23} />
          </div>
        </div>
      </div>
      {/* Sub Menu */}
      <div
        className={clsx({
          hidden: !showProfileMenu,
          "block bg-gray-50 rounded-lg py-4 mb-2": showProfileMenu,
        })}
      >
        <ul>
          <li>
            <MyResources />
          </li>
          {dependants.map((dependant: IDependantInfo) => (
            <li key={dependant._id}>
              <DependantResources dependant={dependant} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

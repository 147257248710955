export const igboGamesData = [

    {
        id: 2,
        title: "Who's Lying?",
        topic: 'Introduction',
        ageGroup: 'Child',
        image: 'https://cdn.topset.app/topset_greetings_2.png',
        projectId: '1009035567',
    },
    {
        id: 1,
        title: 'Ezinaụlọ',
        topic: 'Core Family',
        ageGroup: 'Child',
        image: 'https://cdn.topset.app/ezinaulo.png',
        projectId: '1003266559',
    },
    {
        id: 3,
        title: 'TopSet Cafe',
        topic: 'Greetings',
        ageGroup: 'Child',
        image: 'https://cdn.topset.app/topset-cafe.png',
        projectId: '1040299434',
    },
];

export const yorubaGamesData = [
    {
        id: 1,
        title: "Mr. Taiwo's Adventure",
        topic: 'Introduction & Emotions',
        ageGroup: 'Child',
        image: 'https://cdn.topset.app/new_game.png',
        projectId: '1031817508',
    },
    {
        id: 2,
        title: 'Ẹbi Mi',
        topic: 'Core Family',
        ageGroup: 'Child',
        image: 'https://cdn.topset.app/ebi_mi.png',
        projectId: '1002973465',
    },
    {
        id: 3,
        title: "Who's Lying?",
        topic: 'Greetings',
        ageGroup: 'Child',
        image: 'https://cdn.topset.app/green_students.png',
        projectId: '1004670986',
    },
    {
        id: 4,
        title: "The Battle Game",
        topic: 'Introductions',
        ageGroup: 'Child',
        image: 'https://cdn.topset.app/the-battle-game.png',
        projectId: '1036871795',
    },


];
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { StateProvider } from "./context/StateProvider";
import reducer, { initialState } from "./context/reducer";
import { Provider as DirectPayProvider } from "./context/DirectPayContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "https://3b481887525cf64030577adc4ea2ddc9@o4507362394308608.ingest.de.sentry.io/4507367675854928",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
      <DirectPayProvider>
        <GoogleOAuthProvider clientId="88724718691-770dds4t07vg8sqht6k7nbmj25dr6qr5.apps.googleusercontent.com">
          <App />
        </GoogleOAuthProvider>
      </DirectPayProvider>
    </StateProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

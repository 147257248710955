interface ITutor {
  tutor: any;
}
export default function Tutor({ tutor }: ITutor) {
  return (
    <div className="h-full flex items-center border-gray-200 border p-2 rounded-lg mt-2">
      <img
        alt="team"
        className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
        src={tutor?.avatarUrl}
      />
      <div className="flex-grow">
        <h2 className="text-gray-900 title-font font-medium">
          {tutor?.firstName} {tutor?.lastName?.charAt(0)}
        </h2>
        <p className="text-gray-500">{tutor?.subjectName}</p>
      </div>
    </div>
  );
}

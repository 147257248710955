import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import FadeInDown from "src/components/MotionDivs/FadeInDown";
import "./videoModal.css";
export interface ITestimonials {
  videoUrl: string;
  name: string;
  thumbnail?: string;
}
type TestimonialModalProps = {
  isVideoModalOpen: boolean;
  setisVideoModalOpen: any;
  videoDetails: ITestimonials;
};

export default function TestimonialPlayerModal({
  videoDetails,
  isVideoModalOpen,
  setisVideoModalOpen,
}: TestimonialModalProps) {
  if (!isVideoModalOpen) return null;

  return (
    <div className=" videoModalOverlay">
      <FadeInDown>
        <div className=" p-4 rounded-md ">
          <div className="absolute right-5 top-5">
            <AiOutlineClose
              className="text-white hover:text-red-400 text-2xl font-semibold cursor-pointer"
              onClick={() => setisVideoModalOpen(false)}
            />
          </div>

          <div className="relative max-h-fit bg-black overflow-hidden w-full  md:w-[800px] mx-auto rounded-3xl flex  justify-center">
            <video
              src={videoDetails.videoUrl}
              controls
              autoPlay
              className="h-96"
            ></video>
          </div>

          <p className="text-2xl font-semibold text-left text-white mt-2">
            {videoDetails.name}
          </p>
        </div>
      </FadeInDown>
    </div>
  );
}

export const findingTutorMessages = [
    "Sifting through our experts to find your learning match...",
    "Tuning into your learning style...",
    "Gathering the best brains to help you shine...",
    "Brushing off the chalkboard for your personalised sessions...",
    "Aligning the stars for your language learning journey...",
    "Tailoring a learning experience just for you...",
    "Fetching the keys to unlock your potential...",
    "Packing a toolkit for your learning adventure...",
    "Assembling a constellation of knowledge for you...",
    "Laying the groundwork for your academic ascent...",
    "Plotting the coordinates for your learning expedition...",
    "Fine-tuning the orchestra of intellect...",
    "Concocting the perfect formula for your learning...",
    "Weaving the threads of understanding and curiosity...",
];

export const prepDashboardMessages = [
    "Unlocking the secrets of ancient scripts for you...",
    "Tuning the instruments for a symphony of words...",
    "Harvesting the fruits of linguistic wisdom...",
    "Navigating the rivers of communication and culture...",
    "Painting your path with the colors of local expressions...",
    "Carving out a space where language meets soul...",
    "Gathering the sparks of understanding to light up conversation...",
    "Weaving a tapestry of phrases that connect worlds...",
    "Planting seeds of knowledge for a garden of dialogue...",
    "Crafting keys to unlock the doors of verbal expression..."
]
import { useEffect } from "react";
import ReactDom from "react-dom";
import "./LoginModal.css";
import { motion } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";
import { useStateValue } from "src/context/StateProvider";
import SignupForm from "src/views/Authentication/Signup/Student/StudentEmailSignup/StudentEmailSignupForm";
import SocialButtons from "src/views/Authentication/Login/SocialButtons";

export default function SignupModal({
  signupIsOpen,
  setSignupIsOpen,
  setLoginIsOpen,
}) {
  const [{ user }] = useStateValue();

  useEffect(() => {
    if (user) {
      setSignupIsOpen(false);
    }
  }, [user]);

  const handleOpenLogin = () => {
    setLoginIsOpen(true);
    setSignupIsOpen(false);
  };

  if (!signupIsOpen) return null;

  return ReactDom.createPortal(
    <div className="modalOverlay">
      <motion.div
        className="bg-white rounded-lg shadow-xl"
        initial={{ y: -150 }}
        animate={{ y: 0 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
          duration: 0.5,
        }}
      >
        <div className="flex justify-between items-center mb-4 pl-8">
          <AiOutlineClose size={25} className="text-white" />
          <div className="flex flex-col items-center mt-6">
            <h2 className="text-primary font-bold md:text-lg">
              Signup to Book a Lesson
            </h2>
            <h2 className="text-primary md:text-xs mt-1">
              Already have an account?{" "}
              <span
                className="text-secondary cursor-pointer"
                onClick={handleOpenLogin}
              >
                Log in
              </span>
            </h2>
          </div>

          <div
            className="px-4 py-4 flex justify-end cursor-pointer text-gray-600 hover:text-red-500"
            onClick={() => setSignupIsOpen(false)}
          >
            <AiOutlineClose size={25} />
          </div>
        </div>
        <div className="px-8 pb-6 w-[27rem]">
          <SignupForm />
          {/* <div className="mt-3">
            <SocialButtons />
          </div> */}
        </div>
      </motion.div>
    </div>,
    document.getElementById("portal")
  );
}
